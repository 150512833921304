import { checkIsStringContainsSymbols, checkIsStringContainsSpacing, REGEX_WITH_SYMBOLS } from 'helpers/search';
import storage from '../helpers/storage';

const SERVER_URL_KEY = 'stored-server-url';

export const API_POSTFIX = '/cms_api/v1.0';

export const getBaseUrl = () => (storage.sessionLoad(SERVER_URL_KEY) || '') + API_POSTFIX;

export const getDownloadAttachmentLink = (id: string) => `${getBaseUrl()}/multimedia/attachment/${id}`;
const prepareQueryForSearch = (str: string) => {
  let searchQuery = `*${str}*`;

  if (checkIsStringContainsSpacing(str)) {
    searchQuery = `${str}*`;
  }

  if (checkIsStringContainsSymbols(str)) {
    searchQuery = `${str.replace(REGEX_WITH_SYMBOLS, ' ')}*`;
  }
  return searchQuery;
};

// Generic method used to fetch JSON-data from the API.
const fetchJSONData = async (filters: any, sortBy?: string) => {
  try {
    const response = await fetch(`${getBaseUrl()}/search/json`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filters: filters,
        sort: sortBy || 'artifact_name asc',
      }),
    });

    if (!response.ok) throw new Error('Failed to fetch JSON');

    const json = await response.json();
    return json.artifacts;
  } catch (e) {
    // pass
  }
};

// FETCH COLLECTIONS
export const fetchCollections = async () => {
  // collections: ct_31
  return await fetchJSONData({
    object_type: 'ct_31',
    '-marked': true,
  });
};

// FETCH REG. LEVELS
export const fetchRegLevels = async () => {
  // reg. levels: ct_0
  return await fetchJSONData({
    object_type: 'ct_0',
    '-marked': true,
    filter_field_id: 'ct_84-3',
  });
};

// FETCH PRIORITIES (DEVIATION)
export const fetchPriorities = async () => {
  // priorities: ct_69
  return await fetchJSONData({
    object_type: 'ct_69',
    '-marked': true,
  });
};

// FETCH BUILDING DEVIATION TYPES
export const fetchBuildingDeviationTypes = async () => {
  // reg. levels: ct_52, filter.field_id=ct_84-3
  return await fetchJSONData({
    object_type: 'ct_52',
    filter_field_id: 'ct_84-3',
    '-marked': true,
  });
};

// FETCH CAUSE OF EVALUATION / PURPOSE FOR BUILDINGS
export const fetchCauseOfEvaluationTypes = async () => {
  // cause of evaluation types: ct_50, filter.adm_event_type_id: ct_49-13
  return await fetchJSONData({
    object_type: 'ct_50',
    'filters.adm_event_type_id': 'ct_49-13',
    '-marked': true,
  });
};

// FETCH STATUSES
export const fetchStatuses = async () => {
  // statuses: ct_53
  return await fetchJSONData({
    object_type: 'ct_53',
    adm_filter_field_id: 'ct_49-19',
    '-marked': true,
  });
};

// FETCH MEASURES
export const fetchMeasures = async () => {
  // measures: ct_89
  return await fetchJSONData({
    object_type: 'ct_89',
    '-marked': true,
  });
};

// FETCH FILTER VALUES
export const fetchFilterValues = async (artifact_ids: string[]) => {
  //
  // artifact_ids: Represents the selected object type, used to retrieve only relevant filter-values for this type.
  //

  const collections = await fetchCollections();
  const regLevels = await fetchRegLevels();
  const priorities = await fetchPriorities();
  const buildingDeviationTypes = await fetchBuildingDeviationTypes();
  const causeOfEvaluationTypes = await fetchCauseOfEvaluationTypes();
  const statuses = await fetchStatuses();
  const measures = await fetchMeasures();

  const filterOptionsMock = {
    samling: getArtifactNames(collections),
    objekttype: getArtifactNames(regLevels),
    tilstandsklasse: getArtifactNames(measures),
    formal: getArtifactNames(causeOfEvaluationTypes),
    prioritet: getArtifactNames(priorities),
    status: getArtifactNames(statuses),
    type: getArtifactNames(buildingDeviationTypes),
    harMarkeringer: [
      { id: 'ja', name: 'Ja' },
      { id: 'nei', name: 'Nei' },
    ],
  };

  return filterOptionsMock;

  function getArtifactNameAndID(obj: any) {
    if (obj.authority && obj.authority === 'KulturNav') {
      return { name: `${obj.artifact_name} (K)`, id: obj.artifact_id };
    } else {
      return { name: obj.artifact_name, id: obj.artifact_id };
    }
  }

  function getArtifactNames(arr: []) {
    const output = [];
    for (let i = 0, max = arr.length; i < max; i++) {
      output.push(getArtifactNameAndID(arr[i]));
    }
    return output;
  }
};

// FETCH MODELS
export const fetchModels = async () => {
  try {
    const modelsResponse = await fetch(`${getBaseUrl()}/meta/models`, {
      credentials: 'include',
    });
    if (!modelsResponse.ok) throw new Error('something went wrong!');
    return await modelsResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH BUILDING INFO
export const fetchBuildingInfo = async (id: string) => {
  const infoResponse = await fetch(`${getBaseUrl()}/artifact/get/${id}`, {
    credentials: 'include',
  });
  if (!infoResponse.ok) throw new Error('something went wrong!');
  return await infoResponse.json();
};
// FETCH BUILDING EVENTS
export const fetchBuildingEvents = async (id: string) => {
  // fetch events without tasks
  const eventsResponse = await fetch(`${getBaseUrl()}/search/json`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filters: {
        'contexts.context_artifact_id': id,
        object_type: ['BuildingConditionAssessmentEvent', 'BuildingObservationEvent', 'BuildingTreatmentEvent'],
        '-object_type': 'building_part',
      },
      sort: 'created_at desc',
      include_meta: true,
    }),
  });
  if (!eventsResponse.ok) throw new Error('something went wrong!');
  const events = await eventsResponse.json();

  // fetch tasks
  const tasksResponse = await fetch(`${getBaseUrl()}/search/json`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filters: {
        'contexts.context_artifact_id': id,
        object_type: ['task'],
        '-object_type': 'building_part',
      },
      sort: 'created_at desc',
      include_meta: true,
    }),
  });
  if (!eventsResponse.ok) throw new Error('something went wrong!');
  const tasks = await tasksResponse.json();

  return [...events.artifacts, ...tasks.artifacts];
};

// FETCH EVENT BY ID
export const fetchBuildingEvent = async (id: string) => {
  const response = await fetch(`${getBaseUrl()}/artifact/get/${id}`, {
    credentials: 'include',
  });
  if (!response.ok) throw new Error('something went wrong!');
  return await response.json();
};

// FETCH LAST UPDATED EVENTS
export const fetchLastUpdatedObjects = async () => {
  try {
    const response = await fetch(`${getBaseUrl()}/search/json`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        filters: {
          object_type: ['BuildingConditionAssessmentEvent', 'BuildingObservationEvent', 'BuildingTreatmentEvent'],
        },
        query: '*',
        rows: 1000,
        start: 0,
        sort: 'updated_at desc',
      }),
    });
    if (!response.ok) throw new Error('something went wrong!');
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH SEARCHED OBJECTS
export const fetchSearchedObjects = async (query: string) => {
  try {
    const searchQuery = prepareQueryForSearch(query);

    const response = await fetch(`${getBaseUrl()}/search/json`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filters: {
          object_type: [
            'building',
            'BuildingConditionAssessmentEvent',
            'BuildingObservationEvent',
            'BuildingTreatmentEvent',
            'task',
          ],
        },
        query: searchQuery,
        rows: 1000,
        start: 0,
        sort: 'updated_at desc',
      }),
    });
    if (!response.ok) throw new Error('something went wrong!');
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH FAVORITE BUILDINGS
export const fetchFavoriteBuildings = async (ids: string[]) => {
  try {
    const response = await fetch(`${getBaseUrl()}/search/json`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        filters: {
          object_type: 'building',
          artifact_id: ids && ids.length > 0 ? [...ids] : '',
        },
        query: '*',
        rows: 1000,
        start: 0,
        sort: 'updated_at desc',
      }),
    });
    if (!response.ok) throw new Error('something went wrong!');
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// POST DOCUMENT
export const postDocument = async (document: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/artifact/put`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(document),
    });
    if (!postResponse.ok) throw new Error('something went wrong!');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// DELETE DOCUMENT
export const deleteDocument = async (id: string) => {
  try {
    const deleteResponse = await fetch(`${getBaseUrl()}/artifact/delete/${id}`, {
      credentials: 'include',
      method: 'DELETE',
    });
    if (!deleteResponse.ok) throw new Error('something went wrong!');
    return await deleteResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// POST ANNOTATION EVENT
export const postAnnotationEvent = async (annotation: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/artifact/put`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(annotation),
    });
    if (!postResponse.ok) throw new Error('something went wrong!');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// UPLOAD IMAGE
export const uploadImage = async (imageData: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/multimedia/image/upload`, {
      credentials: 'include',
      method: 'POST',
      body: imageData,
    });
    if (!postResponse.ok) throw new Error('something went wrong');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// POST IMAGE
export const postImage = async (image: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/artifact/sub_artifact`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(image),
    });
    if (!postResponse.ok) throw new Error('something went wrong');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH REPORT IMAGES DATA
export const fetchReportImagesData = async (imagesId: string[]) => {
  try {
    const response = await fetch(`${getBaseUrl()}/search/json`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filters: {
          artifact_id: [...imagesId],
          object_type: 'Image',
          '-object_type': ['building_part', 'artcraft', 'archaeology_sample'],
        },
        sort: 'updated_at desc',
      }),
    });
    if (!response.ok) throw new Error('something went wrong!');
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// LOGIN
export const login = async (token: string, server: string) => {
  try {
    const response = await fetch(`${server}${API_POSTFIX}/login`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
    });
    if (!response.ok) throw new Error('error while login!');
    storage.sessionSave(SERVER_URL_KEY, server);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH USER DATA
export const fetchUserData = async () => {
  try {
    const userResponse = await fetch(`${getBaseUrl()}/setting/user_data`, {
      credentials: 'include',
    });
    if (!userResponse.ok) throw new Error('something went wrong!');
    return await userResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH USER DATA DETAILED
export const fetchUserDataDetailed = async (userId: string) => {
  try {
    const userDataResponse = await fetch(
      `${getBaseUrl()}/artifact/get/${userId}?artifact_id=${userId}&include_meta=true`,
      {
        credentials: 'include',
      },
    );
    if (!userDataResponse.ok) throw new Error('something went wrong!');
    return await userDataResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// LOGOUT
export const logout = async () => {
  try {
    const response = await fetch(`${getBaseUrl()}/logout`, {
      credentials: 'include',
    });
    if (!response.ok) throw new Error('error while logout!');
    storage.sessionRemove(SERVER_URL_KEY);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH ATTACHMENTS DATA
export const fetchAttachmentsData = async (ids: string[]) => {
  try {
    const response = await fetch(`${getBaseUrl()}/search/json`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filters: {
          artifact_id: [...ids],
          object_type: 'Attachment',
          '-object_type': ['building_part', 'artcraft', 'archaeology_sample'],
        },
        sort: 'artifact_name',
      }),
    });
    if (!response.ok) throw new Error('something went wrong!');
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH VIDEOS DATA
export const fetchVideosData = async (ids: string[]) => {
  try {
    const response = await fetch(`${getBaseUrl()}/search/json`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filters: {
          artifact_id: [...ids],
          object_type: 'Video',
          '-object_type': ['building_part', 'artcraft', 'archaeology_sample'],
        },
        sort: 'artifact_name',
      }),
    });
    if (!response.ok) throw new Error('something went wrong!');
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

// UPLOAD FILE
export const uploadFile = async (fileData: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/multimedia/attachment/upload`, {
      credentials: 'include',
      method: 'POST',
      body: fileData,
    });
    if (!postResponse.ok) throw new Error('something went wrong');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// POST FILE
export const postFile = async (file: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/artifact/sub_artifact`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(file),
    });
    if (!postResponse.ok) throw new Error('something went wrong');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// UPLOAD VIDEO
export const uploadVideo = async (videoData: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/multimedia/video/upload`, {
      credentials: 'include',
      method: 'POST',
      body: videoData,
    });
    if (!postResponse.ok) throw new Error('something went wrong');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// POST VIDEO
export const postVideo = async (video: any) => {
  try {
    const postResponse = await fetch(`${getBaseUrl()}/artifact/sub_artifact`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(video),
    });
    if (!postResponse.ok) throw new Error('something went wrong');
    return await postResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// DELETE OBJECT BY ARTIFACT ID
export const deleteObjectById = async (id: string) => {
  try {
    const deleteResponse = await fetch(`${getBaseUrl()}/artifact/delete/${id}`, {
      credentials: 'include',
      method: 'DELETE',
    });
    if (!deleteResponse.ok) throw new Error('something went wrong!');
    return await deleteResponse.json();
  } catch (error) {
    console.log(error);
  }
};

// FETCH VIDEO CONTENT BY ID
export const fetchVideoContent = async (videoId: string) => {
  try {
    const videoResponse = await fetch(`${getBaseUrl()}/multimedia/video/playback/${videoId}?videoId=${videoId}`, {
      credentials: 'include',
    });
    if (!videoResponse.ok) throw new Error('something went wrong!');
    return await videoResponse.json();
  } catch (error) {
    console.log(error);
  }
};
