import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, Box, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// types
import { BuildingType } from 'types/Building/BuildingType';

// helpers
import { getCurrentIcon } from 'helpers/getCurrentIcon';

import Gallery from './Gallery';

// interfaces & types
interface Props {
  building: BuildingType;
  // annotations: any[];
  handleImageClick: (id: string) => void;
}

const InfoSection = (props: Props) => {
  const theme = useTheme();
  return (
    <Paper square elevation={4} style={{ backgroundColor: theme.palette.secondary.greyLight }}>
      <Gallery images={props.building.images} handleImageClick={props.handleImageClick} />
      <StyledSection component="section" backgroundColor={theme.palette.secondary.greyLight}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={3} container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">Registreringsnivå</Typography>
              <Typography>{props.building?.reg_level?.reg_level_type_id_value ?? '-'}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">Samling</Typography>
              <Typography>{props.building?.collection_id_value ?? '-'}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={4} md={3} container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">Overordnet tiltaksklasse</Typography>
              <Typography>
                {props.building?.measure_class_type ? getCurrentIcon(props.building.measure_class_type) : '-'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">Vernetype</Typography>
              <Typography>{props.building?.protection_type?.protection_type_id_value ?? '-'}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={4} md={3} container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">Bygningstype</Typography>
              <Typography>{props.building?.building_type?.building_type_id_value ?? '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </StyledSection>
    </Paper>
  );
};

InfoSection.defaultProps = {
  building: {
    identifier: '',
    collection_id_value: '',
    measure_class_type: '',
    protection_type: { protection_type_id_value: '' },
    hazards: [],
    images: [],
  },
};

const StyledSection = styled(({ backgroundColor, ...props }) => <Box {...props} />)`
  padding: 20px 20px 40px;
  background-color: ${props => props.backgroundColor || '#ffffff'};
  @media (min-width: 768px) {
    padding: 10px 18px 40px 18px;
  }
`;

export default InfoSection;
