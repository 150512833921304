import React from 'react';
import styled from 'styled-components';
import { Grid, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, FormHelperText } from '@material-ui/core';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import Datepicker from '../../shared/Datepicker/Datepicker';

interface Props {
  deviationReason: string;
  handleDeviationReasonChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  occureDate: Date | null;
  isOccureDatePickerOpen: boolean;
  backgroundShowStatus: string;
  handleOccureDateChange: (date: Date | null) => void;
  handleOpenOccureDatePicker: () => void;
  handleCloseOccureDatePicker: () => void;
  handleBackgroundShowStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabledConditions: boolean;
}

const DeviationBackgroundForm = (props: Props) => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <FormControl component="fieldset" disabled={props.disabledConditions}>
          <FormLabel component="legend" style={{ marginBottom: 8 }}>
            Ved du når eller hvorfor avviket oppstod?
          </FormLabel>
          <RadioGroup
            name="avvik-type"
            value={props.backgroundShowStatus}
            onChange={props.handleBackgroundShowStatusChange}
            row
          >
            <StyledFormControlLabel value="yes" control={<Radio color="primary" />} label="Ja" />
            <StyledFormControlLabel value="no" control={<Radio color="primary" />} label="Nei" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {props.backgroundShowStatus === 'no' ? null : (
        <>
          <Grid item>
            <FormTextArea
              value={props.deviationReason}
              handleChange={props.handleDeviationReasonChange}
              label="Beskrivelse av årsak"
              disabled={props.disabledConditions}
            />
            <FormHelperText>Hva har skjedd? Hvorfor?</FormHelperText>
          </Grid>
          <Grid item>
            <Datepicker
              value={props.occureDate}
              handleChange={props.handleOccureDateChange}
              open={props.isOccureDatePickerOpen}
              onOpen={props.handleOpenOccureDatePicker}
              onClose={props.handleCloseOccureDatePicker}
              label="Inntraff dato"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: #000000;
  }
`;

export default DeviationBackgroundForm;
