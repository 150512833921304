import React, { useState } from 'react';
import uuid from 'uuid/v4';
import styled from 'styled-components';
import { Grid, Paper, Typography, FormControlLabel, Checkbox, Box } from '@material-ui/core';

import FormInput from '../../shared/FormInput/FormInput';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';

import { ReactComponent as CheckMarkDarkIcon } from 'assets/icons/CheckMark.svg';

interface Props {
  onSaveChecklistPanel: (item: any) => void;
  handleCloseChecklistPanel: () => void;
  drag: any;
  isEditing: boolean;
  dragsLength: number;
}

const TaskChecklistPanel: React.FC<Props> = props => {
  // state
  const [task, setTask] = useState(props.drag.checklist_description || '');
  const [completed, setCompleted] = useState(!props.drag.completed ? false : true);

  // handlers
  const handleTaskChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTask(event.target.value as string);
  };
  const handleCompletedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompleted(event.target.checked);
  };
  const handleSaveComment = () => {
    if (task === '') return;

    let newTask: any = {};
    if (props.isEditing) {
      newTask = {
        ...props.drag,
        model_title: null,
        artifact_name_upper: null,
        completed: completed || null,
        checklist_description: task,
        _update: true,
      };
    } else {
      newTask = {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'TaskChecklistItem',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        no: null,
        object_type_id: null,
        order_number: props.dragsLength,
        completed: completed || null,
        checklist_description: task,
        uuid: uuid(),
        _create: true,
      };
    }

    props.onSaveChecklistPanel(newTask);
    props.handleCloseChecklistPanel();
  };

  return (
    <StyledFormWrapper>
      <Typography variant="h4" style={{ paddingBottom: 28 }}>
        {task || 'Uten tittel'}
      </Typography>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <FormInput value={task} handleChange={handleTaskChange} label="Tittel" />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={completed} onChange={handleCompletedChange} name="completed" />}
            label="Marker som utført"
          />
        </Grid>

        <Grid item>
          <OutlineButton padding="16px 30px" onClick={handleSaveComment}>
            <StyledBox>
              <CheckMarkDarkIcon style={{ marginRight: 4 }} />
            </StyledBox>
            Lagre og lukk
          </OutlineButton>
        </Grid>
      </Grid>
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled(Paper)`
  &.MuiPaper-root {
    padding: 18px;
    background-color: #ffffff;
  }

  @media (max-width: 767px) {
    &.MuiPaper-root {
      margin-bottom: 56px;
    }
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default TaskChecklistPanel;
