import { createMuiTheme } from '@material-ui/core/styles';

const snowWhite = '#fff';
const greyWhite = '#eee';
const blue = '#4299F9';
const primary = '#3154EA';
const greyCollapsed = '#414552';
const greyExpanded = '#dddddd';
const darkGreyCollapsed = '#424242';
const greyCharcoal = '#303030';
const black = '#000000';
const darkLabel = '#999999';
const darkPrimary = '#2c2f38';
const greyLight = '#f5f5f5';
const darkWhite = '#fafafa';

const palette = {
  primary: {
    main: primary,
    darken: primary,
  },
  secondary: {
    darkPrimary,
    blue: blue,
    white: snowWhite,
    greyWhite: greyWhite,
    main: darkPrimary,
    dark: greyCollapsed,
    darkGrey: darkGreyCollapsed,
    darkLabel: darkLabel,
    black: black,
    greyCharcoal: greyCharcoal,
    greyLight,
    darkWhite,
    greyExpanded,
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 543,
    md: 706,
    lg: 1200,
    xl: 1400,
  },
};

const typography = {
  useNextVariants: true /* [1] */,
  fontFamily: '"Akkurat", sans-serif',
  h3: {
    fontWeight: 700,
    fontSize: '18px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '1.125rem',
  },
  h5: {
    fontWeight: 400,
    fontSize: '18px',
  },
  h6: {
    fontWeight: 400,
    fontSize: '16px',
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: '0.75rem',
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: '0.85rem',
  },
};

export interface StyleClosetTheme {
  palette: typeof palette;
  typography: typeof typography;
}

const theme = createMuiTheme({ palette, breakpoints, typography });

export default theme;

declare module '@material-ui/styles/defaultTheme' {
  interface CustomTheme {
    palette: typeof palette;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends CustomTheme {}
}
