import React from 'react';
import styled from 'styled-components';
import { Grid, Box } from '@material-ui/core';
import queryString from 'query-string';

import { Link, useLocation } from 'react-router-dom';

import { getDocumentRoutingName } from 'helpers/getDocumentRoutingName';

import HomeEventListItem from 'components/HomeEventListItem/HomeEventListItem';

interface Props {
  conditions: any[];
}

const SearchConditionsList = (props: Props) => {
  const location = useLocation();
  const searchQuery = queryString.parse(location.search).query || '';

  return (
    <StyledListWrap>
      <Grid container>
        {props.conditions.map(condition => {
          const pathLink = getDocumentRoutingName(condition.artifact_type_id_value)
            ? `/building/${condition['contexts.context_artifact_id'][0]}/${getDocumentRoutingName(
                condition.artifact_type_id_value,
              )}/${condition.artifact_id || condition.uuid}`
            : '/';

          return (
            <Grid item xs={12} key={condition.artifact_id}>
              <Link
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                to={{
                  pathname: pathLink,
                  state: { fromSearch: true, query: searchQuery },
                }}
              >
                <HomeEventListItem object={condition} />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </StyledListWrap>
  );
};

const StyledListWrap = styled(Box)`
  background-color: #ffffff;
`;

export default SearchConditionsList;
