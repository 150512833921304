import React from 'react';
import styled from 'styled-components';

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';

// icons
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  handleNavigationOptionChange: (event: React.ChangeEvent<{}>, value: any) => void;
  navigationOption: string;
  isMenuOpen: boolean;
}

const BottomAppNavigation = ({ navigationOption, handleNavigationOptionChange, isMenuOpen }: Props) => {
  return (
    <StyledBottomNavigation
      value={navigationOption}
      onChange={handleNavigationOptionChange}
      showLabels
      isMenuOpen={isMenuOpen}
    >
      <StyledBottomNavigationAction showLabel label="Favoritter" value="favorites" icon={<StarIcon />} />
      <StyledBottomNavigationAction showLabel label="Siste" value="search" icon={<SearchIcon />} />
      <StyledBottomNavigationAction showLabel label="Under arbeid" value="edit" icon={<EditIcon />} />
    </StyledBottomNavigation>
  );
};

const StyledBottomNavigation = styled(({ isMenuOpen, ...props }) => <BottomNavigation {...props} />)`
  &.MuiBottomNavigation-root {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #8aa7f7;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);

    ${(props: { isMenuOpen: boolean }) =>
      props.isMenuOpen
        ? `
        transform : translateX(85vw);
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      `
        : `transform : translateX(0);
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    `};

    @media (min-width: 768px) {
      ${(props: { isMenuOpen: boolean }) =>
        props.isMenuOpen
          ? `
        transform : translateX(320px);
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      `
          : `transform : translateX(0);
          transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      `};
    }
  }
`;
const StyledBottomNavigationAction = styled(props => <BottomNavigationAction {...props} />)`
  &.MuiBottomNavigationAction-root.Mui-selected {
    color: #000000;
  }
  & .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.75rem;
  }
  & .MuiBottomNavigationAction-label {
    margin-top: 2px;
  }
`;

export default BottomAppNavigation;
