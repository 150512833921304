export const eventsMock: any = {
  'AE_17-135972': {
    artifact_id: 'AE_17-135972',
    identifier: '135972',
    uuid: 'e6f53c6d-36bc-4899-b211-fc0ab65505f0',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1464947525000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-6587',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80566',
        context_identifier: 'RFH6.1.1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 163631,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-135972',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.1.1.11: V\u00e5ningshus Kolbeinstveit',
        checksum: 1424760603,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 163631,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-135972',
      priority_type_id_value: 'Lav',
      checksum: 2359365313,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-37739',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-135972',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 825626001,
        name: 'RFF1998-KRY-531.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-37739',
    updated_by_id: 'USER-6587',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1464947525000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1464967800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3569684505,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1464967800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3569684505,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-135972',
      revision_id: 163631,
      checksum: 3147437195,
    },
    status: {
      status_type_id: 'ct_53-1803',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-135972',
      revision_id: 230450,
      status_type_id_value: 'Avsluttet',
      checksum: 2068124413,
    },
    damage: {
      artifact_id: 'DMG-135973',
      context_id: '3-80566',
      identifier: 135973,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-135972',
      description: {
        damage_description:
          'Hovudd\u00f8ra treng ein refresh. Sm\u00f8rjast med linolje for \u00e5 friska opp fargen.\nEi rute i feltet over hovudd\u00f8ra er knust og m\u00e5 skiftast!',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-135973',
        revision_id: 230450,
        checksum: 2973582609,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-135973',
        revision_id: 230450,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1523192302,
      },
      position: {
        damage_position: 'Hovudd\u00f8r (mot s\u00f8r)',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-135973',
        revision_id: 163631,
        checksum: 671618836,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 220992476,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 135972',
    checksum: 322449460,
    $$acl: { restrictions: [] },
    $$context_name: 'Bygning: RFH6.1.1.11: V\u00e5ningshus Kolbeinstveit',
  },

  'AE_17-131880': {
    artifact_id: 'AE_17-131880',
    identifier: '131880',
    uuid: 'a2f08847-c760-4e31-8476-62f5ccda07d3',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450686098000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80569',
        context_identifier: 'RFH6.1.1.14',
        context_collection_id: 'ct_31-1',
        revision_id: 147131,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-131880',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
        checksum: 1419190040,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 147131,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-131880',
      priority_type_id_value: 'Lav',
      checksum: 2341408440,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35778',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-131880',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 811404684,
        name: 'DSCN0211.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35778',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450686098000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-131881'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450711800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559788551,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450711800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559788551,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-131880',
      revision_id: 147131,
      checksum: 3124368514,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-131880',
      revision_id: 147131,
      status_type_id_value: 'Ny',
      checksum: 2044269304,
    },
    damage: {
      artifact_id: 'DMG-131882',
      context_id: '3-80569',
      identifier: 131882,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-131880',
      description: {
        damage_description:
          'Rekonstruere inventar. Renske opp og rydde. Setta i stand belg, esse og øvrig interiør. Sikre bygningen mot innblåsing av lauv. Hogge skog rundt huset.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-131882',
        revision_id: 147131,
        checksum: 4156901847,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-131882',
        revision_id: 147131,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1513165292,
      },
      position: {
        damage_position: 'Inne',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-131882',
        revision_id: 147131,
        checksum: 3052212222,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 201003983,
    },
    is_current: false,
    annotation_events: [
      {
        artifact_id: 'AE_21-131881',
        identifier: '131881',
        uuid: '51c9d3a3-f5eb-4e0f-89f8-dc2f959a266f',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450686098000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-131880',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80569',
            context_identifier: 'RFH6.1.1.14',
            context_collection_id: 'ct_31-1',
            revision_id: 147131,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-131881',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
            checksum: 1391533844,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-131881',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450686098000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 131880',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450682474000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3565096981,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450682474000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3565096981,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-131881',
          revision_id: 147131,
          checksum: 2400849606,
        },
        image_id: 'IMG-35778',
        annotation_points: [
          {
            revision_id: 147131,
            no: 1,
            x1: 0.1514477,
            x2: 0.8997773,
            y1: 0.2294449,
            y2: 0.8604967,
            a_type: 'square',
            a_description: 'Stor jobb med istandsetting av interiøret i smia.',
            uuid: '0ad0b1ae-efbd-e217-b1d6-2c27d3afdb57',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-131881',
            checksum: 1744387058,
          },
        ],
        artifact_name: 'Markering: Stor jobb med istandsetting av interiøret i smia.: 131881',
        checksum: 3479056338,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 131880',
    checksum: 2284401202,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
  },

  'AE_17-131876': {
    artifact_id: 'AE_17-131876',
    identifier: '131876',
    uuid: '873549eb-bc75-4545-b412-f188650a8ce7',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450685906000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80569',
        context_identifier: 'RFH6.1.1.14',
        context_collection_id: 'ct_31-1',
        revision_id: 230492,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1494425039000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3566080021,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-131876',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
        checksum: 1450581792,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-2',
      priority_remarks: null,
      revision_id: 147128,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-131876',
      priority_type_id_value: 'Medium',
      checksum: 2356809412,
    },
    title: null,
    comments: [
      {
        description: 'Utført',
        revision_id: 230492,
        no: 1,
        order_number: 0,
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1494425052000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id: 'USER-181',
        object_type: 'SuperObjectCommentItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_17-131876',
        updated_by_id_value: 'SH (SH)',
        checksum: 808982187,
      },
    ],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35777',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-131876',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 822087056,
        name: 'RFF2016-116-031.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35777',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450685906000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-131877'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450711800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559788551,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1494425046000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3565621267,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-131876',
      revision_id: 147128,
      checksum: 3142653069,
    },
    status: {
      status_type_id: 'ct_53-1803',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-131876',
      revision_id: 230492,
      status_type_id_value: 'Avsluttet',
      checksum: 2064323330,
    },
    damage: {
      artifact_id: 'DMG-131878',
      context_id: '3-80569',
      identifier: 131878,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-131876',
      description: {
        damage_description:
          'Dårleg tak. Pannetak, mye mose, rotne vannbord. Renske panner, reparere konstruksjon og vannbord. Hogge skogen som heng inn over huset.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-131878',
        revision_id: 230492,
        checksum: 1643397577,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-131878',
        revision_id: 230492,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1522930164,
      },
      position: {
        damage_position: 'Særleg på vestsida.',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-131878',
        revision_id: 147128,
        checksum: 1242109838,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 228660190,
    },
    is_current: false,
    annotation_events: [
      {
        artifact_id: 'AE_21-131877',
        identifier: '131877',
        uuid: '66eb16c0-2f66-4d27-bee6-6f1de19c51dc',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450685906000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-131876',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80569',
            context_identifier: 'RFH6.1.1.14',
            context_collection_id: 'ct_31-1',
            revision_id: 147128,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-131877',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
            checksum: 1423580959,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-131877',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450685906000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 131876',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450682226000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563196432,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450682226000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563196432,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-131877',
          revision_id: 147128,
          checksum: 2414546641,
        },
        image_id: 'IMG-35777',
        annotation_points: [
          {
            revision_id: 147128,
            no: 1,
            x1: 0.1714922,
            x2: 0.9510022,
            y1: 0.1125835,
            y2: 0.8972245,
            a_type: 'square',
            a_description: 'Dårleg tak.',
            uuid: 'a1482772-4b5b-4df4-4b93-c09c7fd28d39',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-131877',
            checksum: 3217370176,
          },
        ],
        artifact_name: 'Markering: Dårleg tak.: 131877',
        checksum: 3328061393,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 131876',
    checksum: 1809199719,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
  },

  'AE_17-131872': {
    artifact_id: 'AE_17-131872',
    identifier: '131872',
    uuid: 'f295393d-d2d0-40ef-b5bd-907c419bccec',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450685736000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80569',
        context_identifier: 'RFH6.1.1.14',
        context_collection_id: 'ct_31-1',
        revision_id: 147126,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-131872',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
        checksum: 1425678109,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-2',
      priority_remarks: null,
      revision_id: 147129,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-131872',
      priority_type_id_value: 'Medium',
      checksum: 2345864897,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35776',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-131872',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 810421643,
        name: 'RFF2016-085-023.JPG.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35776',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450685736000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-131873'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450711800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559788551,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450711800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559788551,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-131872',
      revision_id: 147129,
      checksum: 3128300682,
    },
    status: {
      status_type_id: 'ct_53-1803',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-131872',
      revision_id: 230494,
      status_type_id_value: 'Avsluttet',
      checksum: 2050298624,
    },
    damage: {
      artifact_id: 'DMG-131874',
      context_id: '3-80569',
      identifier: 131874,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-131872',
      description: {
        damage_description:
          'Reparere svillstokk. Terreneget har vakse opp på veggen. Svillstokk, og kanskje andre omfar skada. Behov for å senke terreng, hoggeskog, vurdere skade og gjennomføre eventuelle utskiftingar.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-131874',
        revision_id: 230494,
        checksum: 1976391927,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-131874',
        revision_id: 230494,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1514934770,
      },
      position: {
        damage_position: 'Sør-, vest- og nordsida',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-131874',
        revision_id: 147129,
        checksum: 1837701006,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 206377938,
    },
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-131873',
        identifier: '131873',
        uuid: '7ea5fefb-d3c4-4360-b50d-b2c1433fb01f',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450685736000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-131872',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80569',
            context_identifier: 'RFH6.1.1.14',
            context_collection_id: 'ct_31-1',
            revision_id: 147126,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-131873',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
            checksum: 1398021913,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-131873',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450685736000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 131872',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450682093000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563917330,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450682093000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563917330,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-131873',
          revision_id: 147126,
          checksum: 2403667659,
        },
        // image_id: 'IMG-35776',
        image_id: 'IMG-44267',
        annotation_points: [
          {
            revision_id: 147126,
            no: 1,
            x1: 0.1347439,
            x2: 0.6870824,
            y1: 0.41141490000000003,
            y2: 0.9756886,
            a_type: 'square',
            a_description: 'Jord og lauv opp på andre omfaret. Truleg skade på svillstokk.',
            uuid: '46bfc291-53c4-cbbf-dd1d-2883771fb477',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-131873',
            checksum: 1158824503,
          },
        ],
        artifact_name: 'Markering: Jord og lauv opp på andre omfaret. Truleg skade på svillstokk.: 131873',
        checksum: 3648204760,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 131872',
    checksum: 3656398044,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
  },

  'AE_17-131906': {
    artifact_id: 'AE_17-131906',
    identifier: '131906',
    uuid: '20d9dc01-1f7d-46d7-ab16-9a416ff8864d',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450687897000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80577',
        context_identifier: 'RFH6.1.1.22',
        context_collection_id: 'ct_31-1',
        revision_id: 147145,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-131906',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.1.1.22: Stabbur Bleskestad',
        checksum: 1401626394,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-2',
      priority_remarks: null,
      revision_id: 147145,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-131906',
      priority_type_id_value: 'Medium',
      checksum: 2340032189,
    },
    title: null,
    comments: [
      {
        description:
          'Heile stabburet heller mot nord (vegen). Svilla på den nord-austre hansteinen er knust i nova. Heile stabben og hansteinen held også på å ta overhaling mot nord. Denne svilla er av nyare dato, firskoren .',
        revision_id: 163777,
        no: 1,
        order_number: 0,
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1464956948000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id: 'USER-6587',
        object_type: 'SuperObjectCommentItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_17-131906',
        updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
        checksum: 1139630139,
      },
    ],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35785',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-131906',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 805178761,
        name: 'RFF2016-146-026.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35785',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450687897000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-131907'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450711800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559788551,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450711800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559788551,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-131906',
      revision_id: 147145,
      checksum: 3120764038,
    },
    status: {
      status_type_id: 'ct_53-1803',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-131906',
      revision_id: 169530,
      status_type_id_value: 'Avsluttet',
      checksum: 2044990208,
    },
    damage: {
      artifact_id: 'DMG-131908',
      context_id: '3-80577',
      identifier: 131908,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-131906',
      description: {
        damage_description: 'Svikt i rammestokk (svillstokk?)',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-131908',
        revision_id: 147145,
        checksum: 2631408549,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-131908',
        revision_id: 147145,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1511133680,
      },
      position: {
        damage_position: 'Nordsida',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-131908',
        revision_id: 147145,
        checksum: 83693996,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 189600715,
    },
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-131907',
        identifier: '131907',
        uuid: 'bc281640-10ad-47d8-88a6-1ef74db4eb46',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450687897000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-131906',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80577',
            context_identifier: 'RFH6.1.1.22',
            context_collection_id: 'ct_31-1',
            revision_id: 147145,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-131907',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.22: Stabbur Bleskestad',
            checksum: 1373970198,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-131907',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450687897000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 131906',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450684256000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3565096981,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450684256000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3565096981,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-131907',
          revision_id: 147145,
          checksum: 2398162634,
        },
        image_id: 'IMG-35785',
        annotation_points: [
          {
            revision_id: 147145,
            no: 1,
            x1: 0.19821830000000001,
            x2: 0.5167038,
            y1: 0.2144199,
            y2: 0.8755217000000001,
            a_type: 'square',
            a_description: 'Svikt i rammestokk (svillstokk?). Må skiftast før golvbjelkene sviktar.',
            uuid: 'b587040a-aa3d-d552-4411-1ffe45dec231',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-131907',
            checksum: 761022322,
          },
        ],
        artifact_name: 'Markering: Svikt i rammestokk (svillstokk?). Må skiftast før golvbjelkene sviktar.: 131907',
        checksum: 2121674580,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 131906',
    checksum: 3242800288,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.1.1.22: Stabbur Bleskestad',
  },

  'AE_17-185091': {
    artifact_id: 'AE_17-185091',
    identifier: '185091',
    uuid: 'dce4dd90-37c8-415e-97a6-129e6512b532',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1494414170000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-181',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80632',
        context_identifier: 'RFH6.3.1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 230435,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1494414128000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3564900370,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-185091',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
        checksum: 1368989457,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 230435,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-185091',
      priority_type_id_value: 'Lav',
      checksum: 2349862587,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-32155',
        code: 'ct_92-0',
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-185091',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 3086421997,
        name: 'RFF2010-079-017.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-32155',
    updated_by_id: 'USER-181',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1494414170000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'SH (SH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'SH (SH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1494414128000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564900370,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: null,
        dd_date: null,
        dd_error: 'error.not_datetime',
        dd_error_message: null,
        checksum: 2851541348,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-185091',
      revision_id: 230435,
      checksum: 3135378565,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-185091',
      revision_id: 230435,
      status_type_id_value: 'Ny',
      checksum: 2055082747,
    },
    damage: {
      artifact_id: 'DMG-185092',
      context_id: '3-80632',
      identifier: 185092,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-185091',
      description: {
        damage_description: 'Mangler bakkelitt handtak på to innerdører 53.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-185092',
        revision_id: 235888,
        checksum: 3362792039,
      },
      damage_type: {
        damage_type_id: 'ct_52-8',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-185092',
        revision_id: 235888,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1527255556,
      },
      position: {
        damage_position: null,
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-185092',
        revision_id: 230435,
        checksum: 1305415176,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 176690125,
    },
    is_current: true,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 185091',
    checksum: 4128977289,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
  },

  'AE_17-185089': {
    artifact_id: 'AE_17-185089',
    identifier: '185089',
    uuid: 'b8a65454-c911-4a5e-ac98-2d5f834c8ecb',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1494414082000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-181',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80632',
        context_identifier: 'RFH6.3.1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 230433,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1494414033000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3563524109,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-185089',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
        checksum: 1413291798,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 230433,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-185089',
      priority_type_id_value: 'Lav',
      checksum: 2368933568,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-32155',
        code: 'ct_92-0',
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-185089',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 3108376564,
        name: 'RFF2010-079-017.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-32155',
    updated_by_id: 'USER-181',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1494414082000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'SH (SH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'SH (SH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1494414033000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3563524109,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: null,
        dd_date: null,
        dd_error: 'error.not_datetime',
        dd_error_message: null,
        checksum: 2851541348,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-185089',
      revision_id: 230433,
      checksum: 3160413322,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-185089',
      revision_id: 230433,
      status_type_id_value: 'Ny',
      checksum: 2079658752,
    },
    damage: {
      artifact_id: 'DMG-185090',
      context_id: '3-80632',
      identifier: 185090,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-185089',
      description: {
        damage_description: 'Noko flassing av måling på do i 2.etg. 51. Gul farge',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-185090',
        revision_id: 235889,
        checksum: 1824924380,
      },
      damage_type: {
        damage_type_id: 'ct_52-8',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-185090',
        revision_id: 235889,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1523257859,
      },
      position: {
        damage_position: 'Do 2.etg. 51',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-185090',
        revision_id: 230433,
        checksum: 784994713,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 199103440,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 185089',
    checksum: 788672618,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
  },

  'AE_17-134140': {
    artifact_id: 'AE_17-134140',
    identifier: '134140',
    uuid: 'd5be98c4-572e-45fd-8c43-f3539f4203e7',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1456235397000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-6587',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80632',
        context_identifier: 'RFH6.3.1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 155064,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-134140',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
        checksum: 1298472714,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 155064,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-134140',
      priority_type_id_value: 'Lav',
      checksum: 2318995124,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [],
    has_images: 'false',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: null,
    updated_by_id: 'USER-6587',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1456235397000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1456241400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560771591,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1456241400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560771591,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-134140',
      revision_id: 155064,
      checksum: 3095139454,
    },
    status: {
      status_type_id: 'ct_53-1803',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-134140',
      revision_id: 167975,
      status_type_id_value: 'Avsluttet',
      checksum: 2021135108,
    },
    damage: {
      artifact_id: 'DMG-134141',
      context_id: '3-80632',
      identifier: 134141,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-134140',
      description: {
        damage_description: 'Glas treng overhaling/kitting.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-134141',
        revision_id: 155064,
        checksum: 1681070859,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-134141',
        revision_id: 155064,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1494487527,
      },
      position: {
        damage_position: 'SH',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-134141',
        revision_id: 155064,
        checksum: 2370768650,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 110302124,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 134140',
    checksum: 3509267827,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
  },

  'AE_17-132156': {
    artifact_id: 'AE_17-132156',
    identifier: '132156',
    uuid: 'dbaf6a73-7bf1-4fd0-ac1d-781085244aa5',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450771580000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80632',
        context_identifier: 'RFH6.3.1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 230473,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1494423982000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3568504858,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132156',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
        checksum: 1329209101,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-2',
      priority_remarks: null,
      revision_id: 147303,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132156',
      priority_type_id_value: 'Medium',
      checksum: 2333740727,
    },
    title: null,
    comments: [
      {
        description: 'Utført',
        revision_id: 230473,
        no: 1,
        order_number: 0,
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1494423992000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id: 'USER-181',
        object_type: 'SuperObjectCommentItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_17-132156',
        updated_by_id_value: 'SH (SH)',
        checksum: 777524898,
      },
    ],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35837',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132156',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 796069253,
        name: 'RFF2016-110-013.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35837',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450771580000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-132157'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1494423986000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3569553438,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132156',
      revision_id: 147303,
      checksum: 3112768640,
    },
    status: {
      status_type_id: 'ct_53-1803',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132156',
      revision_id: 230473,
      status_type_id_value: 'Avsluttet',
      checksum: 2034963193,
    },
    damage: {
      artifact_id: 'DMG-132158',
      context_id: '3-80632',
      identifier: 132158,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132156',
      description: {
        damage_description: 'Dårleg dørstokk på baksida.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132158',
        revision_id: 230473,
        checksum: 2870025386,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132158',
        revision_id: 230473,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1505628651,
      },
      position: {
        damage_position: 'Bakdør.',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132158',
        revision_id: 147303,
        checksum: 617878015,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 139006909,
    },
    is_current: false,
    annotation_events: [
      {
        artifact_id: 'AE_21-132157',
        identifier: '132157',
        uuid: '642528fe-f15b-4add-9d78-08edb2f136ce',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450771580000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-132156',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80632',
            context_identifier: 'RFH6.3.1.11',
            context_collection_id: 'ct_31-1',
            revision_id: 147303,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-132157',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
            checksum: 1302208264,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-132157',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450771580000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 132156',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450767964000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3568570397,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450767964000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3568570397,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-132157',
          revision_id: 147303,
          checksum: 2392002244,
        },
        image_id: 'IMG-35837',
        annotation_points: [
          {
            revision_id: 147303,
            no: 1,
            x1: 0.08463252,
            x2: 0.1737194,
            y1: 0.292884,
            y2: 0.8471411,
            a_type: 'square',
            a_description: 'Dårleg dørstokk',
            uuid: '81204456-047a-5b39-c9b9-5c9603f33de3',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-132157',
            checksum: 1866870459,
          },
        ],
        artifact_name: 'Markering: Dårleg dørstokk: 132157',
        checksum: 3086888884,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132156',
    checksum: 993932070,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
  },

  'AE_17-132153': {
    artifact_id: 'AE_17-132153',
    identifier: '132153',
    uuid: 'cbeb78c9-4447-42af-9871-75a78ec789cf',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450771468000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80632',
        context_identifier: 'RFH6.3.1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 235904,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1495115031000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3562344458,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132153',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
        checksum: 1311579918,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 147301,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132153',
      priority_type_id_value: 'Lav',
      checksum: 2323975857,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-32155',
        code: 'ct_92-0',
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132153',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 3056078820,
        name: 'RFF2010-079-017.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-32155',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450771468000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132153',
      revision_id: 147301,
      checksum: 3101824123,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132153',
      revision_id: 147301,
      status_type_id_value: 'Ny',
      checksum: 2022118129,
    },
    damage: {
      artifact_id: 'DMG-132154',
      context_id: '3-80632',
      identifier: 132154,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132153',
      description: {
        damage_description: 'Avskallingar og m�lingsslite i kjellaren.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132154',
        revision_id: 235904,
        checksum: 1983979577,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132154',
        revision_id: 235904,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1498943979,
      },
      position: {
        damage_position: 'Kjellaren (innvendig)',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132154',
        revision_id: 147301,
        checksum: 443553335,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 120460210,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132153',
    checksum: 480063541,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
  },
  'AE_17-132173': {
    artifact_id: 'AE_17-132173',
    identifier: '132173',
    uuid: 'bacecab0-14a3-4048-9048-3eda0fd9adbb',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772540000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80634',
        context_identifier: 'RFH6.3.2.11',
        context_collection_id: 'ct_31-1',
        revision_id: 235906,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1495115084000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3564769298,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132173',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
        checksum: 1343430421,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 147311,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132173',
      priority_type_id_value: 'Lav',
      checksum: 2329743028,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-32126',
        code: 'ct_92-0',
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132173',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 3057586148,
        name: 'RFF2012-041-021.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-32126',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772540000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132173',
      revision_id: 147311,
      checksum: 3109295230,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132173',
      revision_id: 147311,
      status_type_id_value: 'Ny',
      checksum: 2029458164,
    },
    damage: {
      artifact_id: 'DMG-132174',
      context_id: '3-80634',
      identifier: 132174,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132173',
      description: {
        damage_description:
          'Nedsota stove. Stova er nedsota etter siste brukar. Behov for avklaring av funksjon og formidling før eventuelle tiltak.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132174',
        revision_id: 235906,
        checksum: 2712223049,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132174',
        revision_id: 235906,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1503269359,
      },
      position: {
        damage_position: 'Stova',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132174',
        revision_id: 147311,
        checksum: 3395752060,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 141431738,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132173',
    checksum: 1333211370,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
  },

  'AE_17-132171': {
    artifact_id: 'AE_17-132171',
    identifier: '132171',
    uuid: '8edc2f9f-2fee-43e9-9f5f-d74e28c18c8b',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772417000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80634',
        context_identifier: 'RFH6.3.2.11',
        context_collection_id: 'ct_31-1',
        revision_id: 235905,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1495115060000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3563065356,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132171',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
        checksum: 1330650898,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 147312,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132171',
      priority_type_id_value: 'Lav',
      checksum: 2324303539,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-32126',
        code: 'ct_92-0',
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132171',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 3051294690,
        name: 'RFF2012-041-021.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-32126',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772417000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132171',
      revision_id: 147312,
      checksum: 3102151805,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132171',
      revision_id: 147312,
      status_type_id_value: 'Ny',
      checksum: 2022445811,
    },
    damage: {
      artifact_id: 'DMG-132172',
      context_id: '3-80634',
      identifier: 132172,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132171',
      description: {
        damage_description: 'Grua manglar puss.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132172',
        revision_id: 235905,
        checksum: 490935885,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132172',
        revision_id: 235905,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1499140588,
      },
      position: {
        damage_position: 'Grua i kjøkkenet',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132172',
        revision_id: 147312,
        checksum: 3441104248,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 130290612,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132171',
    checksum: 575025393,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
  },

  'AE_17-132168': {
    artifact_id: 'AE_17-132168',
    identifier: '132168',
    uuid: '81cc93e5-8f0b-4897-9c62-83b82ea4070e',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772295000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80634',
        context_identifier: 'RFH6.3.2.11',
        context_collection_id: 'ct_31-1',
        revision_id: 147309,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132168',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
        checksum: 1368268568,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 147309,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132168',
      priority_type_id_value: 'Lav',
      checksum: 2341080767,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35840',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132168',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 793447810,
        name: 'RFF2012-041-018.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35840',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772295000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-132169'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132168',
      revision_id: 147309,
      checksum: 3124040841,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132168',
      revision_id: 147309,
      status_type_id_value: 'Ny',
      checksum: 2043941631,
    },
    damage: {
      artifact_id: 'DMG-132170',
      context_id: '3-80634',
      identifier: 132170,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132168',
      description: {
        damage_description: 'Dørhelle heller inn mot veggen.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132170',
        revision_id: 147309,
        checksum: 3066108951,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132170',
        revision_id: 147309,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1494618602,
      },
      position: {
        damage_position: 'Dørhelle framsida (sørsida)',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132170',
        revision_id: 147309,
        checksum: 445192838,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 148968374,
    },
    is_current: false,
    annotation_events: [
      {
        artifact_id: 'AE_21-132169',
        identifier: '132169',
        uuid: 'daf07d8d-26a0-47cb-8cc3-b8e67c680bab',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450772295000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-132168',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80634',
            context_identifier: 'RFH6.3.2.11',
            context_collection_id: 'ct_31-1',
            revision_id: 147309,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-132169',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
            checksum: 1340612372,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-132169',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450772295000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 132168',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450768671000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3567390745,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450768671000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3567390745,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-132169',
          revision_id: 147309,
          checksum: 2400521933,
        },
        image_id: 'IMG-35840',
        annotation_points: [
          {
            revision_id: 147309,
            no: 1,
            x1: 0.2951002,
            x2: 0.49554570000000003,
            y1: 0.6184265,
            y2: 0.7069073,
            a_type: 'square',
            a_description: 'Dørhella heller inn mot huset',
            uuid: 'ff89776a-91d9-2218-5e70-3d81cd690a09',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-132169',
            checksum: 1199584487,
          },
        ],
        artifact_name: 'Markering: Dørhella heller inn mot huset: 132169',
        checksum: 2235510798,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132168',
    checksum: 597897780,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
  },

  'AE_17-132164': {
    artifact_id: 'AE_17-132164',
    identifier: '132164',
    uuid: 'f4450257-ae06-4ac1-ba0e-bdc4369334d3',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772177000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80634',
        context_identifier: 'RFH6.3.2.11',
        context_collection_id: 'ct_31-1',
        revision_id: 147307,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132164',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
        checksum: 1342709522,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-2',
      priority_remarks: null,
      revision_id: 147307,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132164',
      priority_type_id_value: 'Medium',
      checksum: 2331315898,
    },
    title: null,
    comments: [
      {
        description:
          'I 2010 blei huset malt med: • Kvit: Allbëck linoljemaling m/1l sinkvitt pr. 3l måling Forbruk ca 10l på ca 126m2 Hjørnebord, listverk og dører: • Brun: Gjøco herregård linoljemaling Kode: 7030-Y90R Huset blei vaska i 2015. Det er no svartsopp på veggen. Huset bør vaskast og malast før sommaren. Då må me vurdera å bruka Ottesen maling.',
        revision_id: 152599,
        no: 1,
        order_number: 0,
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1455613806000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id: 'USER-6587',
        object_type: 'SuperObjectCommentItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_17-132164',
        updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
        checksum: 4220885679,
      },
    ],
    attachments: [
      {
        attachment_id: 'ATT-212598',
        revision_id: 302903,
        no: 1,
        order_number: 0,
        object_type: 'AttachmentItem',
        object_type_id: 'ATTACHMENT_ITEM',
        meta_type: 'sub_model',
        context_id: 'AE_17-132164',
        attachment_id_value: 'Dagrap._11.02.16G01.pdf',
        checksum: 1913853550,
      },
    ],
    images: [
      {
        image_id: 'IMG-35839',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132164',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 797052294,
        name: 'RFF2012-041-021.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35839',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450772177000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-132165'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132164',
      revision_id: 152602,
      checksum: 3108770941,
    },
    status: {
      status_type_id: 'ct_53-1802',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132164',
      revision_id: 152595,
      status_type_id_value: 'Under arbeid',
      checksum: 2031489791,
    },
    damage: {
      artifact_id: 'DMG-132166',
      context_id: '3-80634',
      identifier: 132166,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132164',
      description: {
        damage_description: 'Soppangrep og oppsprekking utvendig måling.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132166',
        revision_id: 147307,
        checksum: 1175462217,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132166',
        revision_id: 147307,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1504580077,
      },
      position: {
        damage_position: 'Kledning',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132166',
        revision_id: 147307,
        checksum: 55447969,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 143070140,
    },
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-132165',
        identifier: '132165',
        uuid: '346bfa0b-f932-4047-ab22-cbd295f1aaae',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450772177000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-132164',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80634',
            context_identifier: 'RFH6.3.2.11',
            context_collection_id: 'ct_31-1',
            revision_id: 147307,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-132165',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
            checksum: 1315053326,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-132165',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450772177000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 132164',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450768542000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3566276630,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450768542000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3566276630,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-132165',
          revision_id: 147307,
          checksum: 2389642951,
        },
        image_id: 'IMG-35839',
        annotation_points: [
          {
            revision_id: 147307,
            no: 1,
            x1: 0.3207127,
            x2: 0.8841871,
            y1: 0.08921119,
            y2: 0.8671745000000001,
            a_type: 'square',
            a_description: 'Sopp- og algevekst. Oppsprekking av måling.',
            uuid: '6ab295a0-281a-8056-b3fc-619623f85f69',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-132165',
            checksum: 3328915873,
          },
        ],
        artifact_name: 'Markering: Sopp- og algevekst. Oppsprekking av måling.: 132165',
        checksum: 3215011778,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132164',
    checksum: 2184525010,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
  },

  'AE_17-121722': {
    artifact_id: 'AE_17-121722',
    identifier: '121722',
    uuid: 'a2971a79-ad93-4c79-b621-e3d96f730102',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1432300845000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-181',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80634',
        context_identifier: 'RFH6.3.2.11',
        context_collection_id: 'ct_31-1',
        revision_id: 121410,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-121722',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
        checksum: 1327701763,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-3',
      priority_remarks: null,
      revision_id: 124409,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-121722',
      priority_type_id_value: 'Høy',
      checksum: 2326138551,
    },
    title: null,
    comments: [
      {
        description:
          'Sprukkne og uryddige panner er bytta ut og lagt på plass att. Det er ein del dårlige panner att og mange råtne lekter. Må tekke om heile taket ved høve. Det er papp uder pannene men den må byttast ut. pyntenevra som er synleg frå innsida ligg feil veg (snudd 90 grader på sida).',
        revision_id: 121633,
        no: 1,
        order_number: 0,
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1432910280000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id: 'USER-181',
        object_type: 'SuperObjectCommentItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_17-121722',
        updated_by_id_value: 'SH (SH)',
        checksum: 3807346296,
      },
      {
        description:
          'Når panner, papp og sutaksplater er tatt av ser ein trong for skifting av nokre trobord austre enden av taket mot sør. Det er også ein del rote i tuppane på sperra. Handverkaren tar bort det rotne og feller inn nytt tre på tuppane. Resten av tuppane blir behandla med Borakol. Det blir rydda i nevra som ligg litt i kryss-og-tvers. Nye sutaksplater og papp, lekter og sløyfer.',
        revision_id: 160247,
        no: 3,
        order_number: 1,
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1458645634000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id: 'USER-6587',
        object_type: 'SuperObjectCommentItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_17-121722',
        updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
        checksum: 1975099922,
      },
      {
        description:
          'Det er behov for omlegging av taket. Noverande teglpanner blir tatt ned for gjenbruk. Supplering av panner frå lager. Det er nokre maskinstrokne panner (tenker at desse vert bytt ut med handpanner på baksida, då der er få maskinpanner). Det gamle troer var lagt for nevertekking. Dette er reparert med liknande bord der retta ligg inn. Me let dei ligga som dei gjer. På troet er der lagt eit lag med never. Denne er lagt feil veg, og me vel å snu han slik at fibrane går opp/ned. Etter som her ikkje skal vera torv på taket, må nevra festast med enkelte stifter.',
        revision_id: 152621,
        no: 2,
        order_number: 2,
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1455614560000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id: 'USER-6587',
        object_type: 'SuperObjectCommentItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_17-121722',
        updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
        checksum: 1393747571,
      },
    ],
    attachments: [
      {
        attachment_id: 'ATT-212600',
        revision_id: 302903,
        no: 1,
        order_number: 0,
        object_type: 'AttachmentItem',
        object_type_id: 'ATTACHMENT_ITEM',
        meta_type: 'sub_model',
        context_id: 'AE_17-121722',
        attachment_id_value: 'Dagrap._11_02_20G02.pdf',
        checksum: 1843336796,
      },
    ],
    images: [],
    has_images: 'false',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: null,
    updated_by_id: 'USER-181',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1432300845000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'SH (SH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'SH (SH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1432308600000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559198727,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: null,
        dd_date: null,
        dd_error: 'error.not_datetime',
        dd_error_message: null,
        checksum: 2851541348,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-121722',
      revision_id: 152647,
      checksum: 3102479492,
    },
    status: {
      status_type_id: 'ct_53-1803',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-121722',
      revision_id: 230508,
      status_type_id_value: 'Avsluttet',
      checksum: 2024084213,
    },
    damage: {
      artifact_id: 'DMG-121723',
      context_id: '3-80634',
      identifier: 121723,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-121722',
      description: {
        damage_description: 'Lekkasje i tak. Det er fleire panner som er sunde og nokre har flytta seg.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-121723',
        revision_id: 230508,
        checksum: 1599351235,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-121723',
        revision_id: 230508,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1497240038,
      },
      position: {
        damage_position: 'På begge sider av taket',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-121723',
        revision_id: 124409,
        checksum: 1903957929,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 130290612,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 121722',
    checksum: 1243884970,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
  },

  'AE_17-171754': {
    artifact_id: 'AE_17-171754',
    identifier: '171754',
    uuid: '20996f09-9576-474f-b6b7-e7b8da897258',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1472218884000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-6587',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80646',
        context_identifier: 'RFH6.3.2.23',
        context_collection_id: 'ct_31-1',
        revision_id: 168106,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-171754',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.23: Løe Hustveit',
        checksum: 1429741344,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 168106,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-171754',
      priority_type_id_value: 'Lav',
      checksum: 2353991361,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-37888',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-171754',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 829492628,
        name: 'RFF2016-106-005.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-37888',
    updated_by_id: 'USER-6587',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1472218884000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1472225400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560378375,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1472225400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560378375,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-171754',
      revision_id: 168106,
      checksum: 3140359307,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-171754',
      revision_id: 168106,
      status_type_id_value: 'Ny',
      checksum: 2059997953,
    },
    damage: {
      artifact_id: 'DMG-171755',
      context_id: '3-80646',
      identifier: 171755,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-171754',
      description: {
        damage_description: 'Døra inn til utstillinga manglar eit bord nederst.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-171755',
        revision_id: 168106,
        checksum: 1004544841,
      },
      damage_type: {
        damage_type_id: 'ct_52-8',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-171755',
        revision_id: 168106,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1527714297,
      },
      position: {
        damage_position: null,
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-171755',
        revision_id: 168106,
        checksum: 1309281806,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 204739541,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 171754',
    checksum: 1120939938,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.23: Løe Hustveit',
  },

  'AE_17-171752': {
    artifact_id: 'AE_17-171752',
    identifier: '171752',
    uuid: '524d6d23-b87a-4efe-b970-6e085ffa3cf5',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1472218832000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-6587',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80646',
        context_identifier: 'RFH6.3.2.23',
        context_collection_id: 'ct_31-1',
        revision_id: 168104,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-171752',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.23: Løe Hustveit',
        checksum: 1416896284,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-1',
      priority_remarks: null,
      revision_id: 168104,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-171752',
      priority_type_id_value: 'Lav',
      checksum: 2348355261,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-37889',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-171752',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 826215827,
        name: 'RFF2016-106-009.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-37889',
    updated_by_id: 'USER-6587',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1472218832000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'Myhre, Anne Margrethe (AMY)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1472225400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560378375,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1472225400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560378375,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-171752',
      revision_id: 168104,
      checksum: 3133019271,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-171752',
      revision_id: 168104,
      status_type_id_value: 'Ny',
      checksum: 2052788989,
    },
    damage: {
      artifact_id: 'DMG-171753',
      context_id: '3-80646',
      identifier: 171753,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-171752',
      description: {
        damage_description:
          'Det er ein del vegetasjon oppover veggen og kledningen, spesielt på baksida av løa. Denne må fjernast. Til venstre for døra har sprut av takvatn som treffer berget, tatt hardt på kledningen.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-171753',
        revision_id: 168104,
        checksum: 4130036356,
      },
      damage_type: {
        damage_type_id: 'ct_52-8',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-171753',
        revision_id: 168104,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1523519989,
      },
      position: {
        damage_position: null,
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-171753',
        revision_id: 168104,
        checksum: 1304169994,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 193598415,
    },
    is_current: true,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 171752',
    checksum: 3576640095,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.23: Løe Hustveit',
  },

  'AE_17-170855': {
    artifact_id: 'AE_17-170855',
    identifier: '170855',
    uuid: '37807207-fca1-445d-ad45-7d69e7b64a61',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1467103621000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-181',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80646',
        context_identifier: 'RFH6.3.2.23',
        context_collection_id: 'ct_31-1',
        revision_id: 165645,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-170855',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.23: Løe Hustveit',
        checksum: 1436688166,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-3',
      priority_remarks: null,
      revision_id: 165645,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-170855',
      priority_type_id_value: 'Høy',
      checksum: 2360086217,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-37887',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-170855',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 830213524,
        name: 'RFF2016-106-002.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-37887',
    updated_by_id: 'USER-181',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1467103621000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'SH (SH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'SH (SH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1467127800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1467127800000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-170855',
      revision_id: 165645,
      checksum: 3144553617,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-170855',
      revision_id: 165645,
      status_type_id_value: 'Ny',
      checksum: 2064126727,
    },
    damage: {
      artifact_id: 'DMG-170856',
      context_id: '3-80646',
      identifier: 170856,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-170855',
      description: {
        damage_description: 'Torvhald på vei til å løfte seg av krokane i nordaustre hjørne og bortover forbi låvebrua',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-170856',
        revision_id: 165647,
        checksum: 1435121089,
      },
      damage_type: {
        damage_type_id: 'ct_52-8',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-170856',
        revision_id: 165647,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1530466817,
      },
      position: {
        damage_position: null,
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-170856',
        revision_id: 165645,
        checksum: 1312362004,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 210113496,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 170855',
    checksum: 1740058589,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.23: Løe Hustveit',
  },

  'AE_17-132205': {
    artifact_id: 'AE_17-132205',
    identifier: '132205',
    uuid: 'd62af195-1fd5-473f-bf4b-26d9ece9cdb8',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450774698000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80646',
        context_identifier: 'RFH6.3.2.23',
        context_collection_id: 'ct_31-1',
        revision_id: 147333,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132205',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.23: Løe Hustveit',
        checksum: 1351294739,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-3',
      priority_remarks: null,
      revision_id: 147333,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132205',
      priority_type_id_value: 'Høy',
      checksum: 2321551030,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35846',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 783486336,
        name: 'RFF2016-106-002.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35847',
        code: null,
        order_number: 1,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1245711826,
        name: 'RFF2016-106-002.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35848',
        code: null,
        order_number: 2,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1247612372,
        name: 'RFF2016-106-005.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35849',
        code: null,
        order_number: 3,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1249512918,
        name: 'RFF2016-106-009.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35850',
        code: null,
        order_number: 4,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1234963919,
        name: 'RFF2016-106-002.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35851',
        code: null,
        order_number: 5,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1236864465,
        name: 'RFF2016-106-005.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35852',
        code: null,
        order_number: 6,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1238765011,
        name: 'RFF2016-106-009.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35853',
        code: null,
        order_number: 7,
        motif_object: false,
        context_id: 'AE_17-132205',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1240665557,
        name: 'RFF2016-106-002.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35846',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450774698000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132205',
      revision_id: 147333,
      checksum: 3094942846,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132205',
      revision_id: 147333,
      status_type_id_value: 'Ny',
      checksum: 2015367924,
    },
    damage: {
      artifact_id: 'DMG-132206',
      context_id: '3-80646',
      identifier: 132206,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132205',
      description: {
        damage_description: 'Store lekkasjar i taket.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132206',
        revision_id: 147333,
        checksum: 3051100275,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132206',
        revision_id: 147333,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1494290919,
      },
      position: {
        damage_position: 'Taket',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132206',
        revision_id: 147333,
        checksum: 3351122024,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 132387761,
    },
    is_current: false,
    annotation_events: [],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132205',
    checksum: 350499046,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.23: Løe Hustveit',
  },

  'AE_17-132208': {
    artifact_id: 'AE_17-132208',
    identifier: '132208',
    uuid: '259f6546-f911-436a-96e2-5d51c717c6f8',
    authority: null,
    adm_event_type_id: 'ct_49-17',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450775903000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingObservationEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_17',
    artifact_type_id: 'ct_200-217',
    contexts: [
      {
        context_artifact_id: '3-80650',
        context_identifier: 'RFH6.7.T1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 147335,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_17-132208',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.7.T1.11: Stovehus/Løe Bakken',
        checksum: 1939152743,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    priority: {
      priority_type_id: 'ct_69-2',
      priority_remarks: null,
      revision_id: 147335,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_17-132208',
      priority_type_id_value: 'Medium',
      checksum: 2328563386,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35854',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_17-132208',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 789319042,
        name: 'RFF2012-057-030.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35854',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1450775903000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-132209'],
    artifact_type_id_value: 'Avvik',
    adm_event_type_id_value: 'Observasjon bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    observation_type: 'Damage',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1450798200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_building_obs_desc: null,
      object_type: 'TimespanBuildingObservation',
      meta_type: 'sub_model',
      context_id: 'AE_17-132208',
      revision_id: 147335,
      checksum: 3105887363,
    },
    status: {
      status_type_id: 'ct_53-1801',
      status_remarks: null,
      object_type: 'ObservationStatus',
      meta_type: 'sub_model',
      context_id: 'AE_17-132208',
      revision_id: 147335,
      status_type_id_value: 'Ny',
      checksum: 2026115833,
    },
    damage: {
      artifact_id: 'DMG-132210',
      context_id: '3-80650',
      identifier: 132210,
      building_part_type_id: null,
      object_type: 'BuildingDamage',
      meta_type: 'sub_model',
      event_id: 'AE_17-132208',
      description: {
        damage_description: 'Groing av svartsopp. Målingsslite.',
        object_type: 'DamageDescription',
        meta_type: 'sub_model',
        context_id: 'DMG-132210',
        revision_id: 147335,
        checksum: 176233849,
      },
      damage_type: {
        damage_type_id: 'ct_52-3',
        object_type: 'BuildingDamageType',
        meta_type: 'sub_model',
        context_id: 'DMG-132210',
        revision_id: 147335,
        damage_type_id_value: 'Antikvarisk vedlikehold',
        checksum: 1484329444,
      },
      position: {
        damage_position: 'Kledning',
        object_type: 'DamagePosition',
        meta_type: 'sub_model',
        context_id: 'DMG-132210',
        revision_id: 147335,
        checksum: 23400856,
      },
      damage_timespan: null,
      building_part_type_id_value: null,
      checksum: 111023013,
    },
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-132209',
        identifier: '132209',
        uuid: '1601faae-f22d-4d4f-b06a-a51bd10e7423',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450775903000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_17-132208',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80650',
            context_identifier: 'RFH6.7.T1.11',
            context_collection_id: 'ct_31-1',
            revision_id: 147335,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-132209',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.7.T1.11: Stovehus/Løe Bakken',
            checksum: 1911234403,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-132209',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1450775903000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Avvik: Antikvarisk vedlikehold: 132208',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450772278000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3565424663,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1450772278000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3565424663,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-132209',
          revision_id: 147335,
          checksum: 2386955975,
        },
        image_id: 'IMG-35854',
        annotation_points: [
          {
            revision_id: 147335,
            no: 1,
            x1: 0.1503341,
            x2: 0.7795099999999999,
            y1: 0.01408598,
            y2: 0.7402963,
            a_type: 'square',
            a_description: 'Svertesopp. Behov for vask og eventuell måling.',
            uuid: '3598cf3f-55d0-f864-2a93-c18030b6e770',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-132209',
            checksum: 43072424,
          },
        ],
        artifact_name: 'Markering: Svertesopp. Behov for vask og eventuell måling.: 132209',
        checksum: 2743545730,
      },
    ],
    artifact_name: 'Avvik: Antikvarisk vedlikehold: 132208',
    checksum: 3688641429,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.7.T1.11: Stovehus/Løe Bakken',
  },

  'AE_13-130163': {
    artifact_id: 'AE_13-130163',
    identifier: '130163',
    uuid: '38985c91-ef4c-4149-aa25-a895412fcb31',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1446209015000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-80569',
        context_identifier: 'RFH6.1.1.14',
        context_collection_id: 'ct_31-1',
        revision_id: 144018,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-130163',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
        checksum: 1345396494,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description:
        'Forsømt bygning. Kritisk tilstand mot bakken på baksida. Må drenere/senke terrenget på baksida. Vurdere behov for reparasjon av svillstokk. Rekonstruksjon av interiøret, inklusive belg og esse. TK 3',
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-130163',
      revision_id: 144018,
      checksum: 3823525073,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35423',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_13-130163',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 758058356,
        name: 'RFF2016-085-029.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35424',
        code: null,
        order_number: 1,
        motif_object: false,
        context_id: 'AE_13-130163',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1218710982,
        name: 'RFF2016-116-031.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35425',
        code: null,
        order_number: 2,
        motif_object: false,
        context_id: 'AE_13-130163',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1220611528,
        name: 'DSCN0213.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35423',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1446209015000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-130164', 'AE_21-130165', 'AE_21-130166', 'AE_21-130167'],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1441580400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560181767,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1446219000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560574983,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-130163',
      revision_id: 144018,
      checksum: 477893049,
    },
    condition: {
      method: null,
      measure_class_type_id: 'ct_89-3',
      condition_description:
        'MILJØ: Står i bratt terreng med mye skog tett innpå. VEGGER: Lafta. Oppsamling av lauv og jord på baksida (vestsida) gjer at terrenget går opp til andre omfaret. Svillstokken truleg roten. TAK: Panner. Mye mose på baksida. Rotne vannbord. GOLV: Jordgolv. Mye lauv inne i løa. ELDSTAD: Eldstad/belg i ustand.',
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-130163',
      revision_id: 144018,
      measure_class_type_id_value: 'TK2: Moderate reparasjoner og/eller ytterligere undersøkelse',
      checksum: 2722661368,
    },
    purpose: {
      purpose_description: 'Synfaring i samband med prosjektet Prioritering i bygningssamlinga',
      purpose_type_id: 'ct_50-9',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-130163',
      revision_id: 144018,
      purpose_type_id_value: 'FDV-runde',
      checksum: 44905222,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [],
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-130164',
        identifier: '130164',
        uuid: 'afb57515-c81e-4ca8-be8e-e54df64c50cb',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-130163',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80569',
            context_identifier: 'RFH6.1.1.14',
            context_collection_id: 'ct_31-1',
            revision_id: 144018,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-130164',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
            checksum: 1345003278,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-130164',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 130163',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205259000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3562999826,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205259000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3562999826,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-130164',
          revision_id: 144018,
          checksum: 2380009152,
        },
        image_id: 'IMG-35423',
        annotation_points: [
          {
            revision_id: 144018,
            no: 1,
            x1: 0.15985470000000002,
            x2: 0.8619437,
            y1: 0.02239441,
            y2: 0.5455552,
            a_type: 'square',
            a_description: 'Står i bratt terreng. Mye skog tett innpå.',
            uuid: '2ab90d3a-96ad-0ff9-1c72-6961d581206b',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-130164',
            checksum: 1969896065,
          },
        ],
        artifact_name: 'Markering: Står i bratt terreng. Mye skog tett innpå.: 130164',
        checksum: 2687512561,
      },
      {
        artifact_id: 'AE_21-130165',
        identifier: '130165',
        uuid: '00759b9b-eca3-4adf-b524-d3a494dc7c6c',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-130163',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80569',
            context_identifier: 'RFH6.1.1.14',
            context_collection_id: 'ct_31-1',
            revision_id: 144018,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-130165',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
            checksum: 1351360271,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-130165',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 130163',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205310000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3559723014,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205310000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3559723014,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-130165',
          revision_id: 144018,
          checksum: 2382696129,
        },
        image_id: 'IMG-35424',
        annotation_points: [
          {
            revision_id: 144018,
            no: 1,
            x1: 0.2134423,
            x2: 0.7801998,
            y1: 0.2689884,
            y2: 0.8520947,
            a_type: 'square',
            a_description: 'Lauv og jord har vakse opp på veggen på baksida',
            uuid: '6ab7ed8d-bdf1-c4e8-3ff1-95ba4383fd7b',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-130165',
            checksum: 121191275,
          },
        ],
        artifact_name: 'Markering: Lauv og jord har vakse opp på veggen på baksida: 130165',
        checksum: 3826855870,
      },
      {
        artifact_id: 'AE_21-130166',
        identifier: '130166',
        uuid: '1d07e80a-a9f7-4675-a1c7-95590b29c0b4',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-130163',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80569',
            context_identifier: 'RFH6.1.1.14',
            context_collection_id: 'ct_31-1',
            revision_id: 144018,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-130166',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
            checksum: 1357717264,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-130166',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 130163',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205359000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563393043,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205359000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563393043,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-130166',
          revision_id: 144018,
          checksum: 2385383106,
        },
        image_id: 'IMG-35425',
        annotation_points: [
          {
            revision_id: 144018,
            no: 1,
            x1: 0.1053588,
            x2: 0.9682107,
            y1: 0.1450102,
            y2: 0.8738931,
            a_type: 'square',
            a_description: 'Mye mose på taket. Rotne vannbord.',
            uuid: '9f6c4e24-9754-1397-de84-ba1b96d12701',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-130166',
            checksum: 1036399653,
          },
        ],
        artifact_name: 'Markering: Mye mose på taket. Rotne vannbord.: 130166',
        checksum: 948317914,
      },
      {
        artifact_id: 'AE_21-130167',
        identifier: '130167',
        uuid: '81867aad-2ce6-4317-9f06-2d0cdc63aba2',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-130163',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80569',
            context_identifier: 'RFH6.1.1.14',
            context_collection_id: 'ct_31-1',
            revision_id: 144018,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-130167',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
            checksum: 1364074257,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-130167',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446209015000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 130163',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205395000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563655187,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446205395000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563655187,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-130167',
          revision_id: 144018,
          checksum: 2388070083,
        },
        image_id: 'IMG-35426',
        annotation_points: [
          {
            revision_id: 144018,
            no: 1,
            x1: 0.2207084,
            x2: 0.955495,
            y1: 0.1490974,
            y2: 0.9638113,
            a_type: 'square',
            a_description: 'Esse og belg må rekonstruerast',
            uuid: '31ec0623-42c6-d6c4-f296-7b89cd9bdc96',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-130167',
            checksum: 2789487558,
          },
        ],
        artifact_name: 'Markering: Esse og belg må rekonstruerast: 130167',
        checksum: 2247831392,
      },
    ],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 130163',
    checksum: 675624029,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.1.1.14: Smie Kolbeinstveit',
  },

  'AE_13-130184': {
    artifact_id: 'AE_13-130184',
    identifier: '130184',
    uuid: 'b5a74e39-2205-49fc-b4a1-83237be57549',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1446211419000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-80577',
        context_identifier: 'RFH6.1.1.22',
        context_collection_id: 'ct_31-1',
        revision_id: 144032,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-130184',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.1.1.22: Stabbur Bleskestad',
        checksum: 1353260811,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description: 'Skifte rammestokk. TK2',
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-130184',
      revision_id: 144032,
      checksum: 1467160288,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35434',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_13-130184',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 769133945,
        name: 'RFF2016-085-046.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35435',
        code: null,
        order_number: 1,
        motif_object: false,
        context_id: 'AE_13-130184',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1230441931,
        name: 'RFF2016-146-026.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35434',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1446211419000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-130185'],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1441636200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560116231,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1446219000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3560574983,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-130184',
      revision_id: 144032,
      checksum: 487330232,
    },
    condition: {
      method: null,
      measure_class_type_id: 'ct_89-2',
      condition_description:
        'FUNDAMENT: Trestabber på stein. Rammestokk mot nord i ferd med å bryte saman. ANNA: Bygningen sto ulåst. Stor og uryddig gjenstandssamling inne i bygningen.',
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-130184',
      revision_id: 144032,
      measure_class_type_id_value: 'TK1: Vedlikehold/forebyggende konservering',
      checksum: 3187625202,
    },
    purpose: {
      purpose_description: 'Synfaring i samband med prosjektet Prioritering i bygningssamlinga',
      purpose_type_id: 'ct_50-9',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-130184',
      revision_id: 144032,
      purpose_type_id_value: 'FDV-runde',
      checksum: 1516385097,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [],
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-130185',
        identifier: '130185',
        uuid: 'b0099982-abb5-4246-b685-faaf449e7640',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446211419000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-130184',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80577',
            context_identifier: 'RFH6.1.1.22',
            context_collection_id: 'ct_31-1',
            revision_id: 144032,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-130185',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.1.1.22: Stabbur Bleskestad',
            checksum: 1352867595,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-130185',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1446211419000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 130184',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446207796000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3566407706,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1446207796000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3566407706,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-130185',
          revision_id: 144032,
          checksum: 2388070079,
        },
        image_id: 'IMG-35435',
        annotation_points: [
          {
            revision_id: 144032,
            no: 1,
            x1: 0.2134423,
            x2: 0.4668483,
            y1: 0.2294789,
            y2: 0.8139475,
            a_type: 'square',
            a_description: 'Rammestokk i ferd med å bryte saman',
            uuid: '6d5b1fc9-bc74-ba96-644c-13fde04ebb03',
            order_number: 1,
            color: '#FFFFFF',
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-130185',
            checksum: 1187984850,
          },
        ],
        artifact_name: 'Markering: Rammestokk i ferd med å bryte saman: 130185',
        checksum: 1572220647,
      },
    ],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 130184',
    checksum: 3675534388,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.1.1.22: Stabbur Bleskestad',
  },

  'AE_13-129267': {
    artifact_id: 'AE_13-129267',
    identifier: '129267',
    uuid: '8a884b0b-0d7d-48ff-8113-df31537222a9',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442216776000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-80632',
        context_identifier: 'RFH6.3.1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 142056,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-129267',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
        checksum: 1360076561,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description:
        'Behov for større vedlikehaldsjobb utvendig + behov for rydding og oppussing i kjellaren. KG 1',
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129267',
      revision_id: 142056,
      checksum: 2117874155,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35114',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_13-129267',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 786500990,
        name: 'RFF2016-110-001.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35115',
        code: null,
        order_number: 1,
        motif_object: false,
        context_id: 'AE_13-129267',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1248464336,
        name: 'RFF2016-110-013.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35114',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442216776000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-129268'],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1442244600000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559591943,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1442244600000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559591943,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129267',
      revision_id: 142056,
      checksum: 520294854,
    },
    condition: {
      method: null,
      measure_class_type_id: 'ct_89-3',
      condition_description:
        'MILJØ: Graset slått rundt huset, men ikkje i bakhagen, blomsterbed attgrodde/overtatt av brennesle, noe gras i singelgangar. Portane sto opne, den eine hekta av. Stakitten malingssliten og angrepe av sopp. FUNDAMENT: Avskallingar og malingsslite i kjellaren. VEGGAR: Kledning skada ved sandblåsing. Oppsprekking av maling, avflassing. GLAS: Oppsprekking av kitt og maling. DØRER: Ok, men dårleg dørstokk på baksida i nr. 51. ANNA: Noe rot og rask i 1. etg. i nr. 53, framleis gjenstandssamling i kjellaren i nr. 53. KONKLUSJON: Behov for større vedlikehaldsjobb utvendig, + behov for rydding og oppussing i kjellaren. TG 2',
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-129267',
      revision_id: 142056,
      measure_class_type_id_value: 'TK2: Moderate reparasjoner og/eller ytterligere undersøkelse',
      checksum: 4122211281,
    },
    purpose: {
      purpose_description: 'Synfaring i samband med prosjektet Prioritering i bygningssamlinga',
      purpose_type_id: 'ct_50-9',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-129267',
      revision_id: 142056,
      purpose_type_id_value: 'FDV-runde',
      checksum: 1608987479,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [],
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-129268',
        identifier: '129268',
        uuid: '15b7a8c5-3637-4aaf-ab1b-a26725e10942',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442216776000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-129267',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80632',
            context_identifier: 'RFH6.3.1.11',
            context_collection_id: 'ct_31-1',
            revision_id: 142057,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-129268',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
            checksum: 1359748882,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-129268',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442216776000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 129267',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442209943000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563065362,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442209943000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563065362,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-129268',
          revision_id: 142057,
          checksum: 2416512718,
        },
        image_id: 'IMG-35115',
        annotation_points: [
          {
            revision_id: 142057,
            no: 1,
            x1: 0.06126953,
            x2: 0.2412695,
            y1: 0.31238299999999997,
            y2: 0.8498361,
            a_type: 'box',
            a_description: 'Dårleg dørstokk',
            uuid: 'cb60a366-51c7-ba76-7933-39367a8cffe5',
            order_number: 1,
            color: null,
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-129268',
            checksum: 1448750672,
          },
        ],
        artifact_name: 'Markering: Dårleg dørstokk: 129268',
        checksum: 1666002720,
      },
    ],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 129267',
    checksum: 993145966,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.1.11: Haakonsgt. 51/53',
  },

  'AE_13-129312': {
    artifact_id: 'AE_13-129312',
    identifier: '129312',
    uuid: '0d9b3fad-88be-4c38-acfa-e41f9134bc06',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442246437000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-80634',
        context_identifier: 'RFH6.3.2.11',
        context_collection_id: 'ct_31-1',
        revision_id: 142105,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-129312',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
        checksum: 1321017094,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description:
        'Vaske, skrape og male. Rette opp trappa på sørsida. Avklare funksjon/bruk i forhold til innvendig vedlikehald/restaurering. KG 1',
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129312',
      revision_id: 142105,
      checksum: 2440183679,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35129',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_13-129312',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 774180219,
        name: 'RFF2012-041-021.jpg',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35129',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442246437000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-129313'],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1442244600000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559591943,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1442244600000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559591943,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129312',
      revision_id: 142105,
      checksum: 491262392,
    },
    condition: {
      method: null,
      measure_class_type_id: 'ct_89-3',
      condition_description:
        'FUNDAMENT: Tørrmur på fjell. Kjellar under både stove og bu. Jordgolv. Litt fuktig. VEGGAR: Laft, bordkledd og måla. Soppangrep utvendig, noe oppsprekking. DØRER: Dørhella mot sør heller inn mot veggen. TAK: Panner. Jfr. avviksmelding 22.05.15. ELDSTADER: Dårleg omn i stova. Grua manglar puss. ANNA: Skitne golv/dårleg reinhald. Litt rot rundt omkring. Nedsota stove og kjøkken. KONKLUSJON: Modent for større vedlikehaldsrunde. TG 2',
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-129312',
      revision_id: 142105,
      measure_class_type_id_value: 'TK2: Moderate reparasjoner og/eller ytterligere undersøkelse',
      checksum: 2644226651,
    },
    purpose: {
      purpose_description: 'Synfaring i samband med prosjektet Prioritering i bygningssamlinga',
      purpose_type_id: 'ct_50-9',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-129312',
      revision_id: 142105,
      purpose_type_id_value: 'FDV-runde',
      checksum: 1526281033,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [],
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-129313',
        identifier: '129313',
        uuid: '7312992a-c242-4d45-bfaf-0e913d568bd6',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442246437000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-129312',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80634',
            context_identifier: 'RFH6.3.2.11',
            context_collection_id: 'ct_31-1',
            revision_id: 142106,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-129313',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
            checksum: 1320689415,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-129313',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442246437000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 129312',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442239580000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563589650,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442239580000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3563589650,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-129313',
          revision_id: 142106,
          checksum: 2391609024,
        },
        image_id: 'IMG-35129',
        annotation_points: [
          {
            revision_id: 142106,
            no: 1,
            x1: 0.16626950000000001,
            x2: 0.8800195000000001,
            y1: 0.4228699,
            y2: 0.6307349999999999,
            a_type: 'box',
            a_description: 'Malingsslite.',
            uuid: '6318294c-a1eb-e056-157f-344ab9b69e96',
            order_number: 1,
            color: null,
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-129313',
            checksum: 464727659,
          },
          {
            revision_id: 142106,
            no: 2,
            x1: 0.2762695,
            x2: 0.4850195,
            y1: 0.5708099,
            y2: 0.7093867,
            a_type: 'box',
            a_description: 'Trappa heller inn mot veggen',
            uuid: '5b8b835b-6c49-a181-c938-f27edd7f2948',
            order_number: 2,
            color: null,
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-129313',
            checksum: 2359701117,
          },
        ],
        artifact_name: 'Markering: Malingsslite.: 129313',
        checksum: 1367027474,
      },
    ],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 129312',
    checksum: 237581693,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
  },

  'AE_13-129394': {
    artifact_id: 'AE_13-129394',
    identifier: '129394',
    uuid: '45596963-c336-417f-b080-3e9dc2bf1be6',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442307796000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-80646',
        context_identifier: 'RFH6.3.2.23',
        context_collection_id: 'ct_31-1',
        revision_id: 142181,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-129394',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.23: Løe Hustveit',
        checksum: 1420042010,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description: 'Ingen kortsiktige tiltak, men taket bør sjekkast i dårleg ver.',
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129394',
      revision_id: 142181,
      checksum: 221849571,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35164',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_13-129394',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 798166404,
        name: 'RFF2016-106-005.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35165',
        code: null,
        order_number: 1,
        motif_object: false,
        context_id: 'AE_13-129394',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1260916182,
        name: 'RFF2016-106-009.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: true,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35164',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442307796000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: ['AE_21-129395', 'AE_21-129396'],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1442331000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3555921918,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1442331000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3555921918,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129394',
      revision_id: 142181,
      checksum: 524030406,
    },
    condition: {
      method: null,
      measure_class_type_id: 'ct_89-1',
      condition_description:
        'FUNDAMENT: Tørrmur. Hevdaskut i underetasjen. Gjeldselgrøfter ut frå hevdaskuten attgrodde. VEGGAR: Stavkonstruksjon, bordkledd. Dryppskader på nordsida, men førebels ikkje nemneverdig rote. TAK: Never og torv. Har vore meldt om lekkasjar, men ikkje muleg å sjå i tørrver. ANNA: Ryddig og fint. Lysanlegget fungerer som det skal. KONKLUSJON: I god stand, men med eit spørsmål om korleis det eigentleg står til med taket. TG 0',
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-129394',
      revision_id: 142181,
      measure_class_type_id_value: 'TK0: Ingen tiltak',
      checksum: 2586293273,
    },
    purpose: {
      purpose_description: 'Synfaring i samband med prosjektet Prioritering i bygningssamlinga',
      purpose_type_id: 'ct_50-9',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-129394',
      revision_id: 142181,
      purpose_type_id_value: 'FDV-runde',
      checksum: 1618686807,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [],
    is_current: true,
    annotation_events: [
      {
        artifact_id: 'AE_21-129395',
        identifier: '129395',
        uuid: '769fd18b-a437-42ec-ae6c-8204f8f7cd75',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442307796000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-129394',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80646',
            context_identifier: 'RFH6.3.2.23',
            context_collection_id: 'ct_31-1',
            revision_id: 142182,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-129395',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.3.2.23: Løe Hustveit',
            checksum: 1419714331,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-129395',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442307796000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 129394',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442300934000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3559460874,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442300934000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3559460874,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-129395',
          revision_id: 142182,
          checksum: 2419789518,
        },
        image_id: 'IMG-35164',
        annotation_points: [
          {
            revision_id: 142182,
            no: 1,
            x1: 0.2225195,
            x2: 0.8150195,
            y1: 0.1232444,
            y2: 0.4809223,
            a_type: 'box',
            a_description: 'Usikker på korleis det eigentleg står til med taket. Må sjekkast i dårleg ver.',
            uuid: '803f79ca-b414-fa19-7e48-37be0a625172',
            order_number: 1,
            color: null,
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-129395',
            checksum: 1029587925,
          },
        ],
        artifact_name:
          'Markering: Usikker på korleis det eigentleg står til med taket. Må sjekkast i dårleg ver.: 129395',
        checksum: 3021483946,
      },
      {
        artifact_id: 'AE_21-129396',
        identifier: '129396',
        uuid: 'abde5c3c-e3d9-4be4-b56b-9f8f9314313d',
        authority: null,
        adm_event_type_id: 'ct_49-21',
        published: false,
        created_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442307796000,
          dd_error: null,
          dd_error_message: null,
        },
        created_by_id: 'USER-23',
        parent_id: 'AE_13-129394',
        object_type: 'AnnotationEvent',
        meta_type: 'adm_event',
        object_type_id: 'AE_21',
        artifact_type_id: 'ct_200-221',
        contexts: [
          {
            context_artifact_id: '3-80646',
            context_identifier: 'RFH6.3.2.23',
            context_collection_id: 'ct_31-1',
            revision_id: 142183,
            no: 1,
            remarks: null,
            from_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            to_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            handed_in_date: {
              object_type: 'PrecisionDate',
              meta_type: 'sub_model',
              dd_precision: null,
              dd_date: null,
              dd_error: 'error.not_datetime',
              dd_error_message: null,
              checksum: 2851541348,
            },
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_object_type: 'building',
            context_artifact_type_id: 'ct_200-3',
            context_id: 'AE_21-129396',
            order_number: 0,
            context_artifact_id_value: 'Bygning: RFH6.3.2.23: Løe Hustveit',
            checksum: 1426136861,
          },
        ],
        event_sub_type: null,
        is_restricted: false,
        restriction_type: 1,
        title: null,
        comments: [],
        attachments: [],
        images: [],
        has_images: 'false',
        videos: [],
        has_condition_assessment_events: false,
        has_insurance_events: false,
        has_purchase_events: false,
        has_publishing_events: false,
        has_analysis_events: false,
        has_annotation_events: false,
        has_entry_events: false,
        has_exit_events: false,
        has_loan_events: false,
        has_exhibition_events: false,
        has_location_events: false,
        has_observation_events: false,
        has_salvage_priority_events: false,
        has_treatment_events: false,
        thumbnail_id: 'AE_21-129396',
        updated_by_id: 'USER-23',
        updated_at: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1442307796000,
          dd_error: null,
          dd_error_message: null,
        },
        updated_by_id_value: 'RH (RH)',
        annotation_event_ids: [],
        artifact_type_id_value: 'Markering',
        adm_event_type_id_value: 'Annotering',
        created_by_id_value: 'RH (RH)',
        parent_id_value: 'Tilstandsvurdering bygning: FDV-runde: 129394',
        collection_id: 'ct_31-1',
        timespan: {
          from_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442300956000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3560640526,
          },
          to_date: {
            object_type: 'PrecisionDate',
            meta_type: 'sub_model',
            dd_precision: 'datetime',
            dd_date: 1442300956000,
            dd_error: null,
            dd_error_message: null,
            checksum: 3560640526,
          },
          timespan_event_description: null,
          object_type: 'TimespanEvent',
          meta_type: 'sub_model',
          context_id: 'AE_21-129396',
          revision_id: 142183,
          checksum: 2422542032,
        },
        image_id: 'IMG-35165',
        annotation_points: [
          {
            revision_id: 142183,
            no: 1,
            x1: 0.01376953,
            x2: 0.7137695000000001,
            y1: 0.3610721,
            y2: 0.9547051,
            a_type: 'box',
            a_description: 'Dryppskader på baksida (nordsida)',
            uuid: '2dd792ca-5b68-df12-dee1-880cfa79db6b',
            order_number: 1,
            color: null,
            object_type: 'AnnotationPoint',
            object_type_id: '9',
            meta_type: 'sub_model',
            context_id: 'AE_21-129396',
            checksum: 2858824690,
          },
        ],
        artifact_name: 'Markering: Dryppskader på baksida (nordsida): 129396',
        checksum: 2773168129,
      },
    ],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 129394',
    checksum: 818951293,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.23: Løe Hustveit',
  },

  'AE_13-129400': {
    artifact_id: 'AE_13-129400',
    identifier: '129400',
    uuid: '502779fc-40ce-49c6-9cf0-3cbe31098bc3',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442392088000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-23',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-80650',
        context_identifier: 'RFH6.7.T1.11',
        context_collection_id: 'ct_31-1',
        revision_id: 142187,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-129400',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.7.T1.11: Stovehus/Løe Bakken',
        checksum: 1912086371,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description:
        'Behov for vask av vegger til våren, vurdere måling av glas og dører. Innvendig utvask, vintersikring av tekstiler. KG 1',
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129400',
      revision_id: 142187,
      checksum: 3873914116,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-35166',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_13-129400',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 771231098,
        name: 'RFF2008-059-015.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35167',
        code: null,
        order_number: 1,
        motif_object: false,
        context_id: 'AE_13-129400',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1232670156,
        name: 'RFF2012-057-030.JPG',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-35168',
        code: null,
        order_number: 2,
        motif_object: false,
        context_id: 'AE_13-129400',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 1234570702,
        name: 'RFF2012-057-037.JPG',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-35166',
    updated_by_id: 'USER-23',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1442392088000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'RH (RH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'RH (RH)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1441985400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1441985400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3564834832,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-129400',
      revision_id: 142187,
      checksum: 486216128,
    },
    condition: {
      method: null,
      measure_class_type_id: 'ct_89-2',
      condition_description:
        'MILJØ_ Frittståande på nedsida av vegen. Graset er slått. Reint og ryddig rundt huset. FUNDAMENT: Tørrmur, ok. VEGGAR: Laft/stav/tørrmur, bordkledd, måla. Ein del svartsopp. GLAS: Noe malingsslitne. DØR: Noe malingssliten. TAK. Panner, ok. ANNA: Manglar skilting. Obs på vintersikring av tekstiler. Behov for utvask. KONKLUSJON: Bygning i god stand. TG 1',
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-129400',
      revision_id: 142187,
      measure_class_type_id_value: 'TK1: Vedlikehold/forebyggende konservering',
      checksum: 1779733691,
    },
    purpose: {
      purpose_description: 'Synfaring i samband med prosjektet Prioritering i bygningssamlinga',
      purpose_type_id: 'ct_50-9',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-129400',
      revision_id: 142187,
      purpose_type_id_value: 'FDV-runde',
      checksum: 1509307217,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [],
    is_current: true,
    annotation_events: [],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 129400',
    checksum: 2633904736,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.7.T1.11: Stovehus/Løe Bakken',
  },

  'AE_13-1144190': {
    artifact_id: 'AE_13-1144190',
    identifier: '1144190',
    uuid: '4da04a10-26a0-4076-956b-78653814eb5c',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1565961945000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-236586',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-414103',
        context_identifier: 'SS-J-05',
        context_collection_id: 'ct_31-1',
        revision_id: 997935,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1565961682000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3570733085,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-1144190',
        order_number: 0,
        context_artifact_id_value: 'Bygning: SS-J-05: Størhus, Jørstad',
        checksum: 282336965,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description: null,
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-1144190',
      revision_id: 997935,
      checksum: 1908543198,
    },
    title: {
      title: 'Rutinebasert tilstandsvurdering',
      object_type: 'AdmTitle',
      meta_type: 'sub_model',
      context_id: 'AE_13-1144190',
      revision_id: 997935,
      checksum: 3381729647,
    },
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-176260',
        code: 'ct_92-0',
        order_number: 0,
        motif_object: false,
        context_id: 'AE_13-1144190',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 3476295755,
        name: 'IMG-176260',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-177488',
        code: 'ct_92-0',
        order_number: 1,
        motif_object: false,
        context_id: 'AE_13-1144190',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 4063367337,
        name: 'IMG-177488',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-177491',
        code: 'ct_92-0',
        order_number: 2,
        motif_object: false,
        context_id: 'AE_13-1144190',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 4048556196,
        name: 'IMG-177491',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-176259',
        code: 'ct_92-0',
        order_number: 3,
        motif_object: false,
        context_id: 'AE_13-1144190',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 4050391206,
        name: 'IMG-176259',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-177489',
        code: 'ct_92-0',
        order_number: 4,
        motif_object: false,
        context_id: 'AE_13-1144190',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 4066054317,
        name: 'IMG-177489',
        format_code: 'JPEG',
      },
      {
        image_id: 'IMG-177490',
        code: 'ct_92-0',
        order_number: 5,
        motif_object: false,
        context_id: 'AE_13-1144190',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 4046262438,
        name: 'IMG-177490',
        format_code: 'JPEG',
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-176260',
    updated_by_id: 'USER-236586',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1565962440000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Ulven, Jørn (JUL)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'Ulven, Jørn (JUL)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1565961682000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3570733085,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: null,
        dd_date: null,
        dd_error: 'error.not_datetime',
        dd_error_message: null,
        checksum: 2851541348,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-1144190',
      revision_id: 997935,
      checksum: 702812679,
    },
    condition: {
      method: 'Listeføring',
      measure_class_type_id: 'ct_89-3',
      condition_description:
        'Bygningen er uten innredning, gulv og pipe/ovn, står utsatt til mot fukt fra grunn, begynnende råte i svillstokken nordvegg mot vest. Gjennomgående restaurering av vinduer.',
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-1144190',
      revision_id: 997936,
      measure_class_type_id_value: 'TK2: Moderate reparasjoner og/eller ytterligere undersøkelse',
      checksum: 3834600444,
    },
    purpose: {
      purpose_description: 'Vurdering mot Kulturrådet ',
      purpose_type_id: 'ct_50-15',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-1144190',
      revision_id: 997935,
      purpose_type_id_value: 'FDV-runde',
      checksum: 845554976,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [
      {
        bpca_id: 'BPCA-952947',
        order_number: 0,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-1144190',
        building_part_type: {
          building_part_type_id: 'ct_86-8',
          building_part_description: 'Drenering',
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-952947',
          revision_id: 997936,
          building_part_type_id_value: '21: Bygning» Grunn og fundamenter',
          checksum: 511976019,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-952947',
          revision_id: 997936,
          checksum: 829168414,
        },
        condition: {
          condition_description: 'Manglende drenering gjør at fukt står under inne i bygningen.',
          bp_condition_type_id: 'ct_51-TG3',
          consequence_type_id: 'ct_71-3',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-952947',
          revision_id: 997936,
          bp_condition_type_id_value: 'TG3: Kraftige eller alvorlige symptomer',
          consequence_type_id_value: 'KG2: På kort sikt',
          checksum: 1172912006,
        },
        treatment_suggestions: [],
        checksum: 2528909369,
      },
      {
        bpca_id: 'BPCA-952948',
        order_number: 1,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-1144190',
        building_part_type: {
          building_part_type_id: 'ct_86-10',
          building_part_description: 'Bunnstokk nordvegg hjørne mot vest',
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-952948',
          revision_id: 997936,
          building_part_type_id_value: '23: Bygning» Yttervegger',
          checksum: 3220057374,
        },
        recommendation: {
          recommendation: 'Tett takrenne ',
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-952948',
          revision_id: 997936,
          checksum: 1030693062,
        },
        condition: {
          condition_description: 'Råte i starten.',
          bp_condition_type_id: 'ct_51-TG2',
          consequence_type_id: 'ct_71-3',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-952948',
          revision_id: 997936,
          bp_condition_type_id_value: 'TG2: Vesentlige symptomer',
          consequence_type_id_value: 'KG2: På kort sikt',
          checksum: 3348440410,
        },
        treatment_suggestions: [],
        checksum: 3352762507,
      },
      {
        bpca_id: 'BPCA-952949',
        order_number: 2,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-1144190',
        building_part_type: {
          building_part_type_id: 'ct_86-83',
          building_part_description: 'Vinduer på østvegg og det siste på sørvegg',
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-952949',
          revision_id: 997936,
          building_part_type_id_value: '234: Bygning» Yttervegger» Vinduer, dører, porter',
          checksum: 762131110,
        },
        recommendation: {
          recommendation: 'Linoljebasert',
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-952949',
          revision_id: 997936,
          checksum: 634462428,
        },
        condition: {
          condition_description:
            'Vinduet på sørveggen trenger halvsåling eller nye sprosser/ramme, på østveggen holder det med kitt og maling',
          bp_condition_type_id: 'ct_51-TG2',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-952949',
          revision_id: 997936,
          bp_condition_type_id_value: 'TG2: Vesentlige symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 4009905288,
        },
        treatment_suggestions: [],
        checksum: 3356498061,
      },
      {
        bpca_id: 'BPCA-952950',
        order_number: 3,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-1144190',
        building_part_type: {
          building_part_type_id: 'ct_86-10',
          building_part_description: 'Hjørne sørvest ',
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-952950',
          revision_id: 997936,
          building_part_type_id_value: '23: Bygning» Yttervegger',
          checksum: 2865178284,
        },
        recommendation: {
          recommendation: 'overvåke',
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-952950',
          revision_id: 997936,
          checksum: 2875858928,
        },
        condition: {
          condition_description: 'Har noe av samme utbuling som nordvest hjørne',
          bp_condition_type_id: 'ct_51-TG1',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-952950',
          revision_id: 997936,
          bp_condition_type_id_value: 'TG1: Svake symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 1219375309,
        },
        treatment_suggestions: [],
        checksum: 3327268998,
      },
    ],
    is_current: true,
    annotation_events: [],
    artifact_name: 'Tilstandsvurdering bygning: Rutinebasert tilstandsvurdering',
    checksum: 1636512462,
    $$acl: {
      restrictions: [
        {
          name: 'accession',
          value: null,
          restrictions: [],
        },
        {
          name: 'entry',
          value: null,
          restrictions: [],
        },
        {
          name: 'exit',
          value: null,
          restrictions: [],
        },
        {
          name: 'collection',
          value: null,
          restrictions: [],
        },
        {
          name: 'purchase',
          value: null,
          restrictions: [],
        },
        {
          name: 'insurance',
          value: null,
          restrictions: [],
        },
        {
          name: 'id',
          value: null,
          restrictions: [],
        },
        {
          name: 'publishing',
          value: null,
          restrictions: [],
        },
        {
          name: 'salvage_priority',
          value: null,
          restrictions: [],
        },
      ],
    },
    $$context_name: 'Bygning: SS-J-05: Størhus, Jørstad',
  },
  'AE_13-943438': {
    artifact_id: 'AE_13-943438',
    identifier: '943438',
    uuid: '28508f79-0116-4145-a5cf-1cbbc9dec05f',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1484299177000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-239324',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-395544',
        context_identifier: 'SS-U-01',
        context_collection_id: 'ct_31-1',
        revision_id: 421653,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-943438',
        order_number: 0,
        context_artifact_id_value: 'Bygning: SS-U-01: Søre Hage',
        checksum: 126557859,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description: null,
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-943438',
      revision_id: 421654,
      checksum: 1766198949,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [],
    has_images: 'false',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: null,
    updated_by_id: 'USER-239324',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1484299177000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Sund, Torbjørn Trygve (TTSUND)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'Sund, Torbjørn Trygve (TTSUND)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1484321400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3561164807,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1484321400000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3561164807,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-943438',
      revision_id: 421654,
      checksum: 536220110,
    },
    condition: {
      method: null,
      measure_class_type_id: null,
      condition_description: null,
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-943438',
      revision_id: 421654,
      measure_class_type_id_value: null,
      checksum: 2615021509,
    },
    purpose: {
      purpose_description: null,
      purpose_type_id: 'ct_50-15',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-943438',
      revision_id: 421654,
      purpose_type_id_value: 'FDV-runde',
      checksum: 377821557,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [
      {
        bpca_id: 'BPCA-943441',
        order_number: 0,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943438',
        building_part_type: {
          building_part_type_id: 'ct_86-8',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943441',
          revision_id: 421654,
          building_part_type_id_value: '21: Bygning» Grunn og fundamenter',
          checksum: 2177637927,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943441',
          revision_id: 421654,
          checksum: 772414206,
        },
        condition: {
          condition_description: 'Noe fuktgjennomslag i kjeller.',
          bp_condition_type_id: 'ct_51-TG1',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943441',
          revision_id: 421654,
          bp_condition_type_id_value: 'TG1: Svake symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 461711901,
        },
        treatment_suggestions: [],
        checksum: 2280396809,
      },
      {
        bpca_id: 'BPCA-943440',
        order_number: 1,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943438',
        building_part_type: {
          building_part_type_id: 'ct_86-84',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943440',
          revision_id: 421654,
          building_part_type_id_value: '235: Bygning» Yttervegger» Utvendig kledning og overflate',
          checksum: 2655002202,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943440',
          revision_id: 421654,
          checksum: 767892221,
        },
        condition: {
          condition_description: 'Generell overfladisk slitasje.',
          bp_condition_type_id: 'ct_51-TG1',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943440',
          revision_id: 421654,
          bp_condition_type_id_value: 'TG1: Svake symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 689777248,
        },
        treatment_suggestions: [],
        checksum: 3090618457,
      },
      {
        bpca_id: 'BPCA-943439',
        order_number: 2,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943438',
        building_part_type: {
          building_part_type_id: 'ct_86-115',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943439',
          revision_id: 421654,
          building_part_type_id_value: '271: Bygning» Fast inventar» Murte piper og ildsteder',
          checksum: 3171032717,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943439',
          revision_id: 421654,
          checksum: 804002565,
        },
        condition: {
          condition_description: 'Frostsprengning i pipe over tak.',
          bp_condition_type_id: 'ct_51-TG1',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943439',
          revision_id: 421654,
          bp_condition_type_id_value: 'TG1: Svake symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 2185898717,
        },
        treatment_suggestions: [],
        checksum: 3119978594,
      },
    ],
    is_current: true,
    annotation_events: [],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 943438',
    checksum: 3207737412,
    $$acl: {
      restrictions: [
        {
          name: 'accession',
          value: null,
          restrictions: [],
        },
        {
          name: 'entry',
          value: null,
          restrictions: [],
        },
        {
          name: 'exit',
          value: null,
          restrictions: [],
        },
        {
          name: 'collection',
          value: null,
          restrictions: [],
        },
        {
          name: 'purchase',
          value: null,
          restrictions: [],
        },
        {
          name: 'insurance',
          value: null,
          restrictions: [],
        },
        {
          name: 'id',
          value: null,
          restrictions: [],
        },
        {
          name: 'publishing',
          value: null,
          restrictions: [],
        },
        {
          name: 'salvage_priority',
          value: null,
          restrictions: [],
        },
      ],
    },
    $$context_name: 'Bygning: SS-U-01: Søre Hage',
  },

  'AE_13-943119': {
    artifact_id: 'AE_13-943119',
    identifier: '943119',
    uuid: '4ba568ab-1757-43ec-ac65-28624e5dd80e',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1484232314000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-239324',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-395547',
        context_identifier: 'SS-U-04',
        context_collection_id: 'ct_31-1',
        revision_id: 419606,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-943119',
        order_number: 0,
        context_artifact_id_value: 'Bygning: SS-U-04: Julinbygningen',
        checksum: 136191658,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description: null,
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-943119',
      revision_id: 419607,
      checksum: 1757023910,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [],
    has_images: 'false',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: null,
    updated_by_id: 'USER-239324',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1484232314000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Sund, Torbjørn Trygve (TTSUND)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'Sund, Torbjørn Trygve (TTSUND)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1484235000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3561361415,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1484235000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3561361415,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-943119',
      revision_id: 419607,
      checksum: 524423631,
    },
    condition: {
      method: null,
      measure_class_type_id: null,
      condition_description: null,
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-943119',
      revision_id: 419607,
      measure_class_type_id_value: null,
      checksum: 2605060038,
    },
    purpose: {
      purpose_description: null,
      purpose_type_id: 'ct_50-15',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-943119',
      revision_id: 419607,
      purpose_type_id_value: 'FDV-runde',
      checksum: 360520054,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [
      {
        bpca_id: 'BPCA-943121',
        order_number: 0,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943119',
        building_part_type: {
          building_part_type_id: 'ct_86-7',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943121',
          revision_id: 419607,
          building_part_type_id_value: '20: Bygning» Bygning, generelt',
          checksum: 2150506022,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943121',
          revision_id: 419607,
          checksum: 750590718,
        },
        condition: {
          condition_description: 'Fukt i etasjeskiller/kjeller.',
          bp_condition_type_id: 'ct_51-TG1',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943121',
          revision_id: 419607,
          bp_condition_type_id_value: 'TG1: Svake symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 3905300935,
        },
        treatment_suggestions: [],
        checksum: 2243827712,
      },
      {
        bpca_id: 'BPCA-943120',
        order_number: 1,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943119',
        building_part_type: {
          building_part_type_id: 'ct_86-84',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943120',
          revision_id: 419607,
          building_part_type_id_value: '235: Bygning» Yttervegger» Utvendig kledning og overflate',
          checksum: 2628918874,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943120',
          revision_id: 419607,
          checksum: 746068733,
        },
        condition: {
          condition_description: 'Generell slitasje.',
          bp_condition_type_id: 'ct_51-TG1',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943120',
          revision_id: 419607,
          bp_condition_type_id_value: 'TG1: Svake symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 18032038,
        },
        treatment_suggestions: [],
        checksum: 3052869712,
      },
    ],
    is_current: true,
    annotation_events: [],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 943119',
    checksum: 3474337859,
    $$acl: {
      restrictions: [
        {
          name: 'accession',
          value: null,
          restrictions: [],
        },
        {
          name: 'entry',
          value: null,
          restrictions: [],
        },
        {
          name: 'exit',
          value: null,
          restrictions: [],
        },
        {
          name: 'collection',
          value: null,
          restrictions: [],
        },
        {
          name: 'purchase',
          value: null,
          restrictions: [],
        },
        {
          name: 'insurance',
          value: null,
          restrictions: [],
        },
        {
          name: 'id',
          value: null,
          restrictions: [],
        },
        {
          name: 'publishing',
          value: null,
          restrictions: [],
        },
        {
          name: 'salvage_priority',
          value: null,
          restrictions: [],
        },
      ],
    },
    $$context_name: 'Bygning: SS-U-04: Julinbygningen',
  },

  'AE_13-943072': {
    artifact_id: 'AE_13-943072',
    identifier: '943072',
    uuid: 'd94d559f-c12d-471b-9cd7-54d2079e6b83',
    authority: null,
    adm_event_type_id: 'ct_49-13',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1484227432000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-239324',
    parent_id: null,
    object_type: 'BuildingConditionAssessmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_13',
    artifact_type_id: 'ct_200-213',
    contexts: [
      {
        context_artifact_id: '3-395868',
        context_identifier: 'SS-U-12',
        context_collection_id: 'ct_31-1',
        revision_id: 419507,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_13-943072',
        order_number: 0,
        context_artifact_id_value: 'Bygning: SS-U-12: Pakkhuset fra Brøttum stasjon',
        checksum: 163192493,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    recommendation: {
      recommendation_description: null,
      object_type: 'Recommendation',
      meta_type: 'sub_model',
      context_id: 'AE_13-943072',
      revision_id: 419510,
      checksum: 1751781021,
    },
    title: null,
    comments: [],
    attachments: [],
    images: [],
    has_images: 'false',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: null,
    updated_by_id: 'USER-239324',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1484227432000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Sund, Torbjørn Trygve (TTSUND)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Tilstandsvurdering bygning',
    adm_event_type_id_value: 'Tilstandsvurdering bygning',
    created_by_id_value: 'Sund, Torbjørn Trygve (TTSUND)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1484235000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3561361415,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1484235000000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3561361415,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_13-943072',
      revision_id: 419510,
      checksum: 517870022,
    },
    condition: {
      method: null,
      measure_class_type_id: null,
      condition_description: null,
      object_type: 'BuildingCondition',
      meta_type: 'sub_model',
      context_id: 'AE_13-943072',
      revision_id: 419510,
      measure_class_type_id_value: null,
      checksum: 2599423933,
    },
    purpose: {
      purpose_description: null,
      purpose_type_id: 'ct_50-15',
      object_type: 'BuildingPurposeConditionAssessment',
      meta_type: 'sub_model',
      context_id: 'AE_13-943072',
      revision_id: 419510,
      purpose_type_id_value: 'FDV-runde',
      checksum: 351213933,
    },
    building_treatment_suggestions: [],
    building_parts_condition_assessments: [
      {
        bpca_id: 'BPCA-943074',
        order_number: 0,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943072',
        building_part_type: {
          building_part_type_id: 'ct_86-106',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943074',
          revision_id: 419510,
          building_part_type_id_value: '261: Bygning» Yttertak» Primærkonstruksjoner',
          checksum: 3144294022,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943074',
          revision_id: 419510,
          checksum: 781916926,
        },
        condition: {
          condition_description:
            'For svak tak konstruksjon vestlig side, og skjev belastning på kontruksjonen, da det ikke er det orginale taket. For stor vektbelastning.',
          bp_condition_type_id: 'ct_51-TG3',
          consequence_type_id: 'ct_71-3',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943074',
          revision_id: 419510,
          bp_condition_type_id_value: 'TG3: Kraftige eller alvorlige symptomer',
          consequence_type_id_value: 'KG2: På kort sikt',
          checksum: 2065781380,
        },
        treatment_suggestions: [],
        checksum: 2260146181,
      },
      {
        bpca_id: 'BPCA-943076',
        order_number: 1,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943072',
        building_part_type: {
          building_part_type_id: 'ct_86-84',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943076',
          revision_id: 419510,
          building_part_type_id_value: '235: Bygning» Yttervegger» Utvendig kledning og overflate',
          checksum: 2682330717,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943076',
          revision_id: 419510,
          checksum: 790960896,
        },
        condition: {
          condition_description: 'Generell slitasje på overflaten.',
          bp_condition_type_id: 'ct_51-TG1',
          consequence_type_id: 'ct_71-2',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943076',
          revision_id: 419510,
          bp_condition_type_id_value: 'TG1: Svake symptomer',
          consequence_type_id_value: 'KG1: På middels lang sikt',
          checksum: 3540462583,
        },
        treatment_suggestions: [],
        checksum: 3080853592,
      },
      {
        bpca_id: 'BPCA-943075',
        order_number: 2,
        object_type: 'BuildingPartConditionAssessment',
        meta_type: 'sub_model',
        context_id: 'AE_13-943072',
        building_part_type: {
          building_part_type_id: 'ct_86-8',
          building_part_description: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: 'BPCA-943075',
          revision_id: 419510,
          building_part_type_id_value: '21: Bygning» Grunn og fundamenter',
          checksum: 2194021928,
        },
        recommendation: {
          recommendation: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: 'BPCA-943075',
          revision_id: 419510,
          checksum: 786438911,
        },
        condition: {
          condition_description: 'Mangel av fundament under bærende konstruksjon. Bevegelse i grunn.',
          bp_condition_type_id: 'ct_51-TG3',
          consequence_type_id: 'ct_71-3',
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: 'BPCA-943075',
          revision_id: 419510,
          bp_condition_type_id_value: 'TG3: Kraftige eller alvorlige symptomer',
          consequence_type_id_value: 'KG2: På kort sikt',
          checksum: 223295600,
        },
        treatment_suggestions: [],
        checksum: 3077249112,
      },
    ],
    is_current: true,
    annotation_events: [],
    artifact_name: 'Tilstandsvurdering bygning: FDV-runde: 943072',
    checksum: 1309356063,
    $$acl: {
      restrictions: [
        {
          name: 'accession',
          value: null,
          restrictions: [],
        },
        {
          name: 'entry',
          value: null,
          restrictions: [],
        },
        {
          name: 'exit',
          value: null,
          restrictions: [],
        },
        {
          name: 'collection',
          value: null,
          restrictions: [],
        },
        {
          name: 'purchase',
          value: null,
          restrictions: [],
        },
        {
          name: 'insurance',
          value: null,
          restrictions: [],
        },
        {
          name: 'id',
          value: null,
          restrictions: [],
        },
        {
          name: 'publishing',
          value: null,
          restrictions: [],
        },
        {
          name: 'salvage_priority',
          value: null,
          restrictions: [],
        },
      ],
    },
    $$context_name: 'Bygning: SS-U-12: Pakkhuset fra Brøttum stasjon',
  },

  'AE_24-122176': {
    artifact_id: 'AE_24-122176',
    identifier: '122176',
    uuid: '2742744a-545e-46fc-8877-b9ca8b5db5f3',
    authority: null,
    adm_event_type_id: 'ct_49-24',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1435662936000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-181',
    parent_id: 'AE_17-121722',
    object_type: 'BuildingTreatmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_24',
    artifact_type_id: 'ct_200-225',
    contexts: [
      {
        context_artifact_id: '3-80634',
        context_identifier: 'RFH6.3.2.11',
        context_collection_id: 'ct_31-1',
        revision_id: 230507,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1494425542000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3566538772,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_24-122176',
        order_number: 0,
        context_artifact_id_value: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
        checksum: 1340284685,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    description: {
      description:
        'Taket må leggast om Noverande teglpanner blir tatt ned for gjenbruk. Supplering av panner frå lager. Det er nokre maskinstrokne panner (tenker at desse vert bytt ut med handpanner på baksida, då der er få maskinpanner). Det gamle troer var lagt for nevertekking. Dette er reparert med liknande bord der retta ligg inn. Me let dei ligga som dei gjer. På troet er der lagt eit lag med never. Denne er lagt feil veg, og me vel å snu han slik at fibrane går opp/ned. Etter som her ikkje skal vera torv på taket, må nevra festast med enkelte stifter. Det leggast sutaksplater oppå nevra + papp + trykkimpregnert slisker og lekter. Stubord, vindskier og vannbord skiftast til høvla furubord i same dimensjon og lengde som dei som blir tatt vekk. Det må sytast for spalte for avrenning av vatn mellom stubord og kassekledning. Stubord krabbast til pannene. Over dørene skal det settast opp takrenner i tre som festast til stubordet med sjølvlaga rennekrokar. Det er gode dråpeheller i pipa. I tillegg til det leggast det bly i overgangen tak/pipe som klemmast godt inntil. Her er ikkje feste i leiren. Me gjer ikkje noe med pipa i denne omgangen.',
      object_type: 'Description',
      meta_type: 'sub_model',
      context_id: 'AE_24-122176',
      revision_id: 160253,
      checksum: 1744028463,
    },
    time_spent: {
      hours: null,
      object_type: 'TimeSpent',
      meta_type: 'sub_model',
      context_id: 'AE_24-122176',
      revision_id: 124409,
      checksum: 347475069,
    },
    cost: {
      cost: null,
      currency_type_id: null,
      cost_remarks: null,
      object_type: 'Cost',
      meta_type: 'sub_model',
      context_id: 'AE_24-122176',
      revision_id: 124409,
      currency_type_id_value: null,
      checksum: 3466726013,
    },
    priority: {
      priority_type_id: 'ct_69-2',
      priority_remarks: null,
      revision_id: 124409,
      object_type: 'Priority',
      meta_type: 'sub_model',
      context_id: 'AE_24-122176',
      priority_type_id_value: 'Medium',
      checksum: 2329153208,
    },
    title: null,
    comments: [],
    attachments: [
      {
        attachment_id: 'ATT-212633',
        revision_id: 302903,
        no: 1,
        order_number: 0,
        object_type: 'AttachmentItem',
        object_type_id: 'ATTACHMENT_ITEM',
        meta_type: 'sub_model',
        context_id: 'AE_24-122176',
        attachment_id_value: 'Dagrap.16.03.16-A01.pdf',
        checksum: 1874531940,
      },
    ],
    images: [
      {
        image_id: 'IMG-37192',
        code: null,
        order_number: 0,
        motif_object: false,
        context_id: 'AE_24-122176',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: null,
        checksum: 785649024,
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-37192',
    updated_by_id: 'USER-181',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1435662936000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'SH (SH)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Bygningsarbeid',
    adm_event_type_id_value: 'Bygningsbehandling',
    created_by_id_value: 'SH (SH)',
    parent_id_value: 'Avvik: Antikvarisk vedlikehold: 121722',
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1435678200000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3565031440,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1494425546000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3567587352,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_24-122176',
      revision_id: 152626,
      checksum: 502075844,
    },
    building_treatment: {
      object_type: 'BuildingTreatment',
      meta_type: 'sub_model',
      building_part: {
        building_part_type_id: null,
        object_type: 'BuildingTreatmentBuildingPart',
        meta_type: 'sub_model',
        context_id: 'AE_24-122176',
        revision_id: 160503,
        building_part_type_id_value: null,
        checksum: 2465338502,
      },
      cost: {
        cost: null,
        object_type: 'BuildingTreatmentCost',
        meta_type: 'sub_model',
        context_id: 'AE_24-122176',
        revision_id: 168529,
        checksum: 3890352490,
      },
      time_spent: {
        time_spent: null,
        object_type: 'BuildingTreatmentTimeSpent',
        meta_type: 'sub_model',
        context_id: 'AE_24-122176',
        revision_id: 168529,
        checksum: 1348473706,
      },
      material: {
        material: null,
        object_type: 'BuildingTreatmentMaterial',
        meta_type: 'sub_model',
        context_id: 'AE_24-122176',
        revision_id: 168529,
        checksum: 980620032,
      },
      checksum: 388041885,
    },
    responsible_actors: [
      {
        resp_actor_item_actor_id: 'P-3217',
        revision_id: 124409,
        no: 1,
        object_type: 'ResponsibleActorItem',
        object_type_id: '9',
        meta_type: 'sub_model',
        context_id: 'AE_24-122176',
        order_number: 0,
        role_code: '240',
        resp_actor_item_actor_id_value: 'Hoftun, Sven (1980)',
        actor_role_id_value: null,
        actor_role_status_id_value: null,
        checksum: 3741979814,
      },
    ],
    annotation_events: [],
    artifact_name:
      'Bygningsarbeid: Taket må leggast om Noverande teglpanner blir tatt ned for gjenbruk. Supplering av panner frå lager. Det er nokre maskinstrokne panner (tenker at desse vert bytt ut med handpanner på baksida, då der er få maskinpanner). Det gamle troer var lagt for nevertekking. Dette er reparert med liknande bord der retta ligg inn. Me let dei ligga som dei gjer. På troet er der lagt eit lag med never. Denne er lagt feil veg, og me vel å snu han slik at fibrane går opp/ned. Etter som her ikkje skal vera torv på taket, må nevra festast med enkelte stifter. Det leggast sutaksplater oppå nevra + papp + trykkimpregnert slisker og lekter. Stubord, vindskier og vannbord skiftast til høvla furubord i same dimensjon og lengde som dei som blir tatt vekk. Det må sytast for spalte for avrenning av vatn mellom stubord og kassekledning. Stubord krabbast til pannene. Over dørene skal det settast opp takrenner i tre som festast til stubordet med sjølvlaga rennekrokar. Det er gode dråpeheller i pipa. I tillegg t…: 122176',
    checksum: 65745106,
    $$acl: {
      restrictions: [],
    },
    $$context_name: 'Bygning: RFH6.3.2.11: Våningshus Hustveit',
    $$parent_name: 'Avvik: Antikvarisk vedlikehold: 121722',
  },

  'AE_24-1104927': {
    artifact_id: 'AE_24-1104927',
    identifier: '1104927',
    uuid: 'b2ac496c-9dc7-4ba2-b9a5-ebb1ede63011',
    authority: null,
    adm_event_type_id: 'ct_49-24',
    published: false,
    created_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1561471984000,
      dd_error: null,
      dd_error_message: null,
    },
    created_by_id: 'USER-236586',
    parent_id: null,
    object_type: 'BuildingTreatmentEvent',
    meta_type: 'adm_event',
    object_type_id: 'AE_24',
    artifact_type_id: 'ct_200-225',
    contexts: [
      {
        context_artifact_id: '3-414103',
        context_identifier: 'SS-J-05',
        context_collection_id: 'ct_31-1',
        revision_id: 832860,
        no: 1,
        remarks: null,
        from_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: 'datetime',
          dd_date: 1561471790000,
          dd_error: null,
          dd_error_message: null,
          checksum: 3565883413,
        },
        to_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        handed_in_date: {
          object_type: 'PrecisionDate',
          meta_type: 'sub_model',
          dd_precision: null,
          dd_date: null,
          dd_error: 'error.not_datetime',
          dd_error_message: null,
          checksum: 2851541348,
        },
        object_type: 'AdmEventContextItem',
        meta_type: 'sub_model',
        context_object_type: 'building',
        context_artifact_type_id: 'ct_200-3',
        context_id: 'AE_24-1104927',
        order_number: 0,
        context_artifact_id_value: 'Bygning: SS-J-05: Størhus, Jørstad',
        checksum: 315760316,
      },
    ],
    event_sub_type: null,
    is_restricted: false,
    restriction_type: 1,
    description: {
      description:
        'Restaurert 2 rammer i 1 etasje syd, slitasje og knust rute. Halvsålet begge i andre etasje syd, mot øst,pga slitasje.',
      object_type: 'Description',
      meta_type: 'sub_model',
      context_id: 'AE_24-1104927',
      revision_id: 832860,
      checksum: 3637787126,
    },
    title: {
      title: 'Nyrestaurerte vinduer',
      object_type: 'AdmTitle',
      meta_type: 'sub_model',
      context_id: 'AE_24-1104927',
      revision_id: 832860,
      checksum: 3336246588,
    },
    comments: [],
    attachments: [],
    images: [
      {
        image_id: 'IMG-177493',
        code: 'ct_92-0',
        order_number: 0,
        motif_object: false,
        context_id: 'AE_24-1104927',
        object_type: 'ImageItem',
        meta_type: 'sub_model',
        code_value: '0: Standard-bilde (Ekstern)',
        checksum: 3518107738,
      },
    ],
    has_images: 'true',
    videos: [],
    has_condition_assessment_events: false,
    has_insurance_events: false,
    has_purchase_events: false,
    has_publishing_events: false,
    has_analysis_events: false,
    has_annotation_events: false,
    has_entry_events: false,
    has_exit_events: false,
    has_loan_events: false,
    has_exhibition_events: false,
    has_location_events: false,
    has_observation_events: false,
    has_salvage_priority_events: false,
    has_treatment_events: false,
    thumbnail_id: 'IMG-177493',
    updated_by_id: 'USER-236586',
    updated_at: {
      object_type: 'PrecisionDate',
      meta_type: 'sub_model',
      dd_precision: 'datetime',
      dd_date: 1561472014000,
      dd_error: null,
      dd_error_message: null,
    },
    updated_by_id_value: 'Ulven, Jørn (JUL)',
    annotation_event_ids: [],
    artifact_type_id_value: 'Bygningsarbeid',
    adm_event_type_id_value: 'Bygningsbehandling',
    created_by_id_value: 'Ulven, Jørn (JUL)',
    parent_id_value: null,
    collection_id: 'ct_31-1',
    timespan: {
      from_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1546356600000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3565031440,
      },
      to_date: {
        object_type: 'PrecisionDate',
        meta_type: 'sub_model',
        dd_precision: 'datetime',
        dd_date: 1561044600000,
        dd_error: null,
        dd_error_message: null,
        checksum: 3559985159,
      },
      timespan_conservation_desc: null,
      object_type: 'TimespanConservation',
      meta_type: 'sub_model',
      context_id: 'AE_24-1104927',
      revision_id: 832861,
      checksum: 718606847,
    },
    building_treatment: {
      object_type: 'BuildingTreatment',
      meta_type: 'sub_model',
      building_part: {
        building_part_type_id: null,
        object_type: 'BuildingTreatmentBuildingPart',
        meta_type: 'sub_model',
        context_id: 'AE_24-1104927',
        revision_id: 832860,
        building_part_type_id_value: null,
        checksum: 2700022983,
      },
      cost: {
        cost: null,
        object_type: 'BuildingTreatmentCost',
        meta_type: 'sub_model',
        context_id: 'AE_24-1104927',
        revision_id: 832860,
        checksum: 4094497179,
      },
      time_spent: {
        time_spent: null,
        object_type: 'BuildingTreatmentTimeSpent',
        meta_type: 'sub_model',
        context_id: 'AE_24-1104927',
        revision_id: 832860,
        checksum: 1569985435,
      },
      material: {
        material: null,
        object_type: 'BuildingTreatmentMaterial',
        meta_type: 'sub_model',
        context_id: 'AE_24-1104927',
        revision_id: 832860,
        checksum: 1198658353,
      },
      checksum: 388041885,
    },
    responsible_actors: [],
    annotation_events: [],
    artifact_name: 'Bygningsarbeid: Nyrestaurerte vinduer',
    checksum: 1581067751,
    $$acl: {
      restrictions: [
        {
          name: 'accession',
          value: null,
          restrictions: [],
        },
        {
          name: 'entry',
          value: null,
          restrictions: [],
        },
        {
          name: 'exit',
          value: null,
          restrictions: [],
        },
        {
          name: 'collection',
          value: null,
          restrictions: [],
        },
        {
          name: 'purchase',
          value: null,
          restrictions: [],
        },
        {
          name: 'insurance',
          value: null,
          restrictions: [],
        },
        {
          name: 'id',
          value: null,
          restrictions: [],
        },
        {
          name: 'publishing',
          value: null,
          restrictions: [],
        },
        {
          name: 'salvage_priority',
          value: null,
          restrictions: [],
        },
      ],
    },
    $$context_name: 'Bygning: SS-J-05: Størhus, Jørstad',
  },
};
