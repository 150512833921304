import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Box } from '@material-ui/core';
import queryString from 'query-string';

import { Link, useLocation } from 'react-router-dom';

import { getDocumentRoutingName } from 'helpers/getDocumentRoutingName';

import HomeEventListItem from 'components/HomeEventListItem/HomeEventListItem';

import PrimaryButton from 'modules/Building/shared/PrimaryButton/PrimaryButton';

interface Props {
  treatments: any[];
  tasks?: boolean;
}

const SLICE_INDEX = 30;

const SearchList = (props: Props) => {
  const [sliceIndex, setSliceIndex] = useState(SLICE_INDEX);
  const location = useLocation();
  const searchQuery = queryString.parse(location.search).query || '';

  const { treatments, tasks } = props;

  return (
    <>
      <StyledListWrap>
        <Grid container>
          {props.treatments.slice(0, sliceIndex).map(treatment => {
            const pathLink =
              getDocumentRoutingName(treatment.artifact_type_id_value) || tasks
                ? `/building/${treatment['contexts.context_artifact_id'][0]}/${
                    tasks ? 'task' : getDocumentRoutingName(treatment.artifact_type_id_value)
                  }/${treatment.artifact_id || treatment.uuid}`
                : '/';

            return (
              <Grid item xs={12} key={treatment.artifact_id}>
                <Link
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                  to={{
                    pathname: pathLink,
                    state: { fromSearch: true, query: searchQuery },
                  }}
                >
                  <HomeEventListItem object={treatment} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </StyledListWrap>
      {treatments.length > sliceIndex ? (
        <ButtonContainer>
          <PrimaryButton label="Vis Mer" onClick={() => setSliceIndex(sliceIndex + SLICE_INDEX)} />
        </ButtonContainer>
      ) : null}
    </>
  );
};

const StyledListWrap = styled(Box)`
  background-color: #ffffff;
`;

const ButtonContainer = styled.div`
  padding: 8px 0;
`;

export default SearchList;
