import React from 'react';
import { Grid } from '@material-ui/core';
import WorkBuildingPartDragItem from '../WorkBuildingPartDragItem/WorkBuildingPartDragItem';
import WorkCommentsPanel from '../../WorkCommentsPanel/WorkCommentsPanel';

interface Props {
  dragItems: any[];
  isCommentsPanelOpen: boolean;
  selectedEdit: string | null;
  handleToggleCommentsPanel: (id: string) => void;
  handleSaveCommentsPanel: (item: any) => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  handleCloseCommentsPanel: () => void;
  handleDeleteComment: (id: string) => void;
  disabledConditions: boolean;
}

const WorkBuildingPartDrags: React.FC<Props> = props => {
  const selectedDragItem = props.dragItems.find(
    item =>
      props.selectedEdit !== null &&
      ((item.no && item.no === props.selectedEdit) || (item.order_number && item.order_number === props.selectedEdit)),
  );

  return (
    <>
      {props.dragItems.length > 0 && (
        <Grid item container direction="column" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {props.dragItems.map(({ no, description, _destroy, order_number }, index) => {
            if (_destroy) return null;

            const editPanelRenderRule =
              selectedDragItem &&
              props.isCommentsPanelOpen &&
              ((selectedDragItem.no && selectedDragItem.no === no) ||
                (selectedDragItem.order_number !== null && selectedDragItem.order_number === order_number));

            // render actor panel instead drag if editing
            if (editPanelRenderRule) {
              return (
                <Grid key={no || order_number} item style={{ position: 'relative' }}>
                  <WorkCommentsPanel
                    isEditing={!!props.selectedEdit}
                    drag={selectedDragItem || { description: '' }}
                    onSaveCommentsPanel={props.handleSaveCommentsPanel}
                    handleCloseCommentsPanel={props.handleCloseCommentsPanel}
                    dragsLength={props.dragItems.length}
                  />
                </Grid>
              );
            }
            return (
              <WorkBuildingPartDragItem
                key={no || order_number}
                id={no || order_number}
                index={index}
                title={description}
                handleEdit={() => props.handleToggleCommentsPanel(no === null ? order_number : no)}
                onDeleteDrag={() => props.handleDeleteComment(no === null ? order_number : no)}
                moveCard={props.moveDragItem}
                disabledConditions={props.disabledConditions}
              />
            );
          })}
        </Grid>
      )}
      {/* Render actor panel when new report */}
      {props.isCommentsPanelOpen && !selectedDragItem && (
        <Grid item style={{ position: 'relative' }}>
          <WorkCommentsPanel
            isEditing={!!props.selectedEdit}
            drag={selectedDragItem || { description: '' }}
            onSaveCommentsPanel={props.handleSaveCommentsPanel}
            handleCloseCommentsPanel={props.handleCloseCommentsPanel}
            dragsLength={props.dragItems.length}
          />
        </Grid>
      )}
    </>
  );
};

export default WorkBuildingPartDrags;
