import React from 'react';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import StyledAppBar from '../../../shared/StyledAppBar/StyledAppBar';

interface Props {
  handleClose: () => void;
  name: string;
}

const SliderBar: React.FC<Props> = ({ handleClose, name }) => (
  <StyledAppBar position="fixed" color="#ffffff">
    <StyledToolbar>
      <Typography variant="h5" style={{ flex: 1 }}>
        {name}
      </Typography>
      <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </StyledToolbar>
  </StyledAppBar>
);

const StyledToolbar = styled(Toolbar)`
  height: 64px;
  &.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;
  }
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }
  @media (max-width: 767px) {
    &.MuiToolbar-gutters {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

export default SliderBar;
