import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Paper, Typography } from '@material-ui/core';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';

interface Props {
  onSaveCommentsPanel: (item: any) => void;
  handleCloseCommentsPanel: () => void;
  drag: any;
  isEditing: boolean;
  dragsLength: number;
}

const WorkCommentsPanel: React.FC<Props> = props => {
  // state
  const [comment, setComment] = useState(props.drag.description || '');

  // handlers
  const handleCommentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setComment(event.target.value as string);
  };
  const handleSaveComment = () => {
    if (comment === '') return;

    let newComment: any = {};
    if (props.isEditing) {
      newComment = {
        ...props.drag,
        model_title: null,
        artifact_name_upper: null,
        description: comment,
        _update: true,
      };
    } else {
      newComment = {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'SuperObjectCommentItem',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        no: null,
        order_number: props.dragsLength,
        context_object_type: null,
        context_meta_type: null,
        artifact_id: null,
        created_by_id: null,
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        artifact_name: null,
        object_type_id: null,
        artifact_type_id: null,
        artifact_type_id_value: null,
        _destroy: null,
        updated_by_id: null,
        description: comment,
        _create: true,
      };
    }

    props.onSaveCommentsPanel(newComment);
    props.handleCloseCommentsPanel();
  };

  return (
    <StyledFormWrapper>
      <Typography variant="h4" style={{ paddingBottom: 28 }}>
        {comment || 'Uten kommentar'}
      </Typography>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <FormTextArea value={comment} handleChange={handleCommentChange} label="Kommentar" />
        </Grid>

        <Grid item>
          <OutlineButton padding="16px 30px" onClick={handleSaveComment}>
            Lagre
          </OutlineButton>
        </Grid>
      </Grid>
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled(Paper)`
  &.MuiPaper-root {
    padding: 18px;
    background-color: #ffffff;
  }
`;

export default WorkCommentsPanel;
