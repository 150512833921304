import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, Button, useMediaQuery } from '@material-ui/core';
import PrimaryButton from '../../shared/PrimaryButton/PrimaryButton';
import { ReactComponent as CheckMarkIcon } from '../../../../assets/icons/checkMarkWhite.svg';

interface Props {
  onSave?: () => void;
  onBackClick?: () => void;
  saveLabel?: string;
  isLastStep?: boolean;
  withoutBack?: boolean;
}

const SaveOrCancel = (props: Props) => {
  const mediaMatch = useMediaQuery('(max-width: 483px)');
  return (
    <Grid container wrap="nowrap" alignItems="center" style={{ width: '70%' }}>
      <PrimaryButton
        label={props.saveLabel || 'Lagre og gå videre'}
        icon={<CheckMarkIcon />}
        onClick={props.isLastStep ? props.onSave : props.onSave}
      />
      {!props.withoutBack && (
        <Grid item container alignItems="center" xs={6} direction={mediaMatch ? 'column' : 'row'}>
          <StyledButton onClick={props.onBackClick || (() => null)}>
            <StyledTypography component="span">Gå tilbake</StyledTypography>
          </StyledButton>
        </Grid>
      )}
    </Grid>
  );
};

const StyledTypography = styled(({ color, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    font-size: 0.875rem;
  }
`;

const StyledButton = styled(({ textColor, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    margin: 0 1rem;
    text-transform: none;
    font-weight: 400;
    font-size: 0.875rem;
  }
`;

export default SaveOrCancel;
