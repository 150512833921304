import React from 'react';
import { Box, Grid } from '@material-ui/core';

import ListsItem from '../../shared/ListsItem/ListsItem';

interface Props {
  handleOpenEditReport: (id: string) => void;
  conditionAssessmentEvents: any[];
  handleEditMode: (status: boolean) => void;
  buildingCollectionId: string;
  buildingAcl: { restrictions: any[] };
  handleOpenDeleteModal: (id: string) => void;
}

const ObservationStateList = (props: Props) => {
  const onOpenWithEdit = async (id: string) => {
    await props.handleOpenEditReport(id);
    props.handleEditMode(true);
  };

  return (
    <>
      {props.conditionAssessmentEvents.length > 0 && (
        <Box>
          <Grid container>
            {props.conditionAssessmentEvents.map(condition => (
              <Grid item xs={12} key={condition.artifact_id || condition.uuid}>
                <ListsItem
                  item={condition}
                  onOpen={() => props.handleOpenEditReport(condition.artifact_id || condition.uuid)}
                  onDelete={() => props.handleOpenDeleteModal(condition.artifact_id || condition.uuid)}
                  onOpenWithEdit={() => onOpenWithEdit(condition.artifact_id || condition.uuid)}
                  buildingCollectionId={props.buildingCollectionId}
                  buildingAcl={props.buildingAcl}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ObservationStateList;
