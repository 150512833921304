import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import throttle from 'lodash/throttle';

import BackdropSpinner from 'components/BackdropSpinner/BackdropSpinner';

interface Props {
  src: string;
  annotationPoints: any[];
}

const Slide: React.FC<Props> = ({ src, annotationPoints }) => {
  const imageWrapRef = useRef<HTMLImageElement>(null);
  const [annotations, setAnnotations] = useState<any[]>(annotationPoints);
  const [windowSize, setWindowSize] = useState(0);
  const [imageOnLoadTrigger, setImageOnLoadTrigger] = useState(false);
  const [isImageLoading, setImageLoading] = useState(true);

  const imageWrap: any = imageWrapRef.current;

  useEffect(() => {
    setImageLoading(true);
    const imageWrap: any = imageWrapRef.current;
    window.addEventListener('resize', throttledHandleResize);

    if (imageWrap) {
      imageWrap.onload = function() {
        setImageOnLoadTrigger(true);
        setImageLoading(false);
      };
    }

    return () => window.removeEventListener('resize', throttledHandleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    if (!imageOnLoadTrigger) return;
    setAnnotations([...annotationPoints]);
  }, [annotationPoints, imageOnLoadTrigger]);

  useEffect(() => {
    setAnnotations([...annotationPoints]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    setAnnotations([...annotationPoints]);
  }, [annotationPoints, src]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWindowSize(windowSize);
  };
  const throttledHandleResize = throttle(handleResize, 500);

  const calcWidth = (item: any, wrapperWidth: number) => {
    const x1InPx = item.x1 * wrapperWidth;
    const x2InPx = item.x2 * wrapperWidth;

    return x2InPx - x1InPx;
  };
  const calcHeight = (item: any, wrapperHeight: number) => {
    const y1InPx = item.y1 * wrapperHeight;
    const y2InPx = item.y2 * wrapperHeight;

    return y2InPx - y1InPx;
  };

  return (
    <StyledImageWrap>
      {isImageLoading && <BackdropSpinner />}
      <StyledBoxWrap isLoading={isImageLoading}>
        {annotations.map((annot: any) => {
          return (
            <StyledBadge
              key={annot.uuid}
              color={annot.color}
              borderRadius={annot.a_type === 'square' ? '0' : '50%'}
              top={annot.y1 * (imageWrap && imageWrap.height) || 0}
              left={annot.x1 * (imageWrap && imageWrap.width) || 0}
              width={calcWidth(annot, (imageWrap && imageWrap.width) || 0)}
              height={calcHeight(annot, (imageWrap && imageWrap.height) || 0)}
            />
          );
        })}
        {src ? (
          <StyledImg src={src} ref={imageWrapRef} />
        ) : (
          <ImageIcon style={{ color: '#d6d6d6', width: '2em', height: '2em' }} />
        )}
      </StyledBoxWrap>
    </StyledImageWrap>
  );
};

const StyledImg = styled.img`
  display: block;
  height: 100%;
  max-width: 100%;

  @media (max-width: 767px) {
    object-fit: cover;
  }
`;

const StyledImageWrap = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBadge = styled(({ color, borderRadius, top, left, width, height, ...props }) => <Box {...props} />)`
  border: 2px solid ${props => props.color};
  background-color: transparent;
  border-radius: ${props => props.borderRadius};
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;
const StyledBoxWrap = styled(({ isLoading, ...props }) => <Box {...props} />)`
  position: relative;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: ${props => (props.isLoading ? 0 : 1)};
`;

export default Slide;
