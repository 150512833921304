import React from 'react';
import { Grid, Typography, IconButton, useMediaQuery } from '@material-ui/core';

// icons
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-24px.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/deleteIcon.svg';

interface Props {
  title: string;
  handleEdit?: () => void;
  onDeleteDrag?: () => void;
  titleVariantStyle?: string;
  disabled?: boolean;
  conditionLevel?: string;
  actionsAmount?: number;
  cost?: number;
  timings?: number;
  isActionDrag?: boolean;
  consequenceLevel?: string;
  responsibilities?: string;
}

const DragItem: React.FC<Props> = props => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const mediumMediaMatch = useMediaQuery('(max-width: 470px)');
  const getActionEstimatesTitle = () => {
    if (props.cost && props.timings) {
      return `${props.cost} kr ― ${props.timings} timer`;
    }
    if (props.cost) {
      return `${props.cost} kr`;
    }
    if (props.timings) {
      return `${props.timings} timer`;
    }

    return 'Ingen estimater';
  };
  const getBuildingPartCaption = () => {
    let caption = '';

    if (props.conditionLevel) {
      caption += props.conditionLevel.slice(0, 3).toLowerCase();
    }
    if (props.consequenceLevel) {
      if (caption === '') {
        caption += props.consequenceLevel.slice(0, 3).toLowerCase();
      } else {
        caption += ` ― ${props.consequenceLevel.slice(0, 3).toLowerCase()}`;
      }
    }
    if (props.actionsAmount && props.actionsAmount > 0) {
      if (caption === '') {
        caption += `${props.actionsAmount} tiltak`;
      } else {
        caption += ` ― ${props.actionsAmount} tiltak`;
      }
    }

    return caption === '' ? null : caption;
  };

  const renderAdditionCaption = () => (
    <>
      {props.isActionDrag ? (
        <Grid item>
          <Typography style={{ paddingRight: 14 }} color="textSecondary">
            {getActionEstimatesTitle()}
          </Typography>
        </Grid>
      ) : null}
      {props.responsibilities && (
        <Grid item>
          <Typography color="textSecondary">{props.responsibilities}</Typography>
        </Grid>
      )}
      <Grid
        item
        container
        alignItems="center"
        style={{ width: 'auto', paddingTop: mediumMediaMatch ? '8px' : 0 }}
        wrap="nowrap"
      >
        <Typography color="textSecondary">{getBuildingPartCaption()}</Typography>
      </Grid>
    </>
  );

  return (
    <Grid item container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
      <DragHandleIcon style={{ marginRight: 16 }} />
      <Grid
        item
        container
        alignItems={mediumMediaMatch ? 'flex-start' : 'center'}
        wrap="nowrap"
        style={{ paddingRight: 50 }}
        direction={mediumMediaMatch ? 'column' : 'row'}
      >
        <Grid item>
          <Typography variant={(props.titleVariantStyle as any) || 'h4'} style={{ paddingRight: 14 }}>
            {mediaMatch ? props.title : props.title.replace(/:.*»/g, ': ... »')}
          </Typography>
        </Grid>
        {renderAdditionCaption()}
      </Grid>
      {!props.disabled && (
        <Grid item container xs={1} justify="flex-end" alignItems="center" spacing={1} wrap="nowrap">
          {props.handleEdit && (
            <Grid item>
              <IconButton edge="start" color="inherit" onClick={props.handleEdit || (() => null)}>
                <EditIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={props.onDeleteDrag}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DragItem;
