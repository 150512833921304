import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Button, Typography, Paper } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import { nb } from 'date-fns/locale';

// icons
import { ReactComponent as EditIcon } from 'assets/icons/edit-24px.svg';
import EditButton from '../EditButton/EditButton';

interface Props {
  date: number;
  status: string;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  currentDocumentType?: string;
  currentDocumentStatus?: string;
  handleEditSaveTrigger?: (status: boolean) => void;
  isEditDocumentChanged?: boolean;
}

const BottomStatusBar = (props: Props) => {
  return (
    <StyledWrap square elevation={3} isEditMode={props.isEditMode}>
      <StyledBox>
        <Grid container alignItems="center" justify="center" direction="row" wrap="nowrap" spacing={1}>
          <Grid item>
            <Typography
              color={props.isEditMode ? 'initial' : 'textSecondary'}
              variant="subtitle1"
              style={{ marginRight: 10 }}
            >
              {props.isEditMode ? 'Under arbeid' : props.status}
            </Typography>
          </Grid>

          <Grid item>
            <StyledTypography fontSize="0.75rem" whiteSpace style={{ marginRight: 12 }}>
              {formatDistance(props.date, new Date(), { addSuffix: true, locale: nb })}
            </StyledTypography>
          </Grid>
        </Grid>
      </StyledBox>
      {props.isEditMode ? (
        <EditButton
          label="Lagre"
          isChanged={props.isEditDocumentChanged || false}
          onClick={() => props?.handleEditSaveTrigger && props.handleEditSaveTrigger(true)}
        />
      ) : (
        <StyledIconButton onClick={() => props.handleEditMode(true)}>
          <EditIcon />
        </StyledIconButton>
      )}
    </StyledWrap>
  );
};

const StyledWrap = styled(({ isEditMode, ...props }) => <Paper {...props} />)`
  &.MuiPaper-root {
    color: ${props => (props.isEditMode ? '#ffffff' : '#000000')};
    background-color: ${props => (props.isEditMode ? '#2c2f38' : '#ffffff')};
    z-index: 1;
  }
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  bottom: 0;
  padding: 0;

  @media (max-width: 767px) {
    &.MuiPaper-root {
      height: auto;
      min-height: 56px;
    }
  }
`;
const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 18px;

  @media (max-width: 767px) {
    padding-right: 0;
  }
`;

const StyledIconButton = styled(props => <Button {...props} />)`
  &.MuiButton-root {
    background-color: #ffffff;
    padding: 19px 18px 19px 20px;
    border-radius: 0;
    text-transform: none;
    min-width: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);

    &:hover {
      background-color: #ffffff;
    }
  }
`;

const StyledTypography = styled(({ fontFamily, fontSize, whiteSpace, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    ${props => props.fontFamily && 'font-family: ' + props.fontFamily};
    ${props => props.fontSize && 'font-size: ' + props.fontSize};
    ${props => props.whiteSpace && 'white-space: nowrap'};
  }
`;

export default BottomStatusBar;
