import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Button, Typography, Divider, Paper } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import { nb } from 'date-fns/locale';

// icons
import VisibilityIcon from '@material-ui/icons/Visibility';

interface Props {
  date: number;
  status: string;
}

const InfoBottomButton = (props: Props) => {
  return (
    <StyledWrap square elevation={3}>
      <StyledBox color="#ffffff">
        <Grid container alignItems="center" justify="center" direction="row" wrap="nowrap" spacing={1}>
          <Grid item>
            <Typography color="textSecondary" variant="subtitle1" style={{ marginRight: 10 }}>
              {props.status || 'Unknown status'}
            </Typography>
          </Grid>

          <Grid item>
            <StyledTypography color="textSecondary" fontSize="0.75rem" whiteSpace style={{ marginRight: 12 }}>
              {formatDistance(props.date, new Date(), { addSuffix: true, locale: nb })}
            </StyledTypography>
          </Grid>
        </Grid>
      </StyledBox>
      <StyledDivider orientation="vertical" variant="middle" />
      <StyledIconButton>
        <VisibilityIcon style={{ width: 18, height: 18 }} />
      </StyledIconButton>
    </StyledWrap>
  );
};

const StyledWrap = styled(Paper)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  bottom: 0;
  color: #000000;
  background-color: #ffffff;
  padding: 0;
`;
const StyledBox = styled(({ color, ...props }) => <Box {...props} />)`
  display: flex;
  align-items: center;
  padding: 0 18px;
`;

const StyledIconButton = styled(props => <Button {...props} />)`
  &.MuiButton-root {
    background-color: #ffffff;
    padding: 19px 18px 19px 20px;
    border-radius: 0;
    text-transform: none;
    min-width: 0;

    &:hover {
      background-color: #ffffff;
    }
  }
`;
const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    position: absolute;
    right: 40px;
    top: 0;
    height: 100%;
  }
`;
const StyledTypography = styled(({ fontFamily, fontSize, whiteSpace, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    ${props => props.fontFamily && 'font-family: ' + props.fontFamily};
    ${props => props.fontSize && 'font-size: ' + props.fontSize};
    ${props => props.whiteSpace && 'white-space: nowrap'};
  }
`;

export default InfoBottomButton;
