import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import AssessmentFormDrags from './AssessmentFormDrags/AssessmentFormDrags';

interface Props {
  buildingParts: any[];
  buildingPartInput: { title: string; id: string };
  handleEdit: (newItem: any) => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  handleAddDragItem: (newItem: any) => void;
  handleChange: (value: string, options: any[]) => void;
  handleDeleteDragItem: (id: string) => void;
  isViewReportCondition: boolean;
  isEditPanelOpen: boolean;
  // handleOpenEditPanel: () => void;
  handleCloseEditPanel: () => void;
  handleToggleEditPanel: () => void;
  disabledConditions: boolean;
  isEditMode: boolean;
}

const ChildrenAssessmentForm = (props: Props) => {
  const [selectedEdit, setSelectedEdit] = useState<string | null>(null);

  // HANDLERS
  const onToggleEditPanel = (id: string) => {
    console.log(id);
    if (selectedEdit === null) {
      setSelectedEdit(id);
    } else {
      setSelectedEdit(null);
    }
    props.handleToggleEditPanel();
  };

  const onCloseEditPanel = () => {
    setSelectedEdit(null);
    props.handleCloseEditPanel();
  };
  const handleSaveEditPanel = (item: any) => {
    console.log(item);
    if (!selectedEdit || selectedEdit === null) {
      props.handleAddDragItem(item);
      props.handleCloseEditPanel();
    } else {
      props.handleEdit(item);
      onCloseEditPanel();
    }
  };
  const handleDeleteDrag = (id: string) => {
    props.handleDeleteDragItem(id);
    props.handleCloseEditPanel();
    setSelectedEdit(null);
  };

  // empty drag item for new drag creating
  const emptyDragItem: {
    building_part_type: {
      building_part_type_id_value: string;
      building_part_type_id: string;
      building_part_description: string;
    };
    condition: {
      bp_condition_type_id_value: string;
      bp_condition_type_id: string;
      consequence_type_id_value: string;
      consequence_type_id: string;
    };
    treatment_suggestions: any[];
  } = {
    building_part_type: {
      building_part_type_id: props.buildingPartInput.id,
      building_part_type_id_value: props.buildingPartInput.title,
      building_part_description: '',
    },
    condition: {
      bp_condition_type_id_value: '',
      bp_condition_type_id: '',
      consequence_type_id_value: '',
      consequence_type_id: '',
    },
    treatment_suggestions: [],
  };

  return (
    <Grid container direction="column">
      <AssessmentFormDrags
        dragItems={props.buildingParts}
        isEditPanelOpen={props.isEditPanelOpen}
        selectedEdit={selectedEdit}
        handleToggleEditPanel={onToggleEditPanel}
        handleSaveEditPanel={handleSaveEditPanel}
        emptyDragItem={emptyDragItem}
        moveDragItem={props.moveDragItem}
        handleCloseEditPanel={onCloseEditPanel}
        handleDeleteDrag={handleDeleteDrag}
        disabledConditions={props.disabledConditions}
        isEditMode={props.isEditMode}
      />
    </Grid>
  );
};

export default ChildrenAssessmentForm;
