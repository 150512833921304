import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import update from 'immutability-helper';
import { Typography, Paper, Grid, useMediaQuery, Box } from '@material-ui/core';

// icons
import { ReactComponent as CheckMarkIcon } from 'assets/icons/CheckMark.svg';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as CheckMarkDarkIcon } from 'assets/icons/CheckMark.svg';

import AssessmentEditForm from './AssessmentEditForm/AssessmentEditForm';
import OutlineBottomButton from '../../shared/OutlineBottomButton/OutlineBottomButton';
import AssessmentEditDrags from './AssessmentEditDrags/AssessmentEditDrags';
import SecondarySaveOrCancel from '../SecondarySaveOrCancel/SecondarySaveOrCancel';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';
import AssessmentActionForm from './AssessmentActionForm/AssessmentActionForm';

interface Props {
  onSaveEditPanel: (item: any) => void;
  drag: any;
  handleCloseEditPanel: () => void;
  dragsLength: number;
  isEditing: boolean;
  isEditMode: boolean;
}

const AssessmentEditPanel = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  // states
  const [consequence, setConsequence] = useState({
    title: props.drag.condition.consequence_type_id_value,
    id: props.drag.condition.consequence_type_id,
  });
  const [condition, setCondition] = useState({
    title: props.drag.condition.bp_condition_type_id_value,
    id: props.drag.condition.bp_condition_type_id,
  });
  const [structure, setStructure] = useState({
    title: props.drag.building_part_type.building_part_type_id_value,
    id: props.drag.building_part_type.building_part_type_id,
  });
  const [description, setDescription] = useState(props.drag.building_part_type.building_part_description);
  const [actions, setActions] = useState(props.drag.treatment_suggestions);

  const [actionSuggestion, setActionSuggestion] = useState('');
  const [actionDetailsShowStatus, setActionDetailsShowStatus] = useState('no');
  const [actionCost, setActionCost] = useState<number | null>(null);
  const [actionTimings, setActionTimings] = useState<number | null>(null);
  const [isActionFormOpen, setActionFormOpen] = useState(false);
  const [editedAction, setEditedAction] = useState<any>(null);

  useEffect(() => {
    if (editedAction) {
      setActionSuggestion(editedAction.description || '');
      setActionCost(editedAction.cost || null);
      setActionTimings(editedAction.time_spent || null);
      (editedAction.cost || editedAction.time_spent) && setActionDetailsShowStatus('yes');
    }
  }, [editedAction]);

  // handlers
  const handleStructureChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    setStructure(option);
  };

  const handleConditionChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    setCondition(option);
  };
  const handleConsequenceChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    setConsequence(option);
  };
  const handleDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string);
  };
  const handleActionSuggestionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActionSuggestion(event.target.value as string);
  };
  const handleActionDetailsShowStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionDetailsShowStatus((event.target as HTMLInputElement).value);
  };
  const handleActionCostChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActionCost(event.target.value as number);
  };
  const handleActionTimingsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActionTimings(event.target.value as number);
  };
  const handleSaveAction = () => {
    if (actionSuggestion === '') return;

    if (editedAction) {
      const edited = {
        ...editedAction,
        model_title: null,
        artifact_name_upper: null,
        description: actionSuggestion || null,
        material_description: null,
        cost: (actionCost && Number(actionCost)) || null,
        time_spent: (actionTimings && Number(actionTimings)) || null,
      };

      setActions(
        actions.map((action: any) => {
          if (
            (editedAction.uuid && editedAction.uuid === action.uuid) ||
            (editedAction.artifact_id && editedAction.artifact_id === action.artifact_id)
          ) {
            return edited;
          }

          return action;
        }),
      );
      setEditedAction(null);
    } else {
      const newAction = {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'BuildingPartTreatmentSuggestionItem',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        no: null,
        order_number: actions.length,
        context_object_type: null,
        context_meta_type: null,
        artifact_id: null,
        created_by_id: null,
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        artifact_name: null,
        object_type_id: 'BPTS',
        artifact_type_id: null,
        artifact_type_id_value: null,
        _destroy: null,
        uuid: uuid(),
        description: actionSuggestion || null,
        material_description: null,
        cost: (actionCost && Number(actionCost)) || null,
        cost_remarks: null,
        time_spent: (actionTimings && Number(actionTimings)) || null,
        _create: true,
      };
      setActions([...actions, newAction]);
    }

    setActionFormOpen(false);
    setActionSuggestion('');
    setActionDetailsShowStatus('no');
    setActionCost(null);
    setActionTimings(null);
    setActionFormOpen(false);
  };
  const handleDeleteAction = (id: string) => {
    const deletedItemIndex = actions.findIndex((item: any) => item.uuid === id || item.no === id);

    const newActions = actions.map((action: any, idx: number) => {
      if (idx === deletedItemIndex) {
        return { ...action, _destroy: true };
      }
      return action;
    });
    setActions(newActions);
  };
  const handleOpenActionForm = (id?: string) => {
    if (id) {
      const edited = actions.find((action: any) => action.artifact_id === id || action.uuid === id);
      setEditedAction(edited);
    }
    setActionFormOpen(true);
  };

  const handleSave = () => {
    if (structure.title === '' || structure.title === null) return;
    let newItem;
    if (!props.isEditing) {
      newItem = {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        uuid: uuid(),
        order_number: props.dragsLength,
        _destroy: null,
        context_id: null,
        context_object_type: null,
        context_meta_type: null,
        artifact_id: null,
        created_by_id: null,
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        artifact_name: null,
        object_type_id: 'BPCA',
        artifact_type_id: null,
        artifact_type_id_value: null,
        object_type: 'BuildingPartConditionAssessment',
        bpca_id: null,
        building_part_type: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'BuildingPartConditionAssessmentBuildingPartType',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          building_part_type_id: structure.id || null,
          building_part_type_id_value: structure.title || null,
          building_part_description: description || null,
        },
        condition: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'BuildingPartConditionAssessmentCondition',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          bp_condition_type_id: condition.id || null,
          bp_condition_type_id_value: condition.title || null,
          consequence_type_id: consequence.id || null,
          consequence_type_id_value: consequence.title || null,
          condition_description: null,
        },
        recommendation: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'BuildingPartConditionAssessmentRecommendation',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          recommendation: null,
        },
        treatment_suggestions: [...actions],
        _create: true,
      };
    } else {
      newItem = {
        model_title: null,
        artifact_name_upper: null,
        ...props.drag,
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        building_part_type: {
          model_title: null,
          artifact_name_upper: null,
          ...props.drag.building_part_type,
          building_part_type_id: structure.id || null,
          building_part_type_id_value: structure.title || null,
          building_part_description: description || null,
        },
        condition: {
          model_title: null,
          artifact_name_upper: null,
          ...props.drag.condition,
          bp_condition_type_id: condition.id || null,
          bp_condition_type_id_value: condition.title || null,
          consequence_type_id: consequence.id || null,
          consequence_type_id_value: consequence.title || null,
        },
        treatment_suggestions: [...actions],
      };
    }

    props.onSaveEditPanel(newItem);

    setConsequence({ title: '', id: '' });
    setCondition({ title: '', id: '' });
    setStructure({ title: '', id: '' });
    setDescription('');
    setActions([]);
  };

  // drag and drop
  const moveEditDragItem = useCallback(
    (dragIndex, hoverIndex) => {
      const editDragCard = actions[dragIndex];
      setActions(
        update(actions, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, editDragCard],
          ],
        }),
      );
    },
    [actions],
  );

  // actions without _destroy field
  const actionsWithoutDestroy = useMemo(() => actions.filter((i: any) => !i._destroy), [actions]);
  const editedActionIndex = useMemo(
    () =>
      actions.findIndex((action: any) => {
        if (
          (editedAction?.uuid && editedAction.uuid === action.uuid) ||
          (editedAction?.artifact_id && editedAction.artifact_id === action.artifact_id)
        ) {
          return true;
        }

        return false;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editedAction],
  );

  return (
    <>
      <StyledEditPanel square marginBottom={!mediaMatch && !isActionFormOpen ? '56px' : '0'}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justify="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <Typography component="span" variant="h4">
                {(mediaMatch ? structure.title : structure.title.replace(/:.*»/g, ': ... »')) || 'Uten tittel'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <AssessmentEditForm
              onSaveEditPanel={handleSave}
              description={description}
              consequence={consequence.title}
              condition={condition.title}
              structure={structure.title}
              handleStructureChange={handleStructureChange}
              handleConsequenceChange={handleConsequenceChange}
              handleConditionChange={handleConditionChange}
              handleDescriptionChange={handleDescriptionChange}
              actions={actions}
              moveEditDragItem={moveEditDragItem}
              onCloseEditPanel={props.handleCloseEditPanel}
              handleDeleteAction={handleDeleteAction}
              isEditing={props.isEditing}
              isEditMode={props.isEditMode}
            />
          </Grid>

          {actionsWithoutDestroy.length > 0 && (
            <Grid item>
              <Typography variant="subtitle2">{actionsWithoutDestroy.length} anbefalte tiltak</Typography>
            </Grid>
          )}

          {actionsWithoutDestroy.length > 0 && (
            <Grid item>
              <AssessmentEditDrags
                drags={actions}
                moveEditDragItem={moveEditDragItem}
                handleDeleteAction={handleDeleteAction}
                editedActionIndex={editedActionIndex}
                handleOpenWithEdit={handleOpenActionForm}
              />
            </Grid>
          )}

          {isActionFormOpen && (
            <Grid item>
              <AssessmentActionForm
                actionSuggestion={actionSuggestion}
                handleActionSuggestionChange={handleActionSuggestionChange}
                actionDetailsShowStatus={actionDetailsShowStatus}
                handleActionDetailsShowStatusChange={handleActionDetailsShowStatusChange}
                actionCost={actionCost}
                handleActionCostChange={handleActionCostChange}
                actionTimings={actionTimings}
                handleActionTimingsChange={handleActionTimingsChange}
              />
            </Grid>
          )}

          <Grid item>
            <OutlineButton
              padding="16px 30px"
              width={isActionFormOpen ? '' : '100%'}
              onClick={isActionFormOpen ? handleSaveAction : handleOpenActionForm}
            >
              <StyledBox>
                {isActionFormOpen ? (
                  <CheckMarkDarkIcon style={{ marginRight: 4 }} />
                ) : (
                  <AddIcon style={{ marginRight: 4, width: 18, height: 18 }} />
                )}
              </StyledBox>
              {isActionFormOpen ? 'Lagre og lukk' : 'Foreslå tiltak'}
            </OutlineButton>
          </Grid>

          {mediaMatch && !isActionFormOpen && (
            <Grid item style={{ paddingTop: 24 }}>
              <SecondarySaveOrCancel
                onSave={handleSave}
                onBackClick={props.handleCloseEditPanel}
                icon={CheckMarkDarkIcon}
              />
            </Grid>
          )}
        </Grid>
      </StyledEditPanel>
      {!mediaMatch && !isActionFormOpen && (
        <OutlineBottomButton label="Lagre og lukk" icon={<CheckMarkIcon />} onClick={handleSave} />
      )}
    </>
  );
};

const StyledEditPanel = styled(({ marginBottom, ...props }) => <Paper {...props} />)`
  &.MuiPaper-root {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 26px;
  }

  padding: 15px 12px;
  margin-bottom: 5px;

  @media (max-width: 767px) {
    &.MuiPaper-root {
      margin-bottom: ${props => props.marginBottom};
      padding: 16px;
    }
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

AssessmentEditPanel.defaultProps = {
  drag: {},
};

export default AssessmentEditPanel;
