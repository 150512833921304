import React from 'react';
import styled from 'styled-components';

import { AppBar, Toolbar, Typography, Grid, IconButton, Divider, useMediaQuery } from '@material-ui/core';

// icons
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth } from 'context/Auth';
import SearchBox from '../SearchBox/SearchBox';

interface Props {
  handleSearchOpen: () => void;
  handleToggleMenu: () => void;
  handleSearchClose: () => void;
  handleSearchInput: (value: string) => void;
  handleSearchSubmit: (event: React.BaseSyntheticEvent<Event, EventTarget & Element, EventTarget>) => void;
  searchInputValue: string;
  isSearchOpen: boolean;
}

const HomeAppBar = (props: Props) => {
  const displayVersion = process.env.REACT_APP_VERSION;

  const mediaMatch = useMediaQuery('(min-width: 768px)');
  const { userData } = useAuth();

  return (
    <StyledAppBar position="sticky">
      <StyledToolbar>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item container alignItems="center">
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={props.handleToggleMenu}>
              <MenuIcon />
            </IconButton>
            <StyledTypography color="#B2C7FF" fontSize="18px" fontWeight="700" margin="0 0 0 12px">
              Primus FDV
            </StyledTypography>
            <StyledTypography color="#B2C7FF" fontSize="10px" fontWeight="500" margin="0 0 0 10px" align="bottom">
              v{displayVersion}
            </StyledTypography>
            {mediaMatch && (
              <>
                <Divider
                  orientation="vertical"
                  light
                  style={{ backgroundColor: '#ffffff', height: 24 }}
                  variant="middle"
                />
                <StyledTypography color="rgba(255, 255, 255, 0.85)" fontSize="18px" fontWeight="400">
                  {userData?.user.name || ''}
                </StyledTypography>
              </>
            )}
          </Grid>
          <SearchBox
            handleSearchOpen={props.handleSearchOpen}
            handleSearchClose={props.handleSearchClose}
            handleSearchInput={props.handleSearchInput}
            handleSearchSubmit={props.handleSearchSubmit}
            searchInputValue={props.searchInputValue}
            isSearchOpen={props.isSearchOpen}
          />
        </Grid>
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(props => <AppBar {...props} />)`
  &.MuiAppBar-root {
    color: #ffffff;
    height: 64px;
    background-color: #2d2f37;
  }
`;
const StyledToolbar = styled(Toolbar)`
  height: 64px;
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }
`;
const StyledTypography = styled(({ color, fontSize, fontWeight, margin, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    ${props => props.color && 'color: ' + props.color};
    ${props => props.fontSize && 'font-size: ' + props.fontSize};
    ${props => props.fontWeight && 'font-weight: ' + props.fontWeight};
    ${props => props.margin && 'margin: ' + props.margin};
  }
`;

export default HomeAppBar;
