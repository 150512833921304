import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatDistance } from 'date-fns';
import { nb } from 'date-fns/locale';
import { Grid, Toolbar, IconButton, Typography, Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-24px.svg';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { BuildingType } from 'types/Building/BuildingType';
import { useAuth } from 'context/Auth';
import { getIsUserCanCreateOrEditObjectsByCollectionRole, getIsUserCanCreateOrEditObjectByAcl } from 'services/roles';
import StyledAppBar from '../../shared/StyledAppBar/StyledAppBar';
import EditButton from '../../shared/EditButton/EditButton';

interface Props {
  title: string;
  id: string;
  date: number;
  isNewReportCondition: boolean;
  isNewDeviationCondition: boolean;
  isViewReportCondition: boolean;
  isViewDeviationCondition: boolean;
  isNewWorkCondition: boolean;
  isViewWorkCondition: boolean;
  isNewTaskCondition: boolean;
  isViewTaskCondition: boolean;
  status: string;
  documentTitle: string[];
  onBack: () => void;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  currentStepNumber: number;
  stepsAmount: number;
  currentDocumentType: string;
  currentDocumentStatus: string;
  building: BuildingType;
  documentAcl: { restrictions: any[] };
  addBuildingToFavorites: () => void;
  removeBuildingFromFavorites: () => void;
  isFavorites: boolean;
  handleEditSaveTrigger: (status: boolean) => void;
  isEditDocumentChanged: boolean;
  preventEditing: boolean | undefined;
}

const ChildrenAppBar = (props: Props) => {
  const { userDataDetailed } = useAuth();
  const theme = useTheme();
  const mediaMatch = useMediaQuery('(min-width: 768px)');
  const isNewDocument =
    props.isNewReportCondition || props.isNewDeviationCondition || props.isNewWorkCondition || props.isNewTaskCondition;

  const isViewDocument =
    props.isViewReportCondition ||
    props.isViewDeviationCondition ||
    props.isViewWorkCondition ||
    props.isViewTaskCondition;

  const userCollectionRights = useMemo(() => userDataDetailed?.collection_rights ?? [], [userDataDetailed]);
  const isUserCanCreateAndEditInBuilding =
    getIsUserCanCreateOrEditObjectsByCollectionRole(props.building.collection_id, userCollectionRights) &&
    getIsUserCanCreateOrEditObjectByAcl(props.building.$$acl);
  const isUserCanCreateAndEditDocument =
    getIsUserCanCreateOrEditObjectsByCollectionRole(props.building.collection_id, userCollectionRights) &&
    getIsUserCanCreateOrEditObjectByAcl(props.building.$$acl) &&
    getIsUserCanCreateOrEditObjectByAcl(props.documentAcl);
  const handleFavoritesClick = props.isFavorites ? props.removeBuildingFromFavorites : props.addBuildingToFavorites;

  return (
    <StyledAppBar position="sticky" color={theme.palette.secondary.white}>
      <StyledToolbar>
        <StyledIconButton edge="start" color="default" aria-label="back" onClick={props.onBack}>
          <ArrowBackIcon />
        </StyledIconButton>

        <Grid
          container
          item
          alignItems="center"
          spacing={1}
          justify="flex-start"
          wrap={!isNewDocument && !isViewDocument ? 'nowrap' : 'wrap'}
        >
          {/* SPECIFY TITLE CONDITIONS */}
          {!mediaMatch && (isNewDocument || isViewDocument) ? null : (
            <Typography variant="h4" style={{ marginRight: 8 }}>
              {props.title}
            </Typography>
          )}

          {!isNewDocument && !isViewDocument && (
            <>
              <StyledTypography fontFamily="Akkurat Mono" whiteSpace color="textSecondary">
                {props.id}
              </StyledTypography>
              <IconButton
                color="inherit"
                size="small"
                style={{ margin: '-4px 0 0 4px' }}
                onClick={handleFavoritesClick}
              >
                {props.isFavorites ? <StarIcon /> : <StarBorderIcon />}
              </IconButton>
            </>
          )}

          <StyledGrid item>
            {mediaMatch && (isNewDocument || isViewDocument) && (
              <ArrowForwardIosIcon style={{ margin: '-2px 4px 0 0', width: 16, height: 16 }} />
            )}

            {(isViewDocument || isNewDocument) &&
              props.documentTitle.map((title, idx, arr) => (
                <React.Fragment key={idx}>
                  <Typography variant="h4" align="center">
                    {title}
                  </Typography>
                  {idx !== arr.length - 1 && (
                    <ArrowForwardIosIcon style={{ margin: '-2px 4px 0 4px', width: 16, height: 16 }} />
                  )}
                </React.Fragment>
              ))}
          </StyledGrid>
        </Grid>

        {/* STATUS CONDITIONS */}
        {mediaMatch && (
          <>
            {!isNewDocument && !isViewDocument && (
              <>
                <Typography variant="subtitle2" style={{ marginRight: 10 }}>
                  {props.status || 'Status'}
                </Typography>
                <StyledTypography fontSize="0.85rem" whiteSpace style={{ marginRight: 12 }}>
                  {formatDistance(props.date, new Date(), { addSuffix: true, locale: nb })}
                </StyledTypography>
                {isUserCanCreateAndEditInBuilding && !props.preventEditing && (
                  <StyledButton>
                    <EditIcon />
                  </StyledButton>
                )}
              </>
            )}
            {isNewDocument && (
              <>
                <StyledTypography variant="subtitle2" style={{ marginRight: 10 }} whiteSpace>
                  Ny rapport
                </StyledTypography>
                <StyledTypography variant="body2" whiteSpace>
                  {`${props.currentStepNumber}/${props.stepsAmount}`}
                </StyledTypography>
              </>
            )}
            {isViewDocument && !props.isEditMode && (
              <>
                <StyledTypography variant="subtitle1" style={{ marginRight: 10 }} whiteSpace>
                  {props.status}
                </StyledTypography>
                <StyledTypography fontSize="0.75rem" whiteSpace style={{ marginRight: 12 }}>
                  {formatDistance(props.date, new Date(), { addSuffix: true, locale: nb })}
                </StyledTypography>
                {isUserCanCreateAndEditDocument && (
                  <StyledButton
                    onClick={() => {
                      props.handleEditMode(true);
                    }}
                  >
                    <EditIcon />
                  </StyledButton>
                )}
              </>
            )}
            {isViewDocument && props.isEditMode && (
              <Grid container alignItems="center" justify="flex-end">
                <Grid item>
                  <StyledTypography variant="subtitle1" style={{ marginRight: 10 }} whiteSpace>
                    Under arbeid
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <StyledTypography fontSize="0.75rem" whiteSpace style={{ marginRight: 12 }}>
                    {formatDistance(props.date, new Date(), { addSuffix: true, locale: nb })}
                  </StyledTypography>
                </Grid>
                {isUserCanCreateAndEditDocument && (
                  <Grid item>
                    <EditButton
                      label="Lagre"
                      isChanged={props.isEditDocumentChanged}
                      onClick={() => props.handleEditSaveTrigger(true)}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledTypography = styled(({ fontFamily, fontSize, whiteSpace, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    ${props => props.fontFamily && 'font-family: ' + props.fontFamily};
    ${props => props.fontSize && 'font-size: ' + props.fontSize};
    ${props => props.whiteSpace && 'white-space: nowrap'};
  }
`;
const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`;
const StyledButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 0;
    margin: 0;
    padding: 12px 14px;
    min-width: 0;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
`;
const StyledToolbar = styled(Toolbar)`
  height: 56px;
  &.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;
  }
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }

  @media (max-width: 767px) {
    &.MuiToolbar-gutters {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  @media (min-width: 768px) {
    height: 64px;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin-right: 10px;
  }
`;

ChildrenAppBar.defaultProps = {
  title: '',
  id: '',
};

export default ChildrenAppBar;
