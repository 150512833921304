import React from 'react';
import { Grid } from '@material-ui/core';
import TaskChecklistDragItem from '../TaskChecklistDragItem/TaskChecklistDragItem';
import TaskChecklistPanel from '../../TaskChecklistPanel/TaskChecklistPanel';

interface Props {
  dragItems: any[];
  isChecklistPanelOpen: boolean;
  selectedEdit: string | null;
  handleToggleChecklistPanel: (id: string) => void;
  handleSaveChecklistPanel: (item: any) => void;
  handleCloseChecklistPanel: () => void;
  handleDeleteChecklist: (id: string) => void;
  disabledConditions: boolean;
  handleUpChecklistItem: (id: string) => void;
  handleDownChecklistItem: (id: string) => void;
  handleChecklistItemCompletedToggle: (id: string) => void;
}

const TaskChecklistDrags: React.FC<Props> = props => {
  const selectedDragItem = props.dragItems.find(
    item =>
      props.selectedEdit !== null &&
      ((item.uuid && item.uuid === props.selectedEdit) || (item.no && item.no === props.selectedEdit)),
  );

  return (
    <>
      {props.dragItems.length > 0 && (
        <Grid item container direction="column" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {props.dragItems.map(({ no, checklist_description, completed, uuid, _destroy }, index, arr) => {
            if (_destroy) return null;

            const editPanelRenderRule =
              selectedDragItem &&
              props.isChecklistPanelOpen &&
              ((selectedDragItem.uuid && selectedDragItem.uuid === uuid) ||
                (selectedDragItem.no !== null && selectedDragItem.no === no));

            // render checklist panel instead drag if editing
            if (editPanelRenderRule) {
              return (
                <Grid key={uuid || no} item style={{ position: 'relative' }}>
                  <TaskChecklistPanel
                    isEditing={!!props.selectedEdit}
                    drag={selectedDragItem || { checklist_description: '', completed: null }}
                    onSaveChecklistPanel={props.handleSaveChecklistPanel}
                    handleCloseChecklistPanel={props.handleCloseChecklistPanel}
                    dragsLength={props.dragItems.length}
                  />
                </Grid>
              );
            }
            return (
              <TaskChecklistDragItem
                key={uuid || no}
                title={checklist_description}
                handleEdit={() => props.handleToggleChecklistPanel(uuid || no)}
                onDeleteDrag={() => props.handleDeleteChecklist(uuid || no)}
                onUpDrag={() => props.handleUpChecklistItem(uuid || no)}
                onDownDrag={() => props.handleDownChecklistItem(uuid || no)}
                disabledConditions={props.disabledConditions}
                upDisabled={index === 0}
                downDisabled={index === arr.length - 1}
                completed={completed}
                onToggleCompleted={() => props.handleChecklistItemCompletedToggle(!uuid ? no : uuid)}
              />
            );
          })}
        </Grid>
      )}
      {/* Render checklist panel when new report */}
      {props.isChecklistPanelOpen && !selectedDragItem && (
        <Grid item style={{ position: 'relative' }}>
          <TaskChecklistPanel
            isEditing={!!props.selectedEdit}
            drag={selectedDragItem || { checklist_description: '', completed: null }}
            onSaveChecklistPanel={props.handleSaveChecklistPanel}
            handleCloseChecklistPanel={props.handleCloseChecklistPanel}
            dragsLength={props.dragItems.length}
          />
        </Grid>
      )}
    </>
  );
};

export default TaskChecklistDrags;
