export enum ActionTypes {
  UPDATE_LOGIN_FORM_STATE = 'UPDATE_LOGIN_FORM_STATE',
  RESET_LOGIN_FORM_STATE = 'RESET_LOGIN_FORM_STATE',
}

export type Action = {
  type: ActionTypes;
  payload?: Partial<State>;
};

export interface State {
  remembered: boolean;
  name: string;
  password: string;
  server: string;
  formErrors: { name: string; password: string; server: string };
  nameValid: boolean;
  passwordValid: boolean;
  serverValid: boolean;
  formValid: boolean;
  isLoggedIn: boolean;
  isLoading: boolean;
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOGIN_FORM_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.RESET_LOGIN_FORM_STATE:
      return {
        ...state,
        remembered: false,
        name: '',
        password: '',
        server: '',
      };

    default:
      return state;
  }
};
