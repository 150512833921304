import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Box, Typography, Divider, Paper, ListItem, List, useMediaQuery } from '@material-ui/core';
import Gallery from '../../sections/Gallery';
import { format } from 'date-fns';
import AddIcon from '@material-ui/icons/Add';

import { BuildingType } from 'types/Building/BuildingType';
import { DELETE_MODAL_TEXT } from '../../../../constants';
import ObservationTaskList from '../ObservationTaskList/ObservationTaskList';
import PrimaryButton from '../../shared/PrimaryButton/PrimaryButton';

import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import Slider from '../Slider/Slider';
import Modal from '../../shared/Modal/Modal';

interface Props {
  work: any;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  tasks: any[];
  handleOpenEditTask: (id: string, linkedObjectId?: string) => void;
  handleDeleteTask: (id: string) => void;
  handleOpenNewTask: (linkedObjectId?: string) => void;
  building: BuildingType;
}

const ViewWork: React.FC<Props> = ({ work, isEditMode, handleEditMode, tasks, ...props }) => {
  const tabletMatch = useMediaQuery('(min-width: 768px)');
  const mobileMarginBottom = !tabletMatch ? '56px' : '0';

  const [isSliderOpen, setSliderOpen] = useState(false);
  const [imageViewedId, setImageViewedId] = useState('');

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState<string | null>(null);

  const handleOpenSlider = () => {
    setSliderOpen(true);
  };
  const handleCloseSlider = () => {
    setImageViewedId('');
    setSliderOpen(false);
  };
  const handleImageClick = (id: string) => {
    setImageViewedId(id);
    handleOpenSlider();
  };

  const handleOpenDeleteModal = (id: string) => {
    setDeletedId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeletedId(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteDocument = () => {
    props.handleDeleteTask(deletedId as string);
    handleCloseDeleteModal();
    setDeletedId(null);
  };

  return (
    <StyledWrapper isSliderOpen={isSliderOpen}>
      {/* TITLE SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Arbeid</Typography>
          </Grid>
          {work?.title?.title && (
            <Grid item>
              <StyledCaption color="textSecondary">Tittel</StyledCaption>
              <Typography variant="h6">{work?.title?.title}</Typography>
            </Grid>
          )}
          {work?.description?.description && (
            <Grid item>
              <StyledCaption color="textSecondary">Detaljer</StyledCaption>
              <Typography variant="h6">{work?.description?.description}</Typography>
            </Grid>
          )}
          {work?.timespan?.from_date?.dd_date && (
            <Grid item>
              <StyledCaption color="textSecondary">Utført i perioden</StyledCaption>
              <Typography variant="h6">
                {format(work?.timespan.from_date?.dd_date || Date.now(), 'dd.MM.yyyy')}
                <span> til </span>
                {work?.timespan?.to_date?.dd_date && format(work?.timespan.to_date?.dd_date, 'dd.MM.yyyy')}
              </Typography>
            </Grid>
          )}
          {work?.responsible_actors && work?.responsible_actors.length > 0 && (
            <>
              <Grid item>
                <StyledCaption color="textSecondary">{`${work?.responsible_actors.length} ansvarlige personer`}</StyledCaption>
              </Grid>
              <Grid item>
                <List disablePadding>
                  {work?.responsible_actors.map((actor: any) => (
                    <StyledListItem key={actor.resp_actor_item_actor_id || actor.no} divider>
                      <StyledTypography component="span" fontSize="16px" fontWeight="700" style={{ paddingRight: 14 }}>
                        {actor?.resp_actor_item_actor_id_value}
                      </StyledTypography>
                      <Typography component="span" color="textSecondary">
                        {actor?.responsibilities}
                      </Typography>
                    </StyledListItem>
                  ))}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </StyledSection>
      <Divider />
      {/* BUILDING PART SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Bygningsdel</Typography>
          </Grid>
          {work?.building_treatment?.building_part?.building_part_type_id_value && (
            <Grid item>
              <StyledCaption color="textSecondary">Bygningsdel</StyledCaption>
              <Typography variant="h6">
                {work?.building_treatment?.building_part?.building_part_type_id_value}
              </Typography>
            </Grid>
          )}
          {work?.building_treatment?.cost?.cost && (
            <Grid item>
              <StyledCaption color="textSecondary">Estimert kostnad</StyledCaption>
              <Typography variant="h6">{work?.building_treatment?.cost?.cost}</Typography>
            </Grid>
          )}
          {work?.building_treatment?.time_spent?.time_spent && (
            <Grid item>
              <StyledCaption color="textSecondary">Estimert tidsbruk</StyledCaption>
              <Typography variant="h6">{work?.building_treatment?.time_spent?.time_spent}</Typography>
            </Grid>
          )}
          {work?.building_treatment?.material?.material && (
            <Grid item>
              <StyledCaption color="textSecondary">Materialbruk, etc.</StyledCaption>
              <Typography variant="h6">{work?.building_treatment?.material?.material}</Typography>
            </Grid>
          )}
          {work?.comments && work?.comments.length > 0 && (
            <>
              <Grid item>
                <StyledCaption color="textSecondary">Kommentarer</StyledCaption>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  {work?.comments.map((comment: any) => (
                    <Grid item key={comment.no || comment.order_number}>
                      <StyledPaper square>
                        <StyledCaption color="textSecondary">Kommentar</StyledCaption>
                        <Typography variant="h6">{comment.description}</Typography>
                      </StyledPaper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </StyledSection>
      <Divider />
      {/* MEDIA SECTION */}
      <StyledSection marginBottom={mobileMarginBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Opplastninger</Typography>
          </Grid>
        </Grid>
        <Gallery
          images={work?.images || []}
          firstPadding="0"
          lastPadding="0"
          handleImageClick={handleImageClick}
          attachments={work?.attachments || []}
          videos={work?.videos || []}
        />
      </StyledSection>
      {/* OPPGAVER SECTION */}
      <StyledSection marginBottom={mobileMarginBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Oppgaver</Typography>
          </Grid>
          <Grid item>
            <ObservationTaskList
              taskEvents={tasks}
              handleOpenEditTask={props.handleOpenEditTask}
              handleEditMode={handleEditMode}
              buildingCollectionId={props.building.collection_id}
              buildingAcl={props.building.$$acl}
              handleOpenDeleteModal={handleOpenDeleteModal}
              linkedId={work?.artifact_id || work?.uuid}
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              label="Legg til oppgaver"
              icon={<AddIcon />}
              onClick={() => props.handleOpenNewTask(work?.artifact_id || work?.uuid)}
            />
          </Grid>
        </Grid>
      </StyledSection>

      {!tabletMatch && (
        <BottomStatusBar
          date={work?.updated_at.dd_date ?? 0}
          status="Publisert"
          isEditMode={isEditMode}
          handleEditMode={handleEditMode}
        />
      )}
      {isSliderOpen && (
        <Slider
          images={work?.images || []}
          handleClose={handleCloseSlider}
          imageViewedId={imageViewedId}
          annotations={work?.annotation_events || []}
          videos={work?.videos || []}
        />
      )}
      <Modal
        modalText={DELETE_MODAL_TEXT}
        handleClose={handleCloseDeleteModal}
        open={deleteModalOpen}
        handleConfirmClick={handleDeleteDocument}
      />
    </StyledWrapper>
  );
};

const StyledSection = styled(({ overflow, marginBottom, ...props }) => <Box {...props} />)`
  padding: 16px 16px 34px;
  background-color: #f5f5f5;
  ${props => props.overflow && `overflow: ${props.overflow}`};
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
`;
const StyledCaption = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    font-weight: 700;
  }
`;
const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    padding: 16px;
  }
`;
const StyledListItem = styled(props => <ListItem {...props} />)`
  &.MuiListItem-root {
    padding: 16px;
    background-color: #ffffff;
  }
`;
const StyledTypography = styled(({ color, fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
  }
`;
const StyledWrapper = styled(({ isSliderOpen, ...props }) => <Box {...props} />)`
  ${props =>
    props.isSliderOpen &&
    `
    height: 90vh;
    overflow: hidden;
  `}
`;

export default ViewWork;
