import { fetchPriorities } from './fetchData';

export interface PriorityListItem {
  id: string;
  title: string;
  value: string;
  color?: string;
}

export const getPrioritiesList = async () => {
  function getColor(id: string) {
    if (id === 'ct_69-3') {
      // High
      return '#eb5757';
    } else if (id === 'ct_69-2') {
      // Medium
      return '#f2994a';
    } else if (id === 'ct_69-1') {
      // Low
      return '#f2c94c';
    }
  }

  const priorities: PriorityListItem[] = [];

  const items = await fetchPriorities();
  items.forEach((p: any) => {
    const o = {
      id: p.artifact_id,
      title: p.artifact_name,
      value: p.artifact_name,
      color: getColor(p.artifact_id),
    };

    priorities.push(o);
  });
  return priorities;
};
