import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import debounce from 'lodash/debounce';

// mocks
import { getBaseUrl } from 'services/fetchData';

// icons
import { ReactComponent as CheckMarkDarkIcon } from 'assets/icons/CheckMark.svg';

import AsyncFormInput from '../../shared/AsyncFormInput/AsyncFormInput';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';

export enum PeopleTypes {
  ACTORS = 'ACTORS',
  USERS = 'USERS',
}

const getResponsiblePeopleBodyRequest = (type: string, query: string) => {
  if (type === PeopleTypes.ACTORS) {
    return {
      filters: { object_type: 'actor' },
      rows: 200,
      query: `*${query || ''}*`,
    };
  }
  if (type === PeopleTypes.USERS) {
    return {
      filters: { object_type: 'user', 'user_signature.role': ['super_user', 'admin', 'user'] },
      rows: 200,
      query: `*${query || ''}*`,
    };
  }
};
const mapPeopleResponse = (type: string) => (person: any) => {
  if (type === PeopleTypes.ACTORS) {
    return { title: person.artifact_name, id: person.artifact_id };
  }
  if (type === PeopleTypes.USERS) {
    return { title: person.artifact_name, id: person.artifact_id };
  }
};

interface Props {
  onSaveActorsPanel: (actorData: { person: { title: string; id: string } }) => void;
  handleCloseActorsPanel: () => void;
  drag: any;
  isEditing: boolean;
  dragsLength: number;
  type: PeopleTypes;
}

const ActorsPanel: React.FC<Props> = props => {
  // states
  const [person, setPerson] = useState({
    title: props.drag.actor_id_value,
    id: props.drag.actor_id,
  });
  const [personInputValue, setPersonInputValue] = useState(props.drag.actor_id_value || '');

  const [personOptionsOpen, setPersonOptionsOpen] = React.useState(false);
  const [personOptions, setPersonOptions] = React.useState<{ title: string; id: string }[]>([]);
  const personLoading = personOptionsOpen && personOptions.length === 0;

  useEffect(() => {
    let active = true;
    if (!personLoading) {
      return undefined;
    }
    (async () => {
      try {
        const response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(getResponsiblePeopleBodyRequest(props.type, personInputValue)),
        });
        if (!response.ok) throw new Error('something went wrong!');
        const options = await response.json();

        if (active) {
          setPersonOptions(options.artifacts.map(mapPeopleResponse(props.type)));
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [personInputValue, personOptionsOpen, personLoading, props.type]);

  const debouncedResetPersonOptions = debounce(() => setPersonOptions([]), 1000);

  useEffect(() => {
    debouncedResetPersonOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personInputValue]);

  const setPersonOpenStatus = (status: boolean) => {
    setPersonOptionsOpen(status);
  };

  // handlers
  const handlePersonChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    setPerson(option);
  };
  const handlePersonInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: 'input' | 'reset' | 'clear',
  ) => {
    setPersonInputValue(value);
  };
  const handleSaveActor = () => {
    if (person.title === '') return;

    props.onSaveActorsPanel({ person });
    props.handleCloseActorsPanel();
  };

  return (
    <StyledFormWrapper>
      <Typography variant="h4" style={{ paddingBottom: 18 }}>
        {person.title || 'Uten person'}
      </Typography>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <AsyncFormInput
            value={person.title}
            handleChange={handlePersonChange}
            options={personOptions}
            placeholder="Finn ansvarlig person"
            label="Ansvarlig person"
            loading={personLoading}
            inputValue={personInputValue}
            handleInputChange={handlePersonInputChange}
            open={personOptionsOpen}
            setOpenStatus={setPersonOpenStatus}
            required
            arrowDown
            shrink
          />
        </Grid>

        <Grid item>
          <OutlineButton padding="16px 30px" onClick={handleSaveActor}>
            <StyledBox>
              <CheckMarkDarkIcon style={{ marginRight: 4 }} />
            </StyledBox>
            Lagre og lukk
          </OutlineButton>
        </Grid>
      </Grid>
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled(Paper)`
  &.MuiPaper-root {
    padding: 18px;
    background-color: #ffffff;
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ActorsPanel;
