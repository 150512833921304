import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';

const BackdropSpinner: React.FC = () => (
  <StyledWrap>
    <CircularProgress />
  </StyledWrap>
);

const StyledWrap = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(238, 238, 238, 0.5);
  z-index: 10000;
`;

export default BackdropSpinner;
