import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Grid, Box, IconButton, Link } from '@material-ui/core';
import { useTheme, DefaultTheme } from '@material-ui/styles';
import smoothscroll from 'smoothscroll-polyfill';
import DescriptionIcon from '@material-ui/icons/Description';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { getBaseUrl, getDownloadAttachmentLink } from 'services/fetchData';
import ScrollIndicator from '../shared/ScrollIndicator/ScrollIndicator';
import Image from 'components/Image/Image';

interface Props {
  images: any[];
  firstPadding?: string;
  lastPadding?: string;
  handleImageClick?: (id: string) => void;
  attachments?: any[];
  videos?: any[];
}

interface GalleryProps {
  theme: DefaultTheme;
}

enum ScrollDirection {
  Left,
  Right,
}

const GALLERY_BUTTON_VISIBILITY_OFFSET = {
  LEFT: 2,
  RIGHT: 98,
};
const GALLERY_SCROLL_IMAGES_LENGTH = 3;
const SCROLL_OFFSET = 30;

// kick off the scroll polyfill
smoothscroll.polyfill();

const Gallery: React.FC<Props> = props => {
  const theme = useTheme();
  const galleryRef = useRef<HTMLDivElement>(null);
  const firstImageWrapRef = useRef<HTMLDivElement>(null);

  const [percentScrolled, setPercentScrolled] = useState(0);
  const [isGalleryScrollable, setGalleryScrollable] = useState(false);

  useEffect(() => {
    const imagesWrap = galleryRef.current;
    imagesWrap?.addEventListener('scroll', scrollProgress);

    return () => {
      imagesWrap?.removeEventListener('scroll', scrollProgress);
    };
  }, []);

  useEffect(() => {
    adjustIsGalleryScrollable();

    window.addEventListener('resize', adjustIsGalleryScrollable);

    return () => window.removeEventListener('resize', adjustIsGalleryScrollable);
  }, [props.images]);

  const adjustIsGalleryScrollable = () => {
    const imagesWrap = galleryRef.current;
    const totalWidth = imagesWrap?.scrollWidth ?? 0;
    const clientWidth = imagesWrap?.clientWidth ?? 0;

    setGalleryScrollable(totalWidth > clientWidth);
  };

  const scrollProgress = () => {
    const imagesWrap = galleryRef.current;

    const scrollPx = imagesWrap?.scrollLeft ?? 0;
    const firstHelpValue = imagesWrap?.scrollWidth ?? 0;
    const secondHelpValue = imagesWrap?.clientWidth ?? 0;

    const winWidthPx = firstHelpValue - secondHelpValue;
    const scrolled = (scrollPx / winWidthPx) * 100;

    setPercentScrolled(scrolled);
  };

  const scrollImagesByDirection = (direction: ScrollDirection) => {
    const imagesWrap = galleryRef.current;
    const firstImageWrap = firstImageWrapRef.current;
    const firstImageWrapWidth = firstImageWrap?.offsetWidth ?? 0;

    const scrollDistance = firstImageWrapWidth * GALLERY_SCROLL_IMAGES_LENGTH + SCROLL_OFFSET;

    imagesWrap?.scrollBy({
      left: direction === ScrollDirection.Right ? scrollDistance : -scrollDistance,
      behavior: 'smooth',
    });
  };

  return (
    <Box component="section" style={{ position: 'relative' }}>
      <StyledImagesWrap theme={theme} ref={galleryRef}>
        <Grid container wrap="nowrap">
          {props.images.map((img: { image_id: string }, idx: number) => (
            <StyledGridItem item key={img.image_id} firstPadding={props.firstPadding} lastPadding={props.lastPadding}>
              <StyledImageWrap
                ref={firstImageWrapRef}
                onClick={() => props.handleImageClick && props.handleImageClick(img.image_id)}
              >
                <Image
                  src={`${getBaseUrl()}/multimedia/image/${img.image_id}?size=medium`}
                  imageStyles={{ maxWidth: '90%', maxHeight: '90%', width: 'auto', height: 'auto' }}
                />
              </StyledImageWrap>
            </StyledGridItem>
          ))}
          {props.videos &&
            props.videos.map((video: { video_id: string }, idx: number) => (
              <StyledGridItem
                item
                key={video.video_id}
                firstPadding={props.firstPadding}
                lastPadding={props.lastPadding}
              >
                <StyledImageWrap ref={((!props.images || props.images.length === 0) && firstImageWrapRef) || null}>
                  <StyledCenterIconButton
                    padding="12px"
                    onClick={() => props.handleImageClick && props.handleImageClick(video.video_id)}
                  >
                    <PlayArrowIcon />
                  </StyledCenterIconButton>
                  <Image
                    src={`${getBaseUrl()}/multimedia/image/${video.video_id}?size=medium`}
                    imageStyles={{ maxWidth: '90%', maxHeight: '90%', width: 'auto', height: 'auto' }}
                    withoutIcon
                  />
                </StyledImageWrap>
              </StyledGridItem>
            ))}
          {props.attachments &&
            props.attachments.map((attachment: { attachment_id: string }, idx: number) => (
              <StyledGridItem
                item
                key={attachment.attachment_id}
                firstPadding={props.firstPadding}
                lastPadding={props.lastPadding}
              >
                <StyledImageWrap
                  ref={
                    ((!props.images || props.images.length === 0) &&
                      (!props.videos || props.videos.length === 0) &&
                      firstImageWrapRef) ||
                    null
                  }
                >
                  <StyledCenterIconButton>
                    <StyledLink href={getDownloadAttachmentLink(attachment.attachment_id)} color="inherit">
                      <DescriptionIcon />
                    </StyledLink>
                  </StyledCenterIconButton>
                  <Image
                    src={`${getBaseUrl()}/multimedia/image/${attachment.attachment_id}?size=medium`}
                    imageStyles={{ maxWidth: '90%', maxHeight: '90%', width: 'auto', height: 'auto' }}
                    withoutIcon
                  />
                </StyledImageWrap>
              </StyledGridItem>
            ))}
        </Grid>
        <ScrollIndicator
          isVisible={isGalleryScrollable && percentScrolled > GALLERY_BUTTON_VISIBILITY_OFFSET.LEFT}
          left
          onScrollClick={() => scrollImagesByDirection(ScrollDirection.Left)}
        />
        <ScrollIndicator
          isVisible={isGalleryScrollable && percentScrolled < GALLERY_BUTTON_VISIBILITY_OFFSET.RIGHT}
          right
          onScrollClick={() => scrollImagesByDirection(ScrollDirection.Right)}
        />
      </StyledImagesWrap>
    </Box>
  );
};

const StyledImagesWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 24px 0;
  background-color: ${({ theme }: GalleryProps) => theme.palette.secondary.greyLight};
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledImageWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  height: 256px;
  width: 256px;
  cursor: pointer;

  @media (max-width: 819px) {
    height: 192px;
    width: 192px;
  }
`;
const StyledGridItem = styled(({ firstPadding, lastPadding, ...props }) => <Grid {...props} />)`
  &.MuiGrid-root {
    padding-right: 10px;
  }
  &.MuiGrid-root:first-child {
    padding-left: ${props => (props.firstPadding ? props.firstPadding : '23px')};
  }
  &.MuiGrid-root:last-child {
    padding-right: ${props => (props.lastPadding ? props.lastPadding : '16px')};
  }
`;
const StyledCenterIconButton = styled(({ padding, ...props }) => <IconButton {...props} />)`
  &.MuiIconButton-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    opacity: 0.3;
    color: rgba(0, 0, 0, 0.54);
    transition: 0.3s all linear;
    padding: ${props => props.padding || 0};

    &:hover {
      opacity: 1;
      background-color: #ffffff;
    }
  }
`;
const StyledLink = styled(Link)`
  &.MuiLink-root {
    padding: 12px;
  }
`;

export default Gallery;
