import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

interface Props {
  label: string;
  onClick?: () => void;
  fullWidth?: boolean;
  isChanged: boolean;
}

const EditButton: React.FC<Props> = props => {
  return (
    <StyledButton
      fullWidth={props.fullWidth || false}
      onClick={props.onClick || (() => null)}
      variant={props.isChanged ? 'contained' : 'outlined'}
      color="primary"
      disabled={props.isChanged ? false : true}
    >
      {props.label}
    </StyledButton>
  );
};

const StyledButton = styled(({ padding, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    padding: ${props => props.padding || '16px 24px 16px 24px'};
    font-weight: bold;
    text-transform: none;
    padding: 10px 26px;
    box-shadow: none;
  }
  &.MuiButton-root.Mui-disabled {
    color: #3154ea;
  }

  &.MuiButton-outlined {
    background-color: #ffffff;
  }

  @media (max-width: 767px) {
    &.MuiButton-root {
      padding: 16px 26px;
      border-radius: 0;
      border: none;
    }
    &.MuiButton-outlined.Mui-disabled {
      border: none;
    }
    &.MuiButton-root:hover.Mui-disabled {
      background-color: #ffffff;
    }
  }
`;

export default EditButton;
