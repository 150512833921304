import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Grid, Box, Typography, Paper, IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// helpers
import { getCurrentIcon } from 'helpers/getCurrentIcon';

// icons
import LinkIcon from '@material-ui/icons/Link';
import { ReactComponent as EditIcon } from 'assets/icons/edit-24px.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';

import MeasureClassLabel from 'components/MeasureClassLabel/MeasureClassLabel';

interface Props {
  object: any;
  withoutContextParent?: boolean;
  handleDelete?: () => void;
  handleEdit?: () => void;
}

const HomeEventListItem = (props: Props) => {
  const theme = useTheme();
  const mediaMatch = useMediaQuery('(max-width:520px)');

  const onDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    props.handleDelete && props.handleDelete();
  };
  const onEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    props.handleEdit && props.handleEdit();
  };
  const renderEditAndDeleteButtons = () => (
    <Grid container justify="flex-end" alignItems="center" spacing={1} style={{ width: '25%' }} wrap="nowrap">
      <Grid item>
        <IconButton edge="start" color="inherit" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton edge="start" color="inherit" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const uiConditionWithDeleteButtons = mediaMatch && props.handleDelete && props.handleEdit;

  return (
    <StyledListItemWrap square>
      <Grid container direction="column" wrap="nowrap">
        <StyledTitlesWrap marginBottom={props.withoutContextParent ? 0 : '18px'}>
          <StyledFlexBox margin="0 12px 0 0">
            {props.object.artifact_type_id_value === 'Avvik' &&
              props.object['priority.priority_type_id_value'] &&
              getCurrentIcon(props.object['priority.priority_type_id_value'])}
            {props.object.artifact_type_id_value === 'Tilstandsvurdering bygning' && (
              <MeasureClassLabel
                label={
                  props.object['condition.measure_class_type_id_value']
                    ? props.object['condition.measure_class_type_id_value'].substr(0, 3)
                    : 'N/A'
                }
              />
            )}
          </StyledFlexBox>
          <Grid item container>
            <Grid item style={{ marginBottom: 4 }}>
              <StyledTypography fontWeight="700">
                {props.object['title.title'] || props.object.artifact_name || props.object.artifact_type_id_value}
              </StyledTypography>
            </Grid>
            <Grid item container wrap={uiConditionWithDeleteButtons ? 'wrap' : 'nowrap'}>
              <StyledDescriptionWrap>
                <StyledLabelBox component="div" borderColor="#434343" backGroundColor={theme.palette.secondary.white}>
                  <StyledTypography color="#434343" variant="subtitle2" fontSize="0.75rem">
                    {props.object.artifact_type_id_value === 'Avvik' && props.object['status.status_type_id_value']}
                    {props.object.object_type === 'task' && props.object['status.status_type_id_value']}
                    {props.object.artifact_type_id_value === 'Tilstandsvurdering bygning' &&
                      (props.object['condition.measure_class_type_id_value']
                        ? props.object['condition.measure_class_type_id_value'].substr(0, 3)
                        : 'N/A')}
                    {props.object.artifact_type_id_value === 'Bygningsarbeid' &&
                      (props.object['building_treatment.building_part.building_part_type_id_value']
                        ? props.object['building_treatment.building_part.building_part_type_id_value'].split(':')[0]
                        : 'N/A')}
                  </StyledTypography>
                </StyledLabelBox>
                <StyledTypography fontSize="0.85rem" whiteSpace="nowrap" cropped>
                  {props.object.artifact_type_id_value === 'Avvik' &&
                    (props.object['damage.description.damage_description']
                      ? props.object['damage.description.damage_description']
                      : 'Description')}
                  {props.object.artifact_type_id_value === 'Tilstandsvurdering bygning' &&
                    (props.object['condition.measure_class_type_id_value']
                      ? props.object['condition.measure_class_type_id_value'].substr(5)
                      : 'Description')}
                  {props.object.artifact_type_id_value === 'Bygningsarbeid' &&
                    (props.object['building_treatment.building_part.building_part_type_id_value']
                      ? props.object['building_treatment.building_part.building_part_type_id_value']
                          .split(':')[1]
                          .trimLeft()
                      : 'N/A')}
                </StyledTypography>
              </StyledDescriptionWrap>
              <StyledGrid item padding={uiConditionWithDeleteButtons ? '8px 0 0 0' : ''}>
                <StyledTypography variant="caption" fontSize="0.85rem" color="rgba(0, 0, 0, 0.38)">
                  {format(props.object.updated_at.dd_date, 'dd.MM.yyyy')}
                </StyledTypography>
              </StyledGrid>
            </Grid>
          </Grid>
        </StyledTitlesWrap>
        {props.withoutContextParent ? null : (
          <Grid container alignItems="center" spacing={1}>
            <StyledGrid item>
              <LinkIcon />
            </StyledGrid>
            <StyledGrid item>
              <StyledTypography fontWeight="700" fontSize="0.85rem">
                {props.object['contexts.context_artifact_id_value'][0].split(':')[2]
                  ? props.object['contexts.context_artifact_id_value'][0].split(':')[2].trimLeft()
                  : props.object['contexts.context_artifact_id_value'][0]}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item>
              <StyledTypography
                variant="caption"
                fontSize="0.85rem"
                color="rgba(0, 0, 0, 0.6)"
                fontFamily="Akkurat Mono"
              >
                {props.object['contexts.context_artifact_id_value'][0].match(': (.*):') === null
                  ? props.object['contexts.context_artifact_id_value'][0].match(': (.*)')[1]
                  : props.object['contexts.context_artifact_id_value'][0].match(': (.*):')[1]}
              </StyledTypography>
            </StyledGrid>
          </Grid>
        )}
      </Grid>
      {props.handleDelete && props.handleEdit && renderEditAndDeleteButtons()}
    </StyledListItemWrap>
  );
};

const StyledLabelBox = styled(({ borderColor, backGroundColor, ...props }) => <Box {...props} />)`
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.borderColor || '#ffffff'};
  border-radius: 18px;
  min-height: 22px;
  padding: 0 12px;
  background-color: ${props => props.backGroundColor || 'transparent'};
  margin-right: 6px;
`;
const StyledTypography = styled(({ color, fontSize, fontWeight, fontFamily, whiteSpace, cropped, ...props }) => (
  <Typography {...props} />
))`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    ${props => props.fontFamily && 'font-family: ' + props.fontFamily}
    ${props => props.whiteSpace && 'white-space: ' + props.whiteSpace}
    ${props =>
      props.cropped &&
      `
      max-width: 60vw;
      width: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 6px;
    `}
  }
  
  @media (max-width: 767px) {
    &.MuiTypography-root {
      ${props => props.cropped && 'max-width: 50vw'} 
    }
  }
  @media (max-width: 399px) {
    &.MuiTypography-root {
      ${props => props.cropped && 'max-width: 40vw'} 
    }
  }
`;
const StyledListItemWrap = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 12px 10px;
  background-color: #ffffff;

  @media (min-width: 768) {
    padding: 14px 10px 18px;
  }

  @media (min-width: 1200px) {
    padding: 20px 20px 20px;
  }
`;
const StyledGrid = styled(({ padding, ...props }) => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.padding && 'padding: ' + props.padding}
  }
`;
const StyledFlexBox = styled(({ margin, ...props }) => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.margin && `margin: ${props.margin}`}
`;
const StyledDescriptionWrap = styled(Box)`
  display: flex;
  align-items: center;
`;
const StyledTitlesWrap = styled(({ marginBottom, ...props }) => <Box {...props} />)`
  display: flex;
  margin-bottom: ${props => props.marginBottom};
`;

export default HomeEventListItem;
