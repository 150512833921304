import React from 'react';
import styled from 'styled-components';
import CSS from 'csstype';
import { Box, InputAdornment } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  value: string;
  handleChange: (value: string, options: any[]) => void; //old type (event: React.ChangeEvent<{}>, value: any) => void;
  options: any[];
  placeholder?: string;
  label?: string;
  customStyles?: CSS.Properties;
  open: boolean;
  loading: boolean;
  setOpenStatus: (status: boolean) => void;
  required?: boolean;
  regular?: boolean;
  arrowDown?: boolean;
  shrink?: boolean;
  customInputStyles?: CSS.Properties;
  disabled?: boolean;
  inputValue?: string;
  handleInputChange?: (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: 0,
    },
  }),
);

const AsyncFormInput: React.FC<Props> = props => {
  const classes = useStyles();

  const defaultProps = {
    options: props.options.map(option => option.title),
  };

  return (
    <FormControl className={classes.margin} fullWidth style={props.customStyles}>
      <Autocomplete
        {...defaultProps}
        includeInputInList
        popupIcon={props.arrowDown ? <ArrowDropDownIcon /> : <Box />}
        value={(props.regular ? props.value.replace(/:.*»/g, ': ... »') : props.value) || null}
        onChange={(event: React.ChangeEvent<{}>, value: any) => props.handleChange(value, props.options)}
        inputValue={props.inputValue}
        onInputChange={props.handleInputChange || (() => null)}
        open={props.open}
        disabled={props.disabled}
        loading={props.loading}
        onOpen={() => {
          props.setOpenStatus(true);
        }}
        onClose={() => {
          props.setOpenStatus(false);
        }}
        renderInput={params => (
          <StyledTextField
            {...params}
            required={props.required || false}
            placeholder={props.placeholder}
            label={props.label || props.placeholder}
            fullWidth
            style={{ ...props.customInputStyles }}
            variant="filled"
            color="secondary"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: props.shrink && true,
            }}
          />
        )}
      />
    </FormControl>
  );
};

const StyledTextField = styled(props => <TextField {...props} />)`
  & .MuiInput-underline:before {
    border: none;
  }
  & .MuiInput-underline:after {
    border: none;
  }
  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  & .MuiInput-formControl {
    margin-bottom: 8px;
  }

  @media (max-width: 767px) {
    & .MuiAutocomplete-inputRoot {
      padding-right: 40px;
    }
  }

  & .MuiInput-underline.Mui-disabled:before {
    border: none;
  }
  & .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
    margin-bottom: 14px;
    margin-left: 4px;
  }
  && .MuiFormLabel-root {
    margin-left: 37px;
  }
  & .MuiFilledInput-root {
    border-radius: 0;
  }
`;
export default AsyncFormInput;
