import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';

import MainBuildingsListItem from './MainBuildingsListItem/MainBuildingsListItem';

interface Props {
  buildings: any[];
  addBuildingToFavorites: (building: any) => void;
  removeBuildingFromFavorites: (id: string) => void;
  checkBuildingInFavorites: (id: string) => boolean;
}

const MainBuildingsList = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const sortedByDate = useMemo(() => [...props.buildings].sort((a, b) => b.updated_at.dd_date - a.updated_at.dd_date), [
    props.buildings,
  ]);
  return (
    <StyledListWrap padding={!mediaMatch && '0 8px'}>
      <Grid container spacing={1}>
        {/* RENDER BUILDINGS WITH LIMIT*/}
        {sortedByDate.map((building, idx) => (
          <StyledGridItem item xs={6} md={3} key={building.artifact_id}>
            <StyledLink to={`/building/${building.artifact_id}`}>
              <MainBuildingsListItem
                building={building}
                addBuildingToFavorites={() => props.addBuildingToFavorites(building)}
                removeBuildingFromFavorites={() => props.removeBuildingFromFavorites(building.artifact_id)}
                isFavorites={props.checkBuildingInFavorites(building.artifact_id)}
              />
            </StyledLink>
          </StyledGridItem>
        ))}
      </Grid>
    </StyledListWrap>
  );
};

const StyledListWrap = styled(({ padding, ...props }) => <Box {...props} />)`
  padding: ${props => props.padding};
`;
const StyledGridItem = styled(props => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none !important;
  color: inherit !important;
  height: 100%;
`;

export default MainBuildingsList;
