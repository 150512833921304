import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

interface Props {
  value: Date | null;
  handleChange: (date: Date | null) => void;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  label?: string;
  disabled?: boolean;
}

const Datepicker: React.FC<Props> = props => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid container>
      <StyledDatePicker
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        label={props.label || ''}
        value={props.value}
        onChange={props.handleChange}
        variant="inline"
        readOnly={props.disabled}
        format="dd.MM.yyyy"
        color="secondary"
        inputVariant="filled"
        autoOk
      />
    </Grid>
  </MuiPickersUtilsProvider>
);

const StyledDatePicker = styled(KeyboardDatePicker)`
  & .MuiFilledInput-root {
    border-radius: 0;
  }
`;

export default Datepicker;
