import React from 'react';
import { Box, Grid } from '@material-ui/core';

import ListsItem from '../../shared/ListsItem/ListsItem';

interface Props {
  handleOpenEditWork: (id: string) => void;
  workEvents: any[];
  handleEditMode: (status: boolean) => void;
  buildingCollectionId: string;
  buildingAcl: { restrictions: any[] };
  handleOpenDeleteModal: (id: string) => void;
}

const ObservationWorkList = (props: Props) => {
  const onOpenWithEdit = async (id: string) => {
    await props.handleOpenEditWork(id);
    props.handleEditMode(true);
    return;
  };

  return (
    <>
      {props.workEvents.length > 0 && (
        <Box>
          <Grid container>
            {props.workEvents.map(work => (
              <Grid item xs={12} key={work.artifact_id || work.uuid}>
                <ListsItem
                  item={work}
                  onOpen={() => props.handleOpenEditWork(work.artifact_id || work.uuid)}
                  onDelete={() => props.handleOpenDeleteModal(work.artifact_id || work.uuid)}
                  onOpenWithEdit={() => onOpenWithEdit(work.artifact_id || work.uuid)}
                  buildingCollectionId={props.buildingCollectionId}
                  buildingAcl={props.buildingAcl}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ObservationWorkList;
