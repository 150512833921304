import React from 'react';
import { Box, IconButton } from '@material-ui/core';

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const squaresPositionStyles: React.CSSProperties[] = [
  { position: 'absolute', top: -2, left: -2, backgroundColor: '#ffffff', width: 8, height: 8 },
  { position: 'absolute', bottom: -5, right: -5, backgroundColor: '#ffffff', width: 8, height: 8 },
  { position: 'absolute', bottom: -5, left: -1, backgroundColor: '#ffffff', width: 8, height: 8 },
];
const deletePositionStyles: React.CSSProperties = {
  position: 'absolute',
  top: -16,
  right: -18,
};

interface Props {
  color: string;
  onDelete: (e: React.MouseEvent) => void;
}

const ImageResizeStyleWrap: React.FC<Props> = props => (
  <>
    {squaresPositionStyles.map((style, idx) => (
      <Box key={idx} style={{ ...style, backgroundColor: props.color }} />
    ))}
    <IconButton size="small" style={{ ...deletePositionStyles, color: props.color }} onClick={props.onDelete}>
      <RemoveCircleIcon />
    </IconButton>
  </>
);

export default ImageResizeStyleWrap;
