import React, { useCallback, useMemo, useEffect, useReducer } from 'react';
import update from 'immutability-helper';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// helpers
import { getExpansionPanelMobileRule } from 'helpers/getExpansionPanelMobileRule';

// constants
import { workExpansionsTypes } from '../../../../constants';

import {
  postAnnotationEvent,
  fetchBuildingEvent,
  uploadImage,
  postImage,
  fetchReportImagesData,
  fetchAttachmentsData,
  fetchVideosData,
  uploadFile,
  postFile,
  uploadVideo,
  postVideo,
} from 'services/fetchData';

// types
import { BuildingType } from 'types/Building/BuildingType';

// reducer
import { reducer, ActionTypes } from './reducer';

import {
  StyledExpansionPanel,
  StyledExpansionPanelActions,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
} from '../../shared/ExpansionPanel/ExpansionPanel';
import WorkTitleForm from '../WorkTitleForm/WorkTitleForm';
import WorkBuildingPartForm from '../WorkBuildingPartForm/WorkBuildingPartForm';
import SaveOrCancel from '../../components/SaveOrCancel/SaveOrCancel';
import AttachmentMediaPanel from '../AttachmentMediaPanel/AttachmentMediaPanel';
import ProgressBottomButton from '../../shared/ProgressBottomButton/ProgressBottomButton';
import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import ChildrenAttachmentSection from '../../sections/ChildrenAttachmentSection';

interface Props {
  handleReturnBackToInfo: () => void;
  handleAddNewWork: (newWork: any, isFirstSave?: boolean) => void;
  handleUpdateWork: (updatedWork: any, isLastSaved?: boolean) => void;
  editedWork: any;
  isViewWorkCondition: boolean;
  workModel: any;
  building: BuildingType;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  handleNextExpansion: () => void;
  handlePrevExpansion: () => void;
  handleExpansionChange: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  expanded: string | false;
  currentStepNumber: number;
  stepsAmount: number;
  currentExpansionIndex: number;
  handleEditSaveTrigger: (status: boolean) => void;
  editSaveTrigger: boolean;
  isEditDocumentChanged: boolean;
  handleEditDocumentChanged: (status: boolean) => void;
}

const initialState = {
  workTitle: '',
  description: '',
  isFromDatePickerOpen: false,
  fromSelectedDate: new Date(Date.now()),
  toSelectedDate: null,
  responsibleActors: [],
  isActorsPanelOpen: false,
  buildingPart: { title: '', id: '' },
  cost: null,
  timings: null,
  material: '',
  comments: [],
  isCommentsPanelOpen: false,
  media: [],
  isMediaPanelOpen: false,
  selectedMedia: null,
  annotationEvents: [],
  isFirstSaved: false,
  imagesData: [],
  attachments: [],
  attachmentsData: [],
  videos: [],
  videosData: [],
  isEditChanged: false,
};

const WorkExpansionPanel = (props: Props) => {
  const theme = useTheme();
  const tabletMatch = useMediaQuery('(min-width: 768px)');

  // STATE
  const [state, dispatch] = useReducer(reducer, initialState);

  // title handlers
  const handleWorkTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { workTitle: event.target.value as string },
    });
  };
  const handleDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { description: event.target.value as string },
    });
  };
  //const handleFromDateChange = (date: Date | null) => {
  //  dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { fromSelectedDate: date } });
  //};
  const handleToDateChange = (date: Date | null) => {
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { toSelectedDate: date } });
  };
  const handleOpenFromDatePicker = () => {
    if (disabledConditions) return;
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isFromDatePickerOpen: true } });
  };
  const handleCloseFromDatePicker = () => {
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isFromDatePickerOpen: false } });
  };
  const handleEditDragItem = (newItem: any) => {
    const itemsWithNewItem = state.responsibleActors.map(item => {
      if (newItem.no && newItem.no !== null) {
        return item.no === newItem.no ? newItem : item;
      }
      // SAFE IF ISSUE
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { responsibleActors: itemsWithNewItem } });
  };
  const handleAddDragItem = (newItem: any) => {
    const itemsWithNewItem = [...state.responsibleActors, newItem];
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { responsibleActors: itemsWithNewItem } });
  };
  const handleDeleteDragItem = (id: string) => {
    // comment cause api object don't have _destroy field
    // const deletedItemIndex = state.responsibleActors.findIndex(
    //   item => item.resp_actor_item_actor_id === id || item.no === id,
    // );
    // const newResponsibleActors = state.responsibleActors.map((item, idx) => {
    //   if (idx === deletedItemIndex) {
    //     return { ...item, _destroy: true };
    //   }
    //   return item;
    // });
    // dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { responsibleActors: newResponsibleActors } });
  };
  const handleOpenActorsPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isActorsPanelOpen: true } });
  };
  const handleCloseActorsPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isActorsPanelOpen: false } });
  };
  const handleToggleActorsPanel = () => {
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { isActorsPanelOpen: !state.isActorsPanelOpen },
    });
  };

  // work title drag and drop
  const moveDragItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = state.responsibleActors[dragIndex];
      dispatch({
        type: ActionTypes.UPDATE_WORK_PANEL_STATE,
        payload: {
          responsibleActors: update(state.responsibleActors, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        },
      });
    },
    [state.responsibleActors],
  );

  // building part handlers
  const handleBuildingPartChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { buildingPart: option } });
  };

  const handleCostChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { cost: event.target.value as number },
    });
  };
  const handleTimingsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { timings: event.target.value as number },
    });
  };
  const handleMaterialChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { material: event.target.value as string },
    });
  };
  const handleEditComment = (newItem: any) => {
    const itemsWithNewItem = state.comments.map(item => {
      if (newItem.no && newItem.no !== null) {
        return item.no === newItem.no ? newItem : item;
      }
      if (newItem.order_number && newItem.order_number !== null) {
        return item.order_number === newItem.order_number ? newItem : item;
      }
      // SAFE IF ISSUE
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { comments: itemsWithNewItem } });
  };
  const handleAddComment = (newItem: any) => {
    const itemsWithNewItem = [...state.comments, newItem];
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { comments: itemsWithNewItem } });
  };
  const handleDeleteComment = (id: string) => {
    const deletedItemIndex = state.comments.findIndex(item => item.no === id || item.order_number === id);

    const itemsWithNewItem = state.comments.map((item, idx) => {
      if (idx === deletedItemIndex) {
        return { ...item, _destroy: true };
      }
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { comments: itemsWithNewItem } });
  };
  const handleOpenCommentsPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isCommentsPanelOpen: true } });
  };
  const handleCloseCommentsPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isCommentsPanelOpen: false } });
  };
  const handleToggleCommentsPanel = () => {
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { isCommentsPanelOpen: !state.isCommentsPanelOpen },
    });
  };
  // work comments drag and drop
  const moveCommentDragItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = state.comments[dragIndex];
      dispatch({
        type: ActionTypes.UPDATE_WORK_PANEL_STATE,
        payload: {
          comments: update(state.comments, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        },
      });
    },
    [state.comments],
  );

  // ATTACHMENT HANDLERS
  const handleSaveAnnotations = async (newAnnotations: any[]) => {
    if (state.annotationEvents.some((annot: any) => annot.image_id === state.selectedMedia.image_id)) {
      // FETCH
      const updated = {
        ...state.annotationEvents.find((annot: any) => annot.image_id === state.selectedMedia.image_id),
        annotation_points: [...newAnnotations],
      };
      await postAnnotationEvent(updated);
      //
      const currentWork = await fetchBuildingEvent(props.editedWork.artifact_id);
      dispatch({
        type: ActionTypes.UPDATE_WORK_PANEL_STATE,
        payload: { annotationEvents: currentWork.annotation_events },
      });
      return;
    }

    // IF IT IS NEW ANNOTATION EVENT
    const newAnnotationEvent = {
      model_title: null,
      deletable: { deletable_type: 'bool', is_deletable: true, field: null, comparator: null, value: null },
      artifact_name_upper: null,
      checksum: null,
      artifact_id: null,
      artifact_name: null,
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_by_id: null,
      updated_by_id_value: null,
      thumbnail_id: null,
      object_type: 'AnnotationEvent',
      meta_type: 'adm_event',
      uuid: null,
      identifier: null,
      m_path: null,
      rights_type_id: null,
      attachments: [],
      authority: null,
      publish: null,
      is_restricted: null,
      event_sub_type: null,
      has_annotation_events: null,
      restriction_type: 1,
      artifact_type_id: 'ct_200-221',
      artifact_type_id_value: 'Markering',
      object_type_id: 'AE_21',
      contexts: [
        {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'AdmEventContextItem',
          meta_type: 'sub_model',
          context_id: props.editedWork?.artifact_id || null,
          revision_id: null,
          no: null,
          order_number: null,
          context_object_type: null,
          context_artifact_type_id: null,
          context_identifier: null,
          context_collection_id: null,
          context_artifact_id: props.building.artifact_id,
          context_artifact_id_value: null,
          from_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: Date.now(),
            dd_error_message: null,
          },
          to_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          remarks: null,
          _create: true,
        },
      ],
      parent_id: props.editedWork?.artifact_id || null,
      adm_event_type_id: 'ct_49-21',
      adm_event_type_id_value: 'Annotering',
      image_id: state.selectedMedia.image_id,
      annotation_points: [...newAnnotations],
      timespan: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'TimespanEvent',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        from_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: Date.now(),
          dd_error_message: null,
        },
        to_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: null,
          dd_error_message: null,
        },
        timespan_event_description: null,
      },
      collection_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      created_by_id: null,
      created_by_id_value: null,
      comments: [],
      _create: true,
    };
    await postAnnotationEvent(newAnnotationEvent);
    //
    const currentWork = await fetchBuildingEvent(props.editedWork.artifact_id);
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: {
        annotationEvents: currentWork.annotation_events,
      },
    });
  };
  const handleOpenMediaPanel = (itemId: string) => {
    const selectedImage = state.media.find((img: any) => img.image_id === itemId);
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { selectedMedia: selectedImage } });
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isMediaPanelOpen: true } });
  };
  const handleCloseMediaPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { selectedMedia: null } });
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isMediaPanelOpen: false } });
  };
  const handleAddNewImage = async (imageData: any) => {
    const formData = new FormData();
    formData.append('file', imageData);

    const uploadImageData = await uploadImage(formData);
    const newImage = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'ImageItem',
      meta_type: 'sub_model',
      context_id: props.editedWork?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.media.length,
      image_id: uploadImageData.artifact_id,
      code: uploadImageData.code,
      code_value: uploadImageData.code_value,
      motif_object: null,
      _create: true,
    };
    await postImage(newImage);

    const currentReport = await fetchBuildingEvent(props.editedWork.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { media: currentReport.images } });
  };
  const handleDeleteImage = (id: string) => {
    const updatedMedia = state.media.map(image => {
      if (image.image_id === id) {
        return { ...image, _destroy: true };
      }
      return image;
    });
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: { media: updatedMedia },
    });
  };

  // attachments
  const handleAddNewAttachment = async (attachmentData: any) => {
    const formData = new FormData();
    formData.append('file', attachmentData);

    const uploadAttachmentData = await uploadFile(formData);
    const newAttachment = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'AttachmentItem',
      meta_type: 'sub_model',
      context_id: props.editedWork?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.attachments.length,
      context_object_type: null,
      context_meta_type: null,
      artifact_id: null,
      created_by_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      artifact_name: null,
      object_type_id: 'ATTACHMENT_ITEM',
      artifact_type_id: null,
      artifact_type_id_value: null,
      _destroy: null,
      uuid: null,
      attachment_id: uploadAttachmentData.artifact_id,
      attachment_id_value: null,
      _create: true,
    };
    await postFile(newAttachment);

    const currentReport = await fetchBuildingEvent(props.editedWork.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { attachments: currentReport.attachments } });
  };

  const handleDeleteFile = (id: string) => {
    const updatedAttachments = state.attachments.map(file => {
      if (file.attachment_id === id) {
        return { ...file, _destroy: true };
      }
      return file;
    });
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: {
        attachments: updatedAttachments,
      },
    });
  };
  // videos
  const handleAddNewVideo = async (videoData: any) => {
    const formData = new FormData();
    formData.append('file', videoData);

    const uploadVideoData = await uploadVideo(formData);
    const newVideo = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'VideoItem',
      meta_type: 'sub_model',
      context_id: props.editedWork?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.videos.length,
      video_id: uploadVideoData.artifact_id,
      _create: true,
    };
    await postVideo(newVideo);

    const currentReport = await fetchBuildingEvent(props.editedWork.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { videos: currentReport.videos } });
  };

  const handleDeleteVideo = (id: string) => {
    const updatedVideos = state.videos.map(video => {
      if (video.video_id === id) {
        return { ...video, _destroy: true };
      }
      return video;
    });
    dispatch({
      type: ActionTypes.UPDATE_WORK_PANEL_STATE,
      payload: {
        videos: updatedVideos,
      },
    });
  };

  // HANDLE SAVE REPORT
  const handleSaveWork = async (isLastSaved = false) => {
    try {
      // IT IS NO MATTER WHICH SAVE OPTION CHANGE YET, IT IS THE SAME SAVE IN EACH OPTION YET
      if (props.isEditMode || state.isFirstSaved) {
        const updatedWork = {
          ...props.workModel,
          ...props.editedWork,
          title: {
            ...props.editedWork.title,
            model_title: null,
            artifact_name_upper: null,
            title: state.workTitle || null,
          },
          description: {
            ...props.editedWork.description,
            model_title: null,
            artifact_name_upper: null,
            description: state.description || null,
          },
          building_treatment: {
            ...props.editedWork.building_treatment,
            model_title: null,
            artifact_name_upper: null,
            building_part: {
              ...props.editedWork.building_treatment.building_part,
              model_title: null,
              artifact_name_upper: null,
              building_part_type_id: state.buildingPart.id || null,
              building_part_type_id_value: state.buildingPart.title || null,
            },
            cost: {
              ...props.editedWork.building_treatment.cost,
              model_title: null,
              artifact_name_upper: null,
              cost: state.cost || null,
            },
            time_spent: {
              ...props.editedWork.building_treatment.time_spent,
              model_title: null,
              artifact_name_upper: null,
              time_spent: state.timings || null,
            },
            material: {
              ...props.editedWork.building_treatment.material,
              model_title: null,
              artifact_name_upper: null,
              material: state.material || null,
            },
          },
          responsible_actors: [...state.responsibleActors],
          images: [...state.media],
          annotation_events: [...state.annotationEvents],
          timespan: {
            ...props.editedWork.timespan,
            model_title: null,
            artifact_name_upper: null,
            from_date: {
              ...props.editedWork.timespan.from_date,
              model_title: null,
              artifact_name_upper: null,
              dd_date: Number(format(state.fromSelectedDate as Date, 'T')),
            },
            to_date: {
              ...props.editedWork.timespan.to_date,
              model_title: null,
              artifact_name_upper: null,
              dd_date: Number(format(state.toSelectedDate as Date, 'T')),
            },
          },
          comments: [...state.comments],
          attachments: [...state.attachments],
          videos: [...state.videos],
          _create: true,
        };

        await props.handleUpdateWork(updatedWork, !props.isEditMode ? isLastSaved : false);
        dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isEditChanged: false } });

        if (!isLastSaved) {
          props.handleNextExpansion();
        }
      }
      if (!props.isEditMode && !state.isFirstSaved) {
        // CREATE NEW REPORT TEMPLATE
        const newWork = {
          ...props.workModel,
          artifact_name: null,
          parent_id: props.building.artifact_id,
          uuid: null,
          updated_at: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          thumbnail_id: null,
          object_type: 'BuildingTreatmentEvent',
          meta_type: 'adm_event',
          identifier: null,
          m_path: null,
          rights_type_id: null,
          authority: null,
          publish: null,
          is_restricted: false,
          event_sub_type: null,
          has_annotation_events: null,
          restriction_type: 1,
          object_type_id: 'AE_24',
          artifact_type_id: 'ct_200-225',
          artifact_type_id_value: 'Bygningsarbeid',
          contexts: [
            {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              object_type: 'AdmEventContextItem',
              meta_type: 'sub_model',
              context_id: null,
              revision_id: null,
              no: null,
              order_number: null,
              context_object_type: null,
              context_artifact_type_id: null,
              context_identifier: null,
              context_collection_id: null,
              context_artifact_id: props.building.artifact_id,
              context_artifact_id_value: props.building.artifact_name,
              from_date: {
                model_title: null,
                artifact_name_upper: null,
                checksum: null,
                meta_type: 'sub_model',
                object_type: 'PrecisionDate',
                dd_error: null,
                dd_precision: 'datetime',
                dd_date: Date.now(),
                dd_error_message: null,
              },
              to_date: {
                model_title: null,
                artifact_name_upper: null,
                checksum: null,
                meta_type: 'sub_model',
                object_type: 'PrecisionDate',
                dd_error: null,
                dd_precision: 'datetime',
                dd_date: null,
                dd_error_message: null,
              },
              remarks: null,
              _create: true,
            },
          ],
          adm_event_type_id: 'ct_49-24',
          adm_event_type_id_value: 'Bygningsbehandling',
          title: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'AdmTitle',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            title: state.workTitle || null,
          },
          description: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'Description',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            description: state.description || null,
          },
          building_treatment: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'BuildingTreatment',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            no: null,
            building_part: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              object_type: 'BuildingTreatmentBuildingPart',
              meta_type: 'sub_model',
              context_id: null,
              revision_id: null,
              building_part_type_id: state.buildingPart.id || null,
              building_part_type_id_value: state.buildingPart.title || null,
            },
            cost: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              object_type: 'BuildingTreatmentCost',
              meta_type: 'sub_model',
              context_id: null,
              revision_id: null,
              cost: state.cost || null,
            },
            time_spent: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              object_type: 'BuildingTreatmentTimeSpent',
              meta_type: 'sub_model',
              context_id: null,
              revision_id: null,
              time_spent: state.timings || null,
            },
            material: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              object_type: 'BuildingTreatmentMaterial',
              meta_type: 'sub_model',
              context_id: null,
              revision_id: null,
              material: state.material || null,
            },
          },
          responsible_actors: [...state.responsibleActors],
          images: [...state.media],
          has_images: null,
          annotation_events: [...state.annotationEvents],
          timespan: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'TimespanConservation',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            from_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: 'datetime',
              dd_date: Number(format(state.fromSelectedDate as Date, 'T')),
              dd_error_message: null,
            },
            to_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: 2851541348,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: null,
              dd_date: null,
              dd_error_message: null,
            },
            timespan_conservation_desc: null,
          },
          collection_id: null,
          created_at: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          created_by_id: null,
          created_by_id_value: null,
          comments: [...state.comments],
          published: false,
          has_condition_assessment_events: null,
          has_insurance_events: null,
          has_purchase_events: null,
          has_publishing_events: null,
          has_analysis_events: null,
          has_entry_events: null,
          has_exit_events: null,
          has_loan_events: null,
          has_exhibition_events: null,
          has_location_events: null,
          has_observation_events: null,
          has_salvage_priority_events: null,
          has_treatment_events: null,
          annotation_event_ids: [],
          parent_id_value: null,
          attachments: [...state.attachments],
          videos: [...state.videos],
          _create: true,
        };

        // POST REPORT AND RETURN TO INFO
        await props.handleAddNewWork(newWork, state.isFirstSaved || isLastSaved);

        if (!state.isFirstSaved) {
          props.handleNextExpansion();
        }

        dispatch({ type: ActionTypes.UPDATE_WORK_PANEL_STATE, payload: { isFirstSaved: true } });
        //
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  useEffect(() => {
    if (!props.editSaveTrigger) return;
    handleSaveWork(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editSaveTrigger]);

  // SETTING FORMS STATES IF EDIT REPORT
  useEffect(() => {
    dispatch({
      type: ActionTypes.PREPARE_EDITED,
      payload: {
        workTitle: props.editedWork?.title?.title ?? '',
        description: props.editedWork?.description?.description ?? '',
        fromSelectedDate:
          (props.editedWork?.timespan?.from_date?.dd_date && new Date(props.editedWork.timespan.from_date.dd_date)) ??
          new Date(Date.now()),
        toSelectedDate:
          (props.editedWork?.timespan?.to_date?.dd_date && new Date(props.editedWork.timespan.to_date.dd_date)) ??
          new Date(Date.now()),
        responsibleActors: props.editedWork?.responsible_actors || [],
        buildingPart: {
          title: props.editedWork?.building_treatment?.building_part?.building_part_type_id_value ?? '',
          id: props.editedWork?.building_treatment?.building_part?.building_part_type_id ?? '',
        },
        cost: props.editedWork?.building_treatment?.cost?.cost ?? '',
        timings: props.editedWork?.building_treatment?.time_spent?.time_spent ?? '',
        material: props.editedWork?.building_treatment?.material?.material ?? '',
        comments: props.editedWork?.comments || [],
        media: props.editedWork?.images || [],
        selectedMedia: null,
        annotationEvents: props.editedWork?.annotation_events || [],
        attachments: props.editedWork?.attachments || [],
        videos: props.editedWork?.videos || [],
      },
    });
  }, [props.isViewWorkCondition, props.editedWork]);

  // TRIGGER CHANGED DOCUMENT
  useEffect(() => {
    if (!state.isEditChanged) {
      props.handleEditDocumentChanged(false);
      return;
    }

    props.handleEditDocumentChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isEditChanged]);

  // FETCH IMAGES DATA
  useEffect(() => {
    if (state.media.length === 0) return;
    const imagesIds = state.media.reduce((acc: string[], { image_id }: { image_id: string }) => [...acc, image_id], []);

    (async () => {
      const imagesData = await fetchReportImagesData(imagesIds);
      dispatch({ type: ActionTypes.PREPARE_EDITED, payload: { imagesData: imagesData.artifacts } });
    })();
    return;
  }, [state.media]);

  // FETCH ATTACHMENTS DATA
  useEffect(() => {
    if (state.attachments.length === 0) return;
    const attachmentsIds = state.attachments.reduce(
      (acc: string[], { attachment_id }: { attachment_id: string }) => [...acc, attachment_id],
      [],
    );

    (async () => {
      const attachmentsData = await fetchAttachmentsData(attachmentsIds);
      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { attachmentsData: attachmentsData.artifacts },
      });
    })();
    return;
  }, [state.attachments]);

  // FETCH VIDEOS DATA
  useEffect(() => {
    if (state.videos.length === 0) return;
    const videosIds = state.videos.reduce(
      (acc: string[], { video_id }: { video_id: string }) => [...acc, video_id],
      [],
    );

    (async () => {
      const videosData = await fetchVideosData(videosIds);

      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { videosData: videosData.artifacts },
      });
    })();
    return;
  }, [state.videos]);

  const currentAnnotationEvent = useMemo(
    () =>
      state.annotationEvents.find(
        (annot: any) => state.selectedMedia && annot.image_id === state.selectedMedia.image_id,
      ) || {},
    [state.annotationEvents, state.selectedMedia],
  );

  const currentImageData = useMemo(
    () => state.imagesData.find(img => img.artifact_id === state.selectedMedia?.image_id),
    [state.imagesData, state.selectedMedia],
  );

  const disabledConditions = props.isViewWorkCondition && !props.isEditMode;
  const mediaLength = useMemo(
    () => [...state.media, ...state.videos, ...state.attachments].filter(img => !img._destroy).length,
    [state.attachments, state.media, state.videos],
  );

  return (
    <>
      {!tabletMatch &&
        !props.isViewWorkCondition &&
        !state.isActorsPanelOpen &&
        !state.isMediaPanelOpen &&
        !state.isCommentsPanelOpen && (
          <ProgressBottomButton
            onSave={() => handleSaveWork(true)}
            currentStepNumber={props.currentStepNumber}
            stepsAmount={props.stepsAmount}
            onNext={() => handleSaveWork()}
            onBack={props.handlePrevExpansion}
            expanded={props.expanded}
          />
        )}
      {!tabletMatch && props.isViewWorkCondition && (
        <BottomStatusBar
          date={props.editedWork?.updated_at.dd_date ?? 0}
          status="Utkast"
          isEditMode={props.isEditMode}
          handleEditMode={props.handleEditMode}
          handleEditSaveTrigger={props.handleEditSaveTrigger}
          isEditDocumentChanged={props.isEditDocumentChanged}
        />
      )}

      {getExpansionPanelMobileRule(workExpansionsTypes.WORK, tabletMatch, props.expanded, props.isEditMode) && (
        <StyledExpansionPanel
          expanded={props.expanded === workExpansionsTypes.WORK}
          onChange={props.handleExpansionChange(workExpansionsTypes.WORK)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === workExpansionsTypes.WORK ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Arbeid</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {state.workTitle || 'Uten tittel'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>

          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <WorkTitleForm
              workTitle={state.workTitle}
              description={state.description}
              isFromDatePickerOpen={state.isFromDatePickerOpen}
              fromSelectedDate={state.fromSelectedDate}
              toSelectedDate={state.toSelectedDate}
              handleWorkTitleChange={handleWorkTitleChange}
              handleDescriptionChange={handleDescriptionChange}
              handleToDateChange={handleToDateChange}
              handleOpenFromDatePicker={handleOpenFromDatePicker}
              handleCloseFromDatePicker={handleCloseFromDatePicker}
              disabledConditions={disabledConditions}
              handleEditDragItem={handleEditDragItem}
              handleAddDragItem={handleAddDragItem}
              handleDeleteDragItem={handleDeleteDragItem}
              handleOpenActorsPanel={handleOpenActorsPanel}
              handleCloseActorsPanel={handleCloseActorsPanel}
              handleToggleActorsPanel={handleToggleActorsPanel}
              moveDragItem={moveDragItem}
              responsibleActors={state.responsibleActors}
              isActorsPanelOpen={state.isActorsPanelOpen}
            />
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewWorkCondition && !state.isActorsPanelOpen && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveWork()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(
        workExpansionsTypes.BUILDING_PART,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === workExpansionsTypes.BUILDING_PART}
          onChange={props.handleExpansionChange(workExpansionsTypes.BUILDING_PART)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === workExpansionsTypes.BUILDING_PART ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Bygningsdel</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {state.buildingPart.title || 'Uten bygningsdel'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <WorkBuildingPartForm
              buildingPart={state.buildingPart.title}
              cost={state.cost}
              timings={state.timings}
              material={state.material}
              handleBuildingPartChange={handleBuildingPartChange}
              handleCostChange={handleCostChange}
              handleTimingsChange={handleTimingsChange}
              handleMaterialChange={handleMaterialChange}
              handleEditComment={handleEditComment}
              handleAddComment={handleAddComment}
              handleDeleteComment={handleDeleteComment}
              handleOpenCommentsPanel={handleOpenCommentsPanel}
              handleCloseCommentsPanel={handleCloseCommentsPanel}
              handleToggleCommentsPanel={handleToggleCommentsPanel}
              comments={state.comments}
              disabledConditions={disabledConditions}
              isCommentsPanelOpen={state.isCommentsPanelOpen}
              moveDragItem={moveCommentDragItem}
            />
          </StyledExpansionPanelDetails>
          {tabletMatch && !props.isViewWorkCondition && !state.isCommentsPanelOpen && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveWork()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(workExpansionsTypes.ATTACHMENTS, tabletMatch, props.expanded, props.isEditMode) && (
        <StyledExpansionPanel
          expanded={props.expanded === workExpansionsTypes.ATTACHMENTS}
          onChange={props.handleExpansionChange(workExpansionsTypes.ATTACHMENTS)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === workExpansionsTypes.ATTACHMENTS ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Opplastninger</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {mediaLength}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails padding={!tabletMatch && '8px 8px 24px'} isEdit={props.isEditMode}>
            {state.isMediaPanelOpen ? (
              <AttachmentMediaPanel
                onCloseMediaPanel={handleCloseMediaPanel}
                selectedMedia={state.selectedMedia}
                handleSaveAnnotations={handleSaveAnnotations}
                annotations={currentAnnotationEvent.annotation_points || []}
                imageName={currentImageData?.artifact_name || ''}
              />
            ) : (
              <ChildrenAttachmentSection
                onOpenMediaPanel={handleOpenMediaPanel}
                media={state.media}
                annotationEvents={state.annotationEvents}
                handleAddNewImage={handleAddNewImage}
                disabledConditions={disabledConditions}
                handleDeleteImage={handleDeleteImage}
                imagesData={state.imagesData}
                attachmentsData={state.attachmentsData}
                attachments={state.attachments}
                videosData={state.videosData}
                videos={state.videos}
                handleAddNewAttachment={handleAddNewAttachment}
                handleDeleteFile={handleDeleteFile}
                handleAddNewVideo={handleAddNewVideo}
                handleDeleteVideo={handleDeleteVideo}
              />
            )}
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewWorkCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveWork(true)} withoutBack saveLabel="Lagre og avslutt" />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}
    </>
  );
};

WorkExpansionPanel.defaultProps = {
  building: {
    artifact_id: '',
  },
};

const StyledFlexBox = styled(props => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`;

export default WorkExpansionPanel;
