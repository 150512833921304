import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, useMediaQuery } from '@material-ui/core';

import HomeAppBar from './components/HomeAppBar/HomeAppBar';
import HomeMenu from './components/HomeMenu/HomeMenu';
import LastUpdated from './sections/LastUpdated/LastUpdated';
import Favourites from './sections/Favourites/Favourites';
import Drafts from './sections/Drafts/Drafts';
import BottomAppNavigation from './components/BottomAppNavigation/BottomAppNavigation';

interface Props {
  lastUpdatedObjects: any[];
  handleSearchInput: (value: string) => void;
  handleSearchOpen: () => void;
  handleSearchClose: () => void;
  handleSearchSubmit: (event: React.BaseSyntheticEvent<Event, EventTarget & Element, EventTarget>) => void;
  handleSearchBySuggested: (searchQuery: string) => void;
  isSearchOpen: boolean;
  searchInputValue: string;
  handleToggleMenu: () => void;
  isMenuOpen: boolean;
  suggestedSearch: string[];
  favouritesBuildings: any[];
  draftsObjects: any[];
  handleDeleteDraftObject: (id: string) => void;
  handleLogout: () => void;
  addBuildingToFavorites: (building: any) => void;
  removeBuildingFromFavorites: (id: string) => void;
  checkBuildingInFavorites: (id: string) => boolean;
}

const Main = (props: Props) => {
  const [navigationOption, setNavigationOption] = useState('favorites');

  const handleNavigationOptionChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setNavigationOption(newValue);
  };

  const mediaMatch = useMediaQuery('(min-width:768px)');

  const isViewShouldHidden = (option: string): boolean => {
    if (option === navigationOption && !mediaMatch) return false;
    if (option !== navigationOption && !mediaMatch) return true;
    if (mediaMatch) return false;
    return false;
  };

  return (
    <>
      <HomeMenu
        handleToggleMenu={props.handleToggleMenu}
        isMenuOpen={props.isMenuOpen}
        handleLogout={props.handleLogout}
        handleSearchBySuggested={props.handleSearchBySuggested}
      />
      <StyledWrapper isMenuOpen={props.isMenuOpen}>
        <HomeAppBar
          handleSearchOpen={props.handleSearchOpen}
          handleToggleMenu={props.handleToggleMenu}
          handleSearchClose={props.handleSearchClose}
          searchInputValue={props.searchInputValue}
          handleSearchSubmit={props.handleSearchSubmit}
          handleSearchInput={props.handleSearchInput}
          isSearchOpen={props.isSearchOpen}
        />
        <StyledContainer isSearchOpen={props.isSearchOpen}>
          <Drafts
            draftsObjects={props.draftsObjects}
            handleDeleteDraftObject={props.handleDeleteDraftObject}
            hidden={isViewShouldHidden('edit')}
          />

          <Favourites
            favouritesBuildings={props.favouritesBuildings}
            hidden={isViewShouldHidden('favorites')}
            addBuildingToFavorites={props.addBuildingToFavorites}
            removeBuildingFromFavorites={props.removeBuildingFromFavorites}
            checkBuildingInFavorites={props.checkBuildingInFavorites}
          />
          <LastUpdated lastUpdatedObjects={props.lastUpdatedObjects} hidden={isViewShouldHidden('search')} />
        </StyledContainer>
      </StyledWrapper>
      {!mediaMatch && (
        <BottomAppNavigation
          navigationOption={navigationOption}
          handleNavigationOptionChange={handleNavigationOptionChange}
          isMenuOpen={props.isMenuOpen}
        />
      )}
    </>
  );
};

const StyledContainer = styled(({ isSearchOpen, ...props }) => <Container {...props} />)`
  &.MuiContainer-root {
    padding: 0 30px;
    max-width: none;

    @media (max-width: 767px) {
      padding: 0;
      margin-bottom: 56px;
    }
  }
`;

const StyledWrapper = styled.div`
  transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms';
  ${(props: { isMenuOpen: boolean }) =>
    props.isMenuOpen
      ? `
        transform : translateX(85vw);
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
      `
      : `transform : translateX(0);
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  `};
  @media (min-width: 768px) {
    ${(props: { isMenuOpen: boolean }) =>
      props.isMenuOpen
        ? `
        transform : translateX(320px);
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      `
        : `transform : translateX(0);
          transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  `};
  }
`;

export default Main;
