import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

const StyledButton = styled(({ padding, color, text, width, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    padding: ${props => props.padding || '6px 30px'};
    border: 1px solid #2c2f38;
    border-radius: 0;
    color: ${props => props.color || 'default'};
    min-width: ${props => props.width || '64px'};
    text-transform: none;
    font-weight: bold;
  }
`;

export default StyledButton;
