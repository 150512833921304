import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';

interface Props {
  resultsAmount: number;
  onClick?: () => void;
}

const FilterBottomButton: React.FC<Props> = props => {
  return (
    <StyledButton color="#0069BD" fullWidth onClick={props.onClick || (() => null)}>
      <Typography variant="subtitle2">{`Vis ${props.resultsAmount} treff`}</Typography>
    </StyledButton>
  );
};

const StyledButton = styled(({ color, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    position: fixed;
    bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: ${props => props.color};
    color: #ffffff;
    border-radius: 0;
    text-transform: none;

    &:hover {
      background-color: #368fd6;
    }
  }
`;

export default FilterBottomButton;
