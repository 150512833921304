import React from 'react';
import styled from 'styled-components';

import { Box, Typography } from '@material-ui/core';

import MainBuildingsList from '../../components/MainBuildingsList/MainBuildingsList';

interface Props {
  favouritesBuildings: any[];
  hidden: boolean;
  addBuildingToFavorites: (building: any) => void;
  removeBuildingFromFavorites: (id: string) => void;
  checkBuildingInFavorites: (id: string) => boolean;
}

const Favourites = (props: Props) => {
  return (
    <Box hidden={props.hidden}>
      <CaptionWrap>
        <StyledTypography fontSize="1.1rem">Favoritter</StyledTypography>
      </CaptionWrap>
      <MainBuildingsList
        buildings={props.favouritesBuildings}
        addBuildingToFavorites={props.addBuildingToFavorites}
        removeBuildingFromFavorites={props.removeBuildingFromFavorites}
        checkBuildingInFavorites={props.checkBuildingInFavorites}
      />
    </Box>
  );
};
const CaptionWrap = styled(Box)`
  padding: 28px 0 16px;
  @media (max-width: 705px) {
    padding: 28px 18px 16px;
  }
  @media (max-width: 767px) {
    padding: 28px 26px 16px;
  }
  @media (max-width: 542px) {
    padding: 28px 18px 16px;
  }
`;
const StyledTypography = styled(({ color, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-size: ${props => props.fontSize};
  }
`;
export default Favourites;
