import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button, Typography, Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

interface Props {
  label: string;
  onClick?: () => void;
  icon?: ReactElement;
}

const OutlineBottomButton: React.FC<Props> = props => {
  const theme = useTheme();
  return (
    <StyledButton
      borderColor={theme.palette.primary.main}
      color="#ffffff"
      fullWidth
      onClick={props.onClick || (() => null)}
    >
      <Grid container alignItems="center" justify="center" direction="row" wrap="nowrap" spacing={1}>
        <Grid item>
          <StyledBox>{props.icon}</StyledBox>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{props.label}</Typography>
        </Grid>
      </Grid>
    </StyledButton>
  );
};

const StyledButton = styled(({ color, borderColor, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1300;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: ${props => props.color};
    color: #000000;
    border-radius: 0;
    text-transform: none;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
  @media (hover: none) {
    &.MuiButton-root:hover {
      background-color: #fff;
    }
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default OutlineBottomButton;
