import React from 'react';
import styled from 'styled-components';
import { Grid, Box } from '@material-ui/core';
import queryString from 'query-string';

import { Link, useLocation } from 'react-router-dom';

import { getDocumentRoutingName } from 'helpers/getDocumentRoutingName';

import HomeEventListItem from 'components/HomeEventListItem/HomeEventListItem';

interface Props {
  observations: any[];
}

const SearchObservationsList = (props: Props) => {
  const location = useLocation();
  const searchQuery = queryString.parse(location.search).query || '';

  return (
    <StyledListWrap>
      <Grid container>
        {props.observations.map((observation, idx) => {
          const pathLink = getDocumentRoutingName(observation.artifact_type_id_value)
            ? `/building/${observation['contexts.context_artifact_id'][0]}/${getDocumentRoutingName(
                observation.artifact_type_id_value,
              )}/${observation.artifact_id || observation.uuid}`
            : '/';

          return (
            <Grid item xs={12} key={observation.artifact_id}>
              <Link
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                to={{
                  pathname: pathLink,
                  state: { fromSearch: true, query: searchQuery },
                }}
              >
                <HomeEventListItem object={observation} />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </StyledListWrap>
  );
};

const StyledListWrap = styled(Box)`
  background-color: #ffffff;
`;

export default SearchObservationsList;
