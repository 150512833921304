export const ItemTypes = {
  ASSESSMENT_DRAG: 'assessmentDrag',
  EDIT_DRAG: 'editDrag',
  MEDIA_DRAG: 'mediaDrag',
  ACTOR_DRAG: 'workActorDrag',
  WORK_COMMENT_DRAG: 'workCommentDrag',
};

export const childrenExpansionsTypes = {
  CAUSE_AND_PURPOSE: 'causeAndPurpose',
  ASSESSMENT: 'assessment',
  ATTACHMENTS: 'attachments',
  SUMMARY: 'summary',
};

export const deviationExpansionsTypes = {
  TITLE_AND_PRIORITY: 'titleAndPriority',
  BUILDING_AND_DETAILS: 'buildingAndDetails',
  ATTACHMENTS: 'attachments',
  BACKGROUND_INFORMATION: 'backgroundInformation',
};

export const workExpansionsTypes = {
  WORK: 'work',
  BUILDING_PART: 'buildingPart',
  ATTACHMENTS: 'attachments',
};

export const taskExpansionsTypes = {
  TASK: 'task',
  CHECKLIST: 'checklist',
  ATTACHMENTS: 'attachments',
};

export const documentTypes = {
  CONDITION: 'condition',
  DEVIATION: 'deviation',
  WORK: 'work',
  TASK: 'task',
};

export const LAST_UPDATED_LIMIT = 10;
export const DRAFTS_LIMIT = 10;
export const SEARCH_HISTORY_LIMIT = 10;

export const DELETE_MODAL_TEXT = 'Vil du slette denne elementet?';
