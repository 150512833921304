import React from 'react';
import { Box, Grid } from '@material-ui/core';

import ListsItem from '../../shared/ListsItem/ListsItem';

// types
import { ObservationEventsType } from 'types/Building/ObservationEventsType';

interface Props {
  observationEvents: ObservationEventsType[];
  handleOpenEditDeviation: (id: string) => void;
  handleEditMode: (status: boolean) => void;
  buildingCollectionId: string;
  buildingAcl: { restrictions: any[] };
  handleOpenDeleteModal: (id: string) => void;
}

const ObservationDeviationList = (props: Props) => {
  const onOpenWithEdit = async (id: string) => {
    await props.handleOpenEditDeviation(id);
    props.handleEditMode(true);
  };
  return (
    <>
      {props.observationEvents.length > 0 && (
        <Box>
          <Grid container>
            {props.observationEvents.map(observation => (
              <Grid item xs={12} key={observation.artifact_id || observation.uuid}>
                <ListsItem
                  item={observation}
                  onDelete={() => props.handleOpenDeleteModal(observation.artifact_id || observation.uuid)}
                  onOpen={() => props.handleOpenEditDeviation(observation.artifact_id || observation.uuid)}
                  onOpenWithEdit={() => onOpenWithEdit(observation.artifact_id || observation.uuid)}
                  buildingCollectionId={props.buildingCollectionId}
                  buildingAcl={props.buildingAcl}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ObservationDeviationList;
