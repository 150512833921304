import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { PriorityListItem } from 'services/PrioritiesList';

// import { priorityMock } from '../../mocks/priorityMock';

interface Props {
  deviation: PriorityListItem;
  priority: any;
  disabledConditions: boolean;
  handleDeviationPriorityChange: (deviation: PriorityListItem) => void;
}

const PriorityLabelBox: React.FC<Props> = ({
  deviation,
  priority,
  disabledConditions,
  handleDeviationPriorityChange,
}) => {
  return (
    <StyledPriorityBox
      textColor={priority === deviation ? '#ffffff' : '#4f4f4f'}
      color={(priority?.priority_type_id || priority?.id) === deviation.id ? deviation.color : '#ffffff'}
      borderColor={(priority?.priority_type_id || priority?.id) === deviation.id ? deviation.color : 'rgba(0,0,0,.25)'}
      hoverColor={deviation.color}
      onClick={() => (disabledConditions ? null : handleDeviationPriorityChange(deviation))}
    >
      <StyledTypography fontSize="0.875rem" fontWeight="700">
        {deviation.title}
      </StyledTypography>
    </StyledPriorityBox>
  );
};

const StyledPriorityBox = styled(({ color, borderColor, textColor, hoverColor, ...props }) => <Box {...props} />)`
  cursor: pointer;
  height: 56px;
  color: ${props => props.textColor};
  border: 2px solid ${props => props.borderColor};
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;

  &:hover {
    border: 2px solid ${props => props.hoverColor};
  }
`;
const StyledTypography = styled(({ fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  color: inherit;
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
  }
`;

export default PriorityLabelBox;
