import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  Box,
  useMediaQuery,
} from '@material-ui/core';

// mocks
// import { priorityMock } from '../../mocks/priorityMock';
import { taskStatusMock } from '../../mocks/statusesMock';
// icons
import AddIcon from '@material-ui/icons/Add';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import FormInput from '../../shared/FormInput/FormInput';
import Datepicker from '../../shared/Datepicker/Datepicker';
import PriorityLabelBox from '../../shared/PriorityLabelBox/PriorityLabelBox';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';
import TaskTitleDrags from './TaskTitleDrags/TaskTitleDrags';
import { getPrioritiesList, PriorityListItem } from 'services/PrioritiesList';
import { useEffect } from 'react';

interface Props {
  taskTitle: string;
  taskDescription: string;
  taskPriority: PriorityListItem;
  taskStatus: string;
  isStartDatePickerOpen: boolean;
  startDate: Date | null;
  isDoneDatePickerOpen: boolean;
  doneDate: Date | null;
  deadlineDate: Date | null;
  isDeadlineDatePickerOpen: boolean;
  handleTaskTitleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleTaskDescriptionChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleStartDateChange: (date: Date | null) => void;
  handleDoneDateChange: (date: Date | null) => void;
  handleOpenStartDatePicker: () => void;
  handleCloseStartDatePicker: () => void;
  handleOpenDoneDatePicker: () => void;
  handleCloseDoneDatePicker: () => void;
  handleTaskPriorityChange: (priority: PriorityListItem) => void;
  handleTaskStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabledConditions: boolean;
  handleEditDragItem: (newItem: any) => void;
  handleAddDragItem: (newItem: any) => void;
  handleDeleteDragItem: (id: string) => void;
  handleOpenActorsPanel: () => void;
  handleCloseActorsPanel: () => void;
  handleToggleActorsPanel: () => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  responsibleActors: any[];
  isActorsPanelOpen: boolean;
  handleDeadlineDateChange: (date: Date | null) => void;
  handleOpenDeadlineDatePicker: () => void;
  handleCloseDeadlineDatePicker: () => void;
}

const TaskTitleForm = (props: Props) => {
  const tabletMatch = !useMediaQuery('(min-width: 768px)');
  const [selectedEdit, setSelectedEdit] = useState<string | null>(null);

  const usingTablet = () => {
    return tabletMatch;
  };

  // HANDLERS
  const onToggleActorsPanel = (id: string) => {
    if (selectedEdit === null) {
      setSelectedEdit(id);
    } else {
      setSelectedEdit(null);
    }
    props.handleToggleActorsPanel();
  };

  const onCloseActorsPanel = () => {
    setSelectedEdit(null);
    props.handleCloseActorsPanel();
  };
  const handleSaveActorsPanel = ({ person }: { person: { title: string; id: string } }) => {
    if (!selectedEdit || selectedEdit === null) {
      const newPerson = {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'TaskActorItem',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        no: null,
        order_number: props.responsibleActors.length,
        context_object_type: null,
        context_meta_type: null,
        artifact_id: null,
        created_by_id: null,
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        artifact_name: null,
        object_type_id: 'TASK_ACTOR',
        artifact_type_id: null,
        artifact_type_id_value: null,
        _destroy: null,
        uuid: null,
        actor_id: person.id,
        actor_id_value: person.title,
        role_type_id: 'ct_1-240',
        role_type_id_value: '240: Ansvarlig for oppgave',
        _create: true,
      };
      props.handleAddDragItem(newPerson);
      onCloseActorsPanel();
    } else {
      const editedActor = props.responsibleActors.find(
        actor => actor.no === selectedEdit || actor.actor_id === selectedEdit,
      );
      const editedPerson = {
        ...editedActor,
        model_title: null,
        artifact_name_upper: null,
        actor_id: person.id,
        actor_id_value: person.title,
        role_type_id: 'ct_1-240',
        role_type_id_value: '240: Ansvarlig for oppgave',
        order_number: props.responsibleActors.length,
        _update: true,
      };
      // if (!editedPerson.hasOwnProperty('uuid')) {
      //   editedPerson.uuid = uuid();
      // }
      props.handleEditDragItem(editedPerson);
      onCloseActorsPanel();
    }
  };
  const handleDeleteDrag = (id: string) => {
    props.handleDeleteDragItem(id);
    props.handleCloseActorsPanel();
    setSelectedEdit(null);
  };
  // empty drag item for new drag creating
  const emptyDragItem: {
    actor_id: string;
    actor_id_value: string;
    responsibilities: string;
  } = {
    actor_id: '',
    actor_id_value: '',
    responsibilities: '',
  };

  // actors without _destroy field
  const actorsWithoutDestroy = useMemo(() => props.responsibleActors.filter((i: any) => !i._destroy), [
    props.responsibleActors,
  ]);

  const [priorities, setPriorities] = useState<Array<PriorityListItem>>([]);

  useEffect(() => {
    async function getPriorityItems() {
      setPriorities(await getPrioritiesList());
    }
    getPriorityItems();
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormInput
          value={props.taskTitle}
          handleChange={props.handleTaskTitleChange}
          disabled={props.disabledConditions}
          label="Tittel"
        />
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.taskDescription}
          handleChange={props.handleTaskDescriptionChange}
          label="Beskrivelse"
          disabled={props.disabledConditions}
        />
      </Grid>
      <Grid item>
        <Typography color="textSecondary" style={{ marginBottom: 10 }}>
          Prioritet
        </Typography>
        <Grid container wrap="nowrap" spacing={1} style={{ maxWidth: 500 }}>
          {priorities.map(deviation => {
            return (
              <Grid item xs={4} key={deviation.value}>
                <PriorityLabelBox
                  deviation={deviation}
                  handleDeviationPriorityChange={props.handleTaskPriorityChange}
                  priority={props.taskPriority}
                  disabledConditions={props.disabledConditions}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <RadioGroup name="task-status" value={props.taskStatus} onChange={props.handleTaskStatusChange} row>
            {taskStatusMock.map(({ value, id }) => (
              <StyledFormControlLabel key={id} value={value} control={<Radio color="primary" />} label={value} />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Datepicker
              value={props.startDate}
              handleChange={props.handleStartDateChange}
              open={props.isStartDatePickerOpen}
              onOpen={props.handleOpenStartDatePicker}
              onClose={props.handleCloseStartDatePicker}
              label="Startdato"
              disabled={props.disabledConditions}
            />
          </Grid>
          <Grid item>
            <Datepicker
              value={props.deadlineDate}
              handleChange={props.handleDeadlineDateChange}
              open={props.isDeadlineDatePickerOpen}
              onOpen={props.handleOpenDeadlineDatePicker}
              onClose={props.handleCloseDeadlineDatePicker}
              label="Frist"
              disabled={props.disabledConditions}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Datepicker
          value={props.doneDate}
          handleChange={props.handleDoneDateChange}
          open={props.isDoneDatePickerOpen}
          onOpen={props.handleOpenDoneDatePicker}
          onClose={props.handleCloseDoneDatePicker}
          label="Dato utført"
          disabled={props.disabledConditions}
        />
      </Grid>
      {actorsWithoutDestroy.length > 0 && (
        <Grid item>
          <Typography variant="subtitle2" style={{ paddingTop: 24 }}>
            {actorsWithoutDestroy.length} ansvarlige personer
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column">
          <TaskTitleDrags
            dragItems={props.responsibleActors}
            isActorsPanelOpen={props.isActorsPanelOpen}
            selectedEdit={selectedEdit}
            handleToggleActorsPanel={onToggleActorsPanel}
            handleSaveActorsPanel={handleSaveActorsPanel}
            emptyDragItem={emptyDragItem}
            moveDragItem={props.moveDragItem}
            handleCloseActorsPanel={onCloseActorsPanel}
            handleDeleteDrag={handleDeleteDrag}
            disabledConditions={props.disabledConditions}
          />
        </Grid>
      </Grid>
      {!props.isActorsPanelOpen && (
        <Grid item>
          <div style={usingTablet() ? { paddingBottom: '50px' } : undefined}>
            <OutlineButton padding="16px 30px" width="100%" onClick={props.handleOpenActorsPanel}>
              <StyledBox>
                <AddIcon style={{ marginRight: 4, width: 18, height: 18 }} />
              </StyledBox>
              Legg til person
            </OutlineButton>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: #000000;
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default TaskTitleForm;
