import React from 'react';

import { ReactComponent as AcuteToxicIcon } from '../assets/icons/acute-toxic.svg';
import { ReactComponent as HealthWarnIcon } from '../assets/icons/health-warning.svg';
import { ReactComponent as FlammableIcon } from '../assets/icons/FireIcon.svg';
import { ReactComponent as OxidizingIcon } from '../assets/icons/oxidizing.svg';
import { ReactComponent as ChronicHealthRiskIcon } from '../assets/icons/chronic-health-risk.svg';
import { ReactComponent as CorrosiveIcon } from '../assets/icons/corrosiveIcon.svg';
import { ReactComponent as ExplosionIcon } from '../assets/icons/explosionIcon.svg';
import { ReactComponent as GasPressureIcon } from '../assets/icons/gas-under-pressure-icon.svg';
import { ReactComponent as EnviromentalHazardIcon } from '../assets/icons/environmental-hazards-icon.svg';
import { ReactComponent as EmptyDangerIcon } from '../assets/icons/EmptyDangerIcon.svg';
import { ReactComponent as TK0Icon } from '../assets/icons/TK0.svg';
import { ReactComponent as TK1Icon } from '../assets/icons/TK1-low.svg';
import { ReactComponent as TK2Icon } from '../assets/icons/TK2-medium.svg';
import { ReactComponent as TK3Icon } from '../assets/icons/TK3-high.svg';
import { ReactComponent as LowPriorityIcon } from '../assets/icons/3-low.svg';
import { ReactComponent as MediumPriorityIcon } from '../assets/icons/2-medium.svg';
import { ReactComponent as HighPriorityIcon } from '../assets/icons/1-high.svg';
import { ReactComponent as TG0Icon } from '../assets/icons/TG0.svg';
import { ReactComponent as TG1Icon } from '../assets/icons/TG1.svg';
import { ReactComponent as TG2Icon } from '../assets/icons/TG2.svg';
import { ReactComponent as TG3Icon } from '../assets/icons/TG3.svg';

const alignStyle = { verticalAlign: 'middle' };

export const getCurrentIcon = (iconName: string): React.ReactElement => {
  if (iconName === 'Kjemisk fare» Giftig') {
    return <AcuteToxicIcon />;
  }
  if (iconName === 'Helsefare') {
    return <HealthWarnIcon />;
  }
  if (iconName === 'Kjemisk fare» Brannfarlig') {
    return <FlammableIcon />;
  }
  if (iconName === 'Kjemisk fare» Oxiderende') {
    return <OxidizingIcon />;
  }
  if (iconName === 'chronic health risk') {
    return <ChronicHealthRiskIcon />;
  }
  if (iconName === 'Kjemisk fare» Etsende') {
    return <CorrosiveIcon />;
  }
  if (iconName === 'Kjemisk fare» Eksplosjonsfare') {
    return <ExplosionIcon />;
  }
  if (iconName === 'Kjemisk fare» Gasser under trykk') {
    return <GasPressureIcon />;
  }
  if (iconName === 'Miljøfare') {
    return <EnviromentalHazardIcon />;
  }
  if (iconName.substr(0, 3) === 'TK0') {
    return <TK0Icon style={alignStyle} />;
  }
  if (iconName.substr(0, 3) === 'TK1') {
    return <TK1Icon style={alignStyle} />;
  }
  if (iconName.substr(0, 3) === 'TK2') {
    return <TK2Icon style={alignStyle} />;
  }
  if (iconName.substr(0, 3) === 'TK3') {
    return <TK3Icon style={alignStyle} />;
  }
  if (iconName.includes('Lav')) {
    return <LowPriorityIcon style={alignStyle} />;
  }
  if (iconName.includes('Medium')) {
    return <MediumPriorityIcon style={alignStyle} />;
  }
  if (iconName.includes('Høy')) {
    return <HighPriorityIcon style={alignStyle} />;
  }
  if (iconName.substr(0, 3) === 'TG0') {
    return <TG0Icon style={alignStyle} />;
  }
  if (iconName.substr(0, 3) === 'TG1') {
    return <TG1Icon style={alignStyle} />;
  }
  if (iconName.substr(0, 3) === 'TG2') {
    return <TG2Icon style={alignStyle} />;
  }
  if (iconName.substr(0, 3) === 'TG3') {
    return <TG3Icon style={alignStyle} />;
  }
  // return <Box />;
  return <EmptyDangerIcon />;
};
