import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery, Grid, Box } from '@material-ui/core';

import { getDownloadAttachmentLink } from 'services/fetchData';

import MediaAddBox from '../shared/MediaAddBox/MediaAddBox';
import MediaAttachmentItem from '../components/MediaAttachmentItem/MediaAttachmentItem';
import AttachmentItem from '../components/AttachmentItem/AttachmentItem';
import VideoItem from '../components/VideoItem/VideoItem';
import BackdropSpinner from 'components/BackdropSpinner/BackdropSpinner';
import Slider from '../components/Slider/Slider';

enum ImageAllowedMimeTypes {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PNG_X = 'image/x-png',
}

enum VideoAllowedMimeTypes {
  MP4 = 'video/mp4',
  '3GP' = 'video/3gpp',
  OGG = 'video/ogg',
  WEBM = 'video/webm',
}

interface Props {
  onOpenMediaPanel: (item: any) => void;
  media: any[];
  annotationEvents: any[];
  handleAddNewImage: (imageData: any) => void;
  disabledConditions: boolean;
  handleDeleteImage: (id: string) => void;
  imagesData?: any[];
  attachmentsData?: any[];
  attachments?: any[];
  videosData?: any[];
  videos?: any[];
  handleAddNewAttachment: (attachmentData: any) => void;
  handleDeleteFile: (id: string) => void;
  handleAddNewVideo: (videoData: any) => void;
  handleDeleteVideo: (id: string) => void;
}

const ChildrenAttachmentSection: React.FC<Props> = props => {
  const tabletMatch = useMediaQuery('(max-width: 767px)');
  const [isFileLoading, setFileLoading] = useState(false);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState('');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileLoading(true);

    const file = e.target.files![0];

    // CHECK IF ALLOWED IMAGE
    if (Object.values(ImageAllowedMimeTypes).includes(file.type as any)) {
      props.handleAddNewImage(file);
      return;
    }

    // CHECK IF ALLOWED VIDEO
    if (Object.values(VideoAllowedMimeTypes).includes(file.type as any)) {
      props.handleAddNewVideo(file);
      return;
    }

    // ADD FILE IF IMAGE OR VIDEO DON`T MATCH
    props.handleAddNewAttachment(file);
  };

  const handleOpenSlider = (id: string) => {
    setCurrentVideoId(id);
    setSliderOpen(true);
  };
  const handleCloseSlider = () => {
    setCurrentVideoId('');
    setSliderOpen(false);
  };

  useEffect(() => {
    setFileLoading(false);
  }, [props.media, props.attachments, props.videos]);

  return (
    <Grid container direction="column" style={{ position: 'relative' }}>
      <Grid item container spacing={2}>
        {props.media.map((item, idx) => {
          if (item._destroy) return null;

          const itemAnnotation = props.annotationEvents.find((annot: any) => annot.image_id === item.image_id) || {};

          return (
            <Grid item key={item.image_id} xs={tabletMatch ? 6 : 3}>
              <MediaAttachmentItem
                onOpenMediaPanel={() => (props.disabledConditions ? null : props.onOpenMediaPanel(item.image_id))}
                attachment={item}
                name={(props.imagesData && props.imagesData[idx] && props.imagesData[idx].artifact_name) || ''}
                annotations={itemAnnotation.annotation_points || []}
                handleDeleteImage={() => props.handleDeleteImage(item.image_id)}
                disabledConditions={props.disabledConditions}
                imageData={props.imagesData && props.imagesData[idx]}
              />
            </Grid>
          );
        })}

        {props.videos &&
          props.videos.map((item, idx) => {
            if (item._destroy) return null;

            return (
              <Grid item key={item.video_id} xs={tabletMatch ? 6 : 3}>
                <VideoItem
                  disabledConditions={props.disabledConditions}
                  video={item}
                  videoData={props.videosData && props.videosData[idx]}
                  handleDeleteVideo={() => props.handleDeleteVideo(item.video_id)}
                  onOpenVideo={() => handleOpenSlider(item.video_id)}
                />
              </Grid>
            );
          })}
        {props.attachments &&
          props.attachments.map((item, idx) => {
            if (item._destroy) return null;

            return (
              <Grid item key={item.attachment_id} xs={tabletMatch ? 6 : 3}>
                <AttachmentItem
                  disabledConditions={props.disabledConditions}
                  attachment={item}
                  attachmentData={props.attachmentsData && props.attachmentsData[idx]}
                  handleDeleteAttachment={() => props.handleDeleteFile(item.attachment_id)}
                  downloadLink={getDownloadAttachmentLink(item.attachment_id)}
                />
              </Grid>
            );
          })}
        {!props.disabledConditions && (
          <Grid item xs={tabletMatch ? 6 : 3}>
            <Box style={{ position: 'relative' }}>
              <StyledInput type="file" onChange={handleFileChange} />
              <MediaAddBox />
            </Box>
          </Grid>
        )}
      </Grid>
      {isFileLoading && <BackdropSpinner />}
      {isSliderOpen && (
        <Slider
          images={[]}
          handleClose={handleCloseSlider}
          imageViewedId={currentVideoId}
          annotations={[]}
          videos={props.videos || []}
        />
      )}
    </Grid>
  );
};

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
`;

export default ChildrenAttachmentSection;
