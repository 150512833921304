import React from 'react';
import styled from 'styled-components';
import { Grid, IconButton, Typography, Box } from '@material-ui/core';

// icons
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';

import AttachmentMediaInput from '../../components/AttachmentMediaPanel/AttachmentMediaInput/AttachmentMediaInput';

interface Props {
  issue: string;
  caption: string;
  onDelete: (e: React.MouseEvent) => void;
  id?: string;
  handleChangeName: (event: React.ChangeEvent<{ value: string; id: string }>) => void;
  color: string;
  getTextBadgeColor: (activeColor: string) => string;
  shape: string;
}

const AttachmentDragItem: React.FC<Props> = props => {
  return (
    <Grid item container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
      <Grid item container alignItems="center" wrap="nowrap">
        <DragHandleIcon style={{ marginRight: 12 }} />

        {/* <Typography>{props.caption + '.'}</Typography> */}
        <StyledNumberBadge color={props.color} borderRadius={props.shape === 'circle' ? '50%' : '0'}>
          <StyledTypography variant="body2" color={props.getTextBadgeColor(props.color)}>
            {props.caption}
          </StyledTypography>
        </StyledNumberBadge>
        <Grid item container direction="column">
          <AttachmentMediaInput
            value={props.issue}
            placeholder={props.caption}
            handleChange={props.handleChangeName}
            id={props.id}
          />
        </Grid>
      </Grid>
      <Grid item>
        <IconButton edge="start" color="inherit" onClick={props.onDelete}>
          <DeleteIcon fill="#f2f2f2" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const StyledTypography = styled(({ color, fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    ${props => props.color && 'color: ' + props.color};
  }
`;
const StyledNumberBadge = styled(({ color, borderRadius, ...props }) => <Box {...props} />)`
  background-color: ${props => props.color};
  width: auto;
  height: auto;
  border-radius: ${props => props.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin-right: 8px;
`;

export default AttachmentDragItem;
