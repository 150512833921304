import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import debounce from 'lodash/debounce';
import queryString from 'query-string';

// helpers
import { getContentWordsToSuggest } from 'helpers/getContentWordsToSuggest';
import storage from 'helpers/storage';
import { useAuth } from 'context/Auth';
import { fetchLastUpdatedObjects, fetchSearchedObjects, fetchFavoriteBuildings, logout } from 'services/fetchData';
import searchHistory from 'helpers/searchHistory';

// new mocks
import buildingsMock from './mocks/buildingsMock.json';
import { eventsMock } from 'assets/mocks/eventsMock';

import Main from '../Main/Main';
import Search from '../Search/Search';

const DRAFTS_STATUS = 'Under arbeid';

type Props = RouteComponentProps;

const Home = (props: Props) => {
  const { removeAuthRememberedToken, removeAuthToken } = useAuth();

  // states
  const [lastUpdatedObjects, setLastUpdatedObjects] = useState<any>([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchedObjects, setSearchedObjects] = useState<any[]>([]);
  const [suggestedSearch, setSuggestedSearch] = useState<any[]>([]);
  const [favouritesBuildings, setFavouritesBuildings] = useState<any[]>([]);
  const [draftsObjects, setDraftsObjects] = useState<any>([]);
  const [favoriteTrigger, setFavoriteTrigger] = useState(true);

  // search buildings
  useEffect(() => {
    if (searchQuery === '') return;

    fetchSearchedObjects(searchQuery).then(({ artifacts }) => setSearchedObjects(artifacts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  // set last updated events
  useEffect(() => {
    fetchLastUpdatedObjects().then(({ artifacts }) => setLastUpdatedObjects(artifacts));
  }, []);
  // set drafts events
  useEffect(() => {
    if (lastUpdatedObjects.length === 0) return;

    const draftEvents = lastUpdatedObjects.filter(
      (obj: any) => obj['status.status_type_id_value'] && obj['status.status_type_id_value'] === DRAFTS_STATUS,
    );
    setDraftsObjects(draftEvents);
  }, [lastUpdatedObjects]);
  // set favorites buildings
  useEffect(() => {
    if (!favoriteTrigger) return;
    const favoriteBuildingIDs = storage.load('favoriteBuildingIDs');

    fetchFavoriteBuildings(favoriteBuildingIDs).then(({ artifacts }) => {
      setFavouritesBuildings(artifacts);
      setFavoriteTrigger(false);
    });
  }, [favoriteTrigger]);

  // set search query and search if first mount on search route
  useEffect(() => {
    if (props.location.pathname === '/search') {
      let searchedQuery = (queryString.parse(props.location.search).query as string) || '';
      searchedQuery = searchedQuery.replace(/:/g, '');
      searchHistory.addToHistory(searchedQuery);
      setSearchQuery(searchedQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // filter search suggested words
  useEffect(() => {
    handleFilterSuggested(contentWordsToSuggest, searchInputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);

  // handlers
  const handleSearchOpen = () => {
    setIsSearchOpen(true);
    setSearchInputValue(searchQuery);
  };
  const handleSearchClose = () => {
    if (isSearchOpen) {
      setIsSearchOpen(false);
      return;
    }
    if (props.location.pathname === '/search') {
      setSearchInputValue('');
      setSearchQuery('');
      props.history.push('/');

      window.scrollTo(0, 0);
    }
  };
  const handleSearchInput = useCallback(
    debounce((value: string) => {
      setSearchInputValue(value);
    }, 100),
    [],
  );
  const handleSearchSubmit = (event: React.BaseSyntheticEvent<Event, EventTarget & Element, EventTarget>) => {
    event.preventDefault();

    if (searchInputValue === '' || searchInputValue.trim().length === 0) return;

    searchHistory.addToHistory(searchInputValue);
    setSearchQuery(searchInputValue.replace(/:/g, ''));
    setIsSearchOpen(false);
    props.history.push({
      pathname: '/search',
      search: `query=${searchInputValue}`,
    });
  };
  const handleSearchBySuggested = (searchQuery: string) => {
    searchHistory.addToHistory(searchQuery);
    setSearchQuery(searchQuery.replace(/:/g, ''));
    setIsSearchOpen(false);
    props.history.push({
      pathname: '/search',
      search: `query=${searchQuery}`,
    });
  };
  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleFilterSuggested = useCallback(
    debounce((contentWordsToSuggest: string[], searchInputValue: string) => {
      const suggested = filterWordsToSuggest(contentWordsToSuggest, searchInputValue);
      setSuggestedSearch(suggested);
    }, 100),
    [],
  );
  const handleDeleteDraftObject = (id: string) => {
    const draftsWithoutDeleted = draftsObjects.filter((draft: any) => draft.artifact_id !== id);

    setDraftsObjects(draftsWithoutDeleted);
  };
  const addBuildingToFavorites = (id: string) => {
    const currentIDs = storage.load('favoriteBuildingIDs') || [];

    storage.save('favoriteBuildingIDs', [...currentIDs, id]);
    setFavoriteTrigger(true);
  };
  const removeBuildingFromFavorites = (id: string) => {
    const currentIDs = storage.load('favoriteBuildingIDs') || [];
    const updatedIDs = currentIDs.filter((currentID: string) => currentID !== id);
    storage.save('favoriteBuildingIDs', updatedIDs);
    setFavoriteTrigger(true);
  };
  const checkBuildingInFavorites = (id: string) => favouritesBuildings.some(building => building.artifact_id === id);

  const handleLogout = async () => {
    try {
      removeAuthRememberedToken();
      removeAuthToken();
      await logout();
    } catch (error) {
      console.log('logout error :>> ', error);
    }
  };

  // calc functions and values
  const filterWordsToSuggest = (options: any[], searchQuery: string) => {
    return options.filter(option => option.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  const contentWordsToSuggest = useMemo(
    () => getContentWordsToSuggest([...buildingsMock, ...Object.values(eventsMock)]),
    [],
  );

  return (
    <StyledWrapper>
      <Route
        exact
        path="/"
        render={props => (
          <Main
            {...props}
            lastUpdatedObjects={lastUpdatedObjects}
            isSearchOpen={isSearchOpen}
            handleSearchOpen={handleSearchOpen}
            handleSearchClose={handleSearchClose}
            handleSearchInput={handleSearchInput}
            searchInputValue={searchInputValue}
            handleSearchSubmit={handleSearchSubmit}
            handleSearchBySuggested={handleSearchBySuggested}
            handleToggleMenu={handleToggleMenu}
            isMenuOpen={isMenuOpen}
            suggestedSearch={suggestedSearch}
            favouritesBuildings={favouritesBuildings}
            draftsObjects={draftsObjects}
            handleDeleteDraftObject={handleDeleteDraftObject}
            handleLogout={handleLogout}
            addBuildingToFavorites={addBuildingToFavorites}
            removeBuildingFromFavorites={removeBuildingFromFavorites}
            checkBuildingInFavorites={checkBuildingInFavorites}
          />
        )}
      />
      <Route
        path="/search"
        render={props => (
          <Search
            {...props}
            objects={searchedObjects}
            isSearchOpen={isSearchOpen}
            handleSearchOpen={handleSearchOpen}
            handleSearchClose={handleSearchClose}
            handleSearchInput={handleSearchInput}
            searchInputValue={searchInputValue}
            handleSearchSubmit={handleSearchSubmit}
            handleSearchBySuggested={handleSearchBySuggested}
            searchQuery={searchQuery}
            suggestedSearch={suggestedSearch}
            addBuildingToFavorites={addBuildingToFavorites}
            removeBuildingFromFavorites={removeBuildingFromFavorites}
            checkBuildingInFavorites={checkBuildingInFavorites}
          />
        )}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  @media (max-width: 767px) {
    overflow: hidden;
  }
`;

export default Home;
