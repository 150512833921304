import React from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  value: number | null;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

const NumberedFormTextArea: React.FC<Props> = props => {
  const value = !props.value ? '' : props.value;
  return (
    <TextField
      label={props.label}
      placeholder={props.placeholder}
      disabled={props.disabled}
      fullWidth
      value={value}
      onChange={props.handleChange}
      variant="filled"
      color="secondary"
      type="number"
    />
  );
};
export default NumberedFormTextArea;
