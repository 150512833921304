import React from 'react';
import { Grid } from '@material-ui/core';

import AttachmentMediaDragItem from '../AttachmentMediaDragItem/AttachmentMediaDragItem';

interface Props {
  annotations: any[];
  moveMediaDragItem: (dragIndex: any, hoverIndex: any) => void;
  handleDeleteAnnotation: (id: string, event: React.MouseEvent) => void;
  handleChangeName: (event: React.ChangeEvent<{ value: string; id: string }>) => void;
  getTextBadgeColor: (activeColor: string) => string;
}

const AttachmentMediaDrags: React.FC<Props> = props => {
  return (
    <Grid item container direction="column" spacing={1}>
      {props.annotations.map((annot: any, index) => {
        if (annot._destroy) return null;
        return (
          <AttachmentMediaDragItem
            key={annot.uuid}
            id={annot.uuid}
            index={index}
            order={annot.order_number}
            name={annot.a_description}
            moveCard={props.moveMediaDragItem}
            onDeleteAnnotation={(e: React.MouseEvent) => props.handleDeleteAnnotation(annot.uuid, e)}
            handleChangeName={props.handleChangeName}
            color={annot.color}
            getTextBadgeColor={props.getTextBadgeColor}
            shape={annot.a_type}
          />
        );
      })}
    </Grid>
  );
};

export default AttachmentMediaDrags;
