import React from 'react';
import styled from 'styled-components';
import { AppBar } from '@material-ui/core';

const StyledAppBar = styled(({ color, ...props }) => <AppBar {...props} elevation={0} />)`
  &.MuiAppBar-root {
    color: #000000;
    background-color: ${props => props.color};
  }

  &.MuiAppBar-positionStatic {
    transform: none;
  }
`;

export default StyledAppBar;
