import React from 'react';
import { Grid } from '@material-ui/core';
import { PeopleTypes } from '../../ActorsPanel/ActorsPanel';
import TaskTitleDragItem from '../TaskTitleDragItem/TaskTitleDragItem';
import ActorsPanel from '../../ActorsPanel/ActorsPanel';

interface Props {
  dragItems: any[];
  isActorsPanelOpen: boolean;
  selectedEdit: string | null;
  handleToggleActorsPanel: (id: string) => void;
  handleSaveActorsPanel: (actorData: { person: { title: string; id: string } }) => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  emptyDragItem: {
    actor_id: string;
    actor_id_value: string;
    responsibilities: string;
  };
  handleCloseActorsPanel: () => void;
  handleDeleteDrag: (id: string) => void;
  disabledConditions: boolean;
}

const TaskTitleDrags: React.FC<Props> = props => {
  const selectedDragItem = props.dragItems.find(
    item =>
      props.selectedEdit !== null &&
      ((item.no && item.no === props.selectedEdit) || (item.actor_id && item.actor_id === props.selectedEdit)),
  );

  return (
    <>
      {props.dragItems.length > 0 && (
        <Grid item container direction="column" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {props.dragItems.map(({ actor_id_value, actor_id, no, responsibilities, _destroy }, index) => {
            if (_destroy) return null;

            const editPanelRenderRule =
              selectedDragItem &&
              props.isActorsPanelOpen &&
              ((selectedDragItem.no !== null && selectedDragItem.no === no) ||
                (selectedDragItem.actor_id && selectedDragItem.actor_id === actor_id));

            // render actor panel instead drag if editing
            if (editPanelRenderRule) {
              return (
                <Grid key={no || actor_id} item style={{ position: 'relative' }}>
                  <ActorsPanel
                    isEditing={!!props.selectedEdit}
                    drag={selectedDragItem || props.emptyDragItem}
                    onSaveActorsPanel={props.handleSaveActorsPanel}
                    handleCloseActorsPanel={props.handleCloseActorsPanel}
                    dragsLength={props.dragItems.length}
                    type={PeopleTypes.USERS}
                  />
                </Grid>
              );
            }
            return (
              <TaskTitleDragItem
                key={no || actor_id}
                id={no || actor_id}
                index={index}
                title={actor_id_value}
                handleEdit={() => props.handleToggleActorsPanel(actor_id === null ? no : actor_id)}
                onDeleteDrag={() => props.handleDeleteDrag(actor_id === null ? no : actor_id)}
                moveCard={props.moveDragItem}
                disabledConditions={props.disabledConditions}
                responsibilities={responsibilities}
              />
            );
          })}
        </Grid>
      )}
      {/* Render actor panel when new report */}
      {props.isActorsPanelOpen && !selectedDragItem && (
        <Grid item style={{ position: 'relative' }}>
          <ActorsPanel
            isEditing={!!props.selectedEdit}
            drag={selectedDragItem || props.emptyDragItem}
            onSaveActorsPanel={props.handleSaveActorsPanel}
            handleCloseActorsPanel={props.handleCloseActorsPanel}
            dragsLength={props.dragItems.length}
            type={PeopleTypes.USERS}
          />
        </Grid>
      )}
    </>
  );
};

export default TaskTitleDrags;
