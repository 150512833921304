import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { createGlobalStyle } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import theme from 'theme';
import AuthProvider from './context/Auth';
import PrivateRoute from './services/PrivateRoute';
import Building from 'modules/Building/Building';
import Home from 'modules/Home/Home';
import Login from 'modules/Login/Login';

import * as serviceWorker from './serviceWorker';
import { Button, Snackbar } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

const GlobalStyle = createGlobalStyle`
/* fonts */
@font-face {
    font-family: "Akkurat";
    font-weight: normal;
    font-style: normal;
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot");
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot?#iefix") format("embedded-opentype"),
        url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.woff") format("woff");
}
@font-face {
    font-family: "Akkurat";
    font-weight: bold;
    font-style: normal;
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot");
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot?#iefix") format("embedded-opentype"),
        url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.woff") format("woff");
}
@font-face {
    font-family: "Akkurat";
    font-weight: normal;
    font-style: italic;
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot");
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot?#iefix") format("embedded-opentype"),
        url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.woff") format("woff");
}
@font-face {
    font-family: "Akkurat";
    font-weight: bold;
    font-style: italic;
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot");
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot?#iefix") format("embedded-opentype"),
        url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.woff") format("woff");
}
@font-face {
    font-family: "Akkurat Mono";
    src:url("https://frontend-components-assets.dimu.org/fonts/AkkuratMono/fonts/lineto-akkuratmono-pro-regular.eot");
    src:url("https://frontend-components-assets.dimu.org/fonts/AkkuratMono/fonts/lineto-akkuratmono-pro-regular.eot?#iefix") format("embedded-opentype"),
        url("https://frontend-components-assets.dimu.org/fonts/AkkuratMono/fonts/lineto-akkuratmono-pro-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

  body {
    background-color: #eeeeee;
  }
`;

const App = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const onVersionUpdated = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onVersionUpdated });
  });

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={Backend}>
        <AuthProvider>
          <GlobalStyle />
          <Snackbar
            open={showReload}
            message={'Ny versjon tilgjengelig'}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
              <React.Fragment>
                <Button size="small" onClick={reloadPage} style={{ color: blue[500] }}>
                  Last ned
                </Button>
              </React.Fragment>
            }
          ></Snackbar>
          <Switch>
            <PrivateRoute path="/building/:id" component={Building} />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" component={Home} />
            <Redirect to="/" />
          </Switch>
        </AuthProvider>
      </DndProvider>
    </ThemeProvider>
  );
};

export default App;
