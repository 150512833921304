import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Swipeable } from 'react-swipeable';

import SliderBar from './SliderBar/SliderBar';
import SliderBottomBar from './SliderBottomBar/SliderBottomBar';
import Slide from './Slide/Slide';
import VideoSlide from './VideoSlide/VideoSlide';
import { getBaseUrl } from '../../../../services/fetchData';

enum MediaTypes {
  IMAGE = 'ImageItem',
  VIDEO = 'VideoItem',
}

interface Props {
  images: any[];
  imageViewedId: string;
  handleClose: () => void;
  annotations: any[];
  handleChangeImageViewedId?: (id: string) => void;
  videos?: any[];
}

const emptyFunction = () => null;

// IMAGE WITH ANNOTATION FOR TESTING UI (directly changed image_id) - Smie Kolbeinstveit RFH6.1.1.14, image_id - IMG-44267

const Slider: React.FC<Props> = ({ images, imageViewedId, handleClose, annotations, videos, ...props }) => {
  const [imageId, setImageId] = useState(imageViewedId);
  const media = videos ? [...images, ...videos] : [...images];

  const handleNextImage = () => {
    setImageId(media[activeIndex + 1].image_id || media[activeIndex + 1].video_id);
    if (props.handleChangeImageViewedId) {
      props.handleChangeImageViewedId(media[activeIndex + 1].image_id || media[activeIndex + 1].video_id);
    }
  };
  const handlePrevImage = () => {
    setImageId(media[activeIndex - 1].image_id || media[activeIndex - 1].video_id);
    if (props.handleChangeImageViewedId) {
      props.handleChangeImageViewedId(media[activeIndex - 1].image_id || media[activeIndex - 1].video_id);
    }
  };
  const handleChangeImage = (imageNumber: number) => {
    setImageId(media[imageNumber - 1].image_id || media[imageNumber - 1].video_id);
    if (props.handleChangeImageViewedId) {
      props.handleChangeImageViewedId(media[imageNumber - 1].image_id || media[imageNumber - 1].video_id);
    }
  };

  const viewed = useMemo(() => media.find(item => item.image_id === imageId || item.video_id === imageId), [
    imageId,
    media,
  ]);
  const activeIndex = useMemo(() => media.findIndex(item => item.image_id === imageId || item.video_id === imageId), [
    imageId,
    media,
  ]);

  // const currentImageAnnotations = useMemo(() => {
  //   return annotations.find(annot => annot.image_id === imageId);
  // }, [imageId, annotations]);
  const currentImageAnnotations = useMemo(() => {
    return annotations.find(annot => annot.image_id === imageId)?.annotation_points || [];
  }, [annotations, imageId]);

  return (
    <StyledContainer>
      <SliderBar handleClose={handleClose} name={imageId} />
      <Swipeable
        onSwipedLeft={activeIndex + 1 !== media.length ? handleNextImage : emptyFunction}
        onSwipedRight={activeIndex + 1 !== 1 ? handlePrevImage : emptyFunction}
      >
        <StyledSlidesWrapper>
          {viewed.object_type === MediaTypes.IMAGE && (
            <Slide
              src={viewed ? `${getBaseUrl()}/multimedia/image/${viewed.image_id}?size=large` : ''}
              annotationPoints={currentImageAnnotations || []}
            />
          )}
          {viewed.object_type === MediaTypes.VIDEO && <VideoSlide videoId={imageId} />}
        </StyledSlidesWrapper>
      </Swipeable>
      <SliderBottomBar
        mediaLength={media.length}
        activeImageNumber={activeIndex + 1}
        handleNext={handleNextImage}
        handlePrev={handlePrevImage}
        handleChangeImage={handleChangeImage}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  margin: 0 auto;
  z-index: 5000;
  background-color: #ffffff;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledSlidesWrapper = styled(Box)`
  box-sizing: border-box;
  height: 100vh;
  padding: 100px 50px;

  @media (max-width: 767px) {
    height: 80vh;
    padding: 100px 10px;
  }
`;

export default Slider;
