import React, { useState, useCallback, memo } from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';

import ImageIcon from '@material-ui/icons/Image';

interface Props {
  src: string;
  imageStyles?: React.CSSProperties;
  withoutIcon?: boolean;
}

const Image: React.FC<Props> = ({ src, imageStyles, withoutIcon }) => {
  const [imageErrored, setImageErrored] = useState(false);
  const [imageLoaded, setImageLoading] = useState(false);

  const handleLoad = useCallback(() => setImageLoading(true), []);

  const handleError = useCallback(() => {
    setImageErrored(true);
    setImageLoading(true);
  }, []);

  return (
    <>
      {imageErrored && !withoutIcon && (
        <Box style={{ color: '#d6d6d6' }}>
          <ImageIcon />
        </Box>
      )}
      {!imageErrored && (
        <>
          <Box style={{ color: '#d6d6d6', display: imageLoaded ? 'none' : 'block' }}>
            <CircularProgress />
          </Box>
          <StyledListImage
            src={src}
            onError={handleError}
            onLoad={handleLoad}
            style={{ ...imageStyles, display: imageLoaded ? 'block' : 'none' }}
          />
        </>
      )}
    </>
  );
};

const StyledListImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default memo(Image);
