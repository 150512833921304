import React from 'react';
import styled from 'styled-components';

import { Box, Typography } from '@material-ui/core';
import LastUpdatedList from '../../components/LastUpdatedList/LastUpdatedList';

interface Props {
  lastUpdatedObjects: any[];
  hidden: boolean;
}

const LastUpdated = (props: Props) => {
  return (
    <Box hidden={props.hidden}>
      <CaptionWrap>
        <StyledTypography fontSize="1.1rem">Sist oppdatert</StyledTypography>
      </CaptionWrap>
      <LastUpdatedList lastUpdatedObjects={props.lastUpdatedObjects} />
    </Box>
  );
};
const CaptionWrap = styled(Box)`
  padding: 28px 0 16px;
  @media (max-width: 705px) {
    padding: 28px 18px 16px;
  }
  @media (max-width: 767px) {
    padding: 28px 26px 16px;
  }
  @media (max-width: 542px) {
    padding: 28px 18px 16px;
  }
`;
const StyledTypography = styled(({ color, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-size: ${props => props.fontSize};
  }
`;
export default LastUpdated;
