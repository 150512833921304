import React from 'react';
import { Grid } from '@material-ui/core';

import AssessmentEditDragItem from '../AssessmentEditDragItem/AssessmentEditDragItem';

interface Props {
  drags: any[];
  moveEditDragItem: (dragIndex: any, hoverIndex: any) => void;
  handleDeleteAction: (id: string) => void;
  editedActionIndex: number;
  handleOpenWithEdit: (id?: string | undefined) => void;
}

const AssessmentEditDrags = (props: Props) => (
  <Grid item container direction="column" spacing={1}>
    {props.drags.map(({ description, uuid, no, _destroy, time_spent, cost, order_number }, index) => {
      if (_destroy) return null;

      // not render drag item if edit panel open
      if (props.editedActionIndex !== -1 && props.editedActionIndex <= index) return null;
      return (
        <AssessmentEditDragItem
          key={uuid || no || order_number}
          id={uuid || no || order_number}
          index={index}
          title={description}
          moveCard={props.moveEditDragItem}
          handleDeleteAction={() => props.handleDeleteAction(uuid || no)}
          cost={cost}
          timings={time_spent}
          isActionDrag
          handleOpenWithEdit={() => props.handleOpenWithEdit(uuid || no)}
        />
      );
    })}
  </Grid>
);

export default AssessmentEditDrags;
