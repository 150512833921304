import { EnabledFiltersType } from '../types/Mocks/EnabledFiltersType';

export const filterObjects = (objects: any[], filters: EnabledFiltersType) => {
  return objects.filter(item => {
    const status = [true];

    if (filters.objekttype.filters.length !== 0) {
      status.push(filters.objekttype.filters.some(option => option === item['reg_level.reg_level_type_id']));
    }

    if (filters.samling.filters.length !== 0) {
      status.push(filters.samling.filters.some(option => option === item.collection_id));
    }

    if (filters.tilstandsklasse.filters.length !== 0) {
      status.push(
        filters.tilstandsklasse.filters.some(option => {
          if (item.measure_class_type) return item.measure_class_type.includes(option);
          if (item['condition.measure_class_type_id']) return item['condition.measure_class_type_id'].includes(option);
          return false;
        }),
      );
    }

    if (filters.prioritet.filters.length !== 0) {
      status.push(
        filters.prioritet.filters.some(
          option => item['priority.priority_type_id'] && item['priority.priority_type_id'].includes(option),
        ),
      );
    }

    if (filters.status.filters.length !== 0) {
      status.push(filters.status.filters.some(option => item['status.status_type_id'] === option));
    }

    if (filters.type.filters.length !== 0) {
      status.push(filters.type.filters.some(option => item['damage.damage_type.damage_type_id'] === option));
    }

    if (filters.harMarkeringer.filters.length !== 0) {
      if (filters.harMarkeringer.filters.length === 1 && filters.harMarkeringer.filters.includes('ja')) {
        status.push(item.has_annotation_events === true ? true : false);
      }
      if (filters.harMarkeringer.filters.length === 1 && filters.harMarkeringer.filters.includes('nei')) {
        status.push(item.has_annotation_events === false ? true : false);
      }
      if (filters.harMarkeringer.filters.length === 2) {
        status.push(item.hasOwnProperty('has_annotation_events') ? true : false);
      }
    }

    if (filters.formal.filters.length !== 0) {
      status.push(filters.formal.filters.some(option => item['purpose.purpose_type_id'] === option));
    }

    return status.every(item => item === true);
  });
};
