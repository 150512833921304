import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery, Box } from '@material-ui/core';

import { BuildingType } from 'types/Building/BuildingType';
import { fetchBuildingEvent } from 'services/fetchData';

import InfoSection from '../../sections/InfoSection';
import InfoObservationExpansionPanel from '../InfoObservationExpansionPanel/InfoObservationExpansionPanel';
import Slider from '../Slider/Slider';

interface Props {
  expanded: string | false;
  handleChange: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  handleOpenNewReport: () => void;
  handleOpenEditReport: (id: string) => void;
  buildingEvents: any[];
  handleDeleteConditionReport: (id: string) => void;
  handleDeleteDeviation: (id: string) => void;
  handleDeleteWork: (id: string) => void;
  handleDeleteTask: (id: string) => void;
  handleOpenNewDeviation: () => void;
  handleOpenEditDeviation: (id: string) => void;
  handleEditMode: (status: boolean) => void;
  handleOpenNewWork: () => void;
  handleOpenEditWork: (id: string) => void;
  handleOpenNewTask: () => void;
  handleOpenEditTask: (id: string) => void;
  building: BuildingType;
  annotations: any[];
}

const BuildingInfo: React.FC<Props> = props => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [imageViewedId, setImageViewedId] = useState('');
  const [imagesAnnotations, setImagesAnnotations] = useState<any[]>([]);
  const handleOpenSlider = () => {
    setSliderOpen(true);
  };
  const handleCloseSlider = () => {
    setImageViewedId('');
    setSliderOpen(false);
  };
  const handleImageClick = (id: string) => {
    setImageViewedId(id);
    handleOpenSlider();
  };
  const handleChangeImageViewedId = (id: string) => {
    setImageViewedId(id);
  };
  const getAnnotationEventsByImageId = async (imageId: string) => {
    const eventsWithCurrentImage = props.buildingEvents.filter(
      e => e.has_annotation_events && e['images.image_id'].includes(imageId),
    );
    if (eventsWithCurrentImage.length === 0) {
      setImagesAnnotations([]);
      return [];
    }
    let annotations: any[] = [];
    // ASYNC LOOP
    for (const event of eventsWithCurrentImage) {
      const eventWithCurrentImage = await fetchBuildingEvent(event.artifact_id);
      annotations = [...annotations, ...eventWithCurrentImage.annotation_events];
    }
    setImagesAnnotations(annotations);
  };
  useEffect(() => {
    if (!imageViewedId) return;
    getAnnotationEventsByImageId(imageViewedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageViewedId]);

  return (
    <StyledWrapper isSliderOpen={isSliderOpen}>
      {!mediaMatch && !!props.expanded ? null : (
        <InfoSection building={props.building} handleImageClick={handleImageClick} />
      )}
      <InfoObservationExpansionPanel
        expanded={props.expanded}
        handleChange={props.handleChange}
        handleOpenNewReport={props.handleOpenNewReport}
        handleOpenEditReport={props.handleOpenEditReport}
        buildingEvents={props.buildingEvents}
        handleDeleteConditionReport={props.handleDeleteConditionReport}
        handleDeleteDeviation={props.handleDeleteDeviation}
        handleDeleteWork={props.handleDeleteWork}
        handleDeleteTask={props.handleDeleteTask}
        handleOpenNewDeviation={props.handleOpenNewDeviation}
        handleOpenEditDeviation={props.handleOpenEditDeviation}
        handleOpenNewWork={props.handleOpenNewWork}
        handleOpenEditWork={props.handleOpenEditWork}
        handleOpenNewTask={props.handleOpenNewTask}
        handleOpenEditTask={props.handleOpenEditTask}
        handleEditMode={props.handleEditMode}
        building={props.building}
        annotations={props.annotations}
      />
      {isSliderOpen && (
        <Slider
          images={props.building.images}
          handleClose={handleCloseSlider}
          imageViewedId={imageViewedId}
          annotations={imagesAnnotations || []}
          handleChangeImageViewedId={handleChangeImageViewedId}
        />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(({ isSliderOpen, ...props }) => <Box {...props} />)`
  ${props =>
    props.isSliderOpen &&
    `
    height: 90vh;
    overflow: hidden;
  `}
`;

export default BuildingInfo;
