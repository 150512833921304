import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Paper, useTheme } from '@material-ui/core';
import throttle from 'lodash/throttle';

import { StyledTypography } from '../../shared/StyledTypography';

const JUMBOTRON_TABLET_HEIGHT = '38vh';
const JUMBOTRON_MOBILE_HEIGHT = '270px';
const TABLET_MEDIA = 768;

const Jumbotron: React.FC = () => {
  const theme = useTheme();

  const [jumbotronHeight, setJumbotronHeight] = useState(JUMBOTRON_MOBILE_HEIGHT);

  const handleResize = useCallback(() => {
    if (window.innerHeight <= theme.breakpoints.values.sm && window.innerWidth >= TABLET_MEDIA) {
      setJumbotronHeight(JUMBOTRON_MOBILE_HEIGHT);
      return;
    }
    if (window.innerWidth >= TABLET_MEDIA) {
      setJumbotronHeight(JUMBOTRON_TABLET_HEIGHT);
    }
    if (window.innerWidth < TABLET_MEDIA) {
      setJumbotronHeight(JUMBOTRON_MOBILE_HEIGHT);
    }
  }, [theme.breakpoints.values.sm]);

  useEffect(() => {
    if (window.innerWidth >= TABLET_MEDIA) {
      setJumbotronHeight(JUMBOTRON_TABLET_HEIGHT);
    }
  }, []);

  const throttledHandleResize = throttle(handleResize, 500);

  useEffect(() => {
    window.addEventListener('resize', throttledHandleResize);
    return () => window.removeEventListener('resize', throttledHandleResize);
  }, [throttledHandleResize]);

  return (
    <StyledPaper square height={jumbotronHeight}>
      <StyledTypography color="#B2C7FF" fontSize="2em" fontWeight="700">
        Primus FDV
      </StyledTypography>
    </StyledPaper>
  );
};

const StyledPaper = styled(({ height, ...props }) => <Paper {...props} />)`
  &.MuiPaper-root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.height};
    background-color: #2d2f37;
  }
`;

export default Jumbotron;
