import React from 'react';
import styled from 'styled-components';
import { formatDistance } from 'date-fns';
import { nb } from 'date-fns/locale';
import { Grid, Box, Typography, IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// icons
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import Image from 'components/Image/Image';
import { getBaseUrl } from '../../../../../services/fetchData';

interface Props {
  building: any;
  addBuildingToFavorites: () => void;
  removeBuildingFromFavorites: () => void;
  isFavorites: boolean;
}

const SearchBuildingsListItem = (props: Props) => {
  const mediaMatch = useMediaQuery('(max-width: 400px)');
  const theme = useTheme();
  const getMeasureClassBorderColor = (measureClass: string): string => {
    const measure = measureClass.trim().substr(0, 3);
    if (measure === 'TK0') {
      return '#cccccc';
    }
    if (measure === 'TK1') {
      return '#ffcb4f';
    }
    if (measure === 'TK2') {
      return '#ff5912';
    }
    if (measure === 'TK3') {
      return '#cc0033';
    }
    return '#ffffff';
  };
  const handleAddBuildingToFavorites = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    props.addBuildingToFavorites();
  };
  const handleRemoveBuildingFromFavorites = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    props.removeBuildingFromFavorites();
  };
  const handleFavoritesClick = props.isFavorites ? handleRemoveBuildingFromFavorites : handleAddBuildingToFavorites;
  return (
    <StyledListItemWrap>
      <Grid container spacing={2} wrap="nowrap" style={{ width: '90%' }}>
        <Grid item>
          <StyledImageWrap color="#eeeeee">
            <Image src={`${getBaseUrl()}/multimedia/image/${props.building.thumbnail_id}`} />
          </StyledImageWrap>
        </Grid>
        <StyledGridItem item container direction="column" justify="space-between">
          <Box style={{ marginBottom: 10 }}>
            <Grid container alignItems="center" spacing={mediaMatch ? 0 : 1}>
              <Grid item>
                <StyledTypography fontWeight="700">
                  {(props.building['designations.designation_type_id_value'] &&
                    props.building['designations.designation_type_id_value'][0]) ||
                    props.building.artifact_name}
                </StyledTypography>
              </Grid>
              <Grid item>
                <IconButton color="inherit" size="small" style={{ marginTop: -4 }} onClick={handleFavoritesClick}>
                  {props.isFavorites ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
              </Grid>
            </Grid>
            <StyledTypography component="span" fontSize="0.85rem" style={{ marginRight: 8 }}>
              {props.building.collection_id_value}
            </StyledTypography>
            <StyledTypography component="span" fontSize="0.85rem" fontFamily="Akkurat Mono">
              {props.building.identifier}
            </StyledTypography>
          </Box>
          <Box>
            <StyledLabelBox component="div" borderColor="#434343" backGroundColor={theme.palette.secondary.white}>
              <StyledTypography color="#434343" variant="subtitle2" fontSize="0.7rem">
                {props.building['reg_level.reg_level_type_id_value'] || 'Objekt'}
              </StyledTypography>
            </StyledLabelBox>
          </Box>
        </StyledGridItem>
        <StyledTypography color="#9f9f9f" variant="caption" align="right" component="p">
          {formatDistance(props.building.updated_at.dd_date, new Date(), { addSuffix: true, locale: nb })}
        </StyledTypography>
      </Grid>
      <StyledIconsContainer>
        <Grid container justify="flex-end" alignItems="center" style={{ width: 'auto' }}>
          <Grid item style={{ marginLeft: 2 }}>
            {props.building.measure_class_type && (
              <StyledPriorityBox backgroundColor={getMeasureClassBorderColor(props.building.measure_class_type)}>
                <StyledTypography fontSize="0.7rem">{props.building.measure_class_type.substr(0, 3)}</StyledTypography>
              </StyledPriorityBox>
            )}
          </Grid>
        </Grid>
      </StyledIconsContainer>
    </StyledListItemWrap>
  );
};

const StyledImageWrap = styled(({ color, ...props }) => <Box {...props} />)`
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;

  @media (min-width: 706px) {
    width: 97px;
    height: 97px;
  }
  @media (min-width: 1024px) {
    width: 120px;
    height: 120px;
  }
`;
const StyledGridItem = styled(Grid)`
  &.MuiGrid-container {
    width: auto;
  }
`;
const StyledLabelBox = styled(({ borderColor, backGroundColor, ...props }) => <Box {...props} />)`
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.borderColor || '#ffffff'};
  border-radius: 18px;
  min-height: 22px;
  padding: 0 12px;
  background-color: ${props => props.backGroundColor || 'transparent'};
`;
const StyledPriorityBox = styled(({ backgroundColor, ...props }) => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 22px;
  border: ${props => `1px solid ${props.backgroundColor}`};
`;
const StyledTypography = styled(({ color, fontSize, fontWeight, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    ${props => props.fontFamily && `font-family: "${props.fontFamily}"`}
  }
  &.MuiTypography-caption {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-left: 15px;
    line-height: 1;
    @media (min-width: 1200px) {
      top: 20px;
    }
    @media (max-width: 480px) {
      width: 68px;
    }
  }
`;
const StyledListItemWrap = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  position: relative;
  padding: 12px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.14);

  @media (min-width: 1200px) {
    padding: 20px 20px 20px;
  }
`;
const StyledIconsContainer = styled.div`
  min-height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export default SearchBuildingsListItem;
