// TODO: fix the second array: the list of IDs that should be used to fetch the correct values for the filter-options.

const searchViewOptions = [
  { name: 'Bygninger', artifact_ids: ['ct_200-3'] },
  { name: 'Avvik', artifact_ids: ['ct_200-217', 'ct_90-217'] },
  { name: 'Tilstandsvurdering', artifact_ids: ['ct_200-213'] },
  { name: 'Bygningsarbeid', artifact_ids: ['ct_200-225'] },
  { name: 'Oppgaver', artifact_ids: ['ct_200-60', 'ct_200-61'] },
  { name: 'Media', artifact_ids: ['ct_201-20'] },
];

export default searchViewOptions;
