import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

import Jumbotron from './components/Jumbotron/Jumbotron';
import LoginForm from './components/LoginForm/LoginForm';
import { useAuth } from 'context/Auth';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

const Login: React.FC<Props> = ({ history }) => {
  const { authToken } = useAuth();

  // REDIRECT USER ON HOME WHEN ITS ALREADY AUTHENTICATE
  useEffect(() => {
    if (authToken) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLoginWrapper>
      <Jumbotron />
      <LoginForm />
    </StyledLoginWrapper>
  );
};

const StyledLoginWrapper = styled(Box)`
  width: 100vw;
  min-height: 100vh;
`;

export default Login;
