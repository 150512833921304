import React from 'react';
import styled from 'styled-components';
import { Grid, Box } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import SearchBuildingsListItem from './SearchBuildingsListItem/SearchBuildingsListItem';

interface Props {
  buildings: any[];
  addBuildingToFavorites: (id: string) => void;
  removeBuildingFromFavorites: (id: string) => void;
  checkBuildingInFavorites: (id: string) => boolean;
}

const SearchBuildingsList = (props: Props) => {
  const location = useLocation();
  const searchQuery = queryString.parse(location.search).query || '';

  return (
    <StyledListWrap>
      <Grid container>
        {props.buildings.map((building, idx) => (
          <Grid item xs={12} key={building.artifact_id}>
            <StyledLink
              to={{ pathname: `/building/${building.artifact_id}`, state: { fromSearch: true, query: searchQuery } }}
            >
              <SearchBuildingsListItem
                building={building}
                addBuildingToFavorites={() => props.addBuildingToFavorites(building.artifact_id)}
                removeBuildingFromFavorites={() => props.removeBuildingFromFavorites(building.artifact_id)}
                isFavorites={props.checkBuildingInFavorites(building.artifact_id)}
              />
            </StyledLink>
          </Grid>
        ))}
      </Grid>
    </StyledListWrap>
  );
};

const StyledListWrap = styled(Box)`
  background-color: #ffffff;
`;
const StyledLink = styled(Link)`
  text-decoration: none !important;
  color: inherit !important;
`;

export default SearchBuildingsList;
