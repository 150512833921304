import React from 'react';
import styled from 'styled-components';
import { Grid, Paper, Typography, IconButton } from '@material-ui/core';

// icons
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

interface Props {
  title: string;
  handleEdit: () => void;
  onDeleteDrag: () => void;
  disabledConditions: boolean;
  onUpDrag: () => void;
  onDownDrag: () => void;
  upDisabled: boolean;
  downDisabled: boolean;
  completed: boolean | null;
  onToggleCompleted: () => void;
}

const TaskChecklistDragItem = (props: Props) => {
  return (
    <StyledPaper square elevation={2}>
      <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item>
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                onClick={props.onToggleCompleted}
                style={{ margin: '0 16px 0 8px' }}
              >
                {props.completed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4" style={{ paddingRight: 14, wordBreak: 'break-word' }}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!props.disabledConditions && (
          <Grid item>
            <Grid container justify="flex-end" alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <IconButton size="small" edge="start" color="inherit" onClick={props.handleEdit}>
                  <EditIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  onClick={props.onUpDrag}
                  disabled={props.upDisabled}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  onClick={props.onDownDrag}
                  disabled={props.downDisabled}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size="small" edge="start" color="inherit" onClick={props.onDeleteDrag}>
                  <RemoveCircleIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </StyledPaper>
  );
};

const StyledPaper = styled(props => <Paper {...props} />)`
  &.MuiPaper-root {
    min-height: 52px;
    display: flex;
    align-items: center;
  }
  padding: 15px 12px;
`;

export default TaskChecklistDragItem;
