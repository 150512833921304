import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Grid,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import Gallery from '../../sections/Gallery';
import { format } from 'date-fns';
import { useTheme } from '@material-ui/styles';

import { getCurrentIcon } from 'helpers/getCurrentIcon';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import Slider from '../Slider/Slider';

interface Props {
  task: any;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  handleToggleCompleted: (id: string) => void;
  checklist: any[];
}

const ViewTask: React.FC<Props> = ({ task, isEditMode, handleEditMode, handleToggleCompleted, checklist }) => {
  const theme = useTheme();
  const tabletMatch = useMediaQuery('(min-width: 768px)');
  const mobileMarginBottom = !tabletMatch ? '56px' : '0';

  const [isSliderOpen, setSliderOpen] = useState(false);
  const [imageViewedId, setImageViewedId] = useState('');
  const handleOpenSlider = () => {
    setSliderOpen(true);
  };
  const handleCloseSlider = () => {
    setImageViewedId('');
    setSliderOpen(false);
  };
  const handleImageClick = (id: string) => {
    setImageViewedId(id);
    handleOpenSlider();
  };

  return (
    <StyledWrapper isSliderOpen={isSliderOpen}>
      {/* TITLE SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Oppgave</Typography>
          </Grid>
          {task?.title && (
            <Grid item>
              <StyledCaption color="textSecondary">Tittel</StyledCaption>
              <Typography variant="h6">{task?.title}</Typography>
            </Grid>
          )}
          {task?.description && (
            <Grid item>
              <StyledCaption color="textSecondary">Beskrivelse</StyledCaption>
              <Typography variant="h6">{task?.description}</Typography>
            </Grid>
          )}
          {task?.priority?.priority_type_id_value && (
            <Grid item>
              <StyledFlexBox align="center">
                <StyledMeasureWrap>{getCurrentIcon(task?.priority?.priority_type_id_value)}</StyledMeasureWrap>
                <Box>
                  <StyledCaption color="textSecondary">Prioritet</StyledCaption>
                  <Typography variant="h6">{task?.priority?.priority_type_id_value}</Typography>
                </Box>
              </StyledFlexBox>
            </Grid>
          )}
          {task?.status?.status_type_id_value && (
            <Grid item>
              <StyledCaption color="textSecondary">Status</StyledCaption>
              <Typography variant="h6">{task?.status?.status_type_id_value}</Typography>
            </Grid>
          )}
          {task?.start_date?.dd_date && (
            <Grid item>
              <StyledCaption color="textSecondary">Startdato</StyledCaption>
              <Typography variant="h6">{format(task?.start_date?.dd_date || Date.now(), 'dd.MM.yyyy')}</Typography>
            </Grid>
          )}
          {task?.completed_to_date?.dd_date && (
            <Grid item>
              <StyledCaption color="textSecondary">Utført dato</StyledCaption>
              <Typography variant="h6">
                {format(task?.completed_to_date?.dd_date || Date.now(), 'dd.MM.yyyy')}
              </Typography>
            </Grid>
          )}
          {task?.deadline_date?.dd_date && (
            <Grid item>
              <StyledCaption color="textSecondary">Frist</StyledCaption>
              <Typography variant="h6">{format(task?.deadline_date?.dd_date || Date.now(), 'dd.MM.yyyy')}</Typography>
            </Grid>
          )}
          {task?.actors && task?.actors.length > 0 && (
            <>
              <Grid item>
                <StyledCaption color="textSecondary">{`${task?.actors.length} ansvarlige personer`}</StyledCaption>
              </Grid>
              <Grid item>
                <List disablePadding>
                  {task?.actors.map((actor: any) => (
                    <StyledListItem key={actor.no || actor.actor_id} divider>
                      <StyledTypography component="span" fontSize="16px" fontWeight="700" style={{ paddingRight: 14 }}>
                        {actor?.actor_id_value}
                      </StyledTypography>
                      <Typography component="span" color="textSecondary">
                        {actor?.responsibilities}
                      </Typography>
                    </StyledListItem>
                  ))}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </StyledSection>
      <Divider />
      {/* CHECKLIST SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Sjekkliste</Typography>
          </Grid>
          {checklist && checklist.length > 0 && (
            <Grid item>
              <List disablePadding>
                {checklist.map((item: any) => (
                  <StyledListItem key={item.uuid || item.no} divider>
                    <StyledListItemIcon color={theme.palette.secondary.main}>
                      <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        onClick={() => handleToggleCompleted(item.uuid || item.no)}
                      >
                        {item.completed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                      </IconButton>
                    </StyledListItemIcon>
                    <ListItemText>
                      <StyledTypography component="span" fontSize="16px" fontWeight="700">
                        {item?.checklist_description}
                      </StyledTypography>
                    </ListItemText>
                  </StyledListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </StyledSection>
      <Divider />
      {/* MEDIA SECTION */}
      <StyledSection marginBottom={mobileMarginBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Opplastninger</Typography>
          </Grid>
        </Grid>
        <Gallery
          images={task?.images || []}
          firstPadding="0"
          lastPadding="0"
          handleImageClick={handleImageClick}
          attachments={task?.attachments || []}
          videos={task?.videos || []}
        />
      </StyledSection>
      {!tabletMatch && (
        <BottomStatusBar
          date={task?.updated_at.dd_date ?? 0}
          status={task?.status?.status_type_id_value || 'Publisert'}
          isEditMode={isEditMode}
          handleEditMode={handleEditMode}
        />
      )}
      {isSliderOpen && (
        <Slider
          images={task?.images || []}
          handleClose={handleCloseSlider}
          imageViewedId={imageViewedId}
          annotations={task?.annotation_events || []}
          videos={task?.videos || []}
        />
      )}
    </StyledWrapper>
  );
};

const StyledSection = styled(({ overflow, marginBottom, ...props }) => <Box {...props} />)`
  padding: 16px 16px 34px;
  background-color: #f5f5f5;
  ${props => props.overflow && `overflow: ${props.overflow}`};
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
`;
const StyledCaption = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    font-weight: 700;
  }
`;
const StyledFlexBox = styled(({ direction, align, justify, ...props }) => <Box {...props} />)`
  display: flex;
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.align && `align-items: ${props.align}`};
  ${props => props.justify && `justify-content: ${props.justify}`};
`;
const StyledMeasureWrap = styled(Box)`
  margin-right: 24px;
`;
const StyledListItem = styled(props => <ListItem {...props} />)`
  &.MuiListItem-root {
    padding: 16px;
    background-color: #ffffff;
  }
`;
const StyledListItemIcon = styled(({ color, ...props }) => <ListItemIcon {...props} />)`
  &.MuiListItemIcon-root {
    color: ${props => props.color};
    min-width: 40px;
  }
`;
const StyledTypography = styled(({ color, fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
  }
`;
const StyledWrapper = styled(({ isSliderOpen, ...props }) => <Box {...props} />)`
  ${props =>
    props.isSliderOpen &&
    `
    height: 90vh;
    overflow: hidden;
  `}
`;

export default ViewTask;
