import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  AccordionActions,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import { BuildingType } from 'types/Building/BuildingType';
import { useAuth } from 'context/Auth';
// helpers
import { getExpansionPanelMobileRule } from 'helpers/getExpansionPanelMobileRule';
import { getIsUserCanCreateOrEditObjectsByCollectionRole, getIsUserCanCreateOrEditObjectByAcl } from 'services/roles';
import { sortDeviations } from 'helpers/sortDeviations';
import { fetchBuildingEvent } from 'services/fetchData';
// types
import { ObservationEventsType } from 'types/Building/ObservationEventsType';
import { ConditionAssessmentEventType } from 'types/Building/ConditionAssessmentEventType';
import { documentTypes } from '../../../../constants';

import ObservationDeviationList from '../ObservationDeviationList/ObservationDeviationList';
import ObservationStateList from '../ObservationStateList/ObservationStateList';
import ObservationWorkList from '../ObservationWorkList/ObservationWorkList';
import ObservationTaskList from '../ObservationTaskList/ObservationTaskList';
import PrimaryButton from '../../shared/PrimaryButton/PrimaryButton';
import BottomPrimaryButton from '../../shared/BottomPrimaryButton/BottomPrimaryButton';
import Modal from '../../shared/Modal/Modal';
import Gallery from '../../sections/Gallery';
import Slider from '../Slider/Slider';

interface Props {
  expanded: string | false;
  handleChange: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  handleOpenNewReport: () => void;
  handleOpenEditReport: (id: string) => void;
  buildingEvents: any[];
  handleDeleteConditionReport: (id: string) => void;
  handleDeleteDeviation: (id: string) => void;
  handleDeleteWork: (id: string) => void;
  handleDeleteTask: (id: string) => void;
  handleOpenNewDeviation: () => void;
  handleOpenEditDeviation: (id: string) => void;
  handleEditMode: (status: boolean) => void;
  handleOpenNewWork: () => void;
  handleOpenEditWork: (id: string) => void;
  handleOpenNewTask: () => void;
  handleOpenEditTask: (id: string) => void;
  building: BuildingType;
  annotations: any[];
}

const DELETE_MODAL_TEXT = 'Vil du slette denne elementet?';

const ObservationExpansionPanel = (props: Props) => {
  const { userDataDetailed } = useAuth();
  const theme = useTheme();
  const tabletMatch = useMediaQuery('(min-width:768px)');

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletedType, setDeletedType] = useState<string | null>(null);
  const [deletedId, setDeletedId] = useState<string | null>(null);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [mediaViewedId, setMediaViewedId] = useState('');
  const [imagesAnnotations, setImagesAnnotations] = useState<any[]>([]);

  const deleteDocumentMap = {
    [documentTypes.DEVIATION]: props.handleDeleteDeviation,
    [documentTypes.CONDITION]: props.handleDeleteConditionReport,
    [documentTypes.TASK]: props.handleDeleteTask,
    [documentTypes.WORK]: props.handleDeleteWork,
  };

  const handleOpenDeleteModal = (type: string) => (id: string) => {
    setDeletedType(type);
    setDeletedId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeletedType(null);
    setDeleteModalOpen(false);
  };

  const handleOpenSlider = () => {
    setSliderOpen(true);
  };

  const handleCloseSlider = () => {
    setMediaViewedId('');
    setSliderOpen(false);
  };

  const handleMediaClick = (id: string) => {
    setMediaViewedId(id);
    handleOpenSlider();
  };

  const handleChangeMediaViewedId = (id: string) => {
    setMediaViewedId(id);
  };

  const getAnnotationEventsByImageId = async (imageId: string) => {
    const eventsWithCurrentImage = props.buildingEvents.filter(
      e => e.has_annotation_events && e['images.image_id'].includes(imageId),
    );
    if (eventsWithCurrentImage.length === 0) {
      setImagesAnnotations([]);
      return [];
    }
    let annotations: any[] = [];
    // ASYNC LOOP
    for (const event of eventsWithCurrentImage) {
      const eventWithCurrentImage = await fetchBuildingEvent(event.artifact_id);
      annotations = [...annotations, ...eventWithCurrentImage.annotation_events];
    }
    setImagesAnnotations(annotations);
  };

  useEffect(() => {
    if (!mediaViewedId) return;
    getAnnotationEventsByImageId(mediaViewedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaViewedId]);

  const handleDeleteDocument = () => {
    deleteDocumentMap[deletedType as string] && deleteDocumentMap[deletedType as string](deletedId as string);
    handleCloseDeleteModal();
  };

  const mediaLength = useMemo(
    () =>
      [...(props.building.images || []), ...(props.building.videos || []), ...(props.building.attachments || [])]
        .length,
    [props.building.attachments, props.building.images, props.building.videos],
  );

  const observationEvents: ObservationEventsType[] = useMemo(
    () => sortDeviations(props.buildingEvents.filter(item => item.artifact_type_id_value === 'Avvik')),
    [props.buildingEvents],
  );
  const conditionAssessmentEvents: ConditionAssessmentEventType[] = useMemo(
    () => props.buildingEvents.filter(item => item.artifact_type_id_value === 'Tilstandsvurdering bygning'),
    [props.buildingEvents],
  );
  const workEvents: any[] = useMemo(
    () => props.buildingEvents.filter(item => item.artifact_type_id_value === 'Bygningsarbeid'),
    [props.buildingEvents],
  );
  const taskEvents = useMemo(() => props.buildingEvents.filter(item => item.object_type === 'task'), [
    props.buildingEvents,
  ]);

  const userCollectionRights = useMemo(() => userDataDetailed?.collection_rights ?? [], [userDataDetailed]);
  const isUserCanCreateAndEdit =
    getIsUserCanCreateOrEditObjectsByCollectionRole(props.building.collection_id, userCollectionRights) &&
    getIsUserCanCreateOrEditObjectByAcl(props.building.$$acl);

  return (
    <>
      <Box style={{ marginBottom: tabletMatch ? 0 : 57 }}>
        {getExpansionPanelMobileRule('deviation', tabletMatch, props.expanded) && (
          <StyledExpansionPanel
            expanded={props.expanded === 'deviation'}
            onChange={props.handleChange('deviation')}
            square
            style={{ margin: 0 }}
            color={theme.palette.secondary.greyLight}
          >
            <StyledExpansionPanelSummary>
              <Grid container spacing={1} alignItems="center" direction="row">
                <StyledFlexBox item>
                  {props.expanded === 'deviation' ? (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                  <Typography variant="h6">Avvik</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography color="textSecondary">{observationEvents.length}</Typography>
                </Grid>
              </Grid>
            </StyledExpansionPanelSummary>

            <StyledExpansionPanelDetails color={theme.palette.secondary.greyLight}>
              <ObservationDeviationList
                observationEvents={observationEvents}
                handleOpenDeleteModal={handleOpenDeleteModal(documentTypes.DEVIATION)}
                handleOpenEditDeviation={props.handleOpenEditDeviation}
                handleEditMode={props.handleEditMode}
                buildingCollectionId={props.building.collection_id}
                buildingAcl={props.building.$$acl}
              />
            </StyledExpansionPanelDetails>
            {isUserCanCreateAndEdit && (
              <>
                {tabletMatch && (
                  <StyledExpansionPanelActions>
                    <PrimaryButton label="Nytt avvik" icon={<AddIcon />} onClick={props.handleOpenNewDeviation} />
                  </StyledExpansionPanelActions>
                )}
                {!tabletMatch && (
                  <BottomPrimaryButton
                    label="Legg til avvik"
                    icon={<AddIcon />}
                    onClick={props.handleOpenNewDeviation}
                  />
                )}
              </>
            )}
          </StyledExpansionPanel>
        )}

        {getExpansionPanelMobileRule('condition', tabletMatch, props.expanded) && (
          <StyledExpansionPanel
            expanded={props.expanded === 'condition'}
            onChange={props.handleChange('condition')}
            square
            style={{ margin: 0 }}
            color={theme.palette.secondary.greyLight}
          >
            <StyledExpansionPanelSummary>
              <Grid container spacing={1} alignItems="center" direction="row">
                <StyledFlexBox item>
                  {props.expanded === 'condition' ? (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                  <Typography variant="h6">Tilstandsvurderinger</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography color="textSecondary">{conditionAssessmentEvents.length}</Typography>
                </Grid>
              </Grid>
            </StyledExpansionPanelSummary>
            <StyledExpansionPanelDetails color={theme.palette.secondary.greyLight}>
              <ObservationStateList
                conditionAssessmentEvents={conditionAssessmentEvents}
                handleOpenEditReport={props.handleOpenEditReport}
                handleOpenDeleteModal={handleOpenDeleteModal(documentTypes.CONDITION)}
                handleEditMode={props.handleEditMode}
                buildingCollectionId={props.building.collection_id}
                buildingAcl={props.building.$$acl}
              />
            </StyledExpansionPanelDetails>
            {isUserCanCreateAndEdit && (
              <>
                {tabletMatch && (
                  <StyledExpansionPanelActions>
                    <PrimaryButton
                      label="Ny tilstandsvurdering"
                      icon={<AddIcon />}
                      onClick={props.handleOpenNewReport}
                    />
                  </StyledExpansionPanelActions>
                )}
                {!tabletMatch && (
                  <BottomPrimaryButton
                    label="Ny tilstandsvurdering"
                    icon={<AddIcon />}
                    onClick={props.handleOpenNewReport}
                  />
                )}
              </>
            )}
          </StyledExpansionPanel>
        )}

        {getExpansionPanelMobileRule('assignments', tabletMatch, props.expanded) && (
          <StyledExpansionPanel
            expanded={props.expanded === 'assignments'}
            onChange={props.handleChange('assignments')}
            square
            style={{ margin: 0 }}
            color={theme.palette.secondary.greyLight}
          >
            <StyledExpansionPanelSummary>
              <Grid container spacing={1} alignItems="center" direction="row">
                <StyledFlexBox item>
                  {props.expanded === 'assignments' ? (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                  <Typography variant="h6">Oppgaver</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography color="textSecondary">{taskEvents.length}</Typography>
                </Grid>
              </Grid>
            </StyledExpansionPanelSummary>
            <StyledExpansionPanelDetails color={theme.palette.secondary.greyLight}>
              <ObservationTaskList
                taskEvents={taskEvents}
                handleOpenEditTask={props.handleOpenEditTask}
                handleOpenDeleteModal={handleOpenDeleteModal(documentTypes.TASK)}
                handleEditMode={props.handleEditMode}
                buildingCollectionId={props.building.collection_id}
                buildingAcl={props.building.$$acl}
              />
            </StyledExpansionPanelDetails>
            {isUserCanCreateAndEdit && (
              <>
                {tabletMatch && (
                  <StyledExpansionPanelActions>
                    <PrimaryButton label="Legg til oppgaver" icon={<AddIcon />} onClick={props.handleOpenNewTask} />
                  </StyledExpansionPanelActions>
                )}
                {!tabletMatch && (
                  <BottomPrimaryButton label="Legg til oppgaver" icon={<AddIcon />} onClick={props.handleOpenNewTask} />
                )}
              </>
            )}
          </StyledExpansionPanel>
        )}

        {getExpansionPanelMobileRule('buildingWork', tabletMatch, props.expanded) && (
          <StyledExpansionPanel
            expanded={props.expanded === 'buildingWork'}
            onChange={props.handleChange('buildingWork')}
            square
            style={{ margin: 0 }}
            color={theme.palette.secondary.greyLight}
          >
            <StyledExpansionPanelSummary>
              <Grid container spacing={1} alignItems="center" direction="row">
                <StyledFlexBox item>
                  {props.expanded === 'buildingWork' ? (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                  <Typography variant="h6">Bygningsarbeid</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography color="textSecondary">{workEvents.length}</Typography>
                </Grid>
              </Grid>
            </StyledExpansionPanelSummary>
            <StyledExpansionPanelDetails color={theme.palette.secondary.greyLight}>
              <ObservationWorkList
                workEvents={workEvents}
                handleOpenEditWork={props.handleOpenEditWork}
                handleOpenDeleteModal={handleOpenDeleteModal(documentTypes.WORK)}
                handleEditMode={props.handleEditMode}
                buildingCollectionId={props.building.collection_id}
                buildingAcl={props.building.$$acl}
              />
            </StyledExpansionPanelDetails>
            {isUserCanCreateAndEdit && (
              <>
                {tabletMatch && (
                  <StyledExpansionPanelActions>
                    <PrimaryButton
                      label="Legg til bygningsarbeid"
                      icon={<AddIcon />}
                      onClick={props.handleOpenNewWork}
                    />
                  </StyledExpansionPanelActions>
                )}
                {!tabletMatch && (
                  <BottomPrimaryButton
                    label="Legg til bygningsarbeid"
                    icon={<AddIcon />}
                    onClick={props.handleOpenNewWork}
                  />
                )}
              </>
            )}
          </StyledExpansionPanel>
        )}

        {getExpansionPanelMobileRule('media', tabletMatch, props.expanded) && (
          <StyledExpansionPanel
            expanded={props.expanded === 'media'}
            onChange={props.handleChange('media')}
            square
            style={{ margin: 0 }}
            color={theme.palette.secondary.greyLight}
          >
            <StyledExpansionPanelSummary>
              <Grid container spacing={1} alignItems="center" direction="row">
                <StyledFlexBox item>
                  {props.expanded === 'media' ? (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    <IconButton edge="start" color="inherit" style={{ marginRight: 6 }}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                  <Typography variant="h6">Opplastninger</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography color="textSecondary">{mediaLength}</Typography>
                </Grid>
              </Grid>
            </StyledExpansionPanelSummary>
            <StyledExpansionPanelDetails color={theme.palette.secondary.greyLight}>
              <Gallery
                images={props.building.images || []}
                // firstPadding="0"
                // lastPadding="0"
                handleImageClick={handleMediaClick}
                attachments={props.building.attachments || []}
                videos={props.building.videos || []}
              />
            </StyledExpansionPanelDetails>
          </StyledExpansionPanel>
        )}
      </Box>
      <Modal
        modalText={DELETE_MODAL_TEXT}
        handleClose={handleCloseDeleteModal}
        open={deleteModalOpen}
        handleConfirmClick={handleDeleteDocument}
      />
      {isSliderOpen && (
        <Slider
          images={props.building.images || []}
          handleClose={handleCloseSlider}
          imageViewedId={mediaViewedId}
          annotations={imagesAnnotations || []}
          videos={props.building.videos || []}
          handleChangeImageViewedId={handleChangeMediaViewedId}
        />
      )}
    </>
  );
};

const StyledExpansionPanel = styled(({ color, ...props }) => <Accordion {...props} elevation={3} />)`
  &.MuiExpansionPanel-root {
    background-color: ${props => props.color};
    // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14);
  }
  &.MuiExpansionPanel-root:before {
    background: none;
  }
  &.MuiExpansionPanel-root.Mui-expanded {
    @media (max-width: 767px) {
      min-height: calc(100vh - 113px);
    }
  }
`;

const StyledExpansionPanelSummary = styled(AccordionSummary)`
  &.MuiExpansionPanelSummary-root {
    padding: 0 18px 0 18px;
    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }
  & .MuiExpansionPanelSummary-content {
    margin-top: 0;
    margin-bottom: 0;
    height: 56px;
    @media (max-width: 767px) {
      margin: 4px 0;
    }
  }
  & .MuiExpansionPanelSummary-content.Mui-expanded {
    margin-top: 0;
    margin-bottom: 0;
    height: 64px;
    @media (max-width: 767px) {
      margin: 4px 0;
    }
  }
`;

const StyledExpansionPanelDetails = styled(({ color, ...props }) => <AccordionDetails {...props} />)`
  &.MuiExpansionPanelDetails-root {
    background-color: ${props => props.color};
    padding: 0 16px 8px;
    flex-direction: column;

    @media (max-width: 767px) {
      margin-bottom: 57px;
    }
  }
`;

const StyledExpansionPanelActions = styled(props => <AccordionActions {...props} />)`
  &.MuiExpansionPanelActions-root {
    justify-content: flex-start;
    padding: 16px 16px;
  }
`;

const StyledFlexBox = styled(props => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`;

export default ObservationExpansionPanel;
