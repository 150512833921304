import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import TaskChecklistDrags from './TaskChecklistDrags/TaskChecklistDrags';

interface Props {
  handleEditChecklistItem: (newItem: any) => void;
  handleAddChecklistItem: (newItem: any) => void;
  handleDeleteChecklistItem: (id: string) => void;
  handleCloseChecklistPanel: () => void;
  handleToggleChecklistPanel: () => void;
  checklist: any[];
  disabledConditions: boolean;
  isChecklistPanelOpen: boolean;
  handleUpChecklistItem: (id: string) => void;
  handleDownChecklistItem: (id: string) => void;
  handleChecklistItemCompletedToggle: (id: string) => void;
}

const TaskChecklistForm: React.FC<Props> = props => {
  const [selectedEdit, setSelectedEdit] = useState<string | null>(null);

  // HANDLERS
  const onToggleChecklistPanel = (id: string) => {
    if (selectedEdit === null) {
      setSelectedEdit(id);
    } else {
      setSelectedEdit(null);
    }
    props.handleToggleChecklistPanel();
  };

  const onCloseChecklistPanel = () => {
    setSelectedEdit(null);
    props.handleCloseChecklistPanel();
  };
  const handleSaveChecklistPanel = (item: any) => {
    if (!selectedEdit || selectedEdit === null) {
      props.handleAddChecklistItem(item);
    } else {
      props.handleEditChecklistItem(item);
    }
    onCloseChecklistPanel();
  };
  const handleDeleteChecklist = (id: string) => {
    props.handleDeleteChecklistItem(id);
    onCloseChecklistPanel();
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TaskChecklistDrags
          dragItems={props.checklist}
          isChecklistPanelOpen={props.isChecklistPanelOpen}
          selectedEdit={selectedEdit}
          handleToggleChecklistPanel={onToggleChecklistPanel}
          handleSaveChecklistPanel={handleSaveChecklistPanel}
          handleCloseChecklistPanel={onCloseChecklistPanel}
          handleDeleteChecklist={handleDeleteChecklist}
          disabledConditions={props.disabledConditions}
          handleUpChecklistItem={props.handleUpChecklistItem}
          handleDownChecklistItem={props.handleDownChecklistItem}
          handleChecklistItemCompletedToggle={props.handleChecklistItemCompletedToggle}
        />
      </Grid>
    </Grid>
  );
};

export default TaskChecklistForm;
