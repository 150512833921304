import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const MediaAddBox = () => {
  return (
    <>
      <StyledWrap color="transparent">
        <StyledAddWrap>
          <AddIcon />
        </StyledAddWrap>
      </StyledWrap>
    </>
  );
};

// STYLED COMPONENTS
const StyledAddWrap = styled(({ color, ...props }) => <Box {...props} />)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  color: #000000;
  min-width: 32px;
  min-height: 32px;
`;
const StyledWrap = styled(({ color, ...props }) => <Box {...props} />)`
  position: relative;
  padding: 36px 10px;
  border: 1px solid #cccccc;
  cursor: pointer;
  background-color: ${props => props.color};
  height: 14.8vw;

  @media (max-width: 767px) {
    height: 40vw;
  }
  @media (max-width: 420px) {
    height: 30vw;
  }
`;

export default MediaAddBox;
