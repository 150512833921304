import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Box, Typography, Divider, useMediaQuery } from '@material-ui/core';
import Gallery from '../../sections/Gallery';
import { format } from 'date-fns';
import AddIcon from '@material-ui/icons/Add';

import { BuildingType } from 'types/Building/BuildingType';
import ObservationTaskList from '../ObservationTaskList/ObservationTaskList';
import PrimaryButton from '../../shared/PrimaryButton/PrimaryButton';

import { getCurrentIcon } from 'helpers/getCurrentIcon';
import { DELETE_MODAL_TEXT } from '../../../../constants';

import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import Slider from '../Slider/Slider';
import Modal from '../../shared/Modal/Modal';

interface Props {
  deviation: any;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  tasks: any[];
  handleOpenEditTask: (id: string, linkedObjectId?: string) => void;
  handleDeleteTask: (id: string) => void;
  handleOpenNewTask: (linkedObjectId?: string) => void;
  building: BuildingType;
}

const ViewDeviation: React.FC<Props> = ({ deviation, isEditMode, handleEditMode, tasks, ...props }) => {
  const tabletMatch = useMediaQuery('(min-width: 768px)');
  const mobileMarginBottom = !tabletMatch ? '56px' : '0';

  const [isSliderOpen, setSliderOpen] = useState(false);
  const [imageViewedId, setImageViewedId] = useState('');

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState<string | null>(null);

  const handleOpenSlider = () => {
    setSliderOpen(true);
  };
  const handleCloseSlider = () => {
    setImageViewedId('');
    setSliderOpen(false);
  };
  const handleImageClick = (id: string) => {
    setImageViewedId(id);
    handleOpenSlider();
  };

  const handleOpenDeleteModal = (id: string) => {
    setDeletedId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeletedId(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteDocument = () => {
    props.handleDeleteTask(deletedId as string);
    handleCloseDeleteModal();
    setDeletedId(null);
  };

  return (
    <StyledWrapper isSliderOpen={isSliderOpen}>
      {/* TITLE SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Avvik</Typography>
          </Grid>
          {deviation?.title?.title && (
            <Grid item>
              <StyledCaption color="textSecondary">Tittel</StyledCaption>
              <Typography variant="h6">{deviation?.title?.title}</Typography>
            </Grid>
          )}
          {deviation?.timespan?.from_date?.dd_date && (
            <Grid item>
              <StyledCaption color="textSecondary">Dato observert</StyledCaption>
              <Typography variant="h6">
                {format(deviation?.timespan.from_date.dd_date || Date.now(), 'dd.MM.yyyy')}
              </Typography>
            </Grid>
          )}
          {deviation?.damage?.description?.damage_description && (
            <Grid item>
              <StyledCaption color="textSecondary">Detaljer</StyledCaption>
              <Typography variant="h6">{deviation?.damage?.description?.damage_description}</Typography>
            </Grid>
          )}
          {deviation?.priority?.priority_type_id_value && (
            <Grid item>
              <StyledFlexBox align="center">
                <StyledMeasureWrap>{getCurrentIcon(deviation?.priority?.priority_type_id_value)}</StyledMeasureWrap>
                <Box>
                  <StyledCaption color="textSecondary">Prioritet</StyledCaption>
                  <Typography variant="h6">{deviation?.priority?.priority_type_id_value}</Typography>
                </Box>
              </StyledFlexBox>
            </Grid>
          )}
          {deviation?.status?.status_type_id_value && (
            <Grid item>
              <StyledCaption color="textSecondary">Status</StyledCaption>
              <Typography variant="h6">{deviation?.status?.status_type_id_value}</Typography>
            </Grid>
          )}
        </Grid>
      </StyledSection>
      <Divider />
      {/* BUILDING PART SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Bygningsdel</Typography>
          </Grid>
          {deviation?.damage?.building_part_type_id_value && (
            <Grid item>
              <StyledCaption color="textSecondary">Bygningsdel</StyledCaption>
              <Typography variant="h6">{deviation?.damage?.building_part_type_id_value}</Typography>
            </Grid>
          )}
          {deviation?.damage?.position?.damage_position && (
            <Grid item>
              <StyledCaption color="textSecondary">Posisjon</StyledCaption>
              <Typography variant="h6">{deviation?.damage?.position?.damage_position}</Typography>
            </Grid>
          )}
          {deviation?.damage?.damage_type?.damage_type_id_value && (
            <Grid item>
              <StyledCaption color="textSecondary">Type avvik</StyledCaption>
              <Typography variant="h6">{deviation?.damage?.damage_type?.damage_type_id_value}</Typography>
            </Grid>
          )}
        </Grid>
      </StyledSection>
      <Divider />
      {/* MEDIA SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Opplastninger</Typography>
          </Grid>
        </Grid>
        <Gallery
          images={deviation?.images || []}
          firstPadding="0"
          lastPadding="0"
          handleImageClick={handleImageClick}
          attachments={deviation?.attachments || []}
          videos={deviation?.videos || []}
        />
      </StyledSection>
      <Divider />
      {/* BACKGROUND INFO SECTION */}
      <StyledSection marginBottom={mobileMarginBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Bakgrunnsinformasjon</Typography>
          </Grid>
          {deviation?.damage?.damage_timespan?.timespan_damage_description && (
            <Grid item>
              <StyledCaption color="textSecondary">Beskrivelse av årsak</StyledCaption>
              <Typography variant="h6">{deviation?.damage?.damage_timespan?.timespan_damage_description}</Typography>
            </Grid>
          )}
          {deviation?.damage?.damage_timespan?.timespan_damage_from_date?.dd_date && (
            <Grid item>
              <StyledCaption color="textSecondary">Inntraff dato</StyledCaption>
              <Typography variant="h6">
                {format(
                  deviation?.damage?.damage_timespan?.timespan_damage_from_date?.dd_date || Date.now(),
                  'dd.MM.yyyy',
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </StyledSection>
      {/* OPPGAVER SECTION */}
      <StyledSection marginBottom={mobileMarginBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Oppgaver</Typography>
          </Grid>
          <Grid item>
            <ObservationTaskList
              taskEvents={tasks}
              handleOpenEditTask={props.handleOpenEditTask}
              handleEditMode={handleEditMode}
              buildingCollectionId={props.building.collection_id}
              buildingAcl={props.building.$$acl}
              handleOpenDeleteModal={handleOpenDeleteModal}
              linkedId={deviation?.artifact_id || deviation?.uuid}
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              label="Legg til oppgaver"
              icon={<AddIcon />}
              onClick={() => props.handleOpenNewTask(deviation?.artifact_id || deviation?.uuid)}
            />
          </Grid>
        </Grid>
      </StyledSection>

      {!tabletMatch && (
        <BottomStatusBar
          date={deviation?.updated_at.dd_date ?? 0}
          status={deviation?.status?.status_type_id_value || 'Publisert'}
          isEditMode={isEditMode}
          handleEditMode={handleEditMode}
        />
      )}
      {isSliderOpen && (
        <Slider
          images={deviation?.images || []}
          handleClose={handleCloseSlider}
          imageViewedId={imageViewedId}
          annotations={deviation?.annotation_events || []}
          videos={deviation?.videos || []}
        />
      )}
      <Modal
        modalText={DELETE_MODAL_TEXT}
        handleClose={handleCloseDeleteModal}
        open={deleteModalOpen}
        handleConfirmClick={handleDeleteDocument}
      />
    </StyledWrapper>
  );
};

const StyledSection = styled(({ overflow, marginBottom, ...props }) => <Box {...props} />)`
  padding: 16px 16px 34px;
  background-color: #f5f5f5;
  ${props => props.overflow && `overflow: ${props.overflow}`};
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
`;
const StyledCaption = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    font-weight: 700;
  }
`;
const StyledFlexBox = styled(({ direction, align, justify, ...props }) => <Box {...props} />)`
  display: flex;
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.align && `align-items: ${props.align}`};
  ${props => props.justify && `justify-content: ${props.justify}`};
`;
const StyledMeasureWrap = styled(Box)`
  margin-right: 24px;
`;
const StyledWrapper = styled(({ isSliderOpen, ...props }) => <Box {...props} />)`
  ${props =>
    props.isSliderOpen &&
    `
    height: 90vh;
    overflow: hidden;
  `}
`;

export default ViewDeviation;
