import React from 'react';
import styled from 'styled-components';
import CSS from 'csstype';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  value: string;
  handleChange: (value: string, options: any[]) => void; // old type (event: React.ChangeEvent<{}>, value: any) => void;
  options: any[];
  placeholder?: string;
  label?: string;
  customStyles?: CSS.Properties;
  open: boolean;
  loading: boolean;
  setOpenStatus: (status: boolean) => void;
  required?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: 0,
    },
  }),
);

const AsyncFormSelect: React.FC<Props> = props => {
  const classes = useStyles();

  const defaultProps = {
    options: props.options.map(option => option.title),
  };

  return (
    <FormControl className={classes.margin} fullWidth style={props.customStyles}>
      <Autocomplete
        {...defaultProps}
        includeInputInList
        value={props.value || null}
        onChange={(event: React.ChangeEvent<{}>, value: any) => props.handleChange(value, props.options)}
        open={props.open}
        loading={props.loading}
        disabled={props.disabled}
        onOpen={() => {
          props.setOpenStatus(true);
        }}
        onClose={() => {
          props.setOpenStatus(false);
        }}
        renderInput={params => (
          <StyledTextField
            required={props.required || false}
            {...params}
            placeholder={props.placeholder}
            label={props.label || props.placeholder}
            fullWidth
            variant="filled"
            color="secondary"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

const StyledTextField = styled(props => <TextField {...props} />)`
  & .MuiInput-underline:before {
    border: none;
  }
  & .MuiInput-underline:after {
    border: none;
  }
  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  & .MuiInput-formControl {
    margin-bottom: 8px;
  }

  & .MuiInput-underline.Mui-disabled:before {
    border: none;
  }
  & .MuiFilledInput-root {
    border-radius: 0;
  }
`;
export default AsyncFormSelect;
