import React, { useRef } from 'react';
import styled from 'styled-components';
import { Grid, Paper } from '@material-ui/core';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import { ItemTypes } from '../../../../../constants';

import AttachmentDragItem from '../../../shared/AttachmentDragItem/AttachmentDragItem';

interface Props {
  name: string;
  index: number;
  id: string;
  order: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDeleteAnnotation: (e: React.MouseEvent) => void;
  handleChangeName: (event: React.ChangeEvent<{ value: string; id: string }>) => void;
  color: string;
  getTextBadgeColor: (activeColor: string) => string;
  shape: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const AttachmentMediaDragItem = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: ItemTypes.MEDIA_DRAG,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.MEDIA_DRAG, id: props.id, index: props.index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Grid item style={{ opacity, cursor: 'move' }} ref={ref}>
      <StyledPaper square>
        <AttachmentDragItem
          issue={props.name}
          caption={`${props.order}`}
          onDelete={props.onDeleteAnnotation}
          id={props.id}
          handleChangeName={props.handleChangeName}
          color={props.color}
          getTextBadgeColor={props.getTextBadgeColor}
          shape={props.shape}
        />
      </StyledPaper>
    </Grid>
  );
};

const StyledPaper = styled(props => <Paper {...props} />)`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  min-height: 50px;
  &.MuiPaper-root {
    background-color: #f2f2f2;
  }
`;

export default AttachmentMediaDragItem;
