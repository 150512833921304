import { CollectionRight } from 'types/UserType';

export enum UserRoles {
  ADMIN = 'admin',
  SUPER_USER = 'super_user',
  USER = 'user',
}

export enum CollectionRoles {
  READ = 'LESE',
  READ_ALL = 'LESE ALT',
  WRITE = 'SKRIVE',
  WRITE_ALL = 'SKRIVE ALT',
}

const FORBIDDING_EDIT_ACL_FIELD = 'put_artifact';

export const UNALLOWED_DEVIATION_STATUS_FOR_DELETE = 'Avsluttet';

export const getIsUserCanDeleteObject = (role: string, userId: string, objectCreatedById: string) =>
  role === UserRoles.ADMIN || userId === objectCreatedById || false;

export const getIsDeviationCanBeDeletedByStatus = (status: string) => status !== UNALLOWED_DEVIATION_STATUS_FOR_DELETE;

export const getIsUserCanCreateOrEditObjectsByCollectionRole = (
  buildingCollectionId: string,
  userCollectionRights: CollectionRight[],
) => {
  const currentUserCollectionRight = userCollectionRights.find(right => right.collection_id === buildingCollectionId);

  if (!currentUserCollectionRight) return false;

  return (
    currentUserCollectionRight.collection_role_id_value !== CollectionRoles.READ &&
    currentUserCollectionRight.collection_role_id_value !== CollectionRoles.READ_ALL
  );
};

export const getIsUserCanCreateOrEditObjectByAcl = (objectAcl: { restrictions: { name: string }[] }) =>
  !objectAcl?.restrictions.some(restr => restr.name === FORBIDDING_EDIT_ACL_FIELD);
