import React, { useMemo, useEffect, useReducer } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// types
import { BuildingType } from 'types/Building/BuildingType';
import { deviationStatusMock } from '../../mocks/statusesMock';
// helpers
import { getExpansionPanelMobileRule } from 'helpers/getExpansionPanelMobileRule';

import {
  postAnnotationEvent,
  fetchBuildingEvent,
  uploadImage,
  postImage,
  fetchReportImagesData,
  fetchAttachmentsData,
  fetchVideosData,
  uploadFile,
  postFile,
  uploadVideo,
  postVideo,
} from 'services/fetchData';

// constants
import { deviationExpansionsTypes, documentTypes } from '../../../../constants';

// reducer
import { reducer, ActionTypes } from './reducer';

// mocks
import { deviationTypesMock } from '../../mocks/deviationTypesMock';

import {
  StyledExpansionPanel,
  StyledExpansionPanelActions,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
} from '../../shared/ExpansionPanel/ExpansionPanel';
import ProgressBottomButton from '../../shared/ProgressBottomButton/ProgressBottomButton';
import SaveOrCancel from '../../components/SaveOrCancel/SaveOrCancel';
import DeviationTitleForm from '../DeviationTitleForm/DeviationTitleForm';
import DeviationBuildingForm from '../DeviationBuildingForm/DeviationBuildingForm';
import DeviationBackgroundForm from '../DeviationBackgroundForm/DeviationBackgroundForm';
import ChildrenAttachmentSection from '../../sections/ChildrenAttachmentSection';
import AttachmentMediaPanel from '../AttachmentMediaPanel/AttachmentMediaPanel';
import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import { getPrioritiesList, PriorityListItem } from 'services/PrioritiesList';

interface Props {
  handleReturnBackToInfo: () => void;
  deviationModel: any;
  building: BuildingType;
  handleAddNewDeviation: (newDeviation: any, isFirstSave?: boolean) => void;
  handleUpdateDeviation: (updatedDeviation: any, isLastSaved?: boolean) => void;
  editedDeviation: any;
  isViewDeviationCondition: boolean;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  handleNextExpansion: () => void;
  handlePrevExpansion: () => void;
  handleExpansionChange: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  expanded: string | false;
  currentStepNumber: number;
  stepsAmount: number;
  currentExpansionIndex: number;
  handleEditSaveTrigger: (status: boolean) => void;
  editSaveTrigger: boolean;
  isEditDocumentChanged: boolean;
  handleEditDocumentChanged: (status: boolean) => void;
}

const initialState = {
  deviationTitle: '',
  observeDate: new Date(Date.now()),
  isObserveDatePickerOpen: false,
  deviationDescription: '',
  deviationPriority: {} as PriorityListItem,
  deviationStatus: deviationStatusMock[0].value,
  buildingPartInput: {
    title: '',
    id: '',
  },
  deviationLocation: '',
  deviationType: {
    title: deviationTypesMock[0].title,
    id: deviationTypesMock[0].id,
  },
  media: [],
  isMediaPanelOpen: false,
  selectedMedia: null,
  annotationEvents: [],
  deviationReason: '',
  occureDate: null,
  isOccureDatePickerOpen: false,
  backgroundShowStatus: 'no',
  isFirstSaved: false,
  imagesData: [],
  attachments: [],
  attachmentsData: [],
  videos: [],
  videosData: [],
  isEditChanged: false,
};

const DeviationExpansionPanel: React.FC<Props> = props => {
  const theme = useTheme();
  const tabletMatch = useMediaQuery('(min-width: 768px)');

  // STATE
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function loadPriorities() {
      const priorities = await getPrioritiesList();
      state.deviationPriority = priorities[0];
    }

    if (props.isEditMode && !state.isEditChanged) {
      state.deviationPriority = props.editedDeviation.priority;
    } else if (state.isEditChanged) {
    } else {
      loadPriorities();
    }
  }, [state.deviationPriority, state.isEditChanged, props.isEditMode, props.editedDeviation.priority]);

  // TITLE AND PRIORITY FORM HANDLERS
  const handleDeviationTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: { deviationTitle: event.target.value as string },
    });
  };
  const handleObserveDateChange = (date: Date | null) => {
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { observeDate: date } });
  };
  const handleOpenObserveDatePicker = () => {
    if (disabledConditions) return;
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isObserveDatePickerOpen: true } });
  };
  const handleCloseObserveDatePicker = () => {
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isObserveDatePickerOpen: false } });
  };
  const handleDeviationDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: { deviationDescription: event.target.value as string },
    });
  };
  const handleDeviationPriorityChange = async (deviation: PriorityListItem) => {
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: {
        deviationPriority: {
          title: deviation.title,
          id: deviation.id,
          value: deviation.value,
        },
      },
    });
  };

  const handleDeviationStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: { deviationStatus: (event.target as HTMLInputElement).value },
    });
  };

  // BUILDING AND DETAILS FORM HANDLERS
  const handleBuildingPartInputChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { buildingPartInput: option } });
  };
  const handleDeviationLocationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: { deviationLocation: event.target.value as string },
    });
  };
  const handleDeviationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType = (event.target as HTMLInputElement).value;
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: {
        deviationType: {
          title: newType,
          id: deviationTypesMock.find(option => option.title === newType)!.id,
        },
      },
    });
  };

  // ATTACHMENT HANDLERS
  const handleSaveAnnotations = async (newAnnotations: any[]) => {
    if (state.annotationEvents.some((annot: any) => annot.image_id === state.selectedMedia.image_id)) {
      // FETCH
      const updated = {
        ...state.annotationEvents.find((annot: any) => annot.image_id === state.selectedMedia.image_id),
        annotation_points: [...newAnnotations],
      };
      await postAnnotationEvent(updated);
      //
      const currentDeviation = await fetchBuildingEvent(props.editedDeviation.artifact_id);

      dispatch({
        type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
        payload: { annotationEvents: currentDeviation.annotation_events },
      });
      return;
    }

    // IF IT IS NEW ANNOTATION EVENT
    const newAnnotationEvent = {
      model_title: null,
      deletable: { deletable_type: 'bool', is_deletable: true, field: null, comparator: null, value: null },
      artifact_name_upper: null,
      checksum: null,
      artifact_id: null,
      artifact_name: null,
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_by_id: null,
      updated_by_id_value: null,
      thumbnail_id: null,
      object_type: 'AnnotationEvent',
      meta_type: 'adm_event',
      uuid: null,
      identifier: null,
      m_path: null,
      rights_type_id: null,
      attachments: [],
      authority: null,
      publish: null,
      is_restricted: null,
      event_sub_type: null,
      has_annotation_events: null,
      restriction_type: 1,
      artifact_type_id: 'ct_200-221',
      artifact_type_id_value: 'Markering',
      object_type_id: 'AE_21',
      contexts: [
        {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'AdmEventContextItem',
          meta_type: 'sub_model',
          context_id: props.editedDeviation?.artifact_id || null,
          revision_id: null,
          no: null,
          order_number: null,
          context_object_type: null,
          context_artifact_type_id: null,
          context_identifier: null,
          context_collection_id: null,
          context_artifact_id: props.building.artifact_id,
          context_artifact_id_value: null,
          from_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: Date.now(),
            dd_error_message: null,
          },
          to_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          remarks: null,
          _create: true,
        },
      ],
      parent_id: props.editedDeviation?.artifact_id || null,
      adm_event_type_id: 'ct_49-21',
      adm_event_type_id_value: 'Annotering',
      image_id: state.selectedMedia.image_id,
      annotation_points: [...newAnnotations],
      timespan: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'TimespanEvent',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        from_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: Date.now(),
          dd_error_message: null,
        },
        to_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: null,
          dd_error_message: null,
        },
        timespan_event_description: null,
      },
      collection_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      created_by_id: null,
      created_by_id_value: null,
      comments: [],
      _create: true,
    };
    await postAnnotationEvent(newAnnotationEvent);
    //
    const currentDeviation = await fetchBuildingEvent(props.editedDeviation.artifact_id);
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: {
        annotationEvents: currentDeviation.annotation_events,
      },
    });
  };
  const handleOpenMediaPanel = (itemId: string) => {
    const selectedImage = state.media.find((img: any) => img.image_id === itemId);
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { selectedMedia: selectedImage } });
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isMediaPanelOpen: true } });
  };
  const handleCloseMediaPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { selectedMedia: null } });
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isMediaPanelOpen: false } });
  };
  const handleAddNewImage = async (imageData: any) => {
    const formData = new FormData();
    formData.append('file', imageData);

    const uploadImageData = await uploadImage(formData);
    const newImage = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'ImageItem',
      meta_type: 'sub_model',
      context_id: props.editedDeviation?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.media.length,
      image_id: uploadImageData.artifact_id,
      code: uploadImageData.code,
      code_value: uploadImageData.code_value,
      motif_object: null,
      _create: true,
    };
    await postImage(newImage);

    const currentDeviation = await fetchBuildingEvent(props.editedDeviation.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { media: currentDeviation.images } });
  };
  const handleDeleteImage = (id: string) => {
    const updatedMedia = state.media.map(image => {
      if (image.image_id === id) {
        return { ...image, _destroy: true };
      }
      return image;
    });
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: {
        media: updatedMedia,
      },
    });
  };

  // attachments
  const handleAddNewAttachment = async (attachmentData: any) => {
    const formData = new FormData();
    formData.append('file', attachmentData);

    const uploadAttachmentData = await uploadFile(formData);
    const newAttachment = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'AttachmentItem',
      meta_type: 'sub_model',
      context_id: props.editedDeviation?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.attachments.length,
      context_object_type: null,
      context_meta_type: null,
      artifact_id: null,
      created_by_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      artifact_name: null,
      object_type_id: 'ATTACHMENT_ITEM',
      artifact_type_id: null,
      artifact_type_id_value: null,
      _destroy: null,
      uuid: null,
      attachment_id: uploadAttachmentData.artifact_id,
      attachment_id_value: null,
      _create: true,
    };
    await postFile(newAttachment);

    const currentReport = await fetchBuildingEvent(props.editedDeviation.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { attachments: currentReport.attachments } });
  };

  const handleDeleteFile = (id: string) => {
    const updatedAttachments = state.attachments.map(file => {
      if (file.attachment_id === id) {
        return { ...file, _destroy: true };
      }
      return file;
    });
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: {
        attachments: updatedAttachments,
      },
    });
  };
  // videos
  const handleAddNewVideo = async (videoData: any) => {
    const formData = new FormData();
    formData.append('file', videoData);

    const uploadVideoData = await uploadVideo(formData);
    const newVideo = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'VideoItem',
      meta_type: 'sub_model',
      context_id: props.editedDeviation?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.videos.length,
      video_id: uploadVideoData.artifact_id,
      _create: true,
    };
    await postVideo(newVideo);

    const currentReport = await fetchBuildingEvent(props.editedDeviation.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { videos: currentReport.videos } });
  };

  const handleDeleteVideo = (id: string) => {
    const updatedVideos = state.videos.map(video => {
      if (video.video_id === id) {
        return { ...video, _destroy: true };
      }
      return video;
    });
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: {
        videos: updatedVideos,
      },
    });
  };

  // BUILDING BACKGROUND FORM HANDLERS
  const handleDeviationReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: { deviationReason: event.target.value as string },
    });
  };
  const handleOccureDateChange = (date: Date | null) => {
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { occureDate: date } });
  };
  const handleOpenOccureDatePicker = () => {
    if (disabledConditions) return;
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isOccureDatePickerOpen: true } });
  };
  const handleCloseOccureDatePicker = () => {
    dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isOccureDatePickerOpen: false } });
  };
  const handleBackgroundShowStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE,
      payload: { backgroundShowStatus: (event.target as HTMLInputElement).value },
    });
  };

  // HANDLE SAVE DEVIATION
  const handleSaveDeviation = async (isLastSaved = false) => {
    const status = state.deviationStatus || [deviationStatusMock[0].value];

    const currentStatusData = deviationStatusMock.find(st => st.value === status);

    // IF EDIT DEVIATION
    if (props.isEditMode || state.isFirstSaved) {
      const timespanDamageFromDate = props.editedDeviation.damage.damage_timespan
        ? props.editedDeviation.damage.damage_timespan.timespan_damage_from_date
        : [];
      const timespanDamageToDate = props.editedDeviation.damage.damage_timespan
        ? props.editedDeviation.damage.damage_timespan.timespan_damage_to_date
        : [];
      const updatedDeviation = {
        ...props.deviationModel,
        ...props.editedDeviation,
        title: {
          ...props.editedDeviation.title,
          model_title: null,
          artifact_name_upper: null,
          title: state.deviationTitle || null,
        },
        priority: {
          model_title: null,
          artifact_name_upper: null,
          priority_type_id: state.deviationPriority.id || state.deviationPriority.priority_type_id || null,
          priority_type_id_value:
            state.deviationPriority.value || state.deviationPriority.priority_type_id_value || null,
          priority_remarks: null,
        },
        status: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'ObservationStatus',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          status_type_id: currentStatusData?.id ?? null,
          status_type_id_value: currentStatusData?.value ?? null,
          status_remarks: null,
        },
        damage: {
          ...props.editedDeviation.damage,
          model_title: null,
          artifact_name_upper: null,
          building_part_type_id: state.buildingPartInput.id || null,
          building_part_type_id_value: state.buildingPartInput.title || null,
          description: {
            ...props.editedDeviation.damage.description,
            model_title: null,
            artifact_name_upper: null,
            damage_description: state.deviationDescription || null,
          },
          damage_type: {
            ...props.editedDeviation.damage.damage_type,
            model_title: null,
            artifact_name_upper: null,
            damage_type_id: state.deviationType.id || null,
            damage_type_id_value: state.deviationType.title || null,
          },
          position: {
            ...props.editedDeviation.damage.position,
            model_title: null,
            artifact_name_upper: null,
            damage_position: state.deviationLocation || null,
          },
          damage_timespan: {
            ...props.editedDeviation.damage.damage_timespan,
            model_title: null,
            artifact_name_upper: null,
            timespan_damage_from_date: {
              ...timespanDamageFromDate,
              model_title: null,
              artifact_name_upper: null,
              dd_date:
                state.occureDate && state.backgroundShowStatus === 'yes'
                  ? Number(format(state.occureDate as Date, 'T'))
                  : null,
            },
            timespan_damage_to_date: {
              ...timespanDamageToDate,
              model_title: null,
              artifact_name_upper: null,
            },
            timespan_damage_description: state.backgroundShowStatus === 'yes' ? state.deviationReason || null : null,
          },
        },
        timespan: {
          ...props.editedDeviation.timespan,
          model_title: null,
          artifact_name_upper: null,
          from_date: {
            ...props.editedDeviation.timespan.from_date,
            model_title: null,
            artifact_name_upper: null,
            dd_date: Number(format(state.observeDate as Date, 'T')),
          },
          to_date: {
            ...props.editedDeviation.timespan.to_date,
            model_title: null,
            artifact_name_upper: null,
          },
        },
        images: [...state.media],
        annotation_events: [...state.annotationEvents],
        attachments: [...state.attachments],
        videos: [...state.videos],
        _create: true,
      };

      // delete unnecessary fields
      if (updatedDeviation.hasOwnProperty('$$acl')) delete updatedDeviation.$$acl;
      if (updatedDeviation.hasOwnProperty('$$context_name')) delete updatedDeviation.$$context_name;

      await props.handleUpdateDeviation(updatedDeviation, !props.isEditMode ? isLastSaved : false);
      dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isEditChanged: false } });

      if (!isLastSaved) {
        props.handleNextExpansion();
      }
    }

    // IF NEW DEVIATION
    if (!props.isEditMode && !state.isFirstSaved) {
      const newDeviation = {
        ...props.deviationModel,
        parent_id: props.building.artifact_id,
        uuid: null,
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        contexts: [
          {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            no: null,
            order_number: null,
            context_object_type: null,
            context_artifact_type_id: null,
            context_identifier: null,
            context_collection_id: null,
            context_artifact_id: props.building.artifact_id,
            context_artifact_id_value: props.building.artifact_name,
            from_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: 'datetime',
              dd_date: Number(format(state.observeDate as Date, 'T')),
              dd_error_message: null,
            },
            to_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: 'datetime',
              dd_date: null,
              dd_error_message: null,
            },
            remarks: null,
            _create: true,
          },
        ],
        status: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'ObservationStatus',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          status_type_id: currentStatusData?.id ?? null,
          status_type_id_value: currentStatusData?.value ?? null,
          status_remarks: null,
        },
        title: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'AdmTitle',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          title: state.deviationTitle || null,
        },
        priority: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'Priority',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          priority_type_id: state.deviationPriority.id || null,
          priority_type_id_value: state.deviationPriority.value || null,
          priority_remarks: null,
        },
        damage: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          artifact_id: null,
          identifier: null,
          context_id: null,
          event_id: null,
          object_type: 'BuildingDamage',
          building_part_type_id: state.buildingPartInput.id || null,
          building_part_type_id_value: state.buildingPartInput.title || null,
          description: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'DamageDescription',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            damage_description: state.deviationDescription || null,
          },
          damage_type: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'BuildingDamageType',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            damage_type_id: state.deviationType.id || null,
            damage_type_id_value: state.deviationType.title || null,
          },
          position: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'DamagePosition',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            damage_position: state.deviationLocation || null,
          },
          damage_timespan: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'TimespanBuildingDamage',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            timespan_damage_from_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: 'datetime',
              dd_date:
                state.occureDate && state.backgroundShowStatus === 'yes'
                  ? Number(format(state.occureDate as Date, 'T'))
                  : null,
              dd_error_message: null,
            },
            timespan_damage_to_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: 'datetime',
              dd_date: null,
              dd_error_message: null,
            },
            timespan_damage_description: state.backgroundShowStatus === 'yes' ? state.deviationReason || null : null,
          },
        },
        timespan: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'TimespanBuildingObservation',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          from_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: Number(format(state.observeDate as Date, 'T')),
            dd_error_message: null,
          },
          to_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          timespan_building_obs_desc: null,
        },
        collection_id: null,
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        created_by_id: null,
        created_by_id_value: null,
        comments: [],
        images: [...state.media],
        annotation_events: [...state.annotationEvents],
        has_images: null,
        attachments: [...state.attachments],
        videos: [...state.videos],
        is_current: null,
        _create: true,
      };

      // POST DEVIATION AND RETURN TO INFO
      await props.handleAddNewDeviation(newDeviation, state.isFirstSaved || isLastSaved);

      if (!state.isFirstSaved) {
        props.handleNextExpansion();
      }

      dispatch({ type: ActionTypes.UPDATE_DEVIATION_PANEL_STATE, payload: { isFirstSaved: true } });
      //
    }
  };

  useEffect(() => {
    if (!props.editSaveTrigger) return;
    handleSaveDeviation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editSaveTrigger]);

  // SETTING FORMS STATES IF EDIT REPORT
  useEffect(() => {
    dispatch({
      type: ActionTypes.PREPARE_EDITED,
      payload: {
        deviationTitle: props.editedDeviation?.title?.title ?? '',
        observeDate:
          (props.editedDeviation?.timespan?.from_date?.dd_date &&
            new Date(props.editedDeviation?.timespan?.from_date?.dd_date)) ??
          new Date(Date.now()),
        deviationDescription: props.editedDeviation?.damage?.description?.damage_description ?? '',
        // deviationPriority: getInitialPriorityState(props.editedDeviation?.priority),
        deviationPriority: props.editedDeviation?.priority,
        deviationStatus: props.editedDeviation?.status?.status_type_id_value ?? deviationStatusMock[0].value,
        buildingPartInput: {
          title: props.editedDeviation?.damage?.building_part_type_id_value ?? '',
          id: props.editedDeviation?.damage?.building_part_type_id ?? '',
        },
        deviationLocation: props.editedDeviation?.damage?.position?.damage_position ?? '',
        deviationType: {
          title: props.editedDeviation?.damage?.damage_type?.damage_type_id_value ?? deviationTypesMock[0].title,
          id: props.editedDeviation?.damage?.damage_type?.damage_type_id ?? deviationTypesMock[0].id,
        },
        deviationReason: props.editedDeviation?.damage?.damage_timespan?.timespan_damage_description ?? '',
        occureDate:
          (props.editedDeviation?.damage?.damage_timespan?.timespan_damage_from_date?.dd_date &&
            new Date(props.editedDeviation?.damage?.damage_timespan?.timespan_damage_from_date?.dd_date)) ??
          null,
        backgroundShowStatus:
          props.editedDeviation?.damage?.damage_timespan?.timespan_damage_description ||
          props.editedDeviation?.damage?.damage_timespan?.timespan_damage_from_date?.dd_date
            ? 'yes'
            : 'no',
        media: props.editedDeviation?.images || [],
        annotationEvents: props.editedDeviation?.annotation_events ?? [],
        selectedMedia: null,
        attachments: props.editedDeviation?.attachments || [],
        videos: props.editedDeviation?.videos || [],
      },
    });
  }, [props.isViewDeviationCondition, props.editedDeviation]);

  // TRIGGER CHANGED DOCUMENT
  useEffect(() => {
    if (!state.isEditChanged) {
      props.handleEditDocumentChanged(false);
      return;
    }

    props.handleEditDocumentChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isEditChanged]);

  // FETCH IMAGES DATA
  useEffect(() => {
    if (state.media.length === 0) return;
    const imagesIds = state.media.reduce((acc: string[], { image_id }: { image_id: string }) => [...acc, image_id], []);

    (async () => {
      const imagesData = await fetchReportImagesData(imagesIds);
      dispatch({ type: ActionTypes.PREPARE_EDITED, payload: { imagesData: imagesData.artifacts } });
    })();
    return;
  }, [state.media]);
  // FETCH ATTACHMENTS DATA
  useEffect(() => {
    if (state.attachments.length === 0) return;
    const attachmentsIds = state.attachments.reduce(
      (acc: string[], { attachment_id }: { attachment_id: string }) => [...acc, attachment_id],
      [],
    );

    (async () => {
      const attachmentsData = await fetchAttachmentsData(attachmentsIds);
      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { attachmentsData: attachmentsData.artifacts },
      });
    })();
    return;
  }, [state.attachments]);
  // FETCH VIDEOS DATA
  useEffect(() => {
    if (state.videos.length === 0) return;
    const videosIds = state.videos.reduce(
      (acc: string[], { video_id }: { video_id: string }) => [...acc, video_id],
      [],
    );

    (async () => {
      const videosData = await fetchVideosData(videosIds);

      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { videosData: videosData.artifacts },
      });
    })();
    return;
  }, [state.videos]);

  const currentAnnotationEvent = useMemo(
    () =>
      state.annotationEvents.find(
        (annot: any) => state.selectedMedia && annot.image_id === state.selectedMedia.image_id,
      ) || {},
    [state.annotationEvents, state.selectedMedia],
  );

  const currentImageData = useMemo(
    () => state.imagesData.find(img => img.artifact_id === state.selectedMedia?.image_id),
    [state.imagesData, state.selectedMedia],
  );

  const disabledConditions = props.isViewDeviationCondition && !props.isEditMode;
  const mediaLength = useMemo(
    () => [...state.media, ...state.videos, ...state.attachments].filter(img => !img._destroy).length,
    [state.attachments, state.media, state.videos],
  );

  return (
    <>
      {!tabletMatch && !props.isViewDeviationCondition && (
        <ProgressBottomButton
          onSave={() => handleSaveDeviation(true)}
          currentStepNumber={props.currentStepNumber}
          stepsAmount={props.stepsAmount}
          onNext={() => handleSaveDeviation()}
          expanded={props.expanded}
          onBack={props.handlePrevExpansion}
        />
      )}

      {!tabletMatch && props.isViewDeviationCondition && (
        <BottomStatusBar
          date={props.editedDeviation?.updated_at?.dd_date ?? 0}
          status={props.editedDeviation?.status?.status_type_id_value ?? 'status'}
          isEditMode={props.isEditMode}
          handleEditMode={props.handleEditMode}
          currentDocumentType={documentTypes.DEVIATION}
          currentDocumentStatus={props.editedDeviation?.status?.status_type_id_value || ''}
          handleEditSaveTrigger={props.handleEditSaveTrigger}
          isEditDocumentChanged={props.isEditDocumentChanged}
        />
      )}

      {getExpansionPanelMobileRule(
        deviationExpansionsTypes.TITLE_AND_PRIORITY,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === deviationExpansionsTypes.TITLE_AND_PRIORITY}
          onChange={props.handleExpansionChange(deviationExpansionsTypes.TITLE_AND_PRIORITY)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === deviationExpansionsTypes.TITLE_AND_PRIORITY ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Avvik</Typography>
                </StyledFlexBox>
                <StyledEllipsisCaptionContainer item>
                  <StyledEllipsisCaption variant="h6" color="textSecondary">
                    {state.deviationTitle || 'Uten tittel'}
                  </StyledEllipsisCaption>
                </StyledEllipsisCaptionContainer>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <DeviationTitleForm
              deviationTitle={state.deviationTitle}
              deviationDescription={state.deviationDescription}
              observeDate={state.observeDate}
              deviationPriority={state.deviationPriority}
              deviationStatus={state.deviationStatus}
              isObserveDatePickerOpen={state.isObserveDatePickerOpen}
              handleDeviationTitleChange={handleDeviationTitleChange}
              handleDeviationDescriptionChange={handleDeviationDescriptionChange}
              handleObserveDateChange={handleObserveDateChange}
              handleOpenObserveDatePicker={handleOpenObserveDatePicker}
              handleCloseObserveDatePicker={handleCloseObserveDatePicker}
              handleDeviationPriorityChange={handleDeviationPriorityChange}
              handleDeviationStatusChange={handleDeviationStatusChange}
              disabledConditions={disabledConditions}
              deviationType={state.deviationType.title}
              handleDeviationTypeChange={handleDeviationTypeChange}
            />
          </StyledExpansionPanelDetails>
          {tabletMatch && !props.isViewDeviationCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveDeviation()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(
        deviationExpansionsTypes.BUILDING_AND_DETAILS,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === deviationExpansionsTypes.BUILDING_AND_DETAILS}
          onChange={props.handleExpansionChange(deviationExpansionsTypes.BUILDING_AND_DETAILS)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === deviationExpansionsTypes.BUILDING_AND_DETAILS ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Bygningsdel</Typography>
                </StyledFlexBox>
                <StyledEllipsisCaptionContainer item>
                  <StyledEllipsisCaption variant="h6" color="textSecondary">
                    {state.buildingPartInput.title || 'Uten bygningsdel'}
                  </StyledEllipsisCaption>
                </StyledEllipsisCaptionContainer>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>

          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <DeviationBuildingForm
              buildingPartInput={state.buildingPartInput}
              handleBuildingPartInputChange={handleBuildingPartInputChange}
              deviationLocation={state.deviationLocation}
              handleDeviationLocationChange={handleDeviationLocationChange}
              isViewDeviationCondition={props.isViewDeviationCondition}
              disabledConditions={disabledConditions}
            />
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewDeviationCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveDeviation()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(
        deviationExpansionsTypes.ATTACHMENTS,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === deviationExpansionsTypes.ATTACHMENTS}
          onChange={props.handleExpansionChange(deviationExpansionsTypes.ATTACHMENTS)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === 'attachments' ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Opplastninger</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {mediaLength}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails padding={!tabletMatch && '8px 8px 24px'} isEdit={props.isEditMode}>
            {state.isMediaPanelOpen ? (
              <AttachmentMediaPanel
                onCloseMediaPanel={handleCloseMediaPanel}
                selectedMedia={state.selectedMedia}
                handleSaveAnnotations={handleSaveAnnotations}
                annotations={currentAnnotationEvent.annotation_points || []}
                imageName={currentImageData?.artifact_name || ''}
              />
            ) : (
              <ChildrenAttachmentSection
                onOpenMediaPanel={handleOpenMediaPanel}
                media={state.media}
                annotationEvents={state.annotationEvents}
                handleAddNewImage={handleAddNewImage}
                disabledConditions={disabledConditions}
                handleDeleteImage={handleDeleteImage}
                imagesData={state.imagesData}
                attachmentsData={state.attachmentsData}
                attachments={state.attachments}
                videosData={state.videosData}
                videos={state.videos}
                handleAddNewAttachment={handleAddNewAttachment}
                handleDeleteFile={handleDeleteFile}
                handleAddNewVideo={handleAddNewVideo}
                handleDeleteVideo={handleDeleteVideo}
              />
            )}
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewDeviationCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveDeviation()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(
        deviationExpansionsTypes.BACKGROUND_INFORMATION,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === deviationExpansionsTypes.BACKGROUND_INFORMATION}
          onChange={props.handleExpansionChange(deviationExpansionsTypes.BACKGROUND_INFORMATION)}
          square
          color={theme.palette.secondary.darkWhite}
          elevation={tabletMatch ? 1 : 0}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === deviationExpansionsTypes.BACKGROUND_INFORMATION ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Bakgrunnsinformasjon</Typography>
                </StyledFlexBox>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>

          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <DeviationBackgroundForm
              deviationReason={state.deviationReason}
              handleDeviationReasonChange={handleDeviationReasonChange}
              occureDate={state.occureDate}
              isOccureDatePickerOpen={state.isOccureDatePickerOpen}
              handleOccureDateChange={handleOccureDateChange}
              handleOpenOccureDatePicker={handleOpenOccureDatePicker}
              handleCloseOccureDatePicker={handleCloseOccureDatePicker}
              backgroundShowStatus={state.backgroundShowStatus}
              handleBackgroundShowStatusChange={handleBackgroundShowStatusChange}
              disabledConditions={disabledConditions}
            />
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewDeviationCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel
                onSave={() => handleSaveDeviation(true)}
                withoutBack
                isLastStep={true}
                saveLabel="Lagre og avslutt"
              />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}
    </>
  );
};

const StyledFlexBox = styled(props => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`;
const StyledEllipsisCaption = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const StyledEllipsisCaptionContainer = styled(Grid)`
  &.MuiGrid-root {
    width: 75vw;
  }
  @media (max-width: 767px) {
    &.MuiGrid-root {
      width: 60vw;
    }
  }
  @media (max-width: 420px) {
    &.MuiGrid-root {
      width: 45vw;
    }
  }
`;

export default DeviationExpansionPanel;
