import React, { useCallback, useMemo, useEffect, useReducer, useState } from 'react';
import update from 'immutability-helper';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Grid, Typography, useMediaQuery, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

// helpers
import { getExpansionPanelMobileRule } from 'helpers/getExpansionPanelMobileRule';

// constants
import { childrenExpansionsTypes } from '../../../../constants';

import {
  postAnnotationEvent,
  fetchBuildingEvent,
  uploadImage,
  postImage,
  fetchReportImagesData,
  fetchAttachmentsData,
  fetchVideosData,
  uploadFile,
  postFile,
  uploadVideo,
  postVideo,
  getBaseUrl,
} from 'services/fetchData';

// types
import { BuildingType } from 'types/Building/BuildingType';
import { ConditionAssessmentModelType } from 'types/Building/ConditionAssessmentModelType';

// reducer
import { reducer, ActionTypes } from './reducer';

import {
  StyledExpansionPanel,
  StyledExpansionPanelActions,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
} from '../../shared/ExpansionPanel/ExpansionPanel';
import ChildrenPurposeForm from '../ChildrenPurposeForm/ChildrenPurposeForm';
import ChildrenAssessmentForm from '../ChildrenAssessmentForm/ChildrenAssessmentForm';
import ChildrenAttachmentSection from '../../sections/ChildrenAttachmentSection';
import SaveOrCancel from '../../components/SaveOrCancel/SaveOrCancel';
import AttachmentMediaPanel from '../AttachmentMediaPanel/AttachmentMediaPanel';
import ChildrenSummaryForm from '../ChildrenSummaryForm/ChildrenSummaryForm';
import ProgressBottomButton from '../../shared/ProgressBottomButton/ProgressBottomButton';
import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';

interface Props {
  handleReturnBackToInfo: () => void;
  handleAddNewConditionReport: (newReport: any, isFirstSave?: boolean) => void;
  handleUpdateConditionReport: (updatedReport: any, isLastSaved?: boolean) => void;
  editedReport: any;
  isViewReportCondition: boolean;
  reportModel: ConditionAssessmentModelType;
  building: BuildingType;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  handleNextExpansion: () => void;
  handlePrevExpansion: () => void;
  handleExpansionChange: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  expanded: string | false;
  currentStepNumber: number;
  stepsAmount: number;
  currentExpansionIndex: number;
  handleEditSaveTrigger: (status: boolean) => void;
  editSaveTrigger: boolean;
  isEditDocumentChanged: boolean;
  handleEditDocumentChanged: (status: boolean) => void;
}

interface Reason {
  id: string;
  title: string;
}

const initialState = {
  conditionTitle: '',
  reason: {
    title: 'Innmeldt avvik',
    id: 'ct_50-10',
  },
  description: '',
  isFromDatePickerOpen: false,
  fromSelectedDate: new Date(Date.now()),
  buildingParts: [],
  buildingPartInput: { title: '', id: '' },
  isEditPanelOpen: false,
  media: [],
  isMediaPanelOpen: false,
  selectedMedia: null,
  annotationEvents: [],
  summaryDescription: '',
  conditionMethod: '',
  conditionClass: { title: '', id: '' },
  isFirstSaved: false,
  imagesData: [],
  attachments: [],
  attachmentsData: [],
  videos: [],
  videosData: [],
  isEditChanged: false,
};

const ChildrenExpansionPanel = (props: Props) => {
  const [purposes, setPurposes] = useState();

  useEffect(() => {
    try {
      fetch(`${getBaseUrl()}/search/json`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filters: {
            '-marked': true,
            'filters.adm_event_type_id': 'ct_49-13',
          },
          query: 'object_type:ct_50',
          rows: 1000,
          start: 0,
          sort: 'updated_at desc',
        }),
      }).then(response => {
        if (!response.ok) throw new Error('failed to fetch causes of evaluation');
        response.json().then(data => {
          setPurposes(
            data.artifacts.map((d: any) => {
              const o = {
                id: d['artifact_id'],
                title: d['artifact_name'] + (typeof d['authority'] !== 'undefined' ? ' (' + d['authority'] + ')' : ''),
              } as Reason;
              return o;
            }),
          );
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const theme = useTheme();
  const tabletMatch = useMediaQuery('(min-width: 768px)');

  // STATE
  const [state, dispatch] = useReducer(reducer, initialState);

  // purpose form handlers
  const handleConditionTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: { conditionTitle: event.target.value as string },
    });
  };
  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = purposes.find((option: { id: string; title: string }) => {
      return option.id === event.target.value;
    });
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { reason: option } });
  };
  const handleDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: { description: event.target.value as string },
    });
  };
  const handleFromDateChange = (date: Date | null) => {
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { fromSelectedDate: date } });
  };
  const handleOpenFromDatePicker = () => {
    if (disabledConditions) return;
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isFromDatePickerOpen: true } });
  };
  const handleCloseFromDatePicker = () => {
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isFromDatePickerOpen: false } });
  };

  // assessment form handlers
  const handleBuildingPartInputChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { buildingPartInput: option } });
  };
  const handleEditDragItem = (newItem: any) => {
    const itemsWithNewItem = state.buildingParts.map(item => {
      // IF NEW REPORT
      if (!newItem.bpca_id || newItem.bpca_id === null) {
        return item.uuid === newItem.uuid ? newItem : item;
      }
      // IF REPORT HAS ARTIFACT_ID PROPERTY
      if (newItem.bpca_id && newItem.bpca_id !== null) {
        return item.bpca_id === newItem.bpca_id ? newItem : item;
      }
      // SAFE IF ISSUE
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { buildingParts: itemsWithNewItem } });
  };
  const handleAddDragItem = (newItem: any) => {
    const itemsWithNewItem = [...state.buildingParts, newItem];
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { buildingParts: itemsWithNewItem } });
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { buildingPartInput: { title: '', id: '' } } });
  };
  const handleDeleteDragItem = (id: string) => {
    const deletedItemIndex = state.buildingParts.findIndex(item => item.bpca_id === id || item.uuid === id);

    const newBuildingParts = state.buildingParts.map((item, idx) => {
      if (idx === deletedItemIndex) {
        return { ...item, _destroy: true };
      }
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { buildingParts: newBuildingParts } });
  };
  const handleOpenEditPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isEditPanelOpen: true } });
  };
  const handleCloseEditPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isEditPanelOpen: false } });
  };
  const handleToggleEditPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isEditPanelOpen: !state.isEditPanelOpen } });
  };

  // assessment drag and drop
  const moveDragItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = state.buildingParts[dragIndex];
      dispatch({
        type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
        payload: {
          buildingParts: update(state.buildingParts, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        },
      });
    },
    [state.buildingParts],
  );

  // media handlers
  const handleSaveAnnotations = async (newAnnotations: any[]) => {
    if (state.annotationEvents.some((annot: any) => annot.image_id === state.selectedMedia.image_id)) {
      // FETCH
      const updated = {
        ...state.annotationEvents.find((annot: any) => annot.image_id === state.selectedMedia.image_id),
        annotation_points: [...newAnnotations],
      };
      await postAnnotationEvent(updated);
      //
      const currentReport = await fetchBuildingEvent(props.editedReport.artifact_id);

      dispatch({
        type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
        payload: {
          annotationEvents: currentReport.annotation_events,
        },
      });
      return;
    }

    // IF IT IS NEW ANNOTATION EVENT
    const newAnnotationEvent = {
      model_title: null,
      deletable: { deletable_type: 'bool', is_deletable: true, field: null, comparator: null, value: null },
      artifact_name_upper: null,
      checksum: null,
      artifact_id: null,
      artifact_name: null,
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_by_id: null,
      updated_by_id_value: null,
      thumbnail_id: null,
      object_type: 'AnnotationEvent',
      meta_type: 'adm_event',
      uuid: null,
      identifier: null,
      m_path: null,
      rights_type_id: null,
      attachments: [],
      authority: null,
      publish: null,
      is_restricted: null,
      event_sub_type: null,
      has_annotation_events: null,
      restriction_type: 1,
      artifact_type_id: 'ct_200-221',
      artifact_type_id_value: 'Markering',
      object_type_id: 'AE_21',
      contexts: [
        {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'AdmEventContextItem',
          meta_type: 'sub_model',
          context_id: props.editedReport?.artifact_id || null,
          revision_id: null,
          no: null,
          order_number: null,
          context_object_type: null,
          context_artifact_type_id: null,
          context_identifier: null,
          context_collection_id: null,
          context_artifact_id: props.building.artifact_id,
          context_artifact_id_value: null,
          from_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: Date.now(),
            dd_error_message: null,
          },
          to_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          remarks: null,
          _create: true,
        },
      ],
      parent_id: props.editedReport?.artifact_id || null,
      adm_event_type_id: 'ct_49-21',
      adm_event_type_id_value: 'Annotering',
      image_id: state.selectedMedia.image_id,
      annotation_points: [...newAnnotations],
      timespan: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'TimespanEvent',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        from_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: Date.now(),
          dd_error_message: null,
        },
        to_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: null,
          dd_error_message: null,
        },
        timespan_event_description: null,
      },
      collection_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      created_by_id: null,
      created_by_id_value: null,
      comments: [],
      _create: true,
    };

    await postAnnotationEvent(newAnnotationEvent);
    //
    const currentReport = await fetchBuildingEvent(props.editedReport.artifact_id);

    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: {
        annotationEvents: currentReport.annotation_events,
      },
    });
  };
  const handleOpenMediaPanel = (itemId: string) => {
    const selectedImage = state.media.find((img: any) => img.image_id === itemId);
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { selectedMedia: selectedImage } });
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isMediaPanelOpen: true } });
  };
  const handleCloseMediaPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { selectedMedia: null } });
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isMediaPanelOpen: false } });
  };
  // images
  const handleAddNewImage = async (imageData: any) => {
    const formData = new FormData();
    formData.append('file', imageData);

    const uploadImageData = await uploadImage(formData);
    const newImage = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'ImageItem',
      meta_type: 'sub_model',
      context_id: props.editedReport?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.media.length,
      image_id: uploadImageData.artifact_id,
      code: uploadImageData.code,
      code_value: uploadImageData.code_value,
      motif_object: null,
      _create: true,
    };
    await postImage(newImage);

    const currentReport = await fetchBuildingEvent(props.editedReport.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { media: currentReport.images } });
  };
  const handleDeleteImage = (id: string) => {
    const updatedMedia = state.media.map(image => {
      if (image.image_id === id) {
        return { ...image, _destroy: true };
      }
      return image;
    });
    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: {
        media: updatedMedia,
      },
    });
  };
  // attachments
  const handleAddNewAttachment = async (attachmentData: any) => {
    const formData = new FormData();
    formData.append('file', attachmentData);

    const uploadAttachmentData = await uploadFile(formData);
    const newAttachment = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'AttachmentItem',
      meta_type: 'sub_model',
      context_id: props.editedReport?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.attachments.length,
      context_object_type: null,
      context_meta_type: null,
      artifact_id: null,
      created_by_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      artifact_name: null,
      object_type_id: 'ATTACHMENT_ITEM',
      artifact_type_id: null,
      artifact_type_id_value: null,
      _destroy: null,
      uuid: null,
      attachment_id: uploadAttachmentData.artifact_id,
      attachment_id_value: null,
      _create: true,
    };
    await postFile(newAttachment);

    const currentReport = await fetchBuildingEvent(props.editedReport.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { attachments: currentReport.attachments } });
  };

  const handleDeleteFile = (id: string) => {
    // await deleteObjectById(id);
    // const currentReport = await fetchBuildingEvent(props.editedReport.artifact_id);
    // dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { attachments: currentReport.attachments } });

    const updatedAttachments = state.attachments.map(file => {
      if (file.attachment_id === id) {
        return { ...file, _destroy: true };
      }
      return file;
    });
    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: {
        attachments: updatedAttachments,
      },
    });
  };
  // videos
  const handleAddNewVideo = async (videoData: any) => {
    const formData = new FormData();
    formData.append('file', videoData);

    const uploadVideoData = await uploadVideo(formData);
    const newVideo = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'VideoItem',
      meta_type: 'sub_model',
      context_id: props.editedReport?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.videos.length,
      video_id: uploadVideoData.artifact_id,
      _create: true,
    };
    await postVideo(newVideo);

    const currentReport = await fetchBuildingEvent(props.editedReport.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { videos: currentReport.videos } });
  };

  const handleDeleteVideo = (id: string) => {
    const updatedVideos = state.videos.map(video => {
      if (video.video_id === id) {
        return { ...video, _destroy: true };
      }
      return video;
    });
    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: {
        videos: updatedVideos,
      },
    });
  };

  // summary handlers
  const handleSummaryDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: { summaryDescription: event.target.value as string },
    });
  };
  const handleConditionMethodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_CONDITION_PANEL_STATE,
      payload: { conditionMethod: event.target.value as string },
    });
  };
  const handleStateClassChange = (value: string, options: any[]) => {
    const option = options.find(option => option.title === value) || { title: '', id: '' };
    dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { conditionClass: option } });
  };

  // HANDLE SAVE REPORT
  const handleSaveReport = async (isLastSaved = false) => {
    // IT IS NO MATTER WHICH SAVE OPTION CHANGE YET, IT IS THE SAME SAVE IN EACH OPTION YET
    if (props.isEditMode || state.isFirstSaved) {
      const updatedReport = {
        ...props.reportModel,
        ...props.editedReport,
        purpose: {
          ...props.editedReport.purpose,
          model_title: null,
          artifact_name_upper: null,
          purpose_description: state.description === '' ? null : state.description,
          purpose_type_id: state.reason.id || null,
          purpose_type_id_value: state.reason.title || null,
        },
        timespan: {
          ...props.editedReport.timespan,
          model_title: null,
          artifact_name_upper: null,
          from_date: {
            ...props.editedReport.timespan.from_date,
            model_title: null,
            artifact_name_upper: null,
            dd_date: Number(format(state.fromSelectedDate as Date, 'T')),
          },
          // to_date: {
          //   ...props.editedReport.timespan.to_date,
          //   model_title: null,
          //   artifact_name_upper: null,
          //   dd_date: state.toSelectedDate !== null ? Number(format(state.toSelectedDate as Date, 'T')) : null,
          // },
        },
        condition: {
          ...props.editedReport.condition,
          model_title: null,
          artifact_name_upper: null,
          measure_class_type_id: state.conditionClass.id || null,
          measure_class_type_id_value: state.conditionClass.title || null,
          condition_description: state.summaryDescription || null,
          method: state.conditionMethod || null,
        },
        title: {
          ...props.editedReport.title,
          title: state.conditionTitle || null,
        },
        building_parts_condition_assessments: [...state.buildingParts],
        images: [...state.media],
        annotation_events: [...state.annotationEvents],
        attachments: [...state.attachments],
        videos: [...state.videos],
        _create: true,
      };

      // delete unnecessary fields
      if (updatedReport.hasOwnProperty('$$acl')) delete updatedReport.$$acl;
      if (updatedReport.hasOwnProperty('$$context_name')) delete updatedReport.$$context_name;

      await props.handleUpdateConditionReport(updatedReport, !props.isEditMode ? isLastSaved : false);
      dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isEditChanged: false } });

      if (!isLastSaved) {
        props.handleNextExpansion();
      }
    }

    if (!props.isEditMode && !state.isFirstSaved) {
      // CREATE NEW REPORT TEMPLATE
      const newReport = {
        ...props.reportModel,
        artifact_name: null,
        parent_id: props.building.artifact_id,
        uuid: null,
        contexts: [
          {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            object_type: 'AdmEventContextItem',
            meta_type: 'sub_model',
            context_id: null,
            revision_id: null,
            no: null,
            order_number: null,
            context_object_type: null,
            context_artifact_type_id: null,
            context_identifier: null,
            context_collection_id: null,
            context_artifact_id: props.building.artifact_id,
            context_artifact_id_value: props.building.artifact_name,
            from_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: 'datetime',
              dd_date: Number(format(state.fromSelectedDate as Date, 'T')),
              dd_error_message: null,
            },
            to_date: {
              model_title: null,
              artifact_name_upper: null,
              checksum: null,
              meta_type: 'sub_model',
              object_type: 'PrecisionDate',
              dd_error: null,
              dd_precision: 'datetime',
              dd_date: null,
              dd_error_message: null,
            },
            remarks: null,
            _create: true,
          },
        ],
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        title: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'AdmTitle',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          title: state.conditionTitle || null,
        },
        purpose: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'BuildingPurposeConditionAssessment',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          purpose_description: state.description === '' ? null : state.description,
          purpose_type_id: state.reason.id || null,
          purpose_type_id_value: state.reason.title || null,
        },
        timespan: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'TimespanConservation',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          from_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: Number(format(state.fromSelectedDate as Date, 'T')),
            dd_error_message: null,
          },
          to_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          timespan_conservation_desc: null,
        },
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        condition: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'BuildingCondition',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          method: state.conditionMethod || null,
          measure_class_type_id: state.conditionClass.id || null,
          measure_class_type_id_value: state.conditionClass.title || null,
          condition_description: state.summaryDescription || null,
        },
        recommendation: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'Recommendation',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          recommendation_description: null,
        },
        building_parts_condition_assessments: [...state.buildingParts],
        images: [...state.media],
        annotation_events: [...state.annotationEvents],
        attachments: [...state.attachments],
        videos: [...state.videos],
        _create: true,
      };

      // POST REPORT AND RETURN TO INFO
      await props.handleAddNewConditionReport(newReport);

      if (!state.isFirstSaved) {
        props.handleNextExpansion();
      }

      dispatch({ type: ActionTypes.UPDATE_CONDITION_PANEL_STATE, payload: { isFirstSaved: true } });
      //
    }
  };

  useEffect(() => {
    if (!props.editSaveTrigger) return;
    handleSaveReport(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editSaveTrigger]);

  // SETTING FORMS STATES IF EDIT REPORT
  useEffect(() => {
    dispatch({
      type: ActionTypes.PREPARE_EDITED,
      payload: {
        conditionTitle: props.editedReport?.title?.title ?? '',
        reason: {
          title: props.editedReport?.purpose?.purpose_type_id_value ?? state.reason.title,
          id: props.editedReport?.purpose?.purpose_type_id ?? state.reason.id,
        },
        description: props.editedReport?.purpose?.purpose_description ?? '',
        fromSelectedDate:
          (props.editedReport?.timespan?.from_date?.dd_date &&
            new Date(props.editedReport.timespan.from_date.dd_date)) ??
          new Date(Date.now()),
        buildingParts: props.editedReport?.building_parts_condition_assessments ?? [],
        buildingPartInput: { title: '', id: '' },
        media: props.editedReport?.images || [],
        selectedMedia: null,
        annotationEvents: props.editedReport?.annotation_events || [],
        summaryDescription: props.editedReport?.condition?.condition_description ?? '',
        conditionMethod: props.editedReport?.condition?.method ?? '',
        conditionClass: {
          title: props.editedReport?.condition?.measure_class_type_id_value ?? '',
          id: props.editedReport?.condition?.measure_class_type_id ?? '',
        },
        attachments: props.editedReport?.attachments || [],
        videos: props.editedReport?.videos || [],
      },
    });
  }, [props.isViewReportCondition, props.editedReport]);

  // TRIGGER CHANGED DOCUMENT
  useEffect(() => {
    if (!state.isEditChanged) {
      props.handleEditDocumentChanged(false);
      return;
    }

    props.handleEditDocumentChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isEditChanged]);

  // FETCH IMAGES DATA
  useEffect(() => {
    if (state.media.length === 0) return;
    const imagesIds = state.media.reduce((acc: string[], { image_id }: { image_id: string }) => [...acc, image_id], []);
    (async () => {
      const imagesData = await fetchReportImagesData(imagesIds);
      dispatch({ type: ActionTypes.PREPARE_EDITED, payload: { imagesData: imagesData.artifacts } });
    })();
    return;
  }, [state.media]);

  // FETCH ATTACHMENTS DATA
  useEffect(() => {
    if (state.attachments.length === 0) return;
    const attachmentsIds = state.attachments.reduce(
      (acc: string[], { attachment_id }: { attachment_id: string }) => [...acc, attachment_id],
      [],
    );

    (async () => {
      const attachmentsData = await fetchAttachmentsData(attachmentsIds);

      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { attachmentsData: attachmentsData.artifacts },
      });
    })();
    return;
  }, [state.attachments]);

  // FETCH VIDEOS DATA
  useEffect(() => {
    if (state.videos.length === 0) return;
    const videosIds = state.videos.reduce(
      (acc: string[], { video_id }: { video_id: string }) => [...acc, video_id],
      [],
    );

    (async () => {
      const videosData = await fetchVideosData(videosIds);

      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { videosData: videosData.artifacts },
      });
    })();
    return;
  }, [state.videos]);

  const currentAnnotationEvent = useMemo(
    () =>
      state.annotationEvents.find(
        (annot: any) => state.selectedMedia && annot.image_id === state.selectedMedia.image_id,
      ) || {},
    [state.annotationEvents, state.selectedMedia],
  );

  const currentImageData = useMemo(
    () => state.imagesData.find(img => img.artifact_id === state.selectedMedia?.image_id),
    [state.imagesData, state.selectedMedia],
  );

  const disabledConditions = props.isViewReportCondition && !props.isEditMode;
  const mediaLength = useMemo(
    () => [...state.media, ...state.videos, ...state.attachments].filter(img => !img._destroy).length,
    [state.attachments, state.media, state.videos],
  );

  return (
    <>
      {!tabletMatch && !props.isViewReportCondition && !state.isEditPanelOpen && !state.isMediaPanelOpen && (
        <ProgressBottomButton
          onSave={() => handleSaveReport(true)}
          currentStepNumber={props.currentStepNumber}
          stepsAmount={props.stepsAmount}
          onNext={() => handleSaveReport()}
          onBack={props.handlePrevExpansion}
          expanded={props.expanded}
        />
      )}
      {!tabletMatch && props.isViewReportCondition && (
        <BottomStatusBar
          date={props.editedReport?.updated_at.dd_date ?? 0}
          status="Publisert"
          isEditMode={props.isEditMode}
          handleEditMode={props.handleEditMode}
          handleEditSaveTrigger={props.handleEditSaveTrigger}
          isEditDocumentChanged={props.isEditDocumentChanged}
        />
      )}

      {getExpansionPanelMobileRule(
        childrenExpansionsTypes.CAUSE_AND_PURPOSE,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === childrenExpansionsTypes.CAUSE_AND_PURPOSE}
          onChange={props.handleExpansionChange(childrenExpansionsTypes.CAUSE_AND_PURPOSE)}
          square
          color={theme.palette.secondary.darkWhite}
          overflow={state.isMediaPanelOpen && 'hidden'}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === childrenExpansionsTypes.CAUSE_AND_PURPOSE ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Tilstandsvurdering</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {state.reason.title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>

          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <ChildrenPurposeForm
              purposes={purposes}
              conditionTitle={state.conditionTitle}
              reason={state.reason.id}
              description={state.description}
              isFromDatePickerOpen={state.isFromDatePickerOpen}
              fromSelectedDate={state.fromSelectedDate}
              handleConditionTitleChange={handleConditionTitleChange}
              handleReasonChange={handleReasonChange}
              handleDescriptionChange={handleDescriptionChange}
              handleFromDateChange={handleFromDateChange}
              handleOpenFromDatePicker={handleOpenFromDatePicker}
              handleCloseFromDatePicker={handleCloseFromDatePicker}
              disabledConditions={disabledConditions}
            />
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewReportCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveReport()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(
        childrenExpansionsTypes.ASSESSMENT,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === childrenExpansionsTypes.ASSESSMENT}
          onChange={props.handleExpansionChange(childrenExpansionsTypes.ASSESSMENT)}
          square
          color={theme.palette.secondary.darkWhite}
          overflow={state.isMediaPanelOpen && 'hidden'}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === childrenExpansionsTypes.ASSESSMENT ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Vurdering og tiltak</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {state.buildingParts.filter(item => item._destroy !== true).length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>

          <StyledExpansionPanelDetails>
            <ChildrenAssessmentForm
              buildingParts={state.buildingParts}
              handleEdit={handleEditDragItem}
              moveDragItem={moveDragItem}
              handleAddDragItem={handleAddDragItem}
              buildingPartInput={state.buildingPartInput}
              handleChange={handleBuildingPartInputChange}
              handleDeleteDragItem={handleDeleteDragItem}
              isViewReportCondition={props.isViewReportCondition}
              isEditPanelOpen={state.isEditPanelOpen}
              handleCloseEditPanel={handleCloseEditPanel}
              handleToggleEditPanel={handleToggleEditPanel}
              disabledConditions={disabledConditions}
              isEditMode={props.isEditMode}
            />
          </StyledExpansionPanelDetails>

          {!state.isEditPanelOpen && (
            <StyledExpansionPanelActions isEdit={props.isEditMode}>
              {props.isViewReportCondition && !props.isEditMode ? null : (
                <OutlineButton padding="16px 30px" onClick={handleOpenEditPanel}>
                  <StyledBox>
                    <AddIcon style={{ marginRight: 4 }} />
                  </StyledBox>
                  Legg til bygningsdel
                </OutlineButton>
              )}

              {tabletMatch && !props.isViewReportCondition && (
                <SaveOrCancel onSave={() => handleSaveReport()} withoutBack />
              )}
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(
        childrenExpansionsTypes.ATTACHMENTS,
        tabletMatch,
        props.expanded,
        props.isEditMode,
      ) && (
        <StyledExpansionPanel
          expanded={props.expanded === childrenExpansionsTypes.ATTACHMENTS}
          onChange={props.handleExpansionChange(childrenExpansionsTypes.ATTACHMENTS)}
          square
          color={theme.palette.secondary.darkWhite}
          overflow={state.isMediaPanelOpen && 'hidden'}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === childrenExpansionsTypes.ATTACHMENTS ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Opplastninger</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {mediaLength}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails padding={!tabletMatch && '8px 8px 24px'} isEdit={props.isEditMode}>
            {state.isMediaPanelOpen ? (
              <AttachmentMediaPanel
                onCloseMediaPanel={handleCloseMediaPanel}
                selectedMedia={state.selectedMedia}
                handleSaveAnnotations={handleSaveAnnotations}
                annotations={currentAnnotationEvent.annotation_points || []}
                imageName={currentImageData?.artifact_name || ''}
              />
            ) : (
              <ChildrenAttachmentSection
                onOpenMediaPanel={handleOpenMediaPanel}
                media={state.media}
                annotationEvents={state.annotationEvents}
                handleAddNewImage={handleAddNewImage}
                disabledConditions={disabledConditions}
                handleDeleteImage={handleDeleteImage}
                imagesData={state.imagesData}
                attachmentsData={state.attachmentsData}
                attachments={state.attachments}
                videosData={state.videosData}
                videos={state.videos}
                handleAddNewAttachment={handleAddNewAttachment}
                handleDeleteFile={handleDeleteFile}
                handleAddNewVideo={handleAddNewVideo}
                handleDeleteVideo={handleDeleteVideo}
              />
            )}
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewReportCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveReport()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(childrenExpansionsTypes.SUMMARY, tabletMatch, props.expanded, props.isEditMode) && (
        <StyledExpansionPanel
          expanded={props.expanded === childrenExpansionsTypes.SUMMARY}
          onChange={props.handleExpansionChange(childrenExpansionsTypes.SUMMARY)}
          square
          color={theme.palette.secondary.darkWhite}
          overflow={state.isMediaPanelOpen && 'hidden'}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === childrenExpansionsTypes.SUMMARY ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Oppsummering</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {state.conditionClass.title.substr(0, 3)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <ChildrenSummaryForm
              description={state.summaryDescription}
              comments={state.conditionMethod}
              stateClass={state.conditionClass}
              handleDescriptionChange={handleSummaryDescriptionChange}
              handleCommentsChange={handleConditionMethodChange}
              handleStateClassChange={handleStateClassChange}
              disabledConditions={disabledConditions}
            />
          </StyledExpansionPanelDetails>
          {tabletMatch && !props.isViewReportCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel
                onSave={() => handleSaveReport(true)}
                withoutBack
                isLastStep={true}
                saveLabel="Lagre og avslutt"
              />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}
    </>
  );
};

ChildrenExpansionPanel.defaultProps = {
  building: {
    artifact_id: '',
  },
};

const StyledFlexBox = styled(props => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ChildrenExpansionPanel;
