import React, { useRef } from 'react';
import styled from 'styled-components';
import { Grid, Paper } from '@material-ui/core';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import { ItemTypes } from '../../../../../constants';

import DragItem from '../../../shared/DragItem/DragItem';

interface Props {
  title: string;
  index: number;
  id: string;
  handleEdit?: () => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDeleteDrag: () => void;
  disabledConditions: boolean;
  conditionLevel: string;
  actionsAmount: number;
  consequenceLevel: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const AssessmentDragItem = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: ItemTypes.ASSESSMENT_DRAG,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ASSESSMENT_DRAG, id: props.id, index: props.index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid item style={{ opacity, cursor: 'move', marginBottom: 1 }} ref={ref}>
      <StyledPaper square elevation={2}>
        <DragItem
          title={props.title}
          handleEdit={props.handleEdit}
          onDeleteDrag={props.onDeleteDrag}
          disabled={props.disabledConditions}
          conditionLevel={props.conditionLevel}
          actionsAmount={props.actionsAmount}
          consequenceLevel={props.consequenceLevel}
        />
      </StyledPaper>
    </Grid>
  );
};

const StyledPaper = styled(props => <Paper {...props} />)`
  &.MuiPaper-root {
    min-height: 52px;
    display: flex;
    align-items: center;
  }
  padding: 15px 12px;
`;

export default AssessmentDragItem;
