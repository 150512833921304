export const deviationStatusMock = [
  { value: 'Ny', id: 'ct_53-1801' },
  { value: 'Under arbeid', id: 'ct_53-1802' },
  { value: 'Avsluttet', id: 'ct_53-1803' },
];

export const taskStatusMock = [
  { value: 'Ny', id: 'ct_53-1901' },
  { value: 'Under arbeid', id: 'ct_53-1902' },
  { value: 'Avsluttet', id: 'ct_53-1903' },
  { value: 'Planlagt', id: 'ct_53-1904' },
];
