import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, Box, Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import OutlineButton from '../../shared/OutlineButton/OutlineButton';

interface Props {
  onSave?: () => void;
  onBackClick?: () => void;
  outlineButtonTextColor?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  withoutBack?: boolean;
}

const SecondarySaveOrCancel: React.FC<Props> = props => {
  return (
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <OutlineButton
          padding="16px 30px 16px 24px"
          style={{ whiteSpace: 'nowrap' }}
          onClick={props.onSave}
          color={props.outlineButtonTextColor || null}
        >
          <StyledBox>{props.icon ? <props.icon /> : <AddIcon style={{ marginRight: 8 }} />}</StyledBox>
          Lagre og lukk
        </OutlineButton>
      </Grid>
      {!props.withoutBack && (
        <Grid item container alignItems="center">
          <StyledButton onClick={props.onBackClick || (() => null)}>
            <StyledTypography component="span">Forkast endringer</StyledTypography>
          </StyledButton>
        </Grid>
      )}
    </Grid>
  );
};

const StyledTypography = styled(({ color, fontWeight, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    font-size: 0.875rem;
  }
`;

const StyledButton = styled(({ textColor, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    margin-top: -2px;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: ${props => props.textColor};
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SecondarySaveOrCancel;
