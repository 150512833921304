import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Box, Typography, Divider, Paper, ListItem, List, useMediaQuery } from '@material-ui/core';
import MeasureClassLabel from 'components/MeasureClassLabel/MeasureClassLabel';
import Gallery from '../../sections/Gallery';
import { format } from 'date-fns';
import AddIcon from '@material-ui/icons/Add';

import { BuildingType } from 'types/Building/BuildingType';
import { DELETE_MODAL_TEXT } from '../../../../constants';
import ObservationTaskList from '../ObservationTaskList/ObservationTaskList';
import PrimaryButton from '../../shared/PrimaryButton/PrimaryButton';

import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import Slider from '../Slider/Slider';
import Modal from '../../shared/Modal/Modal';

interface Props {
  condition: any;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  tasks: any[];
  handleOpenEditTask: (id: string, linkedObjectId?: string) => void;
  handleDeleteTask: (id: string) => void;
  handleOpenNewTask: (linkedObjectId?: string) => void;
  building: BuildingType;
}

const ViewCondition: React.FC<Props> = ({ condition, isEditMode, handleEditMode, tasks, ...props }) => {
  const tabletMatch = useMediaQuery('(min-width: 768px)');
  const mobileMarginBottom = !tabletMatch ? '56px' : '0';

  const [isSliderOpen, setSliderOpen] = useState(false);
  const [imageViewedId, setImageViewedId] = useState('');

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState<string | null>(null);

  const handleOpenSlider = () => {
    setSliderOpen(true);
  };
  const handleCloseSlider = () => {
    setImageViewedId('');
    setSliderOpen(false);
  };
  const handleImageClick = (id: string) => {
    setImageViewedId(id);
    handleOpenSlider();
  };
  const handleOpenDeleteModal = (id: string) => {
    setDeletedId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeletedId(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteDocument = () => {
    props.handleDeleteTask(deletedId as string);
    handleCloseDeleteModal();
    setDeletedId(null);
  };

  return (
    <StyledWrapper isSliderOpen={isSliderOpen}>
      {/* TITLE SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Tilstandsvurdering</Typography>
          </Grid>
          {condition?.title?.title && (
            <Grid item>
              <StyledCaption color="textSecondary">Tittel</StyledCaption>
              <Typography variant="h6">{condition?.title?.title}</Typography>
            </Grid>
          )}
          {condition?.purpose?.purpose_type_id_value && (
            <Grid item>
              <StyledCaption color="textSecondary">Årsak til vurdering</StyledCaption>
              <Typography variant="h6">{condition?.purpose?.purpose_type_id_value}</Typography>
            </Grid>
          )}
          {condition?.purpose?.purpose_description && (
            <Grid item>
              <StyledCaption color="textSecondary">Beskrivelse av formål</StyledCaption>
              <Typography variant="h6">{condition?.purpose?.purpose_description}</Typography>
            </Grid>
          )}
          {condition?.timespan?.from_date?.dd_date && (
            <Grid item>
              <StyledCaption color="textSecondary">Dato utført</StyledCaption>
              <Typography variant="h6">
                {format(condition?.timespan.from_date.dd_date || Date.now(), 'dd.MM.yyyy')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </StyledSection>
      <Divider />
      {/* BUILDING PARTS SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Vurdering og tiltak</Typography>
          </Grid>
          {/* PAPERS BUILDING PARTS */}
          {condition?.building_parts_condition_assessments.map((part: any) => (
            <Grid key={part.bpca_id || part.uuid} item>
              <StyledBuildingPart square>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <StyledCaption color="textSecondary">Bygningsdel</StyledCaption>
                    <Typography variant="h6">
                      {part.building_part_type?.building_part_type_id_value || 'Uten bygningsdel'}
                    </Typography>
                  </Grid>
                  {part.condition?.condition_description && (
                    <Grid item>
                      <StyledCaption color="textSecondary">Beskrivelse av bygningsdel</StyledCaption>
                      <Typography variant="h6">{part.condition?.condition_description}</Typography>
                    </Grid>
                  )}
                  {part.condition?.bp_condition_type_id_value && (
                    <Grid item>
                      <StyledFlexBox align="center">
                        <StyledMeasureWrap>
                          <MeasureClassLabel label={part.condition?.bp_condition_type_id_value.substr(0, 3)} />
                        </StyledMeasureWrap>
                        <Box>
                          <StyledCaption color="textSecondary">Tilstandsgrad</StyledCaption>
                          <Typography variant="h6">{part.condition?.bp_condition_type_id_value}</Typography>
                        </Box>
                      </StyledFlexBox>
                    </Grid>
                  )}
                  {part.condition?.consequence_type_id_value && (
                    <Grid item>
                      <StyledFlexBox align="center">
                        <StyledMeasureWrap>
                          <MeasureClassLabel label={part.condition?.consequence_type_id_value.substr(0, 3)} />
                        </StyledMeasureWrap>
                        <Box>
                          <StyledCaption color="textSecondary">Konsekvensgrad</StyledCaption>
                          <Typography variant="h6">{part.condition?.consequence_type_id_value}</Typography>
                        </Box>
                      </StyledFlexBox>
                    </Grid>
                  )}
                  {part?.treatment_suggestions && part?.treatment_suggestions.length > 0 && (
                    <>
                      <Grid item>
                        <StyledCaption color="textSecondary">{`${part?.treatment_suggestions.length} anbefalte tiltak`}</StyledCaption>
                      </Grid>
                      <Grid item>
                        <List disablePadding>
                          {part?.treatment_suggestions.map((action: any, idx: number, arr: any[]) => (
                            <StyledListItem key={action.uuid || action.no} divider={idx !== arr.length - 1}>
                              <StyledTypography
                                component="span"
                                fontSize="16px"
                                fontWeight="700"
                                style={{ paddingRight: 14 }}
                              >
                                {action?.description || 'Uten beskrivelse'}
                              </StyledTypography>
                              <Typography component="span" color="textSecondary">
                                {`${action.cost || 0} kr ― ${action.time_spent || 0} timer`}
                              </Typography>
                            </StyledListItem>
                          ))}
                        </List>
                      </Grid>
                    </>
                  )}
                </Grid>
              </StyledBuildingPart>
            </Grid>
          ))}
        </Grid>
      </StyledSection>
      <Divider />
      {/* MEDIA SECTION */}
      <StyledSection>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Opplastninger</Typography>
          </Grid>
        </Grid>
        <Gallery
          images={condition?.images || []}
          firstPadding="0"
          lastPadding="0"
          handleImageClick={handleImageClick}
          attachments={condition?.attachments || []}
          videos={condition?.videos || []}
        />
      </StyledSection>
      <Divider />
      {/* SUMMARY SECTION */}
      <StyledSection marginBottom={mobileMarginBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Oppsummering</Typography>
          </Grid>
          {condition?.condition?.measure_class_type_id_value && (
            <Grid item>
              <StyledFlexBox align="center">
                <StyledMeasureWrap>
                  <MeasureClassLabel label={condition?.condition?.measure_class_type_id_value?.substr(0, 3)} />
                </StyledMeasureWrap>
                <Box>
                  <StyledCaption color="textSecondary">Tilstandsklasse</StyledCaption>
                  <Typography variant="h6">{condition?.condition?.measure_class_type_id_value}</Typography>
                </Box>
              </StyledFlexBox>
            </Grid>
          )}
          {condition?.condition?.condition_description && (
            <Grid item>
              <StyledCaption color="textSecondary">Beskrivelse av tilstand</StyledCaption>
              <Typography variant="h6">{condition?.condition?.condition_description}</Typography>
            </Grid>
          )}
          {condition?.condition?.method && (
            <Grid item>
              <StyledCaption color="textSecondary">Method for tilstandvurdering</StyledCaption>
              <Typography variant="h6">{condition?.condition?.method}</Typography>
            </Grid>
          )}
        </Grid>
      </StyledSection>
      {/* OPPGAVER SECTION */}
      <StyledSection marginBottom={mobileMarginBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Oppgaver</Typography>
          </Grid>
          <Grid item>
            <ObservationTaskList
              taskEvents={tasks}
              handleOpenEditTask={props.handleOpenEditTask}
              handleEditMode={handleEditMode}
              buildingCollectionId={props.building.collection_id}
              buildingAcl={props.building.$$acl}
              handleOpenDeleteModal={handleOpenDeleteModal}
              linkedId={condition?.artifact_id || condition?.uuid}
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              label="Legg til oppgaver"
              icon={<AddIcon />}
              onClick={() => props.handleOpenNewTask(condition?.artifact_id || condition?.uuid)}
            />
          </Grid>
        </Grid>
      </StyledSection>

      {!tabletMatch && (
        <BottomStatusBar
          date={condition?.updated_at.dd_date ?? 0}
          status="Publisert"
          isEditMode={isEditMode}
          handleEditMode={handleEditMode}
        />
      )}
      {isSliderOpen && (
        <Slider
          images={condition?.images || []}
          handleClose={handleCloseSlider}
          imageViewedId={imageViewedId}
          annotations={condition?.annotation_events || []}
          videos={condition?.videos || []}
        />
      )}
      <Modal
        modalText={DELETE_MODAL_TEXT}
        handleClose={handleCloseDeleteModal}
        open={deleteModalOpen}
        handleConfirmClick={handleDeleteDocument}
      />
    </StyledWrapper>
  );
};

const StyledSection = styled(({ overflow, marginBottom, ...props }) => <Box {...props} />)`
  padding: 16px 16px 34px;
  background-color: #f5f5f5;
  ${props => props.overflow && `overflow: ${props.overflow}`};
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
`;
const StyledCaption = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    font-weight: 700;
  }
`;
const StyledBuildingPart = styled(Paper)`
  &.MuiPaper-root {
    padding: 16px;
  }
`;
const StyledFlexBox = styled(({ direction, align, justify, ...props }) => <Box {...props} />)`
  display: flex;
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.align && `align-items: ${props.align}`};
  ${props => props.justify && `justify-content: ${props.justify}`};
`;
const StyledMeasureWrap = styled(Box)`
  margin-right: 24px;
`;
const StyledListItem = styled(props => <ListItem {...props} />)`
  &.MuiListItem-root {
    padding: 16px 8px;
  }

  &:first-child {
    padding-top: 0px;
  }
`;
const StyledTypography = styled(({ color, fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
  }
`;
const StyledWrapper = styled(({ isSliderOpen, ...props }) => <Box {...props} />)`
  ${props =>
    props.isSliderOpen &&
    `
    height: 90vh;
    overflow: hidden;
  `}
`;

export default ViewCondition;
