import React, { useMemo } from 'react';
import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { getDocumentRoutingName } from 'helpers/getDocumentRoutingName';

import { LAST_UPDATED_LIMIT } from '../../../../constants';

import HomeEventListItem from 'components/HomeEventListItem/HomeEventListItem';

interface Props {
  lastUpdatedObjects: any[];
}

const LastUpdatedList = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const sortedByDate = useMemo(
    () => [...props.lastUpdatedObjects].sort((a, b) => b.updated_at.dd_date - a.updated_at.dd_date),
    [props.lastUpdatedObjects],
  );

  // SORTED BY DATE WITH LAST UPDATED LIMIT AND THEN SORT BY TYPE
  const sortedByDateWithLimit = useMemo(() => sortedByDate.slice(0, LAST_UPDATED_LIMIT), [sortedByDate]);

  const sortedByType = useMemo(
    () =>
      [...sortedByDateWithLimit].sort((a, b) =>
        a.artifact_type_id_value > b.artifact_type_id_value
          ? 1
          : b.artifact_type_id_value > a.artifact_type_id_value
          ? -1
          : 0,
      ),
    [sortedByDateWithLimit],
  );
  return (
    <Box>
      <Grid container spacing={mediaMatch ? 1 : 0}>
        {sortedByType.map((object, idx, arr) => {
          const marginCondition =
            !mediaMatch && arr[idx + 1] && arr[idx + 1].artifact_type_id_value !== object.artifact_type_id_value
              ? { marginBottom: 12 }
              : {};
          const pathLink = getDocumentRoutingName(object.artifact_type_id_value)
            ? `/building/${object['contexts.context_artifact_id'][0]}/${getDocumentRoutingName(
                object.artifact_type_id_value,
              )}/${object.artifact_id || object.uuid}`
            : '/';

          return (
            <Grid item xs={12} key={object.artifact_id || object.uuid} style={marginCondition}>
              <Link
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                to={{
                  pathname: pathLink,
                  state: { fromMain: true },
                }}
              >
                <HomeEventListItem object={object} />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default LastUpdatedList;
