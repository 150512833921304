import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery, FormHelperText } from '@material-ui/core';

// mocks
// import buildingPartsMock from '../../mocks/buildingPartsMock.json';
import { getBaseUrl } from 'services/fetchData';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import AsyncFormInput from '../../shared/AsyncFormInput/AsyncFormInput';

interface Props {
  buildingPartInput: { title: string; id: string };
  handleBuildingPartInputChange: (value: string, options: any[]) => void;
  deviationLocation: string;
  handleDeviationLocationChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  isViewDeviationCondition: boolean;
  disabledConditions: boolean;
}

const DeviationBuildingForm = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');

  // BUILDING PART OPTIONS STATE
  const [buildingPartOptionsOpen, setBuildingPartOptionsOpen] = useState(false);
  const [buildingPartOptions, setBuildingPartOptions] = useState<any[]>([]);
  const buildingPartLoading = buildingPartOptionsOpen && buildingPartOptions.length === 0;

  // BUILDING PART OPTIONS FETCHING AND SET
  useEffect(() => {
    let active = true;
    if (!buildingPartLoading) {
      return undefined;
    }
    (async () => {
      try {
        // LEVEL 2 BUILDING PARTS FETCH
        const level2Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 2,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level2Response.ok) throw new Error('something went wrong!');
        const level2Options = await level2Response.json();

        // LEVEL 3 BUILDING PARTS FETCH
        const level3Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 3,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level3Response.ok) throw new Error('something went wrong!');
        const level3Options = await level3Response.json();
        const options = [...level2Options.artifacts, ...level3Options.artifacts].sort(
          (prev, next) => Number(prev.code) - Number(next.code),
        );

        if (active) {
          setBuildingPartOptions(options.map((option: any) => ({ title: option.artifact_name, id: option.id })));
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [buildingPartLoading]);

  // SETTING BUILDING PARTS AUTOCOMPLETES OPEN STATUS
  const setBuildingPartOpenStatus = (status: boolean) => {
    setBuildingPartOptionsOpen(status);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <AsyncFormInput
          value={props.buildingPartInput.title}
          handleChange={props.handleBuildingPartInputChange}
          options={buildingPartOptions}
          placeholder="Søk og velg bygningsdel"
          label="Bygningsdel"
          loading={buildingPartLoading}
          open={buildingPartOptionsOpen}
          setOpenStatus={setBuildingPartOpenStatus}
          regular={mediaMatch ? false : true}
          arrowDown={true}
          disabled={props.disabledConditions}
        />
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.deviationLocation}
          handleChange={props.handleDeviationLocationChange}
          disabled={props.disabledConditions}
          label="Posisjon"
        />
        <FormHelperText>Hvor på bygningen er avviket?</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default DeviationBuildingForm;
