const load = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);

    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    console.error('Get state error: ', err);
  }
};

const save = (key: string, value: any) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error('Set state error: ', err);
  }
};

const remove = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.log('Remove state error: ', err);
  }
};

const sessionLoad = (key: string) => {
  try {
    const serializedState = sessionStorage.getItem(key);

    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    console.error('Get state error: ', err);
  }
};

const sessionSave = (key: string, value: any) => {
  try {
    const serializedState = JSON.stringify(value);
    sessionStorage.setItem(key, serializedState);
  } catch (err) {
    console.error('Set state error: ', err);
  }
};

const sessionRemove = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (err) {
    console.log('Remove state error: ', err);
  }
};

export default { load, save, remove, sessionLoad, sessionSave, sessionRemove };
