export enum ActionTypes {
  UPDATE_WORK_PANEL_STATE = 'UPDATE_WORK_PANEL_STATE',
  RESET_WORK_STATE = 'RESET_WORK_STATE',
  PREPARE_EDITED = 'PREPARE_EDITED',
}

export type Action = {
  type: ActionTypes;
  payload?: Partial<State>;
};

export interface State {
  workTitle: string;
  description: string;
  isFromDatePickerOpen: boolean;
  fromSelectedDate: Date | null;
  toSelectedDate: Date | null;
  responsibleActors: any[];
  isActorsPanelOpen: boolean;
  buildingPart: { title: string; id: string };
  cost: number | null;
  timings: number | null;
  material: string;
  comments: any[];
  isCommentsPanelOpen: boolean;
  media: any[];
  isMediaPanelOpen: boolean;
  selectedMedia: any | null;
  annotationEvents: any[];
  isFirstSaved: boolean;
  imagesData: any[];
  attachments: any[];
  attachmentsData: any[];
  videos: any[];
  videosData: any[];
  isEditChanged: boolean;
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_WORK_PANEL_STATE:
      const isEditChanged = action.payload?.isEditChanged === false ? (action.payload?.isEditChanged as boolean) : true;
      return {
        ...state,
        ...action.payload,
        isEditChanged,
      };
    case ActionTypes.PREPARE_EDITED:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.RESET_WORK_STATE:
      return {
        ...state,
        workTitle: '',
        description: '',
        fromSelectedDate: null,
        toSelectedDate: null,
        responsibleActors: [],
        buildingPart: { title: '', id: '' },
        cost: null,
        timings: null,
        material: '',
        comments: [],
        isFirstSaved: false,
        imagesData: [],
        attachments: [],
        attachmentsData: [],
        videos: [],
        videosData: [],
        isEditChanged: false,
      };

    default:
      return state;
  }
};
