import React, { createRef } from 'react';
import styled from 'styled-components';
import {
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Highlighter from 'react-highlight-words';

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';

// helpers
import searchHistory from 'helpers/searchHistory';
import { SEARCH_HISTORY_LIMIT } from '../../constants';

interface Props {
  handleSearchClose: () => void;
  handleSearchInput: (value: string) => void;
  handleSearchSubmit: (event: React.BaseSyntheticEvent<Event, EventTarget & Element, EventTarget>) => void;
  handleSearchBySuggested: (searchQuery: string) => void;
  searchInputValue: string;
  searchQuery?: string;
  suggestedSearch: string[];
}

const SearchSection = (props: Props) => {
  const theme = useTheme();
  const searchInputRef = createRef<HTMLInputElement>();

  const handleClearInputValue = () => {
    props.handleSearchInput('');

    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  let searchedHistory = searchHistory.getHistory();

  if (searchedHistory.length > SEARCH_HISTORY_LIMIT) {
    searchedHistory = searchedHistory.slice(0, SEARCH_HISTORY_LIMIT);
  }

  return (
    <StyledSearchWrap elevation={2}>
      <StyledAppBar position="static" color={theme.palette.secondary.white} elevation={0}>
        <StyledToolbar>
          <IconButton
            edge="start"
            color={props.searchInputValue ? 'inherit' : 'default'}
            aria-label="back"
            onClick={props.handleSearchClose}
          >
            <ArrowBackIcon />
          </IconButton>
          <StyledForm onSubmit={props.handleSearchSubmit}>
            <StyledInputBase
              defaultValue={props.searchInputValue}
              onChange={e => props.handleSearchInput(e.target.value)}
              placeholder="Search Primus FDV"
              inputProps={{ 'aria-label': 'search primus fdv' }}
              autoFocus
              inputRef={searchInputRef}
            />
          </StyledForm>
          <IconButton edge="end" onClick={handleClearInputValue}>
            <CloseIcon />
          </IconButton>
        </StyledToolbar>
        <Divider />
      </StyledAppBar>
      <StyledListsWrap>
        {props.searchInputValue && props.suggestedSearch.length > 0 && (
          <StyledList component="section" style={{ width: '100%' }}>
            {props.suggestedSearch.map(option => (
              <StyledListItem
                style={{ paddingRight: 0 }}
                key={option}
                button
                disableGutters
                onClick={() => props.handleSearchBySuggested(option)}
              >
                <StyledListItemIcon>
                  <SearchIcon />
                </StyledListItemIcon>
                <ListItemText
                  primary={
                    <Highlighter
                      textToHighlight={option}
                      searchWords={[props.searchInputValue]}
                      className="suggested"
                      highlightClassName="highlight-suggested"
                    />
                  }
                />
                <Box style={{ color: '#666666', alignItems: 'flex-end' }}>
                  <ChevronRightIcon style={{ display: 'block' }} />
                </Box>
              </StyledListItem>
            ))}
          </StyledList>
        )}

        {!props.searchInputValue && searchedHistory && searchedHistory.length > 0 && (
          <>
            <StyledHistoryList component="section">
              {searchedHistory.map(option => (
                <StyledListItem
                  key={option}
                  button
                  disableGutters
                  onClick={() => props.handleSearchBySuggested(option)}
                >
                  <StyledListItemIcon>
                    <SearchIcon />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={<StyledTypography component="span" fontSize="0.9rem">{`«${option}»`}</StyledTypography>}
                  />
                </StyledListItem>
              ))}
            </StyledHistoryList>
            {/* <Divider /> */}
          </>
        )}
      </StyledListsWrap>
    </StyledSearchWrap>
  );
};

const StyledAppBar = styled(({ color, ...props }) => <AppBar {...props} />)`
  &.MuiAppBar-root {
    background-color: ${props => props.color};
    color: #000000;
    height: 56px;

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
      height: 72px;
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  height: 56px;
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }
  &.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  @media (min-width: 706px) {
    height: 72px;
  }
`;
const StyledSearchWrap = styled(props => <Paper {...props} />)`
  position: fixed;
  top: 0;
  width: 100vw;

  z-index: 1102;
  &.MuiPaper-root {
    border-radius: 0;
    overflow-y: scroll;
  }
  @media (max-width: 705px) {
    position: fixed;
    height: 100vh;
  }
  @media (min-width: 706px) {
    max-height: 60vh;
  }
`;
const StyledInputBase = styled(InputBase)`
  &.MuiInputBase-root {
    width: 100%;
    margin-left: 4px;
    @media (min-width: 706px) {
      margin-left: 12px;
    }
  }
  & .MuiInputBase-input {
    padding: 6px 0;
  }
`;
const StyledListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 0;
    padding-right: 16px;
  }
`;
const StyledList = styled(props => <List {...props} />)`
  &.MuiList-padding {
    padding-top: 20px;
    padding-bottom: 20px;
    @media (min-width: 706px) {
      padding-right: 8%;
    }
  }
  &.MuiList-padding:last-child {
    @media (min-width: 706px) {
      padding-right: 0;
    }
  }
`;
const StyledHistoryList = styled(props => <List {...props} />)`
  @media (min-width: 706px) {
    &.MuiList-root {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 150px;
    }
  }
`;

const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    border-radius: 4px;
    @media (min-width: 706px) {
      padding-right: 2rem;
    }
  }
  &.MuiListItem-button:hover {
    background-color: transparent;
  }
  .suggested {
    font-size: 0.9rem;
    color: #9e9e9e;
  }
  .highlight-suggested {
    background: none;
    color: rgba(0, 0, 0, 0.87);
  }
`;
const StyledListsWrap = styled(Box)`
  padding: 0 16px;
  @media (min-width: 706px) {
    display: flex;
    justify-content: flex-start;
    padding-left: 32px;
    padding-right: 32px;
    overflow-x: auto;
  }
  @media (max-width: 705px) {
    padding: 0 6px 0 16px;
  }
`;
const StyledTypography = styled(({ fontSize, firstLetter, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    font-size: ${props => props.fontSize};

    &::first-letter {
      text-transform: ${props => (props.firstLetter ? 'uppercase' : 'none')};
    }
  }
`;
const StyledForm = styled.form`
  flex: 1;
`;

SearchSection.defaultProps = {
  suggestedSearch: [],
};

export default SearchSection;
