import React from 'react';
import styled from 'styled-components';
import {
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Box,
  Typography,
  Slide,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

// icons
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

// mocks
import sortOptionsMock from '../../mocks/sortOptionsMock';

interface Props {
  isSortOpen: boolean;
  handleCloseSort: () => void;
  enabledSorting: string;
  handleChangeSorting: (newSorting: string) => void;
}

const SortSection = (props: Props) => {
  return (
    <Slide in={props.isSortOpen} direction="down">
      <StyledSectionWrap>
        <StyledAppBar position="static" color="transparent" elevation={0}>
          <StyledToolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={props.handleCloseSort}
              style={{ marginRight: 20 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5">Choose sorting</Typography>
          </StyledToolbar>
          <Divider />
        </StyledAppBar>
        <StyledOptionsSection>
          <List>
            {sortOptionsMock.map(option => (
              <StyledListItem key={option} button onClick={() => props.handleChangeSorting(option)}>
                <StyledListItemIcon>
                  <StyledIconWrap display={option === props.enabledSorting ? 'block' : 'none'}>
                    <CheckIcon />
                  </StyledIconWrap>
                </StyledListItemIcon>
                <ListItemText primary={<StyledTypography color="#3e3e3e">{option}</StyledTypography>} />
              </StyledListItem>
            ))}
          </List>
        </StyledOptionsSection>
      </StyledSectionWrap>
    </Slide>
  );
};

const StyledSectionWrap = styled.div`
  width: 100vw;
  min-height: 92vh;
  background-color: transparent;
  @media (min-width: 706px) {
    min-height: 130px;
  }
`;

const StyledAppBar = styled(({ color, ...props }) => <AppBar {...props} />)`
  &.MuiAppBar-root {
    background-color: ${props => props.color};
    height: 56px;

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
      height: 72px;
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  height: 56px;
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }
  &.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  @media (min-width: 706px) {
    height: 72px;
  }
`;
const StyledTypography = styled(({ color, fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    margin-left: 6px;
  }
  &.MuiTypography-caption {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
  }
`;
const StyledOptionsSection = styled(Box)`
  padding: 16px 0 0;
`;
const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    border-radius: 4px;
  }
`;
const StyledListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 32px;
  }
`;
const StyledIconWrap = styled(({ display, ...props }) => <Box {...props} />)`
  display: ${props => props.display};
`;

export default SortSection;
