import React from 'react';
import styled from 'styled-components';
import { Typography, Box } from '@material-ui/core';

interface Props {
  label: string;
}

const MeasureClassLabel = ({ label = 'N/A' }: Props) => (
  <StyledLabelBox>
    <StyledTypography fontSize="0.65rem">{label}</StyledTypography>
  </StyledLabelBox>
);

const StyledLabelBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 24px;
  border: 1px solid #999999;
  border-radius: 1px;
`;

const StyledTypography = styled(({ color, fontSize, fontWeight, fontFamily, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    font-size: ${props => props.fontSize};
    font-family: 'Akkurat Mono';
  }
`;

export default MeasureClassLabel;
