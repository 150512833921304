import React, { useMemo, useEffect, useCallback, useReducer } from 'react';
import update from 'immutability-helper';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Grid, Typography, useMediaQuery, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { RouteChildrenProps } from 'react-router-dom';
import queryString from 'query-string';
// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

// helpers
import { getExpansionPanelMobileRule } from 'helpers/getExpansionPanelMobileRule';

// types
import { BuildingType } from 'types/Building/BuildingType';

// constants
import { taskExpansionsTypes, documentTypes } from '../../../../constants';

import {
  postAnnotationEvent,
  fetchBuildingEvent,
  uploadImage,
  postImage,
  fetchReportImagesData,
  fetchAttachmentsData,
  fetchVideosData,
  uploadFile,
  postFile,
  uploadVideo,
  postVideo,
} from 'services/fetchData';

// reducer
import { reducer, ActionTypes } from './reducer';

// mocks
import { priorityMock } from '../../mocks/priorityMock';
import { taskStatusMock } from '../../mocks/statusesMock';

import {
  StyledExpansionPanel,
  StyledExpansionPanelActions,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
} from '../../shared/ExpansionPanel/ExpansionPanel';
import ProgressBottomButton from '../../shared/ProgressBottomButton/ProgressBottomButton';
import SaveOrCancel from '../../components/SaveOrCancel/SaveOrCancel';
import TaskTitleForm from '../TaskTitleForm/TaskTitleForm';
import TaskChecklistForm from '../TaskChecklistForm/TaskChecklistForm';
import ChildrenAttachmentSection from '../../sections/ChildrenAttachmentSection';
import AttachmentMediaPanel from '../AttachmentMediaPanel/AttachmentMediaPanel';
import BottomStatusBar from '../../shared/BottomStatusBar/BottomStatusBar';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';
import ViewTask from '../ViewTask/ViewTask';
import { PriorityListItem } from 'services/PrioritiesList';

const TASK_VIEW_MODE_PATH = '/building/:id/task/:taskId';

const getLinkedNameById = (id: string, events: any[]) => {
  const currentDocument = events.find(item => item.artifact_id === id || item.uuid === id);
  return currentDocument?.artifact_name || '';
};

interface Props extends RouteChildrenProps {
  handleReturnBackToInfo: () => void;
  taskModel: any;
  building: BuildingType;
  handleAddNewTask: (newTask: any, isFirstSave?: boolean) => void;
  handleUpdateTask: (updatedTask: any, isLastSaved?: boolean) => void;
  editedTask: any;
  isViewTaskCondition: boolean;
  isEditMode: boolean;
  handleEditMode: (status: boolean) => void;
  handleNextExpansion: () => void;
  handlePrevExpansion: () => void;
  handleExpansionChange: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  expanded: string | false;
  currentStepNumber: number;
  stepsAmount: number;
  currentExpansionIndex: number;
  buildingEvents: any[];
  handleEditSaveTrigger: (status: boolean) => void;
  editSaveTrigger: boolean;
  isEditDocumentChanged: boolean;
  handleEditDocumentChanged: (status: boolean) => void;
}

const initialState = {
  taskTitle: '',
  taskDescription: '',
  taskPriority: {
    title: priorityMock[2].title,
    value: priorityMock[2].value,
    id: priorityMock[2].id,
  },
  taskStatus: taskStatusMock[0].value,
  startDate: new Date(Date.now()),
  doneDate: null,
  deadlineDate: null,
  isStartDatePickerOpen: false,
  isDoneDatePickerOpen: false,
  isDeadlineDatePickerOpen: false,
  responsibleActors: [],
  isActorsPanelOpen: false,
  media: [],
  isMediaPanelOpen: false,
  selectedMedia: null,
  annotationEvents: [],
  checklist: [],
  isChecklistPanelOpen: false,
  isFirstSaved: false,
  imagesData: [],
  attachments: [],
  attachmentsData: [],
  videos: [],
  videosData: [],
  isEditChanged: false,
};

export const TaskExpansionPanel: React.FC<Props> = props => {
  const theme = useTheme();
  const tabletMatch = useMediaQuery('(min-width: 768px)');

  // STATE
  const [state, dispatch] = useReducer(reducer, initialState);

  // TITLE FORM HANDLERS
  const handleTaskTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: { taskTitle: event.target.value as string },
    });
  };
  const handleTaskDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: { taskDescription: event.target.value as string },
    });
  };
  const handleTaskPriorityChange = (priority: PriorityListItem) => {
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: {
        taskPriority: {
          title: priority.title,
          id: priority.id,
          value: priority.value,
        },
      },
    });
  };
  const getInitialPriorityState = (prioryObj: { priority_type_id_value: string; priority_type_id: string }) => {
    const value = (prioryObj && prioryObj.priority_type_id_value) || priorityMock[2].value;
    const id = (prioryObj && prioryObj.priority_type_id) || priorityMock[2].id;
    const title =
      (prioryObj && priorityMock.find(option => option.value.indexOf(prioryObj.priority_type_id_value) > -1)?.title) ||
      priorityMock[2].title;
    return { title, value, id };
  };
  const handleTaskStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: { taskStatus: (event.target as HTMLInputElement).value },
    });
  };
  const handleStartDateChange = (date: Date | null) => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { startDate: date } });
  };
  const handleOpenStartDatePicker = () => {
    if (disabledConditions) return;
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isStartDatePickerOpen: true } });
  };
  const handleCloseStartDatePicker = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isStartDatePickerOpen: false } });
  };
  const handleDoneDateChange = (date: Date | null) => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { doneDate: date } });
  };
  const handleOpenDoneDatePicker = () => {
    if (disabledConditions) return;
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isDoneDatePickerOpen: true } });
  };
  const handleCloseDoneDatePicker = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isDoneDatePickerOpen: false } });
  };
  const handleDeadlineDateChange = (date: Date | null) => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { deadlineDate: date } });
  };
  const handleOpenDeadlineDatePicker = () => {
    if (disabledConditions) return;
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isDeadlineDatePickerOpen: true } });
  };
  const handleCloseDeadlineDatePicker = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isDeadlineDatePickerOpen: false } });
  };
  const handleEditDragItem = (newItem: any) => {
    const itemsWithNewItem = state.responsibleActors.map(item => {
      if (newItem.no && newItem.no !== null) {
        return item.no === newItem.no ? newItem : item;
      }
      // SAFE IF ISSUE
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { responsibleActors: itemsWithNewItem } });
  };
  const handleAddDragItem = (newItem: any) => {
    const itemsWithNewItem = [...state.responsibleActors, newItem];
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { responsibleActors: itemsWithNewItem } });
  };
  const handleDeleteDragItem = (id: string) => {
    // const deletedItemIndex = state.responsibleActors.findIndex(item => item.no === id || item.actor_id === id);
    // const newResponsibleActors = state.responsibleActors.map((item, idx) => {
    //   if (idx === deletedItemIndex) {
    //     return { ...item, _destroy: true };
    //   }
    //   return item;
    // });
    // dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { responsibleActors: newResponsibleActors } });
  };
  const handleOpenActorsPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isActorsPanelOpen: true } });
  };
  const handleCloseActorsPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isActorsPanelOpen: false } });
  };
  const handleToggleActorsPanel = () => {
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: { isActorsPanelOpen: !state.isActorsPanelOpen },
    });
  };
  // work title drag and drop
  const moveDragItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = state.responsibleActors[dragIndex];
      dispatch({
        type: ActionTypes.UPDATE_TASK_PANEL_STATE,
        payload: {
          responsibleActors: update(state.responsibleActors, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        },
      });
    },
    [state.responsibleActors],
  );

  // CHECKLIST HANDLERS
  const handleEditChecklistItem = (newItem: any) => {
    const itemsWithNewItem = state.checklist.map(item => {
      if (newItem.uuid && newItem.uuid !== null) {
        return item.uuid === newItem.uuid ? newItem : item;
      }
      if (newItem.no && newItem.no !== null) {
        return item.no === newItem.no ? newItem : item;
      }
      // SAFE IF ISSUE
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { checklist: itemsWithNewItem } });
  };
  const handleAddChecklistItem = (newItem: any) => {
    const itemsWithNewItem = [...state.checklist, newItem];
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { checklist: itemsWithNewItem } });
  };
  const handleDeleteChecklistItem = (id: string) => {
    const deletedItemIndex = state.checklist.findIndex(item => item.uuid === id || item.no === id);
    // const newItems = state.checklist.filter((item, idx) => idx !== deletedItemIndex);
    const newItems = state.checklist.map((item, idx) => {
      if (idx === deletedItemIndex) {
        return { ...item, _destroy: true };
      }
      return item;
    });
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { checklist: newItems } });
  };
  const handleOpenChecklistPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isChecklistPanelOpen: true } });
  };
  const handleCloseChecklistPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isChecklistPanelOpen: false } });
  };
  const handleToggleChecklistPanel = () => {
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: { isChecklistPanelOpen: !state.isChecklistPanelOpen },
    });
  };
  const handleUpChecklistItem = (id: string) => {
    const currentItemIndex = state.checklist.findIndex(item => item.uuid === id || item.no === id);

    const updatedChecklist = state.checklist
      .map((item, idx, arr) => {
        if (idx === currentItemIndex - 1) {
          return arr[idx + 1];
        }
        if (idx === currentItemIndex) {
          return arr[idx - 1];
        }

        return item;
      })
      .map((item, idx) => ({ ...item, order_number: idx }));

    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { checklist: updatedChecklist } });
  };
  const handleDownChecklistItem = (id: string) => {
    const currentItemIndex = state.checklist.findIndex(item => item.uuid === id || item.no === id);

    const updatedChecklist = state.checklist
      .map((item, idx, arr) => {
        if (idx === currentItemIndex + 1) {
          return arr[idx - 1];
        }
        if (idx === currentItemIndex) {
          return arr[idx + 1];
        }

        return item;
      })
      .map((item, idx) => ({ ...item, order_number: idx }));

    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { checklist: updatedChecklist } });
  };
  const handleChecklistItemCompletedToggle = (id: string) => {
    const updatedChecklist = state.checklist.map(item => {
      if (item?.uuid === id || item?.no === id) {
        return { ...item, completed: !item.completed };
      }
      return item;
    });

    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { checklist: updatedChecklist } });
  };

  // ATTACHMENT HANDLERS
  const handleSaveAnnotations = async (newAnnotations: any[]) => {
    if (state.annotationEvents.some((annot: any) => annot.image_id === state.selectedMedia.image_id)) {
      // FETCH
      const updated = {
        ...state.annotationEvents.find((annot: any) => annot.image_id === state.selectedMedia.image_id),
        annotation_points: [...newAnnotations],
      };
      await postAnnotationEvent(updated);
      //
      const currentTask = await fetchBuildingEvent(props.editedTask.artifact_id);
      dispatch({
        type: ActionTypes.UPDATE_TASK_PANEL_STATE,
        payload: { annotationEvents: currentTask.annotation_events },
      });
      return;
    }

    // IF IT IS NEW ANNOTATION EVENT
    const newAnnotationEvent = {
      model_title: null,
      deletable: { deletable_type: 'bool', is_deletable: true, field: null, comparator: null, value: null },
      artifact_name_upper: null,
      checksum: null,
      artifact_id: null,
      artifact_name: null,
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_by_id: null,
      updated_by_id_value: null,
      thumbnail_id: null,
      object_type: 'AnnotationEvent',
      meta_type: 'adm_event',
      uuid: null,
      identifier: null,
      m_path: null,
      rights_type_id: null,
      attachments: [],
      authority: null,
      publish: null,
      is_restricted: null,
      event_sub_type: null,
      has_annotation_events: null,
      restriction_type: 1,
      artifact_type_id: 'ct_200-221',
      artifact_type_id_value: 'Markering',
      object_type_id: 'AE_21',
      contexts: [
        {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'AdmEventContextItem',
          meta_type: 'sub_model',
          context_id: props.editedTask?.artifact_id || null,
          revision_id: null,
          no: null,
          order_number: null,
          context_object_type: null,
          context_artifact_type_id: null,
          context_identifier: null,
          context_collection_id: null,
          context_artifact_id: props.building.artifact_id,
          context_artifact_id_value: null,
          from_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: Date.now(),
            dd_error_message: null,
          },
          to_date: {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            meta_type: 'sub_model',
            object_type: 'PrecisionDate',
            dd_error: null,
            dd_precision: 'datetime',
            dd_date: null,
            dd_error_message: null,
          },
          remarks: null,
          _create: true,
        },
      ],
      parent_id: props.editedTask?.artifact_id || null,
      adm_event_type_id: 'ct_49-21',
      adm_event_type_id_value: 'Annotering',
      image_id: state.selectedMedia.image_id,
      annotation_points: [...newAnnotations],
      timespan: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'TimespanEvent',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        from_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: Date.now(),
          dd_error_message: null,
        },
        to_date: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'full',
          dd_date: null,
          dd_error_message: null,
        },
        timespan_event_description: null,
      },
      collection_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      created_by_id: null,
      created_by_id_value: null,
      comments: [],
      _create: true,
    };
    await postAnnotationEvent(newAnnotationEvent);
    //
    const currentTask = await fetchBuildingEvent(props.editedTask.artifact_id);
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: {
        annotationEvents: currentTask.annotation_events,
      },
    });
  };
  const handleOpenMediaPanel = (itemId: string) => {
    const selectedImage = state.media.find((img: any) => img.image_id === itemId);
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { selectedMedia: selectedImage } });
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isMediaPanelOpen: true } });
  };
  const handleCloseMediaPanel = () => {
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { selectedMedia: null } });
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isMediaPanelOpen: false } });
  };
  const handleAddNewImage = async (imageData: any) => {
    const formData = new FormData();
    formData.append('file', imageData);

    const uploadImageData = await uploadImage(formData);
    const newImage = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'ImageItem',
      meta_type: 'sub_model',
      context_id: props.editedTask?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.media.length,
      image_id: uploadImageData.artifact_id,
      code: uploadImageData.code,
      code_value: uploadImageData.code_value,
      motif_object: null,
      _create: true,
    };
    await postImage(newImage);

    const currentReport = await fetchBuildingEvent(props.editedTask.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { media: currentReport.images } });
  };
  const handleDeleteImage = (id: string) => {
    const updatedMedia = state.media.map(image => {
      if (image.image_id === id) {
        return { ...image, _destroy: true };
      }
      return image;
    });
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: { media: updatedMedia },
    });
  };

  // attachments
  const handleAddNewAttachment = async (attachmentData: any) => {
    const formData = new FormData();
    formData.append('file', attachmentData);

    const uploadAttachmentData = await uploadFile(formData);
    const newAttachment = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'AttachmentItem',
      meta_type: 'sub_model',
      context_id: props.editedTask?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.attachments.length,
      context_object_type: null,
      context_meta_type: null,
      artifact_id: null,
      created_by_id: null,
      created_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      updated_at: {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        meta_type: 'sub_model',
        object_type: 'PrecisionDate',
        dd_error: null,
        dd_precision: 'datetime',
        dd_date: null,
        dd_error_message: null,
      },
      artifact_name: null,
      object_type_id: 'ATTACHMENT_ITEM',
      artifact_type_id: null,
      artifact_type_id_value: null,
      _destroy: null,
      uuid: null,
      attachment_id: uploadAttachmentData.artifact_id,
      attachment_id_value: null,
      _create: true,
    };
    await postFile(newAttachment);

    const currentReport = await fetchBuildingEvent(props.editedTask.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { attachments: currentReport.attachments } });
  };

  const handleDeleteFile = (id: string) => {
    const updatedAttachments = state.attachments.map(file => {
      if (file.attachment_id === id) {
        return { ...file, _destroy: true };
      }
      return file;
    });
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: {
        attachments: updatedAttachments,
      },
    });
  };
  // videos
  const handleAddNewVideo = async (videoData: any) => {
    const formData = new FormData();
    formData.append('file', videoData);

    const uploadVideoData = await uploadVideo(formData);
    const newVideo = {
      model_title: null,
      deletable: {
        deletable_type: 'has_parent',
        is_deletable: null,
        field: null,
        comparator: null,
        value: null,
      },
      artifact_name_upper: null,
      checksum: null,
      object_type: 'VideoItem',
      meta_type: 'sub_model',
      context_id: props.editedTask?.artifact_id || null,
      revision_id: null,
      no: null,
      order_number: state.videos.length,
      video_id: uploadVideoData.artifact_id,
      _create: true,
    };
    await postVideo(newVideo);

    const currentReport = await fetchBuildingEvent(props.editedTask.artifact_id);
    dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { videos: currentReport.videos } });
  };

  const handleDeleteVideo = (id: string) => {
    const updatedVideos = state.videos.map(video => {
      if (video.video_id === id) {
        return { ...video, _destroy: true };
      }
      return video;
    });
    dispatch({
      type: ActionTypes.UPDATE_TASK_PANEL_STATE,
      payload: {
        videos: updatedVideos,
      },
    });
  };

  // HANDLE SAVE REPORT
  const handleSaveTask = async (isLastSaved = false) => {
    // prepare context values
    let additionalContextObject = {};

    const linkedId = (queryString.parse(props.location.search)?.linkedId as string) || '';

    if (linkedId) {
      const additionalContextId = linkedId;
      const additionalContextValue = getLinkedNameById(linkedId, props.buildingEvents);
      additionalContextObject = {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        uuid: null,
        revision_id: null,
        no: null,
        order_number: null,
        object_type: 'TaskContextItem',
        object_type_id: 'TASK_CONTEXT',
        context_artifact_id: additionalContextId,
        context_artifact_id_value: additionalContextValue,
        task_id: null,
        meta_type: 'context_item',
      };
    }

    const status = state.taskStatus || [taskStatusMock[0].value];

    const currentStatusData = taskStatusMock.find(st => st.value === status);

    if (props.isEditMode || state.isFirstSaved) {
      const updatedTask = {
        ...props.taskModel,
        ...props.editedTask,
        title: state.taskTitle || null,
        description: state.taskDescription || null,
        status: {
          ...props.editedTask.status,
          model_title: null,
          artifact_name_upper: null,
          status_type_id: currentStatusData?.id ?? null,
          status_type_id_value: currentStatusData?.value ?? null,
        },
        priority: {
          ...props.editedTask.priority,
          model_title: null,
          artifact_name_upper: null,
          priority_type_id: state.taskPriority.id,
          priority_type_id_value: state.taskPriority.value,
        },
        checklist: [...state.checklist],
        start_date: {
          ...props.editedTask.start_date,
          dd_date: Number(format(state.startDate as Date, 'T')),
        },
        deadline_date: {
          ...props.editedTask.deadline_date,
          dd_date: state.deadlineDate ? Number(format(state.deadlineDate as Date, 'T')) : null,
        },
        completed_from_date: {
          ...props.editedTask.completed_from_date,
          dd_date: Number(format(state.startDate as Date, 'T')),
        },
        completed_to_date: {
          ...props.editedTask.completed_to_date,
          dd_date: state.doneDate ? Number(format(state.doneDate as Date, 'T')) : null,
        },
        actors: [...state.responsibleActors],
        images: [...state.media],
        annotation_events: [...state.annotationEvents],
        attachments: [...state.attachments],
        videos: [...state.videos],
        _create: true,
      };

      // delete unnecessary fields
      if (updatedTask.hasOwnProperty('$$acl')) delete updatedTask.$$acl;
      if (updatedTask.hasOwnProperty('$$context_name')) delete updatedTask.$$context_name;

      await props.handleUpdateTask(updatedTask, !props.isEditMode ? isLastSaved : false);
      dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isEditChanged: false } });

      if (!isLastSaved) {
        props.handleNextExpansion();
      }
    }
    if (!props.isEditMode && !state.isFirstSaved) {
      // CREATE NEW REPORT TEMPLATE
      const newTask = {
        ...props.taskModel,
        artifact_name: null,
        parent_id: props.building.artifact_id,
        uuid: null,
        updated_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        thumbnail_id: null,
        object_type: 'task',
        object_type_id: 'TASK',
        meta_type: 'activity',
        contexts: [
          {
            model_title: null,
            artifact_name_upper: null,
            checksum: null,
            uuid: null,
            revision_id: null,
            no: null,
            order_number: null,
            object_type: 'TaskContextItem',
            object_type_id: 'TASK_CONTEXT',
            context_artifact_id: props.building.artifact_id,
            context_artifact_id_value: props.building.artifact_name,
            task_id: null,
            meta_type: 'context_item',
          },
        ],
        artifact_type_id: 'ct_200-60',
        artifact_type_id_value: 'Enkeltoppgave',
        identifier: null,
        title: state.taskTitle || null,
        description: state.taskDescription || null,
        actors: [...state.responsibleActors],
        status: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'TaskStatus',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          status_type_id: currentStatusData?.id ?? null,
          status_type_id_value: currentStatusData?.value ?? null,
          status_remarks: null,
        },
        priority: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          object_type: 'Priority',
          meta_type: 'sub_model',
          context_id: null,
          revision_id: null,
          priority_type_id: state.taskPriority.id,
          priority_type_id_value: state.taskPriority.value,
          priority_remarks: null,
        },
        checklist: [...state.checklist],
        start_date: {
          artifact_name_upper: null,
          checksum: null,
          dd_date: Number(format(state.startDate as Date, 'T')),
          dd_error: null,
          dd_error_message: null,
          dd_precision: 'datetime',
          meta_type: 'sub_model',
          model_title: null,
          object_type: 'PrecisionDate',
        },
        deadline_date: {
          dd_date: state.deadlineDate ? Number(format(state.deadlineDate as Date, 'T')) : null,
          artifact_name_upper: null,
          checksum: null,
          dd_error: null,
          dd_error_message: null,
          dd_precision: 'datetime',
          meta_type: 'sub_model',
          model_title: null,
          object_type: 'PrecisionDate',
        },
        completed_from_date: {
          dd_date: Number(format(state.startDate as Date, 'T')),
          artifact_name_upper: null,
          checksum: null,
          dd_error: null,
          dd_error_message: null,
          dd_precision: 'datetime',
          meta_type: 'sub_model',
          model_title: null,
          object_type: 'PrecisionDate',
        },
        completed_to_date: {
          dd_date: state.doneDate ? Number(format(state.doneDate as Date, 'T')) : null,
          artifact_name_upper: null,
          checksum: null,
          dd_error: null,
          dd_error_message: null,
          dd_precision: 'datetime',
          meta_type: 'sub_model',
          model_title: null,
          object_type: 'PrecisionDate',
        },
        collection_id: 'ct_31-1',
        collection_id_value: 'Kurs-samlingen',
        created_at: {
          model_title: null,
          artifact_name_upper: null,
          checksum: null,
          meta_type: 'sub_model',
          object_type: 'PrecisionDate',
          dd_error: null,
          dd_precision: 'datetime',
          dd_date: null,
          dd_error_message: null,
        },
        created_by_id: null,
        created_by_id_value: null,
        images: [...state.media],
        has_images: null,
        has_annotation_events: null,
        is_restricted: false,
        restriction_type: 1,
        annotation_events: [...state.annotationEvents],
        attachments: [...state.attachments],
        videos: [...state.videos],
        _create: true,
      };

      // ADD ADDITIONAL CONTEXT
      Object.keys(additionalContextObject).length > 0 && newTask.contexts.push(additionalContextObject);

      // POST REPORT AND RETURN TO INFO
      await props.handleAddNewTask(newTask);

      if (!state.isFirstSaved) {
        props.handleNextExpansion();
      }

      dispatch({ type: ActionTypes.UPDATE_TASK_PANEL_STATE, payload: { isFirstSaved: true } });
      //
    }
  };

  useEffect(() => {
    if (!props.editSaveTrigger) return;
    handleSaveTask(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editSaveTrigger]);

  // SETTING FORMS STATES IF EDIT REPORT
  useEffect(() => {
    dispatch({
      type: ActionTypes.PREPARE_EDITED,
      payload: {
        taskTitle: props.editedTask?.title ?? '',
        taskDescription: props.editedTask?.description ?? '',
        taskPriority: getInitialPriorityState(props.editedTask?.priority),
        taskStatus: props.editedTask?.status?.status_type_id_value ?? taskStatusMock[0].value,
        startDate:
          (props.editedTask?.start_date?.dd_date && new Date(props.editedTask?.start_date?.dd_date)) ??
          new Date(Date.now()),
        doneDate:
          (props.editedTask?.completed_to_date?.dd_date && new Date(props.editedTask?.completed_to_date?.dd_date)) ??
          null,
        deadlineDate:
          (props.editedTask?.deadline_date?.dd_date && new Date(props.editedTask?.deadline_date?.dd_date)) ?? null,
        responsibleActors: props.editedTask?.actors ?? [],
        checklist: props.editedTask?.checklist ?? [],
        media: props.editedTask?.images || [],
        annotationEvents: props.editedTask?.annotation_events ?? [],
        selectedMedia: null,
        attachments: props.editedTask?.attachments || [],
        videos: props.editedTask?.videos || [],
      },
    });
  }, [props.isViewTaskCondition, props.editedTask]);

  // TRIGGER CHANGED DOCUMENT
  useEffect(() => {
    if (!state.isEditChanged) {
      props.handleEditDocumentChanged(false);
      return;
    }

    props.handleEditDocumentChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isEditChanged]);

  // FETCH IMAGES DATA
  useEffect(() => {
    if (state.media.length === 0) return;
    const imagesIds = state.media.reduce((acc: string[], { image_id }: { image_id: string }) => [...acc, image_id], []);

    (async () => {
      const imagesData = await fetchReportImagesData(imagesIds);
      dispatch({ type: ActionTypes.PREPARE_EDITED, payload: { imagesData: imagesData.artifacts } });
    })();
    return;
  }, [state.media]);

  // FETCH ATTACHMENTS DATA
  useEffect(() => {
    if (state.attachments.length === 0) return;
    const attachmentsIds = state.attachments.reduce(
      (acc: string[], { attachment_id }: { attachment_id: string }) => [...acc, attachment_id],
      [],
    );

    (async () => {
      const attachmentsData = await fetchAttachmentsData(attachmentsIds);
      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { attachmentsData: attachmentsData.artifacts },
      });
    })();
    return;
  }, [state.attachments]);

  // FETCH VIDEOS DATA
  useEffect(() => {
    if (state.videos.length === 0) return;
    const videosIds = state.videos.reduce(
      (acc: string[], { video_id }: { video_id: string }) => [...acc, video_id],
      [],
    );

    (async () => {
      const videosData = await fetchVideosData(videosIds);

      dispatch({
        type: ActionTypes.PREPARE_EDITED,
        payload: { videosData: videosData.artifacts },
      });
    })();
    return;
  }, [state.videos]);

  const currentAnnotationEvent = useMemo(
    () =>
      state.annotationEvents.find(
        (annot: any) => state.selectedMedia && annot.image_id === state.selectedMedia.image_id,
      ) || {},
    [state.annotationEvents, state.selectedMedia],
  );

  const currentImageData = useMemo(
    () => state.imagesData.find(img => img.artifact_id === state.selectedMedia?.image_id),
    [state.imagesData, state.selectedMedia],
  );

  const disabledConditions = props.isViewTaskCondition && !props.isEditMode;

  const mediaLength = useMemo(
    () => [...state.media, ...state.videos, ...state.attachments].filter(img => !img._destroy).length,
    [state.attachments, state.media, state.videos],
  );

  const checklistWithoutDestroy = useMemo(() => state.checklist.filter((i: any) => !i._destroy), [state.checklist]);

  // RENDER VIEW COMPONENT IF VIEW MODE
  if (props.match?.path === TASK_VIEW_MODE_PATH) {
    return (
      <ViewTask
        {...props}
        task={props.editedTask}
        isEditMode={props.isEditMode}
        handleEditMode={props.handleEditMode}
        handleToggleCompleted={handleChecklistItemCompletedToggle}
        checklist={state.checklist}
      />
    );
  }

  return (
    <>
      {!tabletMatch && !props.isViewTaskCondition && !state.isActorsPanelOpen && !state.isChecklistPanelOpen && (
        <ProgressBottomButton
          onSave={() => handleSaveTask(true)}
          currentStepNumber={props.currentStepNumber}
          stepsAmount={props.stepsAmount}
          onNext={() => handleSaveTask()}
          expanded={props.expanded}
          onBack={props.handlePrevExpansion}
        />
      )}

      {!tabletMatch && props.isViewTaskCondition && (
        <BottomStatusBar
          date={props.editedTask?.updated_at?.dd_date ?? 0}
          status={props.editedTask?.status?.status_type_id_value ?? 'status'}
          isEditMode={props.isEditMode}
          handleEditMode={props.handleEditMode}
          currentDocumentType={documentTypes.TASK}
          currentDocumentStatus={props.editedTask?.status?.status_type_id_value || ''}
          handleEditSaveTrigger={props.handleEditSaveTrigger}
          isEditDocumentChanged={props.isEditDocumentChanged}
        />
      )}

      {getExpansionPanelMobileRule(taskExpansionsTypes.TASK, tabletMatch, props.expanded, props.isEditMode) && (
        <StyledExpansionPanel
          expanded={props.expanded === taskExpansionsTypes.TASK}
          onChange={props.handleExpansionChange(taskExpansionsTypes.TASK)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === taskExpansionsTypes.TASK ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Oppgave</Typography>
                </StyledFlexBox>
                <StyledEllipsisCaptionContainer item>
                  <StyledEllipsisCaption variant="h6" color="textSecondary">
                    {state.taskTitle || 'Uten tittel'}
                  </StyledEllipsisCaption>
                </StyledEllipsisCaptionContainer>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails isEdit={props.isEditMode}>
            <TaskTitleForm
              taskTitle={state.taskTitle}
              taskDescription={state.taskDescription}
              taskPriority={state.taskPriority}
              taskStatus={state.taskStatus}
              isStartDatePickerOpen={state.isStartDatePickerOpen}
              startDate={state.startDate}
              isDoneDatePickerOpen={state.isDoneDatePickerOpen}
              doneDate={state.doneDate}
              deadlineDate={state.deadlineDate}
              isDeadlineDatePickerOpen={state.isDeadlineDatePickerOpen}
              handleTaskTitleChange={handleTaskTitleChange}
              handleTaskDescriptionChange={handleTaskDescriptionChange}
              handleStartDateChange={handleStartDateChange}
              handleDoneDateChange={handleDoneDateChange}
              handleDeadlineDateChange={handleDeadlineDateChange}
              handleOpenDeadlineDatePicker={handleOpenDeadlineDatePicker}
              handleCloseDeadlineDatePicker={handleCloseDeadlineDatePicker}
              handleOpenStartDatePicker={handleOpenStartDatePicker}
              handleCloseStartDatePicker={handleCloseStartDatePicker}
              handleOpenDoneDatePicker={handleOpenDoneDatePicker}
              handleCloseDoneDatePicker={handleCloseDoneDatePicker}
              handleTaskPriorityChange={handleTaskPriorityChange}
              handleTaskStatusChange={handleTaskStatusChange}
              disabledConditions={disabledConditions}
              handleEditDragItem={handleEditDragItem}
              handleAddDragItem={handleAddDragItem}
              handleDeleteDragItem={handleDeleteDragItem}
              handleOpenActorsPanel={handleOpenActorsPanel}
              handleCloseActorsPanel={handleCloseActorsPanel}
              handleToggleActorsPanel={handleToggleActorsPanel}
              moveDragItem={moveDragItem}
              responsibleActors={state.responsibleActors}
              isActorsPanelOpen={state.isActorsPanelOpen}
            />
          </StyledExpansionPanelDetails>
          {tabletMatch && !props.isViewTaskCondition && !state.isActorsPanelOpen && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveTask()} withoutBack />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(taskExpansionsTypes.CHECKLIST, tabletMatch, props.expanded, props.isEditMode) && (
        <StyledExpansionPanel
          expanded={props.expanded === taskExpansionsTypes.CHECKLIST}
          onChange={props.handleExpansionChange(taskExpansionsTypes.CHECKLIST)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === taskExpansionsTypes.CHECKLIST ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Sjekkliste</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {`${checklistWithoutDestroy.length} punkter` || 'Ingen punkter'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails>
            <TaskChecklistForm
              handleEditChecklistItem={handleEditChecklistItem}
              handleAddChecklistItem={handleAddChecklistItem}
              handleDeleteChecklistItem={handleDeleteChecklistItem}
              handleCloseChecklistPanel={handleCloseChecklistPanel}
              handleToggleChecklistPanel={handleToggleChecklistPanel}
              checklist={state.checklist}
              disabledConditions={disabledConditions}
              isChecklistPanelOpen={state.isChecklistPanelOpen}
              handleUpChecklistItem={handleUpChecklistItem}
              handleDownChecklistItem={handleDownChecklistItem}
              handleChecklistItemCompletedToggle={handleChecklistItemCompletedToggle}
            />
          </StyledExpansionPanelDetails>
          {!state.isChecklistPanelOpen && (
            <StyledExpansionPanelActions isEdit={props.isEditMode}>
              {props.isViewTaskCondition && !props.isEditMode ? null : (
                <OutlineButton padding="16px 30px" onClick={handleOpenChecklistPanel}>
                  <StyledBox>
                    <AddIcon style={{ marginRight: 4 }} />
                  </StyledBox>
                  Legg til
                </OutlineButton>
              )}
              {tabletMatch && !props.isViewTaskCondition && !state.isChecklistPanelOpen && (
                <SaveOrCancel onSave={() => handleSaveTask()} withoutBack />
              )}
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}

      {getExpansionPanelMobileRule(taskExpansionsTypes.ATTACHMENTS, tabletMatch, props.expanded, props.isEditMode) && (
        <StyledExpansionPanel
          expanded={props.expanded === taskExpansionsTypes.ATTACHMENTS}
          onChange={props.handleExpansionChange(taskExpansionsTypes.ATTACHMENTS)}
          square
          color={theme.palette.secondary.darkWhite}
          isEdit={props.isEditMode}
        >
          <StyledExpansionPanelSummary>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center" spacing={1} direction="row">
                <StyledFlexBox item>
                  {props.expanded === taskExpansionsTypes.ATTACHMENTS ? (
                    <MoreVertIcon style={{ marginRight: 12 }} />
                  ) : (
                    <MoreHorizIcon style={{ marginRight: 12 }} />
                  )}
                  <Typography variant="h5">Opplastninger</Typography>
                </StyledFlexBox>
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    {mediaLength}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails padding={!tabletMatch && '8px 8px 24px'} isEdit={props.isEditMode}>
            {state.isMediaPanelOpen ? (
              <AttachmentMediaPanel
                onCloseMediaPanel={handleCloseMediaPanel}
                selectedMedia={state.selectedMedia}
                handleSaveAnnotations={handleSaveAnnotations}
                annotations={currentAnnotationEvent.annotation_points || []}
                imageName={currentImageData?.artifact_name || ''}
              />
            ) : (
              <ChildrenAttachmentSection
                onOpenMediaPanel={handleOpenMediaPanel}
                media={state.media}
                annotationEvents={state.annotationEvents}
                handleAddNewImage={handleAddNewImage}
                disabledConditions={disabledConditions}
                handleDeleteImage={handleDeleteImage}
                imagesData={state.imagesData}
                attachmentsData={state.attachmentsData}
                attachments={state.attachments}
                videosData={state.videosData}
                videos={state.videos}
                handleAddNewAttachment={handleAddNewAttachment}
                handleDeleteFile={handleDeleteFile}
                handleAddNewVideo={handleAddNewVideo}
                handleDeleteVideo={handleDeleteVideo}
              />
            )}
          </StyledExpansionPanelDetails>

          {tabletMatch && !props.isViewTaskCondition && (
            <StyledExpansionPanelActions>
              <SaveOrCancel onSave={() => handleSaveTask(true)} withoutBack saveLabel="Lagre og avslutt" />
            </StyledExpansionPanelActions>
          )}
        </StyledExpansionPanel>
      )}
    </>
  );
};

const StyledFlexBox = styled(props => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`;
const StyledEllipsisCaption = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const StyledEllipsisCaptionContainer = styled(Grid)`
  &.MuiGrid-root {
    width: 75vw;
  }
  @media (max-width: 767px) {
    &.MuiGrid-root {
      width: 60vw;
    }
  }
  @media (max-width: 420px) {
    &.MuiGrid-root {
      width: 45vw;
    }
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default TaskExpansionPanel;
