import React from 'react';
import { Grid } from '@material-ui/core';

import AssessmentEditPanel from '../../AssessmentEditPanel/AssessmentEditPanel';
import AssessmentDragItem from '../AssessmentDragItem/AssessmentDragItem';

interface Props {
  dragItems: any[];
  isEditPanelOpen: boolean;
  selectedEdit: string | null;
  handleToggleEditPanel: (id: string) => void;
  handleSaveEditPanel: (item: any) => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  emptyDragItem: {
    building_part_type: {
      building_part_type_id_value: string;
      building_part_type_id: string;
      building_part_description: string;
    };
    condition: {
      bp_condition_type_id_value: string;
      bp_condition_type_id: string;
      consequence_type_id_value: string;
      consequence_type_id: string;
    };
    treatment_suggestions: any[];
  };
  handleCloseEditPanel: () => void;
  handleDeleteDrag: (id: string) => void;
  disabledConditions: boolean;
  isEditMode: boolean;
}

const AssessmentFormDrags = (props: Props) => {
  const selectedDragItem = props.dragItems.find(
    item => props.selectedEdit !== null && (item.bpca_id === props.selectedEdit || item.uuid === props.selectedEdit),
  );

  return (
    <>
      {props.dragItems.length > 0 && (
        <Grid item container direction="column" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {props.dragItems.map(
            (
              {
                building_part_type,
                uuid,
                bpca_id,
                _destroy,
                condition: { bp_condition_type_id_value, consequence_type_id_value },
                treatment_suggestions,
              },
              index,
            ) => {
              if (_destroy) return null;

              const editPanelRenderRule =
                selectedDragItem &&
                props.isEditPanelOpen &&
                ((selectedDragItem.uuid && selectedDragItem.uuid === uuid) ||
                  (selectedDragItem.bpca_id !== null && selectedDragItem.bpca_id === bpca_id));

              // render edit panel instead drag if editing
              if (editPanelRenderRule) {
                return (
                  <Grid key={bpca_id || uuid} item style={{ position: 'relative' }}>
                    <AssessmentEditPanel
                      isEditing={!!props.selectedEdit}
                      drag={selectedDragItem || props.emptyDragItem}
                      dragsLength={props.dragItems.length}
                      onSaveEditPanel={props.handleSaveEditPanel}
                      handleCloseEditPanel={props.handleCloseEditPanel}
                      isEditMode={props.isEditMode}
                    />
                  </Grid>
                );
              }
              return (
                <AssessmentDragItem
                  key={bpca_id || uuid}
                  id={bpca_id || uuid}
                  index={index}
                  title={building_part_type.building_part_type_id_value}
                  handleEdit={() => props.handleToggleEditPanel(bpca_id === null ? uuid : bpca_id)}
                  onDeleteDrag={() => props.handleDeleteDrag(bpca_id === null ? uuid : bpca_id)}
                  moveCard={props.moveDragItem}
                  disabledConditions={props.disabledConditions}
                  conditionLevel={bp_condition_type_id_value}
                  actionsAmount={treatment_suggestions.filter((item: any) => !item._destroy).length || 0}
                  consequenceLevel={consequence_type_id_value}
                />
              );
            },
          )}
        </Grid>
      )}
      {/* Render edit panel when new report */}
      {props.isEditPanelOpen && !selectedDragItem && (
        <Grid item style={{ position: 'relative' }}>
          <AssessmentEditPanel
            isEditing={!!props.selectedEdit}
            drag={selectedDragItem || props.emptyDragItem}
            dragsLength={props.dragItems.length}
            onSaveEditPanel={props.handleSaveEditPanel}
            handleCloseEditPanel={props.handleCloseEditPanel}
            isEditMode={props.isEditMode}
          />
        </Grid>
      )}
    </>
  );
};

export default AssessmentFormDrags;
