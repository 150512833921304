import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionSummary, AccordionActions, AccordionDetails } from '@material-ui/core';

export const StyledExpansionPanel = styled(({ color, overflow, isEdit, ...props }) => <Accordion {...props} />)`
  &.MuiExpansionPanel-root {
    background-color: ${props => props.color};
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    ${props => props.overflow && 'overflow: ' + props.overflow};
  }
  &.MuiExpansionPanel-root.Mui-expanded {
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: 767px) {
      ${props => !props.isEdit && 'min-height: calc(100vh - 56px)'}
    }
  }

  @media (max-width: 767px) {
    &.MuiExpansionPanel-root {
      box-shadow: none;
    }

    &.MuiExpansionPanel-root:last-child {
      ${props => props.isEdit && 'margin-bottom: 56px'};
    }
  }
`;

export const StyledExpansionPanelSummary = styled(({ padding, ...props }) => <AccordionSummary {...props} />)`
  &.MuiExpansionPanelSummary-root {
    cursor: pointer;
    padding: 0 18px;
    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }

  & .MuiExpansionPanelSummary-content {
    padding: ${props => props.padding || '2px 0'};
  }

  & .MuiExpansionPanelSummary-content {
    @media (max-width: 767px) {
      margin: 4px 0;
    }
  }
  & .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
    @media (max-width: 767px) {
      margin: 4px 0;
    }
  }
`;

export const StyledExpansionPanelActions = styled(({ padding, isEdit, ...props }) => <AccordionActions {...props} />)`
  &.MuiExpansionPanelActions-root {
    justify-content: flex-start;
    padding: ${props => props.padding || '12px 12px 24px 12px'};
  }
  @media (max-width: 767px) {
    &.MuiExpansionPanelActions-root {
      ${props => !props.isEdit && 'margin-bottom: 56px'};
    }
  }
`;

export const StyledExpansionPanelDetails = styled(({ padding, isEdit, ...props }) => <AccordionDetails {...props} />)`
  &.MuiExpansionPanelDetails-root {
    padding: ${props => props.padding || '0px 12px 12px'};
  }
  @media (max-width: 767px) {
    &.MuiExpansionPanelDetails-root {
      padding: ${props => props.padding || '8px 20px 24px'};
      ${props => props.isEdit === false && 'margin-bottom: 56px'};
    }
  }
`;
