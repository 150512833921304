export enum ActionTypes {
  UPDATE_CONDITION_PANEL_STATE = 'UPDATE_CONDITION_PANEL_STATE',
  RESET_CONDITION_STATE = 'RESET_CONDITION_STATE',
  PREPARE_EDITED = 'PREPARE_EDITED',
}

export type Action = {
  type: ActionTypes;
  payload?: Partial<State>;
};

export interface State {
  conditionTitle: string;
  reason: { title: string; id: string };
  description: string;
  isFromDatePickerOpen: boolean;
  // isToDatePickerOpen: boolean;
  fromSelectedDate: Date | null;
  // toSelectedDate: Date | null;
  buildingParts: any[];
  buildingPartInput: {
    title: string;
    id: string;
  };
  isEditPanelOpen: boolean;
  media: any[];
  isMediaPanelOpen: boolean;
  selectedMedia: any | null;
  annotationEvents: any[];
  summaryDescription: string;
  conditionMethod: string;
  conditionClass: { title: string; id: string };
  isFirstSaved: boolean;
  imagesData: any[];
  attachments: any[];
  attachmentsData: any[];
  videos: any[];
  videosData: any[];
  isEditChanged: boolean;
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_CONDITION_PANEL_STATE:
      const isEditChanged = action.payload?.isEditChanged === false ? (action.payload?.isEditChanged as boolean) : true;
      return {
        ...state,
        ...action.payload,
        isEditChanged,
      };
    case ActionTypes.PREPARE_EDITED:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.RESET_CONDITION_STATE:
      return {
        ...state,
        conditionTitle: '',
        reason: { title: '', id: '' },
        description: '',
        fromSelectedDate: null,
        // toSelectedDate: null,
        buildingParts: [],
        buildingPartInput: { title: '', id: '' },
        media: [],
        selectedMedia: null,
        summaryDescription: '',
        conditionMethod: '',
        conditionClass: { title: '', id: '' },
        isFirstSaved: false,
        imagesData: [],
        attachments: [],
        attachmentsData: [],
        videos: [],
        videosData: [],
        isEditChanged: false,
      };

    default:
      return state;
  }
};
