import React, { useMemo } from 'react';
import { Grid, Box, useMediaQuery } from '@material-ui/core';

import { Link } from 'react-router-dom';

import { getDocumentRoutingName } from 'helpers/getDocumentRoutingName';
import { DRAFTS_LIMIT } from '../../../../constants';
import HomeEventListItem from 'components/HomeEventListItem/HomeEventListItem';

interface Props {
  draftsObjects: any[];
  handleDeleteDraftObject: (id: string) => void;
}

const DraftsList = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const sortedByType = useMemo(
    () =>
      [...props.draftsObjects].sort((a, b) =>
        a.artifact_type_id_value > b.artifact_type_id_value
          ? 1
          : b.artifact_type_id_value > a.artifact_type_id_value
          ? -1
          : 0,
      ),
    [props.draftsObjects],
  );
  const draftsWithLimit = useMemo(() => sortedByType.slice(0, DRAFTS_LIMIT), [sortedByType]);

  return (
    <Box>
      <Grid container spacing={mediaMatch ? 1 : 0}>
        {draftsWithLimit.map((draft, idx, arr) => {
          const marginCondition =
            !mediaMatch && arr[idx + 1] && arr[idx + 1].artifact_type_id_value !== draft.artifact_type_id_value
              ? { marginBottom: 12 }
              : {};
          const pathLink = getDocumentRoutingName(draft.artifact_type_id_value)
            ? `/building/${draft['contexts.context_artifact_id'][0]}/${getDocumentRoutingName(
                draft.artifact_type_id_value,
              )}/${draft.artifact_id || draft.uuid}`
            : '/';

          return (
            <Grid item xs={12} key={draft.artifact_id || draft.uuid} style={marginCondition}>
              <Link
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                to={{
                  pathname: pathLink,
                  state: { fromMain: true },
                }}
              >
                <HomeEventListItem object={draft} withoutContextParent />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DraftsList;
