import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Typography, Grid, Divider, useMediaQuery } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface Props {
  mediaLength: number;
  activeImageNumber: number;
  handleNext: () => void;
  handlePrev: () => void;
  handleChangeImage: (imageNumber: number) => void;
}

const ARROW_STYLES = { width: 18, height: 18 };

const SliderBottomBar: React.FC<Props> = ({
  mediaLength,
  activeImageNumber,
  handleNext,
  handlePrev,
  handleChangeImage,
}) => {
  const mediaMatch = useMediaQuery('(min-width: 768px)');
  const [isScrolled, setIsScrolled] = useState(false);
  const paginationRef = useRef<HTMLDivElement>(null);

  const generatedPaginationContent = Array.from(Array(mediaLength).keys()).map(item => item + 1);

  useEffect(() => {
    const paginationWrapper: any = paginationRef.current;
    if (paginationWrapper?.scrollWidth > paginationWrapper?.clientWidth) {
      setIsScrolled(true);
    }
  }, []);

  return (
    <StyledWrap>
      <StyledArrowButton onClick={handlePrev} disabled={activeImageNumber === 1}>
        <ArrowBackIcon style={{ ...ARROW_STYLES }} />
      </StyledArrowButton>
      <Divider orientation="vertical" style={{ height: '70%' }} />

      <StyledPaginationWrap ref={paginationRef}>
        <Grid container alignItems="center" justify={isScrolled ? 'flex-start' : 'center'} wrap="nowrap">
          {mediaMatch ? (
            generatedPaginationContent.map(num => (
              <Grid key={num} item>
                <StyledPageWrap onClick={() => handleChangeImage(num)}>
                  <Typography
                    key={num}
                    color="textSecondary"
                    component="span"
                    style={{ fontWeight: activeImageNumber === num ? 700 : 400 }}
                  >
                    {num}
                  </Typography>
                </StyledPageWrap>
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography color="textSecondary" component="span">
                {`${activeImageNumber}/${mediaLength}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </StyledPaginationWrap>

      <Divider orientation="vertical" style={{ height: '70%' }} />
      <StyledArrowButton onClick={handleNext} disabled={activeImageNumber === mediaLength}>
        <ArrowForwardIcon style={{ ...ARROW_STYLES }} />
      </StyledArrowButton>
    </StyledWrap>
  );
};

const StyledWrap = styled(Box)`
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  background-color: #ffffff;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledArrowButton = styled(Button)`
  &.MuiButton-root {
    background-color: #ffffff;
    padding: 19px 50px;
    border-radius: 0;
  }
`;
const StyledPageWrap = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
`;
const StyledPaginationWrap = styled.div`
  overflow-x: auto;
  width: 100%;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default SliderBottomBar;
