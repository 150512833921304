import React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

interface Props {
  value: string;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

const FormTextArea: React.FC<Props> = props => {
  return (
    <StyledTextField
      label={props.label}
      placeholder={props.placeholder}
      disabled={props.disabled}
      fullWidth
      value={props.value}
      onChange={props.handleChange}
      variant="filled"
      color="secondary"
    />
  );
};

const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 0;
  }
`;
export default FormTextArea;
