import React from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { Typography, AppBar, Toolbar, IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  handleSearchOpen: () => void;
  handleSearchClose: () => void;
  searchQuery: string;
  handleOpenFilter: () => void;
  handleOpenSort: () => void;
  enabledFiltersAmount: number;
  handleSearchInput: (value: string) => void;
  isSearchOpen: boolean;
}

const SearchBar = (props: Props) => {
  const theme = useTheme();
  const mediaMatch = useMediaQuery('(min-width: 706px)');
  const handleClearSearchInput = () => {
    props.handleSearchInput('');
    debounce(() => {
      props.handleSearchOpen();
    }, 100)();
  };
  return (
    <StyledAppBar
      position="sticky"
      color={theme.palette.secondary.white}
      elevation={mediaMatch ? 4 : 1}
      isSearchOpen={props.isSearchOpen}
    >
      <StyledToolbar>
        <IconButton edge="start" color="inherit" aria-label="back" onClick={props.handleSearchClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          style={{ flex: 1, cursor: 'text', marginLeft: mediaMatch ? 12 : 4 }}
          onClick={props.handleSearchOpen}
        >
          {props.searchQuery || 'Search'}
        </Typography>
        {/* {!mediaMatch && (
          <FilterSortBox
            onFilterClick={props.handleOpenFilter}
            onSortClick={props.handleOpenSort}
            enabledFiltersAmount={props.enabledFiltersAmount}
          />
        )} */}
        <IconButton edge="end" onClick={handleClearSearchInput}>
          <CloseIcon />
        </IconButton>
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(({ color, isSearchOpen, ...props }) => <AppBar {...props} />)`
  &.MuiAppBar-root {
    background-color: transparent;
    color: #000000;
    height: 56px;
    // padding-left: 12px;
    // padding-right: 12px;
    background-color: ${props => props.color};

    @media (max-width: 705px) {
      height: ${props => (props.isSearchOpen ? '2vh' : 'auto')};
      overflow-y: ${props => (props.isSearchOpen ? 'hidden' : 'auto')};
    }

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
      height: 72px;
      // background-color: ${props => props.color};
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  height: 56px;
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }
  &.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  @media (min-width: 706px) {
    height: 72px;
  }
`;

export default SearchBar;
