import React, { useState, useEffect, useMemo } from 'react';
import { Grid, useMediaQuery, FormHelperText, Typography } from '@material-ui/core';

// import buildingPartsMock from '../../mocks/buildingPartsMock.json';
import { getBaseUrl } from 'services/fetchData';

import AsyncFormInput from '../../shared/AsyncFormInput/AsyncFormInput';
import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import NumberedFormInput from '../../shared/NumberedFormInput/NumberedFormInput';
import WorkBuildingPartDrags from './WorkBuildingPartDrags/WorkBuildingPartDrags';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';

interface Props {
  buildingPart: string;
  cost: number | null;
  timings: number | null;
  material: string;
  handleBuildingPartChange: (value: string, options: any[]) => void;
  handleCostChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleTimingsChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleMaterialChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleEditComment: (newItem: any) => void;
  handleAddComment: (newItem: any) => void;
  handleDeleteComment: (id: string) => void;
  handleOpenCommentsPanel: () => void;
  handleCloseCommentsPanel: () => void;
  handleToggleCommentsPanel: () => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  comments: any[];
  disabledConditions: boolean;
  isCommentsPanelOpen: boolean;
}

const WorkBuildingPartForm: React.FC<Props> = props => {
  const mediaMatch = useMediaQuery('(min-width: 768px)');
  const [selectedEdit, setSelectedEdit] = useState<string | null>(null);

  // BUILDING PARTS OPTIONS STATE
  const [buildingPartOptionsOpen, setBuildingPartOptionsOpen] = React.useState(false);
  const [buildingPartOptions, setBuildingPartOptions] = React.useState<any[]>([]);
  const buildingPartLoading = buildingPartOptionsOpen && buildingPartOptions.length === 0;

  // BUILDING PART OPTIONS FETCHING AND SET
  useEffect(() => {
    let active = true;
    if (!buildingPartLoading) {
      return undefined;
    }
    (async () => {
      try {
        // LEVEL 2 BUILDING PARTS FETCH
        const level2Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 2,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level2Response.ok) throw new Error('something went wrong!');
        const level2Options = await level2Response.json();

        // LEVEL 3 BUILDING PARTS FETCH
        const level3Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 3,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level3Response.ok) throw new Error('something went wrong!');
        const level3Options = await level3Response.json();
        const options = [...level2Options.artifacts, ...level3Options.artifacts].sort(
          (prev, next) => Number(prev.code) - Number(next.code),
        );

        if (active) {
          setBuildingPartOptions(options.map((option: any) => ({ title: option.artifact_name, id: option.id })));
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [buildingPartLoading]);

  // PREFETCH BUILDING PART OPTIONS
  // useEffect(() => {
  //   if (!props.buildingPart) return;
  //   (() => {
  //     try {
  //       setBuildingPartOptions(
  //         buildingPartsMock.map((option: any) => ({ title: option.artifact_name, id: option.id })),
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [props.buildingPart]);

  // SETTING BUILDING PARTS AUTOCOMPLETES OPEN STATUS
  const setBuildingPartOpenStatus = (status: boolean) => {
    setBuildingPartOptionsOpen(status);
  };

  // HANDLERS
  const onToggleCommentsPanel = (id: string) => {
    if (selectedEdit === null) {
      setSelectedEdit(id);
    } else {
      setSelectedEdit(null);
    }
    props.handleToggleCommentsPanel();
  };

  const onCloseCommentsPanel = () => {
    setSelectedEdit(null);
    props.handleCloseCommentsPanel();
  };
  const handleSaveCommentsPanel = (item: any) => {
    if (!selectedEdit || selectedEdit === null) {
      props.handleAddComment(item);
    } else {
      props.handleEditComment(item);
    }
    onCloseCommentsPanel();
  };
  const handleDeleteComment = (id: string) => {
    props.handleDeleteComment(id);
    onCloseCommentsPanel();
  };

  // comments without _destroy field
  const commentsWithoutDestroy = useMemo(() => props.comments.filter((i: any) => !i._destroy), [props.comments]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <AsyncFormInput
          value={props.buildingPart}
          handleChange={props.handleBuildingPartChange}
          options={buildingPartOptions}
          placeholder="Søk og velg bygningsdel"
          label="Bygningsdel"
          loading={buildingPartLoading}
          open={buildingPartOptionsOpen}
          setOpenStatus={setBuildingPartOpenStatus}
          regular={mediaMatch ? false : true}
          required
          arrowDown
          shrink
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <NumberedFormInput value={props.cost} handleChange={props.handleCostChange} label="Estimert kostnad" />
          <FormHelperText>Kostnad i kroner</FormHelperText>
        </Grid>
        <Grid item xs={6}>
          <NumberedFormInput value={props.timings} handleChange={props.handleTimingsChange} label="Estimert tidsbruk" />
          <FormHelperText>Estimert tidsbruk i antall timer</FormHelperText>
        </Grid>
      </Grid>
      <Grid item>
        <FormTextArea value={props.material} handleChange={props.handleMaterialChange} label="Materialbruk, etc." />
        <FormHelperText> Estimert materialbruk og andre detailejr</FormHelperText>
      </Grid>
      {commentsWithoutDestroy.length > 0 && (
        <Grid item>
          <Typography variant="subtitle2">Kommentarer</Typography>
        </Grid>
      )}
      <Grid item>
        <WorkBuildingPartDrags
          dragItems={props.comments}
          isCommentsPanelOpen={props.isCommentsPanelOpen}
          selectedEdit={selectedEdit}
          handleToggleCommentsPanel={onToggleCommentsPanel}
          handleSaveCommentsPanel={handleSaveCommentsPanel}
          moveDragItem={props.moveDragItem}
          handleCloseCommentsPanel={onCloseCommentsPanel}
          handleDeleteComment={handleDeleteComment}
          disabledConditions={props.disabledConditions}
        />
      </Grid>
      {!props.isCommentsPanelOpen && (
        <Grid item>
          <OutlineButton padding="16px 30px" width="100%" onClick={props.handleOpenCommentsPanel}>
            Legg til kommentar
          </OutlineButton>
        </Grid>
      )}
    </Grid>
  );
};

export default WorkBuildingPartForm;
