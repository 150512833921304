import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const StyledTypography = styled(({ color, fontWeight, margin, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    ${props => props.color && 'color: ' + props.color};
    ${props => props.fontSize && 'font-size: ' + props.fontSize};
    ${props => props.fontWeight && 'font-weight: ' + props.fontWeight};
    ${props => props.margin && 'margin: ' + props.margin};
  }
`;
