import React from 'react';
import styled from 'styled-components';
import { Grid, useMediaQuery, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl } from '@material-ui/core';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import Datepicker from '../../shared/Datepicker/Datepicker';
import FormInput from '../../shared/FormInput/FormInput';

interface Props {
  purposes: any;
  conditionTitle: string;
  reason: string;
  description: string;
  isFromDatePickerOpen: boolean;
  fromSelectedDate: Date | null;
  handleConditionTitleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleReasonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDescriptionChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleFromDateChange: (date: Date | null) => void;
  handleOpenFromDatePicker: () => void;
  handleCloseFromDatePicker: () => void;
  disabledConditions: boolean;
}

const ChildrenPurposeForm = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width: 550px)');

  const getOptionTitle = (option: any) => {
    return option.title + (typeof option.authority !== 'undefined' ? ' (' + option.authority + ')' : '');
  };

  const getOptions = () => {
    if (!props.purposes) {
      return '';
    } else {
      return props.purposes.map((option: any) => {
        return (
          <StyledFormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio color="primary" />}
            label={getOptionTitle(option)}
          />
        );
      });
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormInput
          value={props.conditionTitle}
          handleChange={props.handleConditionTitleChange}
          disabled={props.disabledConditions}
          label="Tittel"
        />
      </Grid>
      <Grid item>
        <FormControl component="fieldset" disabled={props.disabledConditions}>
          <FormLabel component="legend">Årsak til vurdering</FormLabel>
          <RadioGroup name="condition-formal" value={props.reason} onChange={props.handleReasonChange}>
            {getOptions()}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.description}
          handleChange={props.handleDescriptionChange}
          label="Beskrivelse av formål"
          disabled={props.disabledConditions}
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={mediaMatch ? false : 12}>
          <Datepicker
            value={props.fromSelectedDate}
            handleChange={props.handleFromDateChange}
            open={props.isFromDatePickerOpen}
            onOpen={props.handleOpenFromDatePicker}
            onClose={props.handleCloseFromDatePicker}
            label="Dato utført"
            disabled={props.disabledConditions}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: #000000;
  }
`;

export default ChildrenPurposeForm;
