import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button, Typography, Box, Grid } from '@material-ui/core';

interface Props {
  label: string;
  onClick?: () => void;
  icon?: ReactElement;
}

const BottomPrimaryButton: React.FC<Props> = props => {
  return (
    <StyledButton color="#2c2f38" fullWidth onClick={props.onClick || (() => null)}>
      <Grid container alignItems="center" justify="center" direction="row" wrap="nowrap" spacing={1}>
        <Grid item>
          <StyledBox>{props.icon}</StyledBox>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{props.label}</Typography>
        </Grid>
      </Grid>
    </StyledButton>
  );
};

const StyledButton = styled(({ color, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    position: fixed;
    bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: ${props => props.color};
    color: #ffffff;
    border-radius: 0;
    text-transform: none;

    &:hover {
      background-color: #4d5261;
    }
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default BottomPrimaryButton;
