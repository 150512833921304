import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface Props {
  isVisible: boolean;
  left?: boolean;
  right?: boolean;
  onScrollClick: () => void;
}

const ScrollIndicator = (props: Props) => {
  return (
    <StyledScrollIndicator
      isVisible={props.isVisible}
      left={props.left}
      right={props.right}
      onClick={props.isVisible ? props.onScrollClick : null}
    >
      {props.left ? <ArrowBackIcon /> : <ArrowForwardIcon />}
    </StyledScrollIndicator>
  );
};

const StyledScrollIndicator = styled(({ isVisible, left, right, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    position: absolute;
    top: 50%;
    right: 3%;
    ${props => props.right && 'right: 3%'};
    ${props => props.left && 'left: 3%'};
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
    color: #757575;
    min-width: 0;
    width: 50px;
    height: 50px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    opacity: ${props => (props.isVisible ? '1' : '0')};
    cursor: ${props => (props.isVisible ? 'pointer' : 'auto')};
    transition: all 0.2s linear;

    &:hover {
      background-color: #ffffff;
    }
  }
`;

export default ScrollIndicator;
