import uniq from 'lodash/uniq';
import compact from 'lodash/compact';

import storage from 'helpers/storage';

const getHistory = (): string[] => storage.load('searchHistory') || [];

const addToHistory = (query: string) => {
  const history = storage.load('searchHistory');

  if (!history) {
    storage.save('searchHistory', [query]);
    return;
  }

  const preparedSearchHistory = compact(uniq([query, ...history]));
  storage.save('searchHistory', preparedSearchHistory);
};

export default { getHistory, addToHistory };
