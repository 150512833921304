import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import uuid from 'uuid/v4';
import { Rnd } from 'react-rnd';
import update from 'immutability-helper';
import styled from 'styled-components';
import { Typography, Paper, Grid, Box, Divider, useMediaQuery, Toolbar, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import throttle from 'lodash/throttle';

// icons
import { ReactComponent as CheckMarkIcon } from 'assets/icons/CheckMark.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SecondarySaveOrCancel from '../SecondarySaveOrCancel/SecondarySaveOrCancel';
import AttachmentMediaDrags from './AttachmentMediaDrags/AttachmentMediaDrags';
import ImageResizeStyleWrap from '../../shared/ImageResizeStyleWrap/ImageResizeStyleWrap';
import { ReactComponent as CheckMarkDarkIcon } from '../../../../assets/icons/CheckMark.svg';
import OutlineBottomButton from '../../shared/OutlineBottomButton/OutlineBottomButton';
import StyledAppBar from '../../shared/StyledAppBar/StyledAppBar';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';
import { getBaseUrl } from '../../../../services/fetchData';

interface Props {
  onCloseMediaPanel: () => void;
  selectedMedia: any;
  handleSaveAnnotations: (newAnnotations: any[]) => void;
  annotations: any[];
  imageName?: string;
}

const colors = [
  'white',
  '#bbb',
  '#777',
  '#333',
  'black',
  'red',
  'green',
  'blue',
  '#FF8080',
  '#80FF80',
  '#8080FF',
  'yellow',
  '#0ff',
  '#f0f',
  '#ffff08',
  '#08ffff',
  '#ff08ff',
];

const colorsForWhiteText = ['#777', '#333', 'black', 'red', 'green', 'blue'];

const getTextBadgeColor = (activeColor: string) => {
  const preparedColors = colorsForWhiteText.map(color => color.toLowerCase());
  if (preparedColors.includes(activeColor.toLowerCase())) {
    return '#ffffff';
  }

  return '#000000';
};

const AttachmentMediaPanel: React.FC<Props> = props => {
  const theme = useTheme();
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const [annotations, setAnnotations] = useState<any[]>([...props.annotations]);
  const [windowSize, setWindowSize] = useState(0);
  const [activeAnnotation, setActiveAnnotation] = useState<any>(null);

  const imageWrapRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const imageWrap: any = imageWrapRef.current;
    window.addEventListener('resize', throttledHandleResize);
    imageWrap.onload = function() {
      setAnnotations([...props.annotations]);
    };
    return () => window.removeEventListener('resize', throttledHandleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update active annotation if annotations change
  useEffect(() => {
    if (!activeAnnotation) return;
    setActiveAnnotation(annotations.find(annot => annot.uuid === activeAnnotation.uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotations]);

  useEffect(() => {
    setAnnotations([...annotations]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  // drag and drop
  const moveMediaDragItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = annotations[dragIndex];
      setAnnotations(
        update(annotations, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [annotations],
  );

  // handlers
  const handleAddNewAnnotation = (e: React.MouseEvent) => {
    const imageWrap = imageWrapRef.current;
    const rect = (e.target as Element).getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    const y = e.clientY - rect.top; //y position within the element.
    const targetWidth = 80;
    const targetHalfWidth = targetWidth / 2;

    const newAnnotation = {
      model_title: null,
      artifact_name_upper: null,
      checksum: null,
      object_type: 'AnnotationPoint',
      object_type_id: '9',
      meta_type: 'sub_model',
      context_id: null,
      revision_id: null,
      no: null,
      x1: (x - targetHalfWidth) / (imageWrap?.width ?? 0),
      x2: (x + targetHalfWidth) / (imageWrap?.width ?? 0),
      y1: (y - targetHalfWidth) / (imageWrap?.height ?? 0),
      y2: (y + targetHalfWidth) / (imageWrap?.height ?? 0),
      a_type: 'square',
      a_description: '',
      blob: null,
      order_number: annotations.length + 1,
      color: colors[0],
      uuid: uuid(),
      _create: true,
    };
    setAnnotations([...annotations, newAnnotation]);
  };
  const handleDeleteAnnotation = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();

    const updateAnnotations = annotations.map((item, idx, arr) => {
      if (item.uuid === id) {
        return { ...item, _destroy: true };
      }

      return { ...item, order_number: idx + 1 };
    });

    setAnnotations(updateAnnotations);
  };
  const handleChangeName = (event: React.ChangeEvent<{ value: string; id: string }>) => {
    const updatedAnnotations = annotations.map(item => {
      if (item.uuid === event.target.id) {
        return {
          ...item,
          a_description: event.target.value,
        };
      } else {
        return item;
      }
    });
    setAnnotations(updatedAnnotations);
  };
  const handleSaveAllAnnotations = () => {
    const annotationsWithImportantFields = annotations.map((annot: any) => ({
      ...annot,
      model_title: null,
      artifact_name_upper: null,
    }));
    props.handleSaveAnnotations(annotationsWithImportantFields);
    props.onCloseMediaPanel();
  };
  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWindowSize(windowSize);
  };
  const throttledHandleResize = throttle(handleResize, 500);

  const handleActiveAnnotationChange = (id: string) => {
    const annotation = annotations.find(item => item.uuid === id);

    setActiveAnnotation(annotation);
  };
  const handleChangeAnnotationShape = (id: string, e: React.MouseEvent) => {
    const updatedAnnotations = annotations.map(annot => {
      if (annot.uuid === id) {
        // CALC NEW POSITION AND SIZE WHEN CHANGE TO CIRCLE AND NEED TO BE PERFECT CIRCLE INSTEAD OVAL
        const imageWrap: any = imageWrapRef.current;

        const width = calcWidth(annot, imageWrap.width);
        const height = calcHeight(annot, imageWrap.height);

        const offsetTarget = Math.abs(width - height);

        const targetHeight = width;
        const y2InPx = annot.y2 * imageWrap.height;

        const targetY1InPx = y2InPx - targetHeight + offsetTarget / 2;
        const targetY1 = targetY1InPx / imageWrap.height;
        const targetY2InPx = y2InPx + offsetTarget / 2;
        const targetY2 = targetY2InPx / imageWrap.height;

        return {
          ...annot,
          a_type: annot.a_type === 'square' ? 'circle' : 'square',
          y1: annot.a_type === 'square' ? targetY1 : annot.y1,
          y2: annot.a_type === 'square' ? targetY2 : annot.y2,
        };
      }
      return annot;
    });
    setAnnotations(updatedAnnotations);
    setActiveAnnotation({
      ...activeAnnotation,
      a_type: activeAnnotation.a_type === 'square' ? 'circle' : 'square',
    });
  };
  const handleAnnotationClick = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();

    if (activeAnnotation?.uuid === id) {
      handleChangeAnnotationShape(id, event);
      return;
    }

    handleActiveAnnotationChange(id);
  };
  const handleChangeNextColor = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();

    const currentColor = annotations.find(item => item.uuid === id).color;
    const preparedColors = colors.map(color => color.toLowerCase());
    const currentColorIndex = preparedColors.findIndex(color => color === currentColor.toLowerCase());

    if (currentColorIndex === -1) {
      const updatedAnnotations = annotations.map(annot => {
        if (annot.uuid === id) {
          return { ...annot, color: colors[1] };
        }
        return annot;
      });
      setAnnotations(updatedAnnotations);
      setActiveAnnotation({ ...activeAnnotation, color: colors[1] });
      return;
    }

    const updatedAnnotations = annotations.map(annot => {
      if (annot.uuid === id) {
        return { ...annot, color: colors[currentColorIndex + 1] || colors[0] };
      }
      return annot;
    });
    setAnnotations(updatedAnnotations);
    setActiveAnnotation({ ...activeAnnotation, color: colors[currentColorIndex + 1] || colors[0] });
  };
  const handleChangePrevColor = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();

    const currentColor = annotations.find(item => item.uuid === id).color;
    const preparedColors = colors.map(color => color.toLowerCase());
    const currentColorIndex = preparedColors.findIndex(color => color === currentColor.toLowerCase());

    if (currentColorIndex === -1) {
      const updatedAnnotations = annotations.map(annot => {
        if (annot.uuid === id) {
          return { ...annot, color: colors[colors.length - 1] };
        }
        return annot;
      });
      setAnnotations(updatedAnnotations);
      setActiveAnnotation({ ...activeAnnotation, color: colors[colors.length - 1] });
      return;
    }

    const updatedAnnotations = annotations.map(annot => {
      if (annot.uuid === id) {
        return { ...annot, color: colors[currentColorIndex - 1] || colors[colors.length - 1] };
      }
      return annot;
    });
    setAnnotations(updatedAnnotations);
    setActiveAnnotation({ ...activeAnnotation, color: colors[currentColorIndex - 1] || colors[colors.length - 1] });
  };

  // helper functions
  const calcWidth = (item: any, wrapperWidth: number) => {
    const x1InPx = item.x1 * wrapperWidth;
    const x2InPx = item.x2 * wrapperWidth;

    return x2InPx - x1InPx;
  };
  const calcHeight = (item: any, wrapperHeight: number) => {
    const y1InPx = item.y1 * wrapperHeight;
    const y2InPx = item.y2 * wrapperHeight;

    return y2InPx - y1InPx;
  };

  const imageWrap: any = imageWrapRef.current;
  const annotationLength = useMemo(() => annotations.filter((annot: any) => annot._destroy !== true).length, [
    annotations,
  ]);

  const currentColor = annotations.find(item => item.uuid === activeAnnotation?.uuid)?.color?.toLowerCase() || '';
  const currentColorIndex = colors.map(color => color.toLowerCase()).findIndex(color => color === currentColor);

  return (
    <StyledWrapper>
      <StyledAppBar position="sticky" color={theme.palette.secondary.white}>
        <StyledToolbar>
          <StyledIconButton edge="start" color="default" aria-label="back" onClick={props.onCloseMediaPanel}>
            <ArrowBackIcon />
          </StyledIconButton>

          <Grid container item alignItems="center" spacing={1} justify="flex-start" wrap="nowrap">
            <Typography variant="h4" style={{ marginRight: 14 }}>
              Opplastninger
            </Typography>

            <Typography variant="h6" align="center" style={{ whiteSpace: 'nowrap' }}>
              {props.imageName ? props.imageName : props.selectedMedia.name}
            </Typography>
          </Grid>
          <Grid container justify="flex-end">
            <OutlineButton padding="8px 10px" onClick={handleSaveAllAnnotations}>
              <StyledBox>
                <CheckMarkIcon style={{ marginRight: 4 }} />
              </StyledBox>
              Lagre og lukk
            </OutlineButton>
          </Grid>
        </StyledToolbar>
      </StyledAppBar>
      <StyledMediaPanel square>
        <Grid container direction="column" spacing={3}>
          <Grid item container justify="center" alignItems="center">
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                Klikk på bildet for å legge til en ny markering
              </Typography>
            </Grid>
          </Grid>

          <Grid item container justify="center">
            <StyledImageWrap component="div">
              <Box style={{ position: 'relative' }}>
                {annotations.map((item, idx) => {
                  if (item._destroy) return null;
                  return (
                    <Rnd
                      key={item.uuid}
                      id={`rnd-annotation-${idx}`}
                      disableDragging={item.uuid !== activeAnnotation?.uuid}
                      style={{ cursor: 'default' }}
                      enableResizing={{
                        top: item.uuid === activeAnnotation?.uuid,
                        right: item.uuid === activeAnnotation?.uuid,
                        bottom: item.uuid === activeAnnotation?.uuid,
                        left: item.uuid === activeAnnotation?.uuid,
                        topRight: false,
                        bottomRight: item.uuid === activeAnnotation?.uuid,
                        bottomLeft: item.uuid === activeAnnotation?.uuid,
                        topLeft: item.uuid === activeAnnotation?.uuid,
                      }}
                      tabIndex="0"
                      className="rnd-hover"
                      size={{
                        width: calcWidth(item, (imageWrap && imageWrap.width) || 0),
                        height: calcHeight(item, (imageWrap && imageWrap.height) || 0),
                      }}
                      position={{
                        x: item.x1 * (imageWrap && imageWrap.width) || 0,
                        y: item.y1 * (imageWrap && imageWrap.height) || 0,
                      }}
                      bounds="parent"
                      minWidth={90}
                      minHeight={90}
                      lockAspectRatio={item.a_type === 'circle'}
                      onDragStop={(e, d) => {
                        const imageWrap: any = imageWrapRef.current;
                        const updateAnnotations = annotations.map(annot => {
                          if (item.uuid === annot.uuid) {
                            const width = calcWidth(annot, imageWrap.width);
                            const height = calcHeight(annot, imageWrap.height);
                            return {
                              ...annot,
                              x1: d.x / imageWrap.width,
                              y1: d.y / imageWrap.height,
                              x2: (d.x + width) / imageWrap.width,
                              y2: (d.y + height) / imageWrap.height,
                            };
                          } else {
                            return annot;
                          }
                        });
                        setAnnotations(updateAnnotations);
                      }}
                      onResizeStop={(e, direction, ref, delta, position) => {
                        const imageWrap: any = imageWrapRef.current;
                        const updateAnnotations = annotations.map(annot => {
                          if (item.uuid === annot.uuid) {
                            return {
                              ...annot,
                              x1: position.x / imageWrap.width,
                              y1: position.y / imageWrap.height,
                              x2: (position.x + parseFloat(ref.style.width)) / imageWrap.width,
                              y2: (position.y + parseFloat(ref.style.height)) / imageWrap.height,
                            };
                          } else {
                            return annot;
                          }
                        });
                        setAnnotations(updateAnnotations);
                      }}
                    >
                      <StyledBadge
                        color={item.color}
                        borderRadius={item.a_type === 'square' ? '0' : '50%'}
                        isActive={item.uuid === activeAnnotation?.uuid}
                      >
                        {item.uuid === activeAnnotation?.uuid && (
                          <ImageResizeStyleWrap
                            color={item.color}
                            onDelete={(e: React.MouseEvent) => handleDeleteAnnotation(item.uuid, e)}
                          />
                        )}
                        {item.uuid === activeAnnotation?.uuid && (
                          <StyledLightLayout
                            isActive={item.uuid === activeAnnotation?.uuid}
                            borderRadius={item.a_type === 'square' ? '0' : '50%'}
                            color={item.color}
                          />
                        )}
                        <Grid container alignItems="center" justify="center" spacing={1}>
                          {item.uuid === activeAnnotation?.uuid && (
                            <Grid item>
                              <StyledColorBadge
                                color={
                                  currentColorIndex === -1
                                    ? colors[colors.length - 1]
                                    : colors[currentColorIndex - 1] || colors[colors.length - 1]
                                }
                                onClick={(e: React.MouseEvent) => handleChangePrevColor(item.uuid, e)}
                              />
                            </Grid>
                          )}
                          <Grid item>
                            <StyledNumberBadge
                              color={item.color}
                              borderRadius={item.a_type === 'square' ? '50%' : '0'}
                              onClick={(e: React.MouseEvent) => handleAnnotationClick(item.uuid, e)}
                            >
                              <StyledTypography
                                variant="body2"
                                color={getTextBadgeColor(
                                  item.uuid === activeAnnotation?.uuid ? activeAnnotation?.color : item.color,
                                )}
                              >
                                {item.order_number}
                              </StyledTypography>
                            </StyledNumberBadge>
                          </Grid>
                          {item.uuid === activeAnnotation?.uuid && (
                            <Grid item>
                              <StyledColorBadge
                                color={
                                  currentColorIndex === -1 ? colors[1] : colors[currentColorIndex + 1] || colors[0]
                                }
                                onClick={(e: React.MouseEvent) => handleChangeNextColor(item.uuid, e)}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </StyledBadge>
                    </Rnd>
                  );
                })}
                <StyledAttachmentImg
                  src={`${getBaseUrl()}/multimedia/image/${props.selectedMedia.image_id}?size=medium`}
                  onClick={e => handleAddNewAnnotation(e)}
                  ref={imageWrapRef}
                />
              </Box>
            </StyledImageWrap>
          </Grid>
          <Divider
            style={{
              marginBottom: '10px',
            }}
          />
          <Grid item container direction="column" spacing={3}>
            <Grid item>
              <StyledTypography variant="subtitle2">{`${annotationLength} markeringer`}</StyledTypography>
            </Grid>
            <Grid item>
              <AttachmentMediaDrags
                annotations={annotations}
                moveMediaDragItem={moveMediaDragItem}
                handleDeleteAnnotation={handleDeleteAnnotation}
                handleChangeName={handleChangeName}
                getTextBadgeColor={getTextBadgeColor}
              />
            </Grid>
          </Grid>

          {mediaMatch ? (
            <Grid item>
              <SecondarySaveOrCancel
                icon={CheckMarkDarkIcon}
                onSave={handleSaveAllAnnotations}
                onBackClick={props.onCloseMediaPanel}
              />
            </Grid>
          ) : (
            <OutlineBottomButton label="Lagre og lukk" icon={<CheckMarkIcon />} onClick={handleSaveAllAnnotations} />
          )}
        </Grid>
      </StyledMediaPanel>
    </StyledWrapper>
  );
};

const StyledMediaPanel = styled(({ textColor, color, ...props }) => <Paper {...props} />)`
  &.MuiPaper-root {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 18px 26px 26px;
    background-color: ${props => props.color};
    color: ${props => props.textColor || 'default'};
  }

  padding: 15px 12px;

  @media (max-width: 767px) {
    margin-bottom: 56px;
  }
`;

const StyledAttachmentImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const StyledImageWrap = styled(({ color, ...props }) => <Box {...props} />)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 20px 50px;

  .rnd-hover {
    display: block;

    &:focus {
      outline-width: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 50px;
  }
`;

const StyledTypography = styled(({ color, fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    ${props => props.color && 'color: ' + props.color};
  }
`;

const StyledBadge = styled(({ color, borderRadius, isActive, ...props }) => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #ffffff;
  width: 100%;
  height: 100%;
  border: 2px ${props => (props.isActive ? 'dashed' : 'solid')} ${props => props.color};
  background-color: transparent;
  border-radius: ${props => props.borderRadius};
`;
const StyledNumberBadge = styled(({ color, borderRadius, ...props }) => <Box {...props} />)`
  background-color: ${props => props.color};
  width: auto;
  height: auto;
  border-radius: ${props => props.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
`;

const StyledToolbar = styled(Toolbar)`
  height: 56px;
  &.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;
  }
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }

  @media (max-width: 767px) {
    &.MuiToolbar-gutters {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  @media (min-width: 768px) {
    height: 64px;
  }
`;
const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin-right: 10px;
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
`;
const StyledColorBadge = styled(({ color, ...props }) => <Box {...props} />)`
  background-color: ${props => props.color};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledLightLayout = styled(({ isActive, color, borderRadius, ...props }) => <Box {...props} />)`
  background-color: ${props => props.color};
  opacity: 0.25;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.borderRadius};
  position: absolute;
  z-index: -1;
`;

export default AttachmentMediaPanel;
