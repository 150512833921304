import React, { useState, useEffect, useMemo } from 'react';

import { Box, useMediaQuery } from '@material-ui/core';
import { Prompt, Route, Switch, Redirect, matchPath, useLocation } from 'react-router-dom';
import queryString from 'query-string';
// types
import { ConditionAssessmentModelType } from 'types/Building/ConditionAssessmentModelType';
import storage from 'helpers/storage';
// mocks
import { buildingTemplateMock } from './mocks/buildingTemplateMock';

import {
  fetchModels,
  fetchBuildingInfo,
  fetchBuildingEvents,
  fetchBuildingEvent,
  postDocument,
  deleteDocument,
} from 'services/fetchData';

// constants
import {
  childrenExpansionsTypes,
  deviationExpansionsTypes,
  workExpansionsTypes,
  taskExpansionsTypes,
  documentTypes,
} from '../../constants';

import ChildrenAppBar from './components/ChildrenAppBar/ChildrenAppBar';
import BuildingInfo from './components/BuildingInfo/BuildingInfo';

import ChildrenExpansionPanel from './components/ChildrenExpansionPanel/ChildrenExpansionPanel';
import DeviationExpansionPanel from './components/DeviationExpansionPanel/DeviationExpansionPanel';
import WorkExpansionPanel from './components/WorkExpansionPanel/WorkExpansionPanel';
import TaskExpansionPanel from './components/TaskExpansionPanel/TaskExpansionPanel';
import ViewCondition from './components/ViewCondition/ViewCondition';
import ViewDeviation from './components/ViewDeviation/ViewDeviation';
import ViewWork from './components/ViewWork/ViewWork';

import InfoBottomButton from './shared/InfoBottomButton/InfoBottomButton';

// expansion types values
const conditionExpansionTypes = Object.values(childrenExpansionsTypes);
const deviationExpansionTypes = Object.values(deviationExpansionsTypes);
const workExpansionTypes = Object.values(workExpansionsTypes);
const taskExpansionTypes = Object.values(taskExpansionsTypes);

const PROMPT_MESSAGE = 'Du har ikke lagrede endringer, og du vil miste endringene hvis du fortsetter. Er du sikker?';

const Building = (props: any) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const location = useLocation();
  // UI STATES
  const [expanded, setExpanded] = useState<string | false>(false);
  const [editedReport, setEditedReport] = useState<any>(null);
  const [editedDeviation, setEditedDeviation] = useState<any>(null);
  const [editedWork, setEditedWork] = useState<any>(null);
  const [editedTask, setEditedTask] = useState<any>(null);
  const [conditionExpanded, setConditionExpanded] = useState<string | false>(conditionExpansionTypes[0]);
  const [deviationExpanded, setDeviationExpanded] = useState<string | false>(deviationExpansionTypes[0]);
  const [workExpanded, setWorkExpanded] = useState<string | false>(workExpansionTypes[0]);
  const [taskExpanded, setTaskExpanded] = useState<string | false>(taskExpansionTypes[0]);

  // DATA STATES
  const [building, setBuilding] = useState<any>(null);
  const [buildingEvents, setBuildingEvents] = useState<any>([]);
  const [models, setModels] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [selectedSaveOption, setSelectedSaveOption] = useState<string | null>(null);
  const [isBuildingInFavorites, setBuildingInFavorites] = useState(false);
  const [editSaveTrigger, setEditSaveTrigger] = useState(false);
  const [isEditDocumentChanged, setEditDocumentChanged] = useState(false);

  const handleEditSaveTrigger = (status: boolean) => {
    setEditSaveTrigger(status);
  };

  const handleEditDocumentChanged = (status: boolean) => {
    setEditDocumentChanged(status);
  };

  const expansionTypesMap: {
    [keyof: string]: string[];
  } = {
    condition: conditionExpansionTypes,
    deviation: deviationExpansionTypes,
    work: workExpansionTypes,
    task: taskExpansionTypes,
  };

  const expandedMap: {
    [keyof: string]: {
      value: string | false;
      set: React.Dispatch<React.SetStateAction<string | false>>;
    };
  } = {
    condition: {
      value: conditionExpanded,
      set: setConditionExpanded,
    },
    deviation: {
      value: deviationExpanded,
      set: setDeviationExpanded,
    },
    work: {
      value: workExpanded,
      set: setWorkExpanded,
    },
    task: {
      value: taskExpanded,
      set: setTaskExpanded,
    },
  };

  useEffect(() => {
    if (isEditMode) {
      if (props.location.pathname.includes('edit')) return;

      const pathnameWithoutId = props.location.pathname.substr(0, props.location.pathname.lastIndexOf('/'));
      const id = props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1);

      props.history.push({ pathname: `${pathnameWithoutId}/edit/${id}`, search: props.location.search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  // PREPARE UI STATES IF DOCUMENTS URL ON MOUNT
  useEffect(() => {
    prepareEditedEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FETCH ALL DATA
  useEffect(() => {
    fetchBuildingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareEditedEvents = async () => {
    // condition
    if (props.location.pathname.includes('tilstand/new')) return;

    if (props.location.pathname.includes('tilstand')) {
      const id = props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1);
      const editReport = await fetchBuildingEvent(id);

      if (!editReport) {
        props.history.push(props.match.url);
        return;
      }

      setEditedReport(editReport);

      props.location.pathname.includes('edit') && setIsEditMode(true);
      return;
    }

    // deviation
    if (props.location.pathname.includes('avvik/new')) return;

    if (props.location.pathname.includes('avvik')) {
      const id = props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1);
      const editDeviation = await fetchBuildingEvent(id);

      if (!editDeviation) {
        props.history.push(props.match.url);
        return;
      }

      setEditedDeviation(editDeviation);

      props.location.pathname.includes('edit') && setIsEditMode(true);
      return;
    }

    // work
    if (props.location.pathname.includes('work/new')) return;
    if (props.location.pathname.includes('work')) {
      const id = props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1);
      const editWork = await fetchBuildingEvent(id);

      if (!editWork) {
        props.history.push(props.match.url);
        return;
      }

      setEditedWork(editWork);

      props.location.pathname.includes('edit') && setIsEditMode(true);
      return;
    }

    // task
    if (props.location.pathname.includes('task/new')) return;
    if (props.location.pathname.includes('task')) {
      const id = props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1);
      const editTask = await fetchBuildingEvent(id);

      if (!editTask) {
        props.history.push(props.match.url);
        return;
      }

      setEditedTask(editTask);

      props.location.pathname.includes('edit') && setIsEditMode(true);
      return;
    }
  };

  // SETTING BUILDING ALL STATE
  const fetchBuildingData = async () => {
    try {
      !props.location.pathname.includes('tilstand') &&
        setEditedReport(getModelWithoutMeta(models.BuildingConditionAssessmentEvent));

      !props.location.pathname.includes('avvik') &&
        setEditedDeviation(getModelWithoutMeta(models.BuildingObservationEvent));

      !props.location.pathname.includes('work') && setEditedWork(getModelWithoutMeta(models.BuildingTreatmentEvent));
      !props.location.pathname.includes('task') && setEditedTask(getModelWithoutMeta(models.task));

      // fetch models
      const fetchedModels = await fetchModels();
      setModels(fetchedModels);
      // fetch building info
      const fetchedInfo = await fetchBuildingInfo(props.match.params.id);
      setBuilding(fetchedInfo);
      // fetch building events
      const fetchedEvents = await fetchBuildingEvents(props.match.params.id);
      setBuildingEvents(fetchedEvents);
    } catch (error) {
      console.log(error);
    }
  };

  const updateBuildingData = async () => {
    // fetch building events
    const fetchedEvents = await fetchBuildingEvents(props.match.params.id);
    setBuildingEvents(fetchedEvents);

    // fetch building info
    const fetchedInfo = await fetchBuildingInfo(props.match.params.id);
    setBuilding(fetchedInfo);
  };

  // HANDLERS
  // reset expanded
  const resetExpanded = () => {
    Object.entries(expandedMap).forEach(([type, { value, set }]) => {
      set(expansionTypesMap[type][0]);
    });
  };
  // const handleSelectedSaveOptionChange = (option: string) => {
  //   setSelectedSaveOption(option);
  // };
  const handleEditMode = (status: boolean) => {
    setIsEditMode(status);
  };
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleOpenNewReport = () => {
    props.history.push(`${props.match.url}/tilstand/new`);
  };
  const handleOpenEditReport = async (id: string) => {
    const editReport = await fetchBuildingEvent(id);

    setEditedReport(editReport);

    props.history.push(`${props.match.url}/tilstand/${id}`);
  };
  const handleOpenNewDeviation = () => {
    props.history.push(`${props.match.url}/avvik/new`);
  };
  const handleReturnBackToInfo = async () => {
    if (props.location?.state?.fromMain || props.location?.state?.fromSearch) {
      props.history.push('/');
      return;
    }
    await updateBuildingData();
    resetExpanded();
    props.history.push(props.match.url);
  };
  // expand handlers
  const handleNextExpansion = (type: string) => {
    const currentIndex = expansionTypesMap[type].findIndex(item => item === expandedMap[type].value);
    expandedMap[type].set(expansionTypesMap[type][currentIndex + 1]);
  };
  const handlePrevExpansion = (type: string) => {
    const currentIndex = expansionTypesMap[type].findIndex(item => item === expandedMap[type].value);
    expandedMap[type].set(expansionTypesMap[type][currentIndex - 1]);
  };
  const handleExpansionChange = (type: string) => (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    expandedMap[type].set(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (
      props.location.pathname.includes('tilstand') ||
      props.location.pathname.includes('avvik') ||
      props.location.pathname.includes('work') ||
      props.location.pathname.includes('task')
    )
      return;

    isEditMode && setIsEditMode(false);

    // setSelectedSaveOption(null);
    setEditDocumentChanged(false);
    setEditSaveTrigger(false);
    setEditedReport(getModelWithoutMeta(models.BuildingConditionAssessmentEvent));
    setEditedDeviation(getModelWithoutMeta(models.BuildingObservationEvent));
    setEditedWork(getModelWithoutMeta(models.BuildingTreatmentEvent));
    setEditedTask(getModelWithoutMeta(models.task));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname, isEditMode]);

  const handleAppBarBack = () => {
    // check if user go from search and push user to search back
    if (props.location?.state?.fromSearch && props.location?.state?.query) {
      props.history.push({
        pathname: '/search',
        search: `query=${props.location.state.query}`,
      });

      return;
    }

    if (isReportCondition || isDeviationCondition || isWorkCondition || isTaskCondition) {
      handleReturnBackToInfo();
      resetExpanded();
      return;
    }
    // collapse panel if expanded when mobile instead back to main
    if (
      !mediaMatch &&
      !isReportCondition &&
      !isDeviationCondition &&
      !isWorkCondition &&
      !isTaskCondition &&
      expanded
    ) {
      setExpanded(false);
      return;
    }

    props.history.push('/');
  };

  const handleAddNewConditionReport = async (newReport: any, isFirstSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(newReport);

    if (!isFirstSaved) {
      const editReport = await fetchBuildingEvent(id);
      setEditedReport(editReport);
      return;
    }

    setEditDocumentChanged(false);
    setEditSaveTrigger(false);
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };

  const handleUpdateConditionReport = async (updatedReport: any, isLastSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(updatedReport);

    setEditDocumentChanged(false);
    setEditSaveTrigger(false);

    if (!isLastSaved) {
      const editReport = await fetchBuildingEvent(id);
      setEditedReport(editReport);
      return;
    }

    setIsEditMode(false);
    setEditedReport(getModelWithoutMeta(models.BuildingConditionAssessmentEvent));
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };
  const handleDeleteConditionReport = async (id: string) => {
    await deleteDocument(id);
    await updateBuildingData();
  };
  const handleAddNewDeviation = async (newDeviation: any, isFirstSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(newDeviation);

    if (!isFirstSaved) {
      const editDeviation = await fetchBuildingEvent(id);
      setEditedDeviation(editDeviation);
      return;
    }

    setEditDocumentChanged(false);
    setEditSaveTrigger(false);
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };
  const handleOpenEditDeviation = async (id: string) => {
    const editDeviation = await fetchBuildingEvent(id);

    setEditedDeviation(editDeviation);

    props.history.push(`${props.match.url}/avvik/${id}`);
  };
  const handleUpdateDeviation = async (updatedDeviation: any, isLastSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(updatedDeviation);

    setEditDocumentChanged(false);
    setEditSaveTrigger(false);

    if (!isLastSaved) {
      const editDeviation = await fetchBuildingEvent(id);
      setEditedDeviation(editDeviation);
      return;
    }

    setIsEditMode(false);
    setEditedDeviation(getModelWithoutMeta(models.BuildingObservationEvent));
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };
  const handleDeleteDeviation = async (id: string) => {
    await deleteDocument(id);
    await updateBuildingData();
  };

  const handleOpenNewWork = () => {
    props.history.push(`${props.match.url}/work/new`);
  };
  const handleOpenEditWork = async (id: string) => {
    const editWork = await fetchBuildingEvent(id);

    setEditedWork(editWork);

    props.history.push(`${props.match.url}/work/${id}`);
  };
  const handleAddNewWork = async (newWork: any, isFirstSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(newWork);

    if (!isFirstSaved) {
      const editWork = await fetchBuildingEvent(id);
      setEditedWork(editWork);
      return;
    }

    setEditDocumentChanged(false);
    setEditSaveTrigger(false);
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };
  const handleUpdateWork = async (updatedWork: any, isLastSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(updatedWork);

    setEditSaveTrigger(false);
    setEditDocumentChanged(false);

    if (!isLastSaved) {
      const editWork = await fetchBuildingEvent(id);
      setEditedWork(editWork);
      return;
    }

    setIsEditMode(false);
    setEditedWork(getModelWithoutMeta(models.BuildingTreatmentEvent));
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };
  const handleDeleteWork = async (id: string) => {
    await deleteDocument(id);
    await updateBuildingData();
  };

  const handleOpenNewTask = (linkedObjectId?: string) => {
    let linkedIdQuery = '';

    if (linkedObjectId && typeof linkedObjectId === 'string') {
      linkedIdQuery = queryString.stringify({ linkedId: linkedObjectId });
    }

    props.history.push({
      pathname: `${props.match.url}/task/new`,
      search: linkedIdQuery,
    });
  };

  const handleOpenEditTask = async (id: string, linkedObjectId?: string) => {
    let linkedIdQuery = '';

    if (linkedObjectId && typeof linkedObjectId === 'string') {
      linkedIdQuery = queryString.stringify({ linkedId: linkedObjectId });
    }

    const editTask = await fetchBuildingEvent(id);

    setEditedTask(editTask);

    props.history.push({ pathname: `${props.match.url}/task/${id}`, search: linkedIdQuery });
  };
  const handleAddNewTask = async (newTask: any, isFirstSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(newTask);

    if (!isFirstSaved) {
      const editTask = await fetchBuildingEvent(id);
      setEditedTask(editTask);
      return;
    }

    setEditDocumentChanged(false);
    setEditSaveTrigger(false);
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };
  const handleUpdateTask = async (updatedTask: any, isLastSaved?: boolean) => {
    const { artifact_id: id } = await postDocument(updatedTask);

    setEditDocumentChanged(false);
    setEditSaveTrigger(false);

    if (!isLastSaved) {
      const editTask = await fetchBuildingEvent(id);
      setEditedTask(editTask);
      return;
    }

    setIsEditMode(false);
    setEditedTask(getModelWithoutMeta(models.task));
    props.history.push({
      pathname: `${props.match.url}`,
      state: { save: true },
    });
    await updateBuildingData();
    resetExpanded();
  };
  const handleDeleteTask = async (id: string) => {
    await deleteDocument(id);
    await updateBuildingData();
  };

  // FAVORITES
  const addBuildingToFavorites = (id: any) => {
    const currentIDs = storage.load('favoriteBuildingIDs') || [];
    storage.save('favoriteBuildingIDs', [...currentIDs, id]);
    setBuildingInFavorites(true);
  };
  const removeBuildingFromFavorites = (id: string) => {
    const currentIDs = storage.load('favoriteBuildingIDs') || [];
    const updatedIDs = currentIDs.filter((currentID: string) => currentID !== id);
    storage.save('favoriteBuildingIDs', updatedIDs);
    setBuildingInFavorites(false);
  };

  const checkIsBuildingInFavorites = () => {
    const currentIDs = storage.load('favoriteBuildingIDs') || [];
    if (currentIDs.includes(building?.artifact_id)) {
      setBuildingInFavorites(true);
    }
  };

  // set is building in favorites
  useEffect(() => {
    checkIsBuildingInFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  // DOCUMENTS CONDITIONS
  const isNewReportCondition = props.location.pathname.includes('tilstand/new');
  const isNewDeviationCondition = props.location.pathname.includes('avvik/new');
  const isNewWorkCondition = props.location.pathname.includes('work/new');
  const isNewTaskCondition = props.location.pathname.includes('task/new');
  const isViewReportCondition =
    props.location.pathname.includes('tilstand') &&
    props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1) !== 'new';
  const isViewDeviationCondition =
    props.location.pathname.includes('avvik') &&
    props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1) !== 'new';
  const isViewWorkCondition =
    props.location.pathname.includes('work') &&
    props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1) !== 'new';
  const isViewTaskCondition =
    props.location.pathname.includes('task') &&
    props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1) !== 'new';

  const isReportCondition = isNewReportCondition || isViewReportCondition;
  const isDeviationCondition = isNewDeviationCondition || isViewDeviationCondition;
  const isWorkCondition = isNewWorkCondition || isViewWorkCondition;
  const isTaskCondition = isNewTaskCondition || isViewTaskCondition;
  const isEditDocumentCondition = props.location.pathname.includes('edit');

  // MODELS
  const getModelWithoutMeta = (model: any): any => {
    const copyModel = { ...model };
    delete copyModel.$$meta;
    return copyModel;
  };
  const reportModel: ConditionAssessmentModelType = useMemo(
    () => getModelWithoutMeta(models?.BuildingConditionAssessmentEvent),
    [models],
  );
  const deviationModel = useMemo(() => getModelWithoutMeta(models?.BuildingObservationEvent), [models]);
  const workModel = useMemo(() => getModelWithoutMeta(models?.BuildingTreatmentEvent), [models]);
  const taskModel = useMemo(() => getModelWithoutMeta(models?.task), [models]);

  // CALC DOCUMENT INFORMATION
  const getDocumentStatus = () => {
    if (isViewDeviationCondition) {
      if (!editedDeviation) return;

      return editedDeviation?.status?.status_type_id_value || 'Status';
    }
    if (isViewReportCondition) {
      return 'Publisert';
    }
    if (isViewWorkCondition) {
      return 'Utkast';
    }
    if (isViewTaskCondition) {
      return editedTask?.status?.status_type_id_value || 'Utkast';
    }

    if (
      !isReportCondition &&
      !isDeviationCondition &&
      !isViewWorkCondition &&
      !isViewTaskCondition &&
      building !== null
    ) {
      return (
        (building.status && building.status.building_status_id_value) || (building.published === true && 'Publisert')
      );
    }
    return 'Status';
  };
  const getDocumentCurrentStatus = () => {
    if (isViewDeviationCondition) {
      return editedDeviation?.status?.status_type_id_value || '';
    }
    if (isViewReportCondition) {
      return '';
    }
    if (isViewWorkCondition) {
      return '';
    }
    if (isViewTaskCondition) {
      return editedTask?.status?.status_type_id_value || '';
    }

    return '';
  };
  const getDocumentAppBarTitle = (): string[] => {
    if (isViewReportCondition) {
      if (editedReport) {
        return [
          (editedReport.title && editedReport.title.title) ||
            (editedReport.purpose && editedReport.purpose.purpose_type_id_value) ||
            editedReport.identifier,
        ];
      }
    }
    if (isViewDeviationCondition) {
      if (editedDeviation) {
        return [
          (editedDeviation.title && editedDeviation.title.title && editedDeviation.title.title) ||
            editedDeviation.damage?.damage_type.damage_type_id_value,
        ];
      }
    }
    if (isViewWorkCondition) {
      if (editedWork) {
        return [editedWork?.title?.title ?? editedWork.artifact_type_id_value];
      }
    }
    if (isViewTaskCondition) {
      const linkedId = queryString.parse(props.location.search)?.linkedId as string;
      if (linkedId) {
        const currentDocument = buildingEvents.find(
          (item: any) => item.artifact_id === linkedId || item.uuid === linkedId,
        );
        return [
          currentDocument?.artifact_name.match(': (.*)')[1],
          editedTask?.title ?? editedTask?.artifact_type_id_value ?? '',
        ];
      }
      return [editedTask?.title ?? editedTask?.artifact_type_id_value ?? ''];
    }
    if (isNewReportCondition || isNewDeviationCondition || isNewWorkCondition || isNewTaskCondition) {
      const linkedId = queryString.parse(props.location.search)?.linkedId as string;
      if (linkedId) {
        const currentDocument = buildingEvents.find(
          (item: any) => item.artifact_id === linkedId || item.uuid === linkedId,
        );
        return [currentDocument?.artifact_name.match(': (.*)')[1], 'Uten tittel'];
      }
      return ['Uten tittel'];
    }

    return [''];
  };

  const promptCondition =
    isNewReportCondition ||
    isNewDeviationCondition ||
    isNewWorkCondition ||
    isNewTaskCondition ||
    (isEditDocumentCondition && isEditDocumentChanged);

  // get functions
  const getCurrentExpansionIndex = (type: string) => {
    return expansionTypesMap[type].findIndex(item => item === expandedMap[type].value);
  };
  const getCurrentStepNumber = (type: string) => {
    const currentExpansionIndex = getCurrentExpansionIndex(type);
    const currentStepNumber = currentExpansionIndex !== -1 ? currentExpansionIndex + 1 : 0;

    return currentStepNumber;
  };
  const getStepsAmount = (type: string) => {
    return expansionTypesMap[type].length;
  };
  const getStepsForAppBar = () => {
    if (isReportCondition) return getStepsAmount(documentTypes.CONDITION);
    if (isDeviationCondition) return getStepsAmount(documentTypes.DEVIATION);
    if (isWorkCondition) return getStepsAmount(documentTypes.WORK);
    if (isTaskCondition) return getStepsAmount(documentTypes.TASK);
    return 0;
  };
  const getCurrentStepForAppBar = () => {
    if (isReportCondition) return getCurrentStepNumber(documentTypes.CONDITION);
    if (isDeviationCondition) return getCurrentStepNumber(documentTypes.DEVIATION);
    if (isWorkCondition) return getCurrentStepNumber(documentTypes.WORK);
    if (isTaskCondition) return getCurrentStepNumber(documentTypes.TASK);
    return 0;
  };
  const getCurrentDocumentType = () => {
    if (isReportCondition) return documentTypes.CONDITION;
    if (isDeviationCondition) return documentTypes.DEVIATION;
    if (isWorkCondition) return documentTypes.WORK;
    if (isTaskCondition) return documentTypes.TASK;
    return 'Unknown type';
  };
  const getCurrentObjectDate = () => {
    if (isReportCondition) return editedReport?.updated_at?.dd_date || 1;
    if (isDeviationCondition) return editedDeviation?.updated_at?.dd_date || 1;
    if (isWorkCondition) return editedWork?.updated_at?.dd_date || 1;
    if (isTaskCondition) return editedTask?.updated_at?.dd_date || 1;
    return building !== null && building.updated_at !== null ? building.updated_at.dd_date : 1;
  };
  const getCurrentDocumentAcl = () => {
    if (isReportCondition) return editedReport?.$$acl;
    if (isDeviationCondition) return editedDeviation?.$$acl;
    if (isWorkCondition) return editedWork?.$$acl;
    if (isTaskCondition) return editedTask?.$$acl;
    return { restrictions: [] };
  };
  const getViewTasks = () => {
    if (editedReport?.artifact_id) {
      return buildingEvents.filter((event: any) =>
        event['contexts.context_artifact_id'].includes(editedReport.artifact_id),
      );
    }

    if (editedDeviation?.artifact_id) {
      return buildingEvents.filter((event: any) =>
        event['contexts.context_artifact_id'].includes(editedDeviation.artifact_id),
      );
    }

    if (editedWork?.artifact_id) {
      return buildingEvents.filter((event: any) =>
        event['contexts.context_artifact_id'].includes(editedWork.artifact_id),
      );
    }

    return [];
  };

  const viewTasks = useMemo(
    () => getViewTasks(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editedReport, editedDeviation, editedWork, buildingEvents],
  );

  const buildingImagesAnnotations = useMemo(
    () =>
      buildingEvents.reduce((acc: any[], event: any) => {
        if (event.has_annotation_events && event.annotation_events) return [...acc, ...event.annotation_events];
        return acc;
      }, []),
    [buildingEvents],
  );

  const preventEditing = matchPath(location.pathname, props.match.path)?.isExact;

  return (
    <>
      <Box component="section">
        <ChildrenAppBar
          title={
            building === null || building.designations.length === 0
              ? `Bygning`
              : building.designations[0].designation_type_id_value
          }
          id={!building ? '' : building.identifier}
          date={getCurrentObjectDate()}
          status={getDocumentStatus()}
          isNewReportCondition={isNewReportCondition}
          isNewDeviationCondition={isNewDeviationCondition}
          isViewReportCondition={isViewReportCondition}
          isViewDeviationCondition={isViewDeviationCondition}
          isNewWorkCondition={isNewWorkCondition}
          isViewWorkCondition={isViewWorkCondition}
          isNewTaskCondition={isNewTaskCondition}
          isViewTaskCondition={isViewTaskCondition}
          documentTitle={getDocumentAppBarTitle()}
          onBack={handleAppBarBack}
          isEditMode={isEditMode}
          handleEditMode={handleEditMode}
          currentStepNumber={getCurrentStepForAppBar()}
          stepsAmount={getStepsForAppBar()}
          currentDocumentType={getCurrentDocumentType()}
          currentDocumentStatus={getDocumentCurrentStatus()}
          building={building === null ? buildingTemplateMock : building}
          documentAcl={getCurrentDocumentAcl()}
          addBuildingToFavorites={() => addBuildingToFavorites(building.artifact_id)}
          removeBuildingFromFavorites={() => removeBuildingFromFavorites(building.artifact_id)}
          isFavorites={isBuildingInFavorites}
          handleEditSaveTrigger={handleEditSaveTrigger}
          isEditDocumentChanged={isEditDocumentChanged}
          preventEditing={preventEditing}
        />
        <Switch>
          <Route
            path={props.match.path}
            exact
            render={props => (
              <>
                <BuildingInfo
                  {...props}
                  expanded={expanded}
                  handleChange={handleChange}
                  handleOpenNewReport={handleOpenNewReport}
                  handleOpenEditReport={handleOpenEditReport}
                  buildingEvents={buildingEvents}
                  handleDeleteConditionReport={handleDeleteConditionReport}
                  handleDeleteDeviation={handleDeleteDeviation}
                  handleDeleteWork={handleDeleteWork}
                  handleDeleteTask={handleDeleteTask}
                  handleOpenNewDeviation={handleOpenNewDeviation}
                  handleOpenEditDeviation={handleOpenEditDeviation}
                  handleOpenNewWork={handleOpenNewWork}
                  handleOpenEditWork={handleOpenEditWork}
                  handleOpenNewTask={handleOpenNewTask}
                  handleOpenEditTask={handleOpenEditTask}
                  handleEditMode={handleEditMode}
                  building={building === null ? buildingTemplateMock : building}
                  annotations={buildingImagesAnnotations}
                />
                {!mediaMatch && !expanded && (
                  <InfoBottomButton
                    date={building !== null && building.updated_at !== null ? building.updated_at.dd_date : 1}
                    status={getDocumentStatus()}
                  />
                )}
              </>
            )}
          />
          {/* CONDITION ROUTES */}
          <Route
            exact
            path={[`${props.match.path}/tilstand/new`, `${props.match.path}/tilstand/edit/:condition`]}
            render={props => (
              <ChildrenExpansionPanel
                {...props}
                handleReturnBackToInfo={handleReturnBackToInfo}
                handleAddNewConditionReport={handleAddNewConditionReport}
                handleUpdateConditionReport={handleUpdateConditionReport}
                editedReport={editedReport}
                isViewReportCondition={isViewReportCondition}
                reportModel={reportModel}
                building={building === null ? buildingTemplateMock : building}
                isEditMode={isEditMode}
                handleEditMode={handleEditMode}
                handleNextExpansion={() => handleNextExpansion(documentTypes.CONDITION)}
                handlePrevExpansion={() => handlePrevExpansion(documentTypes.CONDITION)}
                handleExpansionChange={handleExpansionChange(documentTypes.CONDITION)}
                expanded={conditionExpanded}
                currentStepNumber={getCurrentStepNumber(documentTypes.CONDITION)}
                stepsAmount={getStepsAmount(documentTypes.CONDITION)}
                currentExpansionIndex={getCurrentExpansionIndex(documentTypes.CONDITION)}
                handleEditSaveTrigger={handleEditSaveTrigger}
                editSaveTrigger={editSaveTrigger}
                isEditDocumentChanged={isEditDocumentChanged}
                handleEditDocumentChanged={handleEditDocumentChanged}
              />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/tilstand/:condition`}
            render={props => (
              <ViewCondition
                {...props}
                condition={editedReport}
                isEditMode={isEditMode}
                handleEditMode={handleEditMode}
                tasks={viewTasks}
                handleDeleteTask={handleDeleteTask}
                handleOpenEditTask={handleOpenEditTask}
                handleOpenNewTask={handleOpenNewTask}
                building={building === null ? buildingTemplateMock : building}
              />
            )}
          />
          {/* DEVIATION ROUTES */}
          <Route
            exact
            path={[`${props.match.path}/avvik/new`, `${props.match.path}/avvik/edit/:deviationId`]}
            render={props => (
              <DeviationExpansionPanel
                {...props}
                handleReturnBackToInfo={handleReturnBackToInfo}
                deviationModel={deviationModel}
                building={building === null ? buildingTemplateMock : building}
                handleAddNewDeviation={handleAddNewDeviation}
                handleUpdateDeviation={handleUpdateDeviation}
                editedDeviation={editedDeviation}
                isViewDeviationCondition={isViewDeviationCondition}
                isEditMode={isEditMode}
                handleEditMode={handleEditMode}
                handleNextExpansion={() => handleNextExpansion(documentTypes.DEVIATION)}
                handlePrevExpansion={() => handlePrevExpansion(documentTypes.DEVIATION)}
                handleExpansionChange={handleExpansionChange(documentTypes.DEVIATION)}
                expanded={deviationExpanded}
                currentStepNumber={getCurrentStepNumber(documentTypes.DEVIATION)}
                stepsAmount={getStepsAmount(documentTypes.DEVIATION)}
                currentExpansionIndex={getCurrentExpansionIndex(documentTypes.DEVIATION)}
                handleEditSaveTrigger={handleEditSaveTrigger}
                editSaveTrigger={editSaveTrigger}
                isEditDocumentChanged={isEditDocumentChanged}
                handleEditDocumentChanged={handleEditDocumentChanged}
              />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/avvik/:deviationId`}
            render={props => (
              <ViewDeviation
                {...props}
                deviation={editedDeviation}
                isEditMode={isEditMode}
                handleEditMode={handleEditMode}
                tasks={viewTasks}
                handleDeleteTask={handleDeleteTask}
                handleOpenEditTask={handleOpenEditTask}
                handleOpenNewTask={handleOpenNewTask}
                building={building === null ? buildingTemplateMock : building}
              />
            )}
          />
          {/* WORK ROUTES */}
          <Route
            exact
            path={[`${props.match.path}/work/new`, `${props.match.path}/work/edit/:workId`]}
            render={props => (
              <WorkExpansionPanel
                {...props}
                handleReturnBackToInfo={handleReturnBackToInfo}
                handleAddNewWork={handleAddNewWork}
                handleUpdateWork={handleUpdateWork}
                editedWork={editedWork}
                isViewWorkCondition={isViewWorkCondition}
                workModel={workModel}
                building={building === null ? buildingTemplateMock : building}
                isEditMode={isEditMode}
                handleEditMode={handleEditMode}
                handleNextExpansion={() => handleNextExpansion(documentTypes.WORK)}
                handlePrevExpansion={() => handlePrevExpansion(documentTypes.WORK)}
                handleExpansionChange={handleExpansionChange(documentTypes.WORK)}
                expanded={workExpanded}
                currentStepNumber={getCurrentStepNumber(documentTypes.WORK)}
                stepsAmount={getStepsAmount(documentTypes.WORK)}
                currentExpansionIndex={getCurrentExpansionIndex(documentTypes.WORK)}
                handleEditSaveTrigger={handleEditSaveTrigger}
                editSaveTrigger={editSaveTrigger}
                isEditDocumentChanged={isEditDocumentChanged}
                handleEditDocumentChanged={handleEditDocumentChanged}
              />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/work/:workId`}
            render={props => (
              <ViewWork
                {...props}
                work={editedWork}
                isEditMode={isEditMode}
                handleEditMode={handleEditMode}
                tasks={viewTasks}
                handleDeleteTask={handleDeleteTask}
                handleOpenEditTask={handleOpenEditTask}
                handleOpenNewTask={handleOpenNewTask}
                building={building === null ? buildingTemplateMock : building}
              />
            )}
          />
          {/* TASK ROUTE */}
          <Route
            exact
            path={[
              `${props.match.path}/task/new`,
              `${props.match.path}/task/edit/:taskId`,
              `${props.match.path}/task/:taskId`,
            ]}
            render={props => (
              <TaskExpansionPanel
                {...props}
                handleReturnBackToInfo={handleReturnBackToInfo}
                handleAddNewTask={handleAddNewTask}
                handleUpdateTask={handleUpdateTask}
                editedTask={editedTask}
                isViewTaskCondition={isViewTaskCondition}
                taskModel={taskModel}
                building={building === null ? buildingTemplateMock : building}
                isEditMode={isEditMode}
                handleEditMode={handleEditMode}
                handleNextExpansion={() => handleNextExpansion(documentTypes.TASK)}
                handlePrevExpansion={() => handlePrevExpansion(documentTypes.TASK)}
                handleExpansionChange={handleExpansionChange(documentTypes.TASK)}
                expanded={taskExpanded}
                currentStepNumber={getCurrentStepNumber(documentTypes.TASK)}
                stepsAmount={getStepsAmount(documentTypes.TASK)}
                currentExpansionIndex={getCurrentExpansionIndex(documentTypes.TASK)}
                buildingEvents={buildingEvents}
                handleEditSaveTrigger={handleEditSaveTrigger}
                editSaveTrigger={editSaveTrigger}
                isEditDocumentChanged={isEditDocumentChanged}
                handleEditDocumentChanged={handleEditDocumentChanged}
              />
            )}
          />
          <Redirect to={`${props.match.url}`} />
        </Switch>
      </Box>
      {/* CHECK IF USER BACK TO MAIN PAGE OR BUILDING INFO WHEN DOCUMENT NEW OR EDIT CONDITION */}
      <Prompt
        message={location => {
          return promptCondition && !location?.state?.hasOwnProperty('save') ? PROMPT_MESSAGE : true;
        }}
        when={promptCondition}
      />
    </>
  );
};

export default Building;
