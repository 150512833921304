import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';

import { getBaseUrl } from 'services/fetchData';
import AsyncFormInput from '../../../shared/AsyncFormInput/AsyncFormInput';
import AsyncFormSelect from '../../../shared/AsyncFormSelect/AsyncFormSelect';
import FormTextArea from '../../../shared/FormTextArea/FormTextArea';

interface Props {
  onSaveEditPanel: () => void;
  consequence: string;
  condition: string;
  structure: string;
  description: string;
  handleStructureChange: (value: string, options: any[]) => void;
  handleConsequenceChange: (value: string, options: any[]) => void;
  handleConditionChange: (value: string, options: any[]) => void;
  handleDescriptionChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  actions: any[] | [];
  moveEditDragItem: (dragIndex: any, hoverIndex: any) => void;
  onCloseEditPanel: () => void;
  handleDeleteAction: (id: string) => void;
  isEditing: boolean;
  isEditMode: boolean;
}

const AssessmentEditForm: React.FC<Props> = props => {
  const mediaMatch = useMediaQuery('(min-width: 768px)');
  // CONDITION OPTIONS STATE
  const [conditionOptionsOpen, setConditionOptionsOpen] = React.useState(false);
  const [conditionOptions, setConditionOptions] = React.useState<any[]>([]);
  const conditionLoading = conditionOptionsOpen && conditionOptions.length === 0;

  // CONSEQUENCE OPTIONS STATE
  const [consequenceOptionsOpen, setConsequenceOptionsOpen] = React.useState(false);
  const [consequenceOptions, setConsequenceOptions] = React.useState<any[]>([]);
  const consequenceLoading = consequenceOptionsOpen && consequenceOptions.length === 0;

  // BUILDING PARTS OPTIONS STATE
  const [buildingPartOptionsOpen, setBuildingPartOptionsOpen] = React.useState(false);
  const [buildingPartOptions, setBuildingPartOptions] = React.useState<any[]>([]);
  const buildingPartLoading = buildingPartOptionsOpen && buildingPartOptions.length === 0;

  // CONDITIONS OPTIONS FETCHING AND SET
  useEffect(() => {
    let active = true;
    if (!conditionLoading) {
      return undefined;
    }
    (async () => {
      try {
        const response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_51',
              '-marked': true,
              filter_field_id: ['ct_84-3'],
            },
            sort: 'artifact_id',
            rows: 200,
          }),
        });
        if (!response.ok) throw new Error('something went wrong!');
        const options = await response.json();

        if (active) {
          setConditionOptions(options.artifacts.map((option: any) => ({ title: option.artifact_name, id: option.id })));
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [conditionLoading]);
  useEffect(() => {
    if (!conditionOptionsOpen) {
      setConditionOptions([]);
    }
  }, [conditionOptionsOpen]);

  // CONSEQUENCE OPTIONS FETCHING AND SET
  useEffect(() => {
    let active = true;
    if (!consequenceLoading) {
      return undefined;
    }
    (async () => {
      try {
        const response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_71',
              '-marked': true,
            },
            sort: 'artifact_id',
            rows: 200,
          }),
        });
        if (!response.ok) throw new Error('something went wrong!');
        const options = await response.json();

        if (active) {
          setConsequenceOptions(
            options.artifacts.map((option: any) => ({ title: option.artifact_name, id: option.id })),
          );
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [consequenceLoading]);
  useEffect(() => {
    if (!consequenceOptionsOpen) {
      setConsequenceOptions([]);
    }
  }, [consequenceOptionsOpen]);

  // BUILDING PART OPTIONS FETCHING AND SET
  useEffect(() => {
    let active = true;
    if (!buildingPartLoading) {
      return undefined;
    }
    (async () => {
      try {
        // LEVEL 2 BUILDING PARTS FETCH
        const level2Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 2,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level2Response.ok) throw new Error('something went wrong!');
        const level2Options = await level2Response.json();

        // LEVEL 3 BUILDING PARTS FETCH
        const level3Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 3,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level3Response.ok) throw new Error('something went wrong!');
        const level3Options = await level3Response.json();
        const options = [...level2Options.artifacts, ...level3Options.artifacts].sort(
          (prev, next) => Number(prev.code) - Number(next.code),
        );

        if (active) {
          setBuildingPartOptions(options.map((option: any) => ({ title: option.artifact_name, id: option.id })));
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [buildingPartLoading]);

  // SETTING CONDITION AND CONSEQUENCE AUTOCOMPLETES OPEN STATUS
  const setConditionOpenStatus = (status: boolean) => {
    setConditionOptionsOpen(status);
  };
  const setConsequenceOpenStatus = (status: boolean) => {
    setConsequenceOptionsOpen(status);
  };
  // SETTING BUILDING PARTS AUTOCOMPLETES OPEN STATUS
  const setBuildingPartOpenStatus = (status: boolean) => {
    setBuildingPartOptionsOpen(status);
  };

  // PREFETCH BUILDING PART OPTIONS IF EDITING
  useEffect(() => {
    if (!props.isEditMode) return;
    (async () => {
      try {
        // LEVEL 2 BUILDING PARTS FETCH
        const level2Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 2,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level2Response.ok) throw new Error('something went wrong!');
        const level2Options = await level2Response.json();

        // LEVEL 3 BUILDING PARTS FETCH
        const level3Response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: {
              object_type: 'ct_86',
              level: 3,
              '-marked': true,
            },
            rows: 10000,
            start: 0,
          }),
        });
        if (!level3Response.ok) throw new Error('something went wrong!');
        const level3Options = await level3Response.json();
        const options = [...level2Options.artifacts, ...level3Options.artifacts].sort(
          (prev, next) => Number(prev.code) - Number(next.code),
        );
        setBuildingPartOptions(options.map((option: any) => ({ title: option.artifact_name, id: option.id })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.isEditMode]);
  //

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <AsyncFormInput
          value={props.structure}
          handleChange={props.handleStructureChange}
          options={buildingPartOptions}
          placeholder="Søk og velg bygningsdel"
          label="Bygningsdel"
          loading={buildingPartLoading}
          open={buildingPartOptionsOpen}
          setOpenStatus={setBuildingPartOpenStatus}
          regular={mediaMatch ? false : true}
          required
          arrowDown
          shrink
        />
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.description}
          handleChange={props.handleDescriptionChange}
          label="Beskrivelse av bygningsdel"
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={mediaMatch ? 6 : 12} style={{ marginBottom: mediaMatch ? 0 : 16 }}>
          <AsyncFormSelect
            value={props.condition}
            handleChange={props.handleConditionChange}
            options={conditionOptions}
            placeholder="Velg tilstansgrad"
            label="Tilstansgrad"
            loading={conditionLoading}
            open={conditionOptionsOpen}
            setOpenStatus={setConditionOpenStatus}
            required
          />
        </Grid>
        <Grid item xs={mediaMatch ? 6 : 12}>
          <AsyncFormSelect
            value={props.consequence}
            handleChange={props.handleConsequenceChange}
            options={consequenceOptions}
            placeholder="Velg konsekvensgrad"
            label="Konsekvensgrad"
            loading={consequenceLoading}
            open={consequenceOptionsOpen}
            setOpenStatus={setConsequenceOpenStatus}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssessmentEditForm;
