import _ from 'lodash';

const getPriorityNumber = _.cond<string, number>([
  [_.curry(_.eq)('03 Lav'), _.constant(3)],
  [_.curry(_.eq)('02 Medium'), _.constant(2)],
  [_.curry(_.eq)('01 Høy'), _.constant(1)],
]);

// maybe add sorting mode by second func argument in future to improve sorting functionality
export const sortDeviations = (deviations: any[]) =>
  // sorting by priority
  [...deviations].sort((prev, next) => {
    //avviks with Avsluttet and avviks without status to bottom of the list
    if (prev['status.status_type_id_value'] === 'Avsluttet' || !prev['priority.priority_type_id_value']) {
      return 1;
    }
    if (next['status.status_type_id_value'] === 'Avsluttet' || !next['priority.priority_type_id_value']) {
      return -1;
    }

    const prevPriorityNumber = getPriorityNumber(prev['priority.priority_type_id_value']);
    const nextPriorityNumber = getPriorityNumber(next['priority.priority_type_id_value']);

    return prevPriorityNumber - nextPriorityNumber;
  });
