import React, { useReducer, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { useLocation, Redirect } from 'react-router-dom';

// reducer
import { reducer, ActionTypes } from './reducer';
import { login } from 'services/fetchData';
import { useAuth } from 'context/Auth';
import { StyledTypography } from '../../shared/StyledTypography';

const initialState = {
  remembered: false,
  name: '',
  password: '',
  server: '',
  formErrors: { name: '', password: '', server: '' },
  nameValid: false,
  passwordValid: false,
  serverValid: false,
  formValid: false,
  isLoggedIn: false,
  isLoading: false,
};

const NAME_ERROR_TEXT = 'Brukernavn skal ikke være tomt';
const PASSWORD_ERROR_TEXT = 'Passord skal ikke være tomt';
const SERVER_LENGTH_ERROR_TEXT = 'Server skal ikke være tomt';
const SERVER_URL_ERROR_TEXT = 'Ugyldig servernavn';
const INVALID_CREDENTIALS_MESSAGE = 'Innlogging feilet. Sjekk at brukernavn og passord er korrekt.';

const validateServerName = (value: string): boolean =>
  new RegExp(
    /^https?:\/\/(?:(?:(?:(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(?:localhost))(?::\d{2,4}))|(?:(?:(?:\w|[-_~])+\.)+\w{2,3}))(?:\/.*)?$/i,
  ).test(value);

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

const LoginForm: React.FC = () => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setAuthToken, setAuthRememberedToken } = useAuth();

  // VALIDATION
  const validateForm = () => {
    dispatch({
      type: ActionTypes.UPDATE_LOGIN_FORM_STATE,
      payload: { formValid: state.nameValid && state.passwordValid && state.serverValid },
    });
  };
  const validateField = (fieldName: string, value: string) => {
    const fieldValidationErrors = state.formErrors;
    let nameValid = state.nameValid;
    let passwordValid = state.passwordValid;
    let serverValid = state.serverValid;
    switch (fieldName) {
      case 'name':
        nameValid = value.length > 0;
        fieldValidationErrors.name = nameValid ? '' : NAME_ERROR_TEXT;
        break;
      case 'password':
        passwordValid = value.length > 0;
        fieldValidationErrors.password = passwordValid ? '' : PASSWORD_ERROR_TEXT;
        break;

      case 'server':
        serverValid = value.length > 0 && validateServerName(value);
        fieldValidationErrors.server = !serverValid && value.length === 0 ? SERVER_LENGTH_ERROR_TEXT : '';
        if (!serverValid && value.length > 0) {
          fieldValidationErrors.server = SERVER_URL_ERROR_TEXT;
        }
        break;
      default:
        break;
    }
    dispatch({
      type: ActionTypes.UPDATE_LOGIN_FORM_STATE,
      payload: {
        formErrors: fieldValidationErrors,
        nameValid: nameValid,
        passwordValid: passwordValid,
        serverValid: serverValid,
      },
    });
  };
  //

  const handleRememberedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionTypes.UPDATE_LOGIN_FORM_STATE, payload: { remembered: event.target.checked } });
  };

  const handleFormFieldChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    dispatch({ type: ActionTypes.UPDATE_LOGIN_FORM_STATE, payload: { [name]: event.target.value } });
    validateField(name, event.target.value);
  };

  const handleLoginFormSubmit = async (event: React.FormEvent) => {
    preventDefault(event);
    dispatch({ type: ActionTypes.UPDATE_LOGIN_FORM_STATE, payload: { isLoading: true } });

    const token = 'Basic ' + window.btoa(`${state.name}:${state.password}`);

    const loginResponse = await login(token, state.server);
    if (!loginResponse) {
      dispatch({ type: ActionTypes.UPDATE_LOGIN_FORM_STATE, payload: { isLoading: false } });
      alert(INVALID_CREDENTIALS_MESSAGE);
      return;
    }

    if (state.remembered) {
      setAuthRememberedToken(token);
    } else {
      setAuthToken(token);
    }
    dispatch({ type: ActionTypes.UPDATE_LOGIN_FORM_STATE, payload: { isLoading: false } });
    dispatch({ type: ActionTypes.UPDATE_LOGIN_FORM_STATE, payload: { isLoggedIn: true } });
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.nameValid, state.passwordValid, state.serverValid]);

  const locationFrom = useMemo(() => {
    const locationState = location.state as { from: { pathname: string } };
    return locationState?.from || { from: { pathname: '/' } };
  }, [location.state]);

  if (state.isLoggedIn) {
    return <Redirect to={{ ...locationFrom }} />;
  }

  return (
    <StyledContainer>
      <StyledFormWrapper elevation={4}>
        <Typography variant="h5">Logg inn</Typography>
        <StyledForm onSubmit={handleLoginFormSubmit} noValidate>
          <Grid container spacing={2} direction="column" justify="center">
            <Grid item>
              <TextField
                label="Brukernavn"
                color="secondary"
                fullWidth
                value={state.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFormFieldChange(e, 'name');
                }}
                name="name"
                helperText={state.formErrors.name}
                error={state.formErrors.name.length > 0 && !state.nameValid}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Passord"
                color="secondary"
                fullWidth
                type="password"
                value={state.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFormFieldChange(e, 'password');
                }}
                name="password"
                helperText={state.formErrors.password}
                error={state.formErrors.password.length > 0 && !state.passwordValid}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Server"
                color="secondary"
                fullWidth
                value={state.server}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFormFieldChange(e, 'server');
                }}
                name="server"
                helperText={state.formErrors.server}
                error={state.formErrors.server.length > 0 && !state.serverValid}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={state.remembered} onChange={handleRememberedChange} name="remembered" />}
                label={<StyledTypography fontSize="14px">Husk informasjon ved pålogging</StyledTypography>}
              />
            </Grid>
            <Grid item>
              <StyledSubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={!state.formValid}
              >
                {state.isLoading ? <CircularProgress size={24} /> : 'LOGG INN'}
              </StyledSubmitButton>
            </Grid>
          </Grid>
        </StyledForm>
      </StyledFormWrapper>
      <Typography component="p" variant="caption" align="center" style={{ paddingBottom: '1em' }}>
        Problem ved innlogging kontakt
        <Link href="#" onClick={preventDefault} color="inherit" underline="always" style={{ marginLeft: 4 }}>
          support@kulturit.no
        </Link>
      </Typography>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    max-width: 500px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;
const StyledForm = styled.form`
  width: 100%;
  margin-top: 1em;
`;
const StyledSubmitButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 22px;
    padding: 10px 16px;
  }
  &.MuiButton-contained.Mui-disabled {
    background-color: #e0e0e0;
  }

  @media (max-width: 767px) {
    &.MuiButton-root {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 60%;
    }
  }
`;
const StyledFormWrapper = styled(Paper)`
  &.MuiPaper-root {
    position: relative;
    bottom: 60px;
    padding: 20px 60px;
    border-radius: 10px;
  }

  @media (max-width: 767px) {
    &.MuiPaper-root {
      padding: 20px;
    }
  }
`;

export default LoginForm;
