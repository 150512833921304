import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Grid, useMediaQuery, FormHelperText, Box, Typography } from '@material-ui/core';

// icons
import AddIcon from '@material-ui/icons/Add';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import Datepicker from '../../shared/Datepicker/Datepicker';
import FormInput from '../../shared/FormInput/FormInput';
import WorkTitleDrags from './WorkTitleDrags/WorkTitleDrags';
import OutlineButton from '../../shared/OutlineButton/OutlineButton';

interface Props {
  workTitle: string;
  description: string;
  isFromDatePickerOpen: boolean;
  fromSelectedDate: Date | null;
  toSelectedDate: Date | null;
  handleWorkTitleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleDescriptionChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleToDateChange: (date: Date | null) => void;
  handleOpenFromDatePicker: () => void;
  handleCloseFromDatePicker: () => void;
  disabledConditions: boolean;
  handleEditDragItem: (newItem: any) => void;
  handleAddDragItem: (newItem: any) => void;
  handleDeleteDragItem: (id: string) => void;
  handleOpenActorsPanel: () => void;
  handleCloseActorsPanel: () => void;
  handleToggleActorsPanel: () => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  responsibleActors: any[];
  isActorsPanelOpen: boolean;
}

const WorkTitleForm = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width: 550px)');
  const [selectedEdit, setSelectedEdit] = useState<string | null>(null);

  // HANDLERS
  const onToggleActorsPanel = (id: string) => {
    if (selectedEdit === null) {
      setSelectedEdit(id);
    } else {
      setSelectedEdit(null);
    }
    props.handleToggleActorsPanel();
  };

  const onCloseActorsPanel = () => {
    setSelectedEdit(null);
    props.handleCloseActorsPanel();
  };
  const handleSaveActorsPanel = ({ person }: { person: { title: string; id: string } }) => {
    if (!selectedEdit || selectedEdit === null) {
      const newPerson = {
        model_title: null,
        artifact_name_upper: null,
        checksum: null,
        object_type: 'ResponsibleActorItem',
        meta_type: 'sub_model',
        context_id: null,
        revision_id: null,
        no: null,
        role_code: '240',
        resp_actor_item_actor_id: person.id,
        resp_actor_item_actor_id_value: person.title,
        order_number: props.responsibleActors.length,
        _create: true,
      };
      props.handleAddDragItem(newPerson);
      onCloseActorsPanel();
    } else {
      const editedActor = props.responsibleActors.find(
        actor => actor.no === selectedEdit || actor.resp_actor_item_actor_id === selectedEdit,
      );
      const editedPerson = {
        ...editedActor,
        model_title: null,
        artifact_name_upper: null,
        resp_actor_item_actor_id: person.id,
        resp_actor_item_actor_id_value: person.title,
        // responsibilities: responsibility,
        _update: true,
      };
      props.handleEditDragItem(editedPerson);
      onCloseActorsPanel();
    }
  };
  const handleDeleteDrag = (id: string) => {
    props.handleDeleteDragItem(id);
    props.handleCloseActorsPanel();
    setSelectedEdit(null);
  };
  // empty drag item for new drag creating
  const emptyDragItem: {
    resp_actor_item_actor_id: string;
    resp_actor_item_actor_id_value: string;
    responsibilities: string;
  } = {
    resp_actor_item_actor_id: '',
    resp_actor_item_actor_id_value: '',
    responsibilities: '',
  };

  // actors without _destroy field
  const actorsWithoutDestroy = useMemo(() => props.responsibleActors.filter((i: any) => !i._destroy), [
    props.responsibleActors,
  ]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormInput
          value={props.workTitle}
          handleChange={props.handleWorkTitleChange}
          disabled={props.disabledConditions}
          label="Tittel"
        />
        <FormHelperText>Kort beskrivelse av arbeidet</FormHelperText>
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.description}
          handleChange={props.handleDescriptionChange}
          label="Beskrivelse av formål"
          disabled={props.disabledConditions}
        />
        <FormHelperText>Evt. utfyllende beskrivelse av arbeidet</FormHelperText>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={mediaMatch ? false : 12}>
          <Datepicker
            value={props.toSelectedDate}
            handleChange={props.handleToDateChange}
            open={props.isFromDatePickerOpen}
            onOpen={props.handleOpenFromDatePicker}
            onClose={props.handleCloseFromDatePicker}
            label="Dato utført"
            disabled={props.disabledConditions}
          />
        </Grid>
      </Grid>
      {actorsWithoutDestroy.length > 0 && (
        <Grid item>
          <Typography variant="subtitle2" style={{ paddingTop: 24 }}>
            {actorsWithoutDestroy.length} ansvarlige personer
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column">
          <WorkTitleDrags
            dragItems={props.responsibleActors}
            isActorsPanelOpen={props.isActorsPanelOpen}
            selectedEdit={selectedEdit}
            handleToggleActorsPanel={onToggleActorsPanel}
            handleSaveActorsPanel={handleSaveActorsPanel}
            emptyDragItem={emptyDragItem}
            moveDragItem={props.moveDragItem}
            handleCloseActorsPanel={onCloseActorsPanel}
            handleDeleteDrag={handleDeleteDrag}
            disabledConditions={props.disabledConditions}
          />
        </Grid>
      </Grid>
      {!props.isActorsPanelOpen && (
        <Grid item>
          <OutlineButton padding="16px 30px" width="100%" onClick={props.handleOpenActorsPanel}>
            <StyledBox>
              <AddIcon style={{ marginRight: 4, width: 18, height: 18 }} />
            </StyledBox>
            Legg til person
          </OutlineButton>
        </Grid>
      )}
    </Grid>
  );
};

const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default WorkTitleForm;
