import React from 'react';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';

interface Props {
  value: string;
  handleChange: (event: React.ChangeEvent<{ value: string; id: string }>) => void;
  placeholder?: string;
  id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    width: {
      width: '90%',
    },
  }),
);

const AttachmentMediaInput: React.FC<Props> = props => {
  const classes = useStyles();
  return (
    <FormControl className={classes.width}>
      <StyledTextField
        id={props.id || `id--${props.placeholder}`}
        value={props.value}
        onChange={props.handleChange}
        variant="filled"
      />
    </FormControl>
  );
};

const StyledTextField = styled(props => <TextField {...props} />)`
  & .MuiFilledInput-root {
    background-color: #f2f2f2;
    padding: 0;
    font-size: 1rem;

    &:hover {
      background-color: #f2f2f2;
    }
  }
  & .MuiFilledInput-underline {
    &:before {
      border: none;
    }
    &:after {
      border: none;
    }
  }
  & .MuiFilledInput-underline:hover:before {
    border: none;
  }
  & .MuiFilledInput-root.Mui-focused {
    background-color: #f2f2f2;
  }

  & .MuiFilledInput-input {
    padding-top: 11px;
    padding-left: 4px;
  }
`;

export default AttachmentMediaInput;
