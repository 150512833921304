import React, { createRef, useEffect } from 'react';
import styled from 'styled-components';
import { IconButton, InputBase, Button } from '@material-ui/core';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';

// icons
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

interface AnimationState {
  state: TransitionStatus;
}

interface Props {
  handleSearchOpen: () => void;
  handleSearchClose: () => void;
  handleSearchInput: (value: string) => void;
  handleSearchSubmit: (event: React.BaseSyntheticEvent<Event, EventTarget & Element, EventTarget>) => void;
  searchInputValue: string;
  isSearchOpen: boolean;
}

const SearchBox: React.FC<Props> = props => {
  const searchInputRef = createRef<HTMLInputElement>();

  const handleClearInputValue = () => {
    if (searchInputRef?.current?.value === '') {
      props.handleSearchClose();
      return;
    }
    props.handleSearchInput('');

    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (props.isSearchOpen) {
      searchInputRef.current?.focus();
    }
  }, [props.isSearchOpen, searchInputRef]);

  return (
    <Transition in={props.isSearchOpen} timeout={200}>
      {state => (
        <StyledSearchWrapper state={state}>
          <IconButton
            size={props.isSearchOpen ? 'medium' : 'small'}
            color="default"
            aria-label="search"
            onClick={props.handleSearchClose}
          >
            <SearchIcon />
          </IconButton>
          <StyledForm onSubmit={props.handleSearchSubmit}>
            <StyledInputBase
              defaultValue={props.searchInputValue}
              onChange={(e: React.BaseSyntheticEvent) => props.handleSearchInput(e.target.value)}
              placeholder="Søk i Primus FDV"
              inputProps={{ 'aria-label': 'search primus fdv' }}
              inputRef={searchInputRef}
              isSearchOpen={props.isSearchOpen}
            />
          </StyledForm>
          {props.isSearchOpen && (
            <IconButton size="small" onClick={handleClearInputValue}>
              <CloseIcon />
            </IconButton>
          )}

          {!props.isSearchOpen && <StyledBackdropButton onClick={props.handleSearchOpen}>{'  '}</StyledBackdropButton>}
        </StyledSearchWrapper>
      )}
    </Transition>
  );
};

const StyledSearchWrapper = styled.div`
  transition: 0.2s;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props: AnimationState) => (props.state === 'entered' || props.state === 'entering' ? '100%' : '144px')};
  background-color: #ffffff;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(props: AnimationState) => (props.state === 'entered' || props.state === 'entering' ? '0' : '24px')};
  padding: 4px 10px;
  overflow: hidden;
  height: ${(props: AnimationState) => (props.state === 'entered' || props.state === 'entering' ? '100%' : '39px')};
  margin: ${(props: AnimationState) => (props.state === 'entered' || props.state === 'entering' ? '0' : '12px 24px;')};

  @media (max-width: 767px) {
    width: ${(props: AnimationState) => (props.state === 'entered' || props.state === 'entering' ? '100%' : '40px')};
    padding: ${(props: AnimationState) =>
      props.state === 'entered' || props.state === 'entering' ? '4px 10px' : '4px 6px;'};
    border-radius: ${(props: AnimationState) =>
      props.state === 'entered' || props.state === 'entering' ? '0' : '50%'};
  }
`;
const StyledBackdropButton = styled(Button)`
  &.MuiButton-root {
    margin: 0;
    padding: 0;
    min-width: 0;
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    z-index: 2000;
  }
`;
const StyledInputBase = styled(({ isSearchOpen, ...props }) => <InputBase {...props} />)`
  &.MuiInputBase-root {
    width: ${props => (props.isSearchOpen ? '100%' : '95px')};
    padding-left: 4px;
  }
  & .MuiInputBase-input {
    padding: 6px 0;
    text-overflow: ellipsis;
  }
`;
const StyledForm = styled.form`
  flex: 1;
`;

export default SearchBox;
