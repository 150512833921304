import uniq from 'lodash/uniq';
import compact from 'lodash/compact';

export const getContentWordsToSuggest = (objects: any[]) => {
  const array = objects.flatMap(item => {
    if (item.artifact_type_id_value === 'Avvik') {
      return [item.artifact_type_id_value];
    }
    if (item.artifact_type_id_value === 'Tilstandsvurdering bygning') {
      return [item.artifact_type_id_value, item.purpose.purpose_type_id_value];
    }
    if (item.artifact_type_id_value === 'Bygning') {
      return [
        item.artifact_type_id_value,
        item.reg_level && item.reg_level.reg_level_type_id_value,
        item.collection_id_value,
        item.designations[0].designation_type_id_value,
        item.object_type,
      ];
    }
    if (item.artifact_type_id_value === 'Bygningsarbeid') {
      return [item.artifact_type_id_value];
    }
    return;
  });
  // return Array.from(new Set(array));
  return compact(uniq(array));
};
