import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Button, Typography } from '@material-ui/core';

// icons
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkMarkWhite.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface Props {
  onSave: () => void;
  onNext: () => void;
  currentStepNumber: number;
  stepsAmount: number;
  expanded: string | false;
  onBack?: () => void;
}

const ProgressBottomButton = (props: Props) => {
  return (
    <StyledWrap background="#2c2f38" color="#ffffff">
      <StyledBackButton onClick={props.onBack} disabled={props.currentStepNumber === 1 ? true : false}>
        <ArrowBackIcon />
      </StyledBackButton>
      <StyledProgressBox>
        <StyledTypography variant="caption" component="span" fontWeight={400}>
          {`${props.currentStepNumber} av ${props.stepsAmount}`}
        </StyledTypography>
      </StyledProgressBox>

      <StyledButton
        fullWidth
        onClick={props.currentStepNumber === props.stepsAmount ? props.onSave : props.onNext}
        color="#ffffff"
      >
        <Grid container alignItems="center" justify="center" direction="row" wrap="nowrap" spacing={1}>
          <Grid item>
            <StyledBox>{<CheckMarkIcon />}</StyledBox>
          </Grid>
          <Grid item>
            <Typography style={{ marginBottom: -2 }} variant="subtitle2">
              {props.currentStepNumber === props.stepsAmount ? 'Lagre og lukk' : 'Lagre og gå videre'}
            </Typography>
          </Grid>
        </Grid>
      </StyledButton>
    </StyledWrap>
  );
};

const StyledWrap = styled(({ background, color, borderTop, ...props }) => <Box {...props} />)`
  z-index: 3;
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  color: ${props => props.color};
  background-color: ${props => props.background};
  border-top: ${props => props.borderTop};
  padding: 0;
`;
const StyledButton = styled(({ color, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    color: ${props => props.color};
    padding: 16px 0;
    border-radius: 0;
    text-transform: none;
    justify-content: flex-start;
  }
`;
const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledProgressBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  width: 120px;
  border-radius: 0;
  text-transform: none;
  border-right: 1px solid #3f424a;
`;
const StyledTypography = styled(({ fontWeight, ...props }) => <Typography {...props} />)`
  &.MuiTypography-caption {
    font-weight: ${props => props.fontWeight};
  }
`;
const StyledBackButton = styled(Button)`
  &.MuiButton-root {
    color: #ffffff;
    padding: 6px 14px;
    border-radius: 0;
    border-right: 1px solid #3f424a;
  }
  &.MuiButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.26);
  }
`;

export default ProgressBottomButton;
