import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  Box,
  Drawer,
  Divider,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  Grid,
  Avatar,
} from '@material-ui/core';

// icons
import SearchIcon from '@material-ui/icons/Search';
import LockIcon from '@material-ui/icons/Lock';

// mocks
import userMock from 'assets/photos/user-mock.png';
import { useAuth } from 'context/Auth';
import searchHistory from 'helpers/searchHistory';
import { SEARCH_HISTORY_LIMIT } from '../../../../constants';
import { getBaseUrl } from '../../../../services/fetchData';

interface Props {
  handleToggleMenu: () => void;
  isMenuOpen: boolean;
  handleLogout: () => void;
  handleSearchBySuggested: (searchQuery: string) => void;
}

const HomeMenu = (props: Props) => {
  const { userData } = useAuth();

  let searchedHistory = searchHistory.getHistory();

  if (searchedHistory.length > SEARCH_HISTORY_LIMIT) {
    searchedHistory = searchedHistory.slice(0, SEARCH_HISTORY_LIMIT);
  }

  return (
    <StyledDrawer
      open={props.isMenuOpen}
      isMenuOpen={props.isMenuOpen}
      onClose={props.handleToggleMenu}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <StyledContentWrap>
        <Grid container direction="column">
          {searchedHistory && searchedHistory.length > 0 && (
            <>
              <StyledList
                component="section"
                paddingTop="0"
                style={{
                  maxHeight: '70vh',
                  overflowY: 'auto',
                }}
              >
                {searchedHistory.map(option => (
                  <StyledListItem
                    key={option}
                    button
                    disableGutters
                    onClick={() => props.handleSearchBySuggested(option)}
                  >
                    <StyledListItemIcon>
                      <SearchIcon />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={
                        <StyledTypography component="span" fontSize="0.875rem">{`«${option}»`}</StyledTypography>
                      }
                    />
                  </StyledListItem>
                ))}
              </StyledList>
              <Divider />
            </>
          )}
          <StyledList component="section">
            <StyledListItem button disableGutters onClick={props.handleLogout}>
              <StyledListItemIcon>
                <LockIcon />
              </StyledListItemIcon>
              <ListItemText
                primary={
                  <StyledTypography component="span" fontSize="0.875rem">
                    Logg ut
                  </StyledTypography>
                }
              />
            </StyledListItem>
          </StyledList>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <StyledAvatar
              alt={userData?.user.name || 'Bruker'}
              src={userData?.user.image_id ? `${getBaseUrl()}/multimedia/image/${userData?.user.image_id}` : userMock}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6">{userData?.user.name || 'Bruker'}</Typography>
            <StyledTypography fontSize="0.875rem">email_mock@kulturit.no</StyledTypography>
          </Grid>
        </Grid>
      </StyledContentWrap>
    </StyledDrawer>
  );
};

const StyledContentWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px;
  height: 100%;
`;

const StyledDrawer = styled(({ isMenuOpen, ...props }) => <Drawer {...props} />)`
  & .MuiDrawer-paperAnchorDockedLeft {
    border-right: none;
  }
  & .MuiDrawer-paper {
    width: 320px;

    @media (max-width: 767px) {
      width: 85vw;
    }
  }
`;
const StyledListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 0;
    padding-right: 32px;
  }
`;
const StyledList = styled(({ paddingTop, ...props }) => <List {...props} />)`
  &.MuiList-padding {
    padding-top: ${props => props.paddingTop || '20px'};
    padding-bottom: 20px;
    @media (min-width: 706px) {
      padding-right: 8%;
    }
  }
  &.MuiList-padding:last-child {
    @media (min-width: 706px) {
      padding-right: 0;
    }
  }
`;
const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }
  &.MuiListItem-button:hover {
    background-color: transparent;
  }
  .suggested {
    font-size: 0.9rem;
    color: #9e9e9e;
  }
  .highlight-suggested {
    background: none;
    color: rgba(0, 0, 0, 0.87);
  }
`;
const StyledTypography = styled(({ fontSize, firstLetter, ...props }) => <Typography {...props} />)`
  &.MuiTypography-root {
    font-size: ${props => props.fontSize};

    &::first-letter {
      text-transform: ${props => (props.firstLetter ? 'uppercase' : 'none')};
    }
  }
`;
const StyledAvatar = styled(props => <Avatar {...props} />)`
  &.MuiAvatar-root {
    width: 64px;
    height: 64px;
    background-color: #ffffff;
  }
`;

export default HomeMenu;
