import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Box, Container, Paper, Chip, Tabs, Tab, useMediaQuery } from '@material-ui/core';

import startCase from 'lodash/startCase';

// types
import { EnabledFiltersType } from 'types/Mocks/EnabledFiltersType';

// mocks
import searchViewOptions from './mocks/searchViewOptions';

// helpers
import { filterObjects } from 'helpers/filterObjects';

import SearchSection from 'components/SearchSection/SearchSection';
import SearchBar from './components/SearchBar/SearchBar';
import SearchBuildingsList from './components/SearchBuildingsList/SearchBuildingsList';
import FilterSection from './sections/FilterSection/FilterSection';
import SortSection from './sections/SortSection/SortSection';
import TabPanel from './components/TabPanel/TabPanel';
import SearchObservationsList from './components/SearchObservationsList/SearchObservationsList';
import SearchConditionsList from './components/SearchConditionsList/SearchConditionsList';
import SearchList from './components/SearchList/SearchList';

interface Props {
  objects: any[];
  handleSearchInput: (value: string) => void;
  handleSearchOpen: () => void;
  handleSearchClose: () => void;
  handleSearchSubmit: (event: React.BaseSyntheticEvent<Event, EventTarget & Element, EventTarget>) => void;
  handleSearchBySuggested: (searchQuery: string) => void;
  isSearchOpen: boolean;
  searchInputValue: string;
  searchQuery: string;
  suggestedSearch: string[];
  addBuildingToFavorites: (id: string) => void;
  removeBuildingFromFavorites: (id: string) => void;
  checkBuildingInFavorites: (id: string) => boolean;
}

const Search = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width: 768px)');

  // state
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [enabledFilters, setEnabledFilters] = useState<EnabledFiltersType>({
    samling: {
      filters: [] as string[],
      isSingleSelected: true,
    },
    objekttype: {
      filters: [] as string[],
      isSingleSelected: false,
    },
    tilstandsklasse: {
      filters: [] as string[],
      isSingleSelected: false,
    },
    prioritet: {
      filters: [] as string[],
      isSingleSelected: false,
    },
    status: {
      filters: [] as string[],
      isSingleSelected: false,
    },
    type: {
      filters: [] as string[],
      isSingleSelected: true,
    },
    harMarkeringer: {
      filters: [] as string[],
      isSingleSelected: true,
    },
    formal: {
      filters: [] as string[],
      isSingleSelected: true,
    },
  });
  const [enabledSorting, setEnabledSorting] = useState('Last updated');
  const [searchViewOption, setSearchViewOption] = React.useState(0);

  // SET FIRST SEARCH VIEW OPTION ACCORDING TO SEARCH RESULTS
  const setFirstViewOption = () => {
    const index = objectsLength.findIndex(num => num > 0);
    return index === -1 ? 0 : index;
  };

  useEffect(() => {
    setSearchViewOption(setFirstViewOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.objects, enabledFilters]);

  // handlers
  const handleOpenFilter = () => {
    setIsFilterOpen(true);
  };
  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };
  const handleToggleFilter = () => {
    if (isSortOpen) setIsSortOpen(false);
    setIsFilterOpen(!isFilterOpen);
  };
  const handleToggleEnableFilter = (option: string, filterGroup: string) => {
    // check not selected single selected filters
    if (
      (enabledFilters as any)[filterGroup].isSingleSelected &&
      !(enabledFilters as any)[filterGroup].filters.includes(option)
    ) {
      setEnabledFilters({
        ...enabledFilters,
        [filterGroup]: {
          ...(enabledFilters as any)[filterGroup],
          filters: [option],
        },
      });
      return;
    }

    // check if option is selected to unselect it
    if ((enabledFilters as any)[filterGroup].filters.includes(option)) {
      const newEnabledFilters = {
        ...enabledFilters,
        [filterGroup]: {
          ...(enabledFilters as any)[filterGroup],
          filters: (enabledFilters as any)[filterGroup].filters.filter((opt: string) => opt !== option),
        },
      };
      setEnabledFilters(newEnabledFilters);
      return;
    }

    setEnabledFilters({
      ...enabledFilters,
      [filterGroup]: {
        ...(enabledFilters as any)[filterGroup],
        filters: [...(enabledFilters as any)[filterGroup].filters, option],
      },
    });
  };
  const handleOpenSort = () => {
    setIsSortOpen(true);
  };
  const handleCloseSort = () => {
    setIsSortOpen(false);
  };
  // const handleToggleSort = () => {
  //   if (isFilterOpen) setIsFilterOpen(false);
  //   setIsSortOpen(!isSortOpen);
  // };
  const handleChangeSorting = (newSorting: string) => {
    if (newSorting === enabledSorting) return;

    setEnabledSorting(newSorting);
  };
  const handleSearchViewOptionChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleCloseFilter();
    setSearchViewOption(newValue);
  };

  // calc values
  const getEnabledFiltersAmount = (filters: EnabledFiltersType): number =>
    Object.values(filters)
      .map(filter => filter.filters)
      .flat().length;
  const filteredAndSortedObjects = useMemo(() => filterObjects(props.objects, enabledFilters), [
    props.objects,
    enabledFilters,
  ]);
  const enabledFiltersAmount = useMemo(() => getEnabledFiltersAmount(enabledFilters), [enabledFilters]);

  // GET DIFFERENT OBJECT TYPES FROM PROPS.OBJECTS
  const buildings = useMemo(() => filteredAndSortedObjects.filter(item => item.object_type === 'building'), [
    filteredAndSortedObjects,
  ]);
  const observations = useMemo(
    () => filteredAndSortedObjects.filter(item => item.object_type === 'BuildingObservationEvent'),
    [filteredAndSortedObjects],
  );
  const conditionAssessments = useMemo(
    () => filteredAndSortedObjects.filter(item => item.object_type === 'BuildingConditionAssessmentEvent'),
    [filteredAndSortedObjects],
  );
  const treatments = useMemo(
    () => filteredAndSortedObjects.filter(item => item.object_type === 'BuildingTreatmentEvent'),
    [filteredAndSortedObjects],
  );
  const assignments = useMemo(() => filteredAndSortedObjects.filter(item => item.object_type === 'task'), [
    filteredAndSortedObjects,
  ]);

  // mocks yet
  const media = [];

  // OBJECTS LENGTH FOR TABS
  const objectsLength = [
    buildings.length,
    observations.length,
    conditionAssessments.length,
    treatments.length,
    assignments.length,
    media.length,
  ];

  return (
    <>
      {props.isSearchOpen && (
        <SearchSection
          handleSearchClose={props.handleSearchClose}
          handleSearchInput={props.handleSearchInput}
          searchInputValue={props.searchInputValue}
          handleSearchSubmit={props.handleSearchSubmit}
          handleSearchBySuggested={props.handleSearchBySuggested}
          searchQuery={props.searchQuery}
          suggestedSearch={props.suggestedSearch}
        />
      )}
      {isFilterOpen && (
        <FilterSection
          objectType={searchViewOptions[searchViewOption]}
          isFilterOpen={isFilterOpen}
          handleCloseFilter={handleCloseFilter}
          enabledFilters={enabledFilters}
          handleToggleEnableFilter={handleToggleEnableFilter}
          searchResultsLength={filteredAndSortedObjects.length}
          enabledFiltersAmount={enabledFiltersAmount.toLocaleString()}
        />
      )}
      {isSortOpen && (
        <SortSection
          isSortOpen={isSortOpen}
          handleCloseSort={handleCloseSort}
          enabledSorting={enabledSorting}
          handleChangeSorting={handleChangeSorting}
        />
      )}
      {isFilterOpen || isSortOpen ? null : (
        <>
          <SearchBar
            handleSearchOpen={props.handleSearchOpen}
            handleSearchClose={props.handleSearchClose}
            searchQuery={props.searchQuery}
            handleOpenFilter={handleOpenFilter}
            handleOpenSort={handleOpenSort}
            enabledFiltersAmount={enabledFiltersAmount}
            handleSearchInput={props.handleSearchInput}
            isSearchOpen={props.isSearchOpen}
          />
          <CaptionWrap isSearchOpen={props.isSearchOpen}>
            {enabledFiltersAmount > 0 ? (
              <>
                {Object.entries(enabledFilters).map(([filterSection, filters]) =>
                  filters.filters.length > 0 ? (
                    <StyledChip
                      key={filterSection}
                      label={startCase(filterSection)}
                      variant="outlined"
                      background="transparent"
                      borderColor="#9c9c9c"
                      onClick={handleToggleFilter}
                    />
                  ) : null,
                )}
                <StyledChip
                  label="Flere filter"
                  variant="outlined"
                  background="transparent"
                  borderColor="#9c9c9c"
                  onClick={handleToggleFilter}
                />
              </>
            ) : (
              <StyledChip
                label={mediaMatch ? 'Velg filter' : 'Ingen filter'}
                variant="outlined"
                background="transparent"
                borderColor="#9c9c9c"
                onClick={handleToggleFilter}
              />
            )}
            {/* {mediaMatch && ( */}
            {/* <FilterSortBox
              onFilterClick={handleToggleFilter}
              onSortClick={handleToggleSort}
              enabledFiltersAmount={enabledFiltersAmount}
            /> */}
            {/* )} */}
          </CaptionWrap>
        </>
      )}
      <StyledResultsWrap isFilterOpen={isFilterOpen} isSearchOpen={props.isSearchOpen}>
        <StyledTabs
          value={searchViewOption}
          onChange={handleSearchViewOptionChange}
          variant="scrollable"
          scrollButtons="off"
        >
          {searchViewOptions.map((option, idx) => (
            <StyledTab
              disableRipple
              disableFocusRipple
              key={Math.random()}
              label={
                <>
                  <StyledTypography component="span" fontSize="14px">{`${option.name} `}</StyledTypography>
                  <StyledTypography
                    component="span"
                    fontFamily="Akkurat Mono"
                    color="rgba(0, 0, 0, 0.5)"
                    fontSize="14px"
                  >{`${objectsLength[idx]}`}</StyledTypography>
                </>
              }
            />
          ))}
          {/* EMPTY TAB FOR SPACE */}
          <Tab disabled label="" style={{ minWidth: 2, margin: 0, padding: 0 }} />
        </StyledTabs>
        <StyledContainer isSearchOpen={props.isSearchOpen}>
          <StyledContentWrap backgroundColor="transparent" elevation={0}>
            <TabPanel value={searchViewOption} index={0}>
              {buildings.length > 0 && (
                <SearchBuildingsList
                  buildings={buildings}
                  addBuildingToFavorites={props.addBuildingToFavorites}
                  removeBuildingFromFavorites={props.removeBuildingFromFavorites}
                  checkBuildingInFavorites={props.checkBuildingInFavorites}
                />
              )}
            </TabPanel>
            <TabPanel value={searchViewOption} index={1}>
              {observations.length > 0 && <SearchObservationsList observations={observations} />}
            </TabPanel>
            <TabPanel value={searchViewOption} index={2}>
              {conditionAssessments.length > 0 && <SearchConditionsList conditions={conditionAssessments} />}
            </TabPanel>
            <TabPanel value={searchViewOption} index={3}>
              {treatments.length > 0 && <SearchList treatments={treatments} />}
            </TabPanel>
            <TabPanel value={searchViewOption} index={4}>
              {assignments.length > 0 && <SearchList tasks treatments={assignments} />}
            </TabPanel>
          </StyledContentWrap>
        </StyledContainer>
      </StyledResultsWrap>
    </>
  );
};

const CaptionWrap = styled(({ backgroundColor, isSearchOpen, ...props }) => <Box {...props} />)`
  display: flex;
  align-items: center;
  padding: 24px 28px 10px;
  background-color: ${props => props.backgroundColor};

  @media (max-width: 767px) {
    padding: 24px 28px 10px;
    overflow-x: auto;
  }
  @media (max-width: 705px) {
    padding: 20px 12px 8px;
    height: ${props => (props.isSearchOpen ? '10vh' : 'auto')};
    overflow-y: ${props => (props.isSearchOpen ? 'hidden' : 'auto')};
  }
`;
const StyledContainer = styled(({ isSearchOpen, ...props }) => <Container {...props} />)`
  &.MuiContainer-root {
    padding: 0 48px;
    max-width: none;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
`;
const StyledTypography = styled(({ color, fontWeight, fontFamily, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    ${props => props.fontFamily && 'font-family: ' + props.fontFamily}
  }
`;

const StyledContentWrap = styled(({ backgroundColor, ...props }) => <Paper {...props} />)`
  &.MuiPaper-root {
    background-color: ${props => props.backgroundColor};
  }
`;

const StyledChip = styled(({ background, borderColor, ...props }) => <Chip {...props} />)`
  &.MuiChip-root {
    margin-right: 8px;
    font-size: 14px;
    background-color: ${props => props.background};
    border-color: ${props => props.borderColor};
    cursor: pointer;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    text-transform: capitalize;
    padding: 6px 0;
    margin: 0 12px;
    min-width: 0;
    border-radius: 4px;
  }
  &.MuiTab-root:last-child {
    margin: 0 12px 0 12px;
  }
  &.MuiTab-root:first-child {
    margin-left: 0;
  }
  & .MuiTab-wrapper {
    display: inline-block;
  }
`;
const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    padding: 0 0px 30px 30px;

    @media (max-width: 767px) {
      padding: 0 0px 30px 30px;
    }
    @media (max-width: 705px) {
      padding: 0 0px 12px 14px;
    }
  }
  & .MuiTabs-indicator {
    background-color: #777777;
  }
  & .MuiTabs-scroller {
    padding-right: 12px;
  }
`;

const StyledResultsWrap = styled(({ isFilterOpen, isSearchOpen, ...props }) => <Box {...props} />)`
  @media (max-width: 705px) {
    height: ${props => (props.isFilterOpen || props.isSearchOpen ? '80vh' : 'auto')};
    overflow-y: ${props => (props.isFilterOpen || props.isSearchOpen ? 'hidden' : 'auto')};
  }
`;

export default Search;
