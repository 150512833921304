import React from 'react';
import styled from 'styled-components';
import { formatDistance } from 'date-fns';
import { nb } from 'date-fns/locale';
import { Grid, Box, Typography, Paper, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import Image from 'components/Image/Image';
import { getBaseUrl } from '../../../../../services/fetchData';

interface Props {
  building: any;
  addBuildingToFavorites: () => void;
  removeBuildingFromFavorites: () => void;
  isFavorites: boolean;
}
const MainBuildingsListItem = (props: Props) => {
  const theme = useTheme();

  const getMeasureClassBorderColor = (measureClass: string): string => {
    const measure = measureClass.trim().substr(0, 3);
    if (measure === 'TK0') {
      return '#cccccc';
    }
    if (measure === 'TK1') {
      return '#ffcb4f';
    }
    if (measure === 'TK2') {
      return '#ff5912';
    }
    if (measure === 'TK3') {
      return '#cc0033';
    }

    return '#ffffff';
  };
  const handleAddBuildingToFavorites = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    props.addBuildingToFavorites();
  };
  const handleRemoveBuildingFromFavorites = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    props.removeBuildingFromFavorites();
  };
  const handleFavoritesClick = props.isFavorites ? handleRemoveBuildingFromFavorites : handleAddBuildingToFavorites;
  return (
    <Paper square style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
      <StyledListItemWrap>
        <Grid container style={{ marginBottom: 12 }}>
          <Grid item xs={12}>
            <StyledImageWrap color="#eeeeee">
              <Image src={`${getBaseUrl()}/multimedia/image/${props.building.thumbnail_id}`} />
            </StyledImageWrap>
            <StyledTypography fontWeight="700">
              {(props.building['designations.designation_type_id_value'] &&
                props.building['designations.designation_type_id_value'][0]) ||
                props.building.artifact_name}
            </StyledTypography>
            <Grid container>
              <StyledTypography component="span" fontSize="0.85rem" style={{ paddingRight: 8 }}>
                {props.building.collection_id_value}
              </StyledTypography>
              <StyledTypography
                component="span"
                color="rgba(0, 0, 0, 0.74)"
                fontSize="0.85rem"
                fontFamily="Akkurat Mono"
              >
                {props.building.identifier}
              </StyledTypography>
            </Grid>
          </Grid>
          <StyledFavoriteButton color="inherit" size="small" onClick={handleFavoritesClick}>
            {props.isFavorites ? <StarIcon /> : <StarBorderIcon />}
          </StyledFavoriteButton>
          <StyledTypography color="#9f9f9f" variant="caption" align="right" component="p">
            {formatDistance(props.building.updated_at.dd_date, new Date(), { addSuffix: true, locale: nb })}
          </StyledTypography>
        </Grid>
      </StyledListItemWrap>
      <StyledIconsContainer>
        <Box>
          <StyledLabelBox component="div" borderColor="#434343" backGroundColor={theme.palette.secondary.white}>
            <StyledTypography color="#434343" variant="subtitle2" fontSize="0.7rem">
              {props.building['reg_level.reg_level_type_id_value'] || 'Objekt'}
            </StyledTypography>
          </StyledLabelBox>
        </Box>

        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            width: 'auto',
            position: 'relative',
            top: 0,
          }}
        >
          <Grid item style={{ marginLeft: 2 }}>
            {props.building.measure_class_type && (
              <StyledPriorityBox backgroundColor={getMeasureClassBorderColor(props.building.measure_class_type)}>
                <StyledTypography fontFamily="Akkurat Mono" fontSize="0.65rem">
                  {props.building.measure_class_type.substr(0, 3)}
                </StyledTypography>
              </StyledPriorityBox>
            )}
          </Grid>
        </Grid>
      </StyledIconsContainer>
    </Paper>
  );
};

const StyledImageWrap = styled(({ color, ...props }) => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
  margin-bottom: 10px;
  width: 180px;
  height: 180px;

  @media (min-width: 971px) and (max-width: 1100px) {
    width: 140px;
    height: 140px;
  }
  @media (min-width: 831px) and (max-width: 970px) {
    width: 110px;
    height: 110px;
  }
  @media (min-width: 706px) and (max-width: 830px) {
    width: 96px;
    height: 96px;
  }
  @media (min-width: 530px) and (max-width: 705px) {
    width: 180px;
    height: 180px;
  }
  @media (min-width: 473px) and (max-width: 529px) {
    width: 150px;
    height: 150px;
  }
  @media (min-width: 408px) and (max-width: 472px) {
    width: 96px;
    height: 96px;
  }
  @media (min-width: 344px) and (max-width: 407px) {
    width: 96px;
    height: 96px;
  }
  @media (max-width: 343px) {
    width: 80px;
    height: 80px;
  }
`;
const StyledLabelBox = styled(({ borderColor, backGroundColor, ...props }) => <Box {...props} />)`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid ${props => props.borderColor || '#ffffff'};
  border-radius: 18px;
  min-height: 22px;
  padding: 0 6px;
  background-color: ${props => props.backGroundColor || 'transparent'};
`;
const StyledPriorityBox = styled(({ backgroundColor, ...props }) => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 22px;
  border: ${props => `1px solid ${props.backgroundColor}`};
`;
const StyledTypography = styled(({ color, fontSize, fontFamily, fontWeight, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    ${props => props.fontFamily && `font-family: "${props.fontFamily}"`}
  }
  &.MuiTypography-caption {
    position: absolute;
    top: 38px;
    right: 10px;
    padding-left: 15px;
    max-width: 56px;
    line-height: 1;
  }
`;
const StyledListItemWrap = styled(Box)`
  position: relative;
  padding: 14px 10px 16px;

  @media (min-width: 1200px) {
    padding: 20px 20px 20px;
  }
`;
const StyledIconsContainer = styled.div`
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  padding: 0 10px 6px;

  @media (min-width: 1200px) {
    padding: 0 20px 20px;
  }
`;
const StyledFavoriteButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 8px;
    right: 3px;
  }
`;

export default MainBuildingsListItem;
