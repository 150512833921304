import React, { createContext, useContext, useState, useEffect } from 'react';
import storage from 'helpers/storage';
import { fetchUserData, fetchUserDataDetailed } from 'services/fetchData';
import { UserData, UserDataDetailed } from 'types/UserType';

const defaultAuthValues = {
  authToken: '',
  setAuthToken: () => null,
  setAuthRememberedToken: () => null,
  userData: null,
  userDataDetailed: null,
  removeAuthToken: () => null,
  removeAuthRememberedToken: () => null,
};

interface Props {
  children: React.ReactNode;
}

interface AuthProps {
  authToken: string;
  setAuthToken: (token: string) => void;
  setAuthRememberedToken: (token: string) => void;
  userData: UserData | null;
  userDataDetailed: UserDataDetailed | null;
  removeAuthToken: () => void;
  removeAuthRememberedToken: () => void;
}

export const AuthContext = createContext<AuthProps>(defaultAuthValues);

export function useAuth() {
  return useContext(AuthContext);
}

const { Provider } = AuthContext;

const AuthProvider: React.FC<Props> = ({ children }) => {
  // AUTH STATE
  const existingToken = storage.load('authToken') || storage.sessionLoad('authToken');
  const [authToken, setAuthToken] = useState(existingToken);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userDataDetailed, setUserDataDetailed] = useState<UserDataDetailed | null>(null);

  const setToken = (token: string) => {
    storage.sessionSave('authToken', token);
    setAuthToken(token);
  };
  const setRememberedToken = (token: string) => {
    storage.save('authToken', token);
    setAuthToken(token);
  };
  const removeToken = () => {
    storage.sessionRemove('authToken');
    setAuthToken(null);
  };
  const removeRememberedToken = () => {
    storage.remove('authToken');
    setAuthToken(null);
  };

  // FETCH USER DATA
  useEffect(() => {
    if (!authToken) return;
    (async () => {
      try {
        const user: UserData = await fetchUserData();
        setUserData(user);

        const dataDetailed: UserDataDetailed = await fetchUserDataDetailed(user.user.artifact_id);
        // // CASE FOR TESTING COLLECTION ROLE RESTRICTION
        // const updatedCollectionRights = dataDetailed.collection_rights.map(item => ({
        //   ...item,
        //   collection_role_id_value: 'LESE',
        // }));
        // setUserDataDetailed({ ...dataDetailed, collection_rights: updatedCollectionRights });
        // //
        setUserDataDetailed(dataDetailed);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [authToken]);

  return (
    <Provider
      value={{
        authToken,
        setAuthToken: setToken,
        setAuthRememberedToken: setRememberedToken,
        userData,
        userDataDetailed,
        removeAuthToken: removeToken,
        removeAuthRememberedToken: removeRememberedToken,
      }}
    >
      {children}
    </Provider>
  );
};

export default AuthProvider;
