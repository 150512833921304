export enum ActionTypes {
  UPDATE_TASK_PANEL_STATE = 'UPDATE_TASK_PANEL_STATE',
  RESET_TASK_STATE = 'RESET_TASK_STATE',
  PREPARE_EDITED = 'PREPARE_EDITED',
}

export type Action = {
  type: ActionTypes;
  payload?: Partial<State>;
};

export interface State {
  taskTitle: string;
  taskDescription: string;
  taskPriority: {
    title: string;
    value: string;
    id: string;
  };
  taskStatus: string;
  startDate: Date | null;
  doneDate: Date | null;
  deadlineDate: Date | null;
  isStartDatePickerOpen: boolean;
  isDoneDatePickerOpen: boolean;
  isDeadlineDatePickerOpen: boolean;
  responsibleActors: any[];
  isActorsPanelOpen: boolean;
  media: any[];
  isMediaPanelOpen: boolean;
  selectedMedia: any | null;
  annotationEvents: any[];
  checklist: any[];
  isChecklistPanelOpen: boolean;
  isFirstSaved: boolean;
  imagesData: any[];
  attachments: any[];
  attachmentsData: any[];
  videos: any[];
  videosData: any[];
  isEditChanged: boolean;
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_TASK_PANEL_STATE:
      const isEditChanged = action.payload?.isEditChanged === false ? (action.payload?.isEditChanged as boolean) : true;
      return {
        ...state,
        ...action.payload,
        isEditChanged,
      };
    case ActionTypes.PREPARE_EDITED:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.RESET_TASK_STATE:
      return {
        ...state,
        taskTitle: '',
        taskDescription: '',
        taskPriority: { title: '', id: '', value: '' },
        taskStatus: '',
        startDate: null,
        doneDate: null,
        deadlineDate: null,
        responsibleActors: [],
        checklist: [],
        isFirstSaved: false,
        imagesData: [],
        attachments: [],
        attachmentsData: [],
        videos: [],
        videosData: [],
        isEditChanged: false,
      };

    default:
      return state;
  }
};
