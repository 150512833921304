import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';

import { getBaseUrl } from 'services/fetchData';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import AsyncFormInput from '../../shared/AsyncFormInput/AsyncFormInput';

interface Props {
  description: string;
  comments: string;
  stateClass: { title: string; id: string };
  handleDescriptionChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleCommentsChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleStateClassChange: (value: string, options: any[]) => void;
  disabledConditions: boolean;
}

const ChildrenSummaryForm = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  // STATE CLASS OPTIONS STATE
  const [stateClassOptionsOpen, setStateClassOptionsOpen] = useState(false);
  const [stateClassOptions, setStateClassOptions] = useState<any[]>([]);
  const stateClassLoading = stateClassOptionsOpen && stateClassOptions.length === 0;

  // REASON OPTIONS FETCHING AND SET
  useEffect(() => {
    let active = true;
    if (!stateClassLoading) {
      return undefined;
    }
    (async () => {
      try {
        const response = await fetch(`${getBaseUrl()}/search/json`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: { object_type: 'ct_89', '-marked': true },
            rows: 200,
          }),
        });
        if (!response.ok) throw new Error('something went wrong!');
        const options = await response.json();

        if (active) {
          setStateClassOptions(
            options.artifacts.map((option: any) => ({ title: option.artifact_name, id: option.id })),
          );
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [stateClassLoading]);
  useEffect(() => {
    if (!stateClassOptionsOpen) {
      setStateClassOptions([]);
    }
  }, [stateClassOptionsOpen]);
  // SETTING REASON AUTOCOMPLETE OPEN STATUS
  const setStateClassOpenStatus = (status: boolean) => {
    setStateClassOptionsOpen(status);
  };

  return (
    <Grid container direction="column" spacing={mediaMatch ? 2 : 4}>
      <Grid item>
        <AsyncFormInput
          value={props.stateClass.title}
          handleChange={props.handleStateClassChange}
          options={stateClassOptions}
          label="Overordnet tistandsklasse"
          placeholder="Velg tistandsklasse"
          loading={stateClassLoading}
          open={stateClassOptionsOpen}
          setOpenStatus={setStateClassOpenStatus}
          disabled={props.disabledConditions}
          arrowDown
        />
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.description}
          handleChange={props.handleDescriptionChange}
          label="Beskrivelse av tilstand"
          disabled={props.disabledConditions}
        />
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.comments}
          handleChange={props.handleCommentsChange}
          label="Method for tilstandsvurdering"
          disabled={props.disabledConditions}
        />
      </Grid>
    </Grid>
  );
};

export default ChildrenSummaryForm;
