import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AppBar, Toolbar, IconButton, Box, Typography, Slide, Grid, Chip, useMediaQuery } from '@material-ui/core';

// icons
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// mocks
// import filterOptionsMock from '../../mocks/filterOptionsMock';

// types
import { EnabledFiltersType } from 'types/Mocks/EnabledFiltersType';

import FilterBottomButton from '../../components/FilterBottomButton/FilterBottomButton';
import { fetchFilterValues } from 'services/fetchData';
import { useState } from 'react';

interface Props {
  objectType: { name: string; artifact_ids: string[] };
  isFilterOpen: boolean;
  enabledFilters: EnabledFiltersType;
  handleCloseFilter: () => void;
  handleToggleEnableFilter: (option: string, filterGroup: string) => void;
  searchResultsLength: number;
  enabledFiltersAmount: string;
}

interface FilterValue {
  name: string;
  id: string;
}

interface FilterValues {
  samling: FilterValue[];
  objekttype: FilterValue[];
  tilstandsklasse: FilterValue[];
  formal: FilterValue[];
  prioritet: FilterValue[];
  status: FilterValue[];
  type: FilterValue[];
  harMarkeringer: FilterValue[];
}

const FilterSection = (props: Props) => {
  const mediaMatch = useMediaQuery('(min-width:768px)');
  const halfTabletMatch = useMediaQuery('(max-width:705px)');

  const [filterValues, setFilterValues] = useState<FilterValues>();
  useEffect(() => {
    const getFilterValues = async () => {
      const data = (await fetchFilterValues(props.objectType.artifact_ids)) as FilterValues;
      setFilterValues(data);
    };
    getFilterValues();
  }, [props.objectType.artifact_ids]);

  const getTitleText = (amount: string) => {
    if (Number(amount) === 0 || mediaMatch) return 'Velg filter';
    if (amount.endsWith('1')) return `${amount} filter`;

    return `${amount} filtre`;
  };

  return (
    <Slide in={props.isFilterOpen} direction="down">
      <StyledSectionWrap>
        <StyledAppBar position="static" color="transparent" elevation={1}>
          <StyledToolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={props.handleCloseFilter}
              style={{ marginRight: 12 }}
            >
              {mediaMatch ? <CloseIcon /> : <ArrowBackIcon />}
            </IconButton>
            <Typography>{getTitleText(props.enabledFiltersAmount)}</Typography>
          </StyledToolbar>
        </StyledAppBar>
        <StyledOptionsSection>
          <Grid container direction="column" spacing={1}>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Samling
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.samling.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'samling')}
                      variant="outlined"
                      background={props.enabledFilters.samling.filters.includes(option.id) ? '#fcfcfc' : 'transparent'}
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Objekttype
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.objekttype.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'objekttype')}
                      variant="outlined"
                      background={
                        props.enabledFilters.objekttype.filters.includes(option.id) ? '#fcfcfc' : 'transparent'
                      }
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Tilstandsklasse
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.tilstandsklasse.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'tilstandsklasse')}
                      variant="outlined"
                      background={
                        props.enabledFilters.tilstandsklasse.filters.includes(option.id) ? '#fcfcfc' : 'transparent'
                      }
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Formål
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.formal.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'formal')}
                      variant="outlined"
                      background={props.enabledFilters.formal.filters.includes(option.id) ? '#fcfcfc' : 'transparent'}
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Prioritet
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.prioritet.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'prioritet')}
                      variant="outlined"
                      background={
                        props.enabledFilters.prioritet.filters.includes(option.id) ? '#fcfcfc' : 'transparent'
                      }
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Status
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.status.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'status')}
                      variant="outlined"
                      background={props.enabledFilters.status.filters.includes(option.id) ? '#fcfcfc' : 'transparent'}
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Type
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.type.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'type')}
                      variant="outlined"
                      background={props.enabledFilters.type.filters.includes(option.id) ? '#fcfcfc' : 'transparent'}
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledTypography color="rgba(0, 0, 0, 0.5);" fontSize="0.75rem" fontWeight="700">
                  Har markeringer
                </StyledTypography>
              </Grid>
              <Grid item style={{ overflowX: 'auto', width: '100%' }}>
                <StyledChipsWrap>
                  {filterValues?.harMarkeringer.map(option => (
                    <StyledChip
                      key={option.id}
                      label={option.name}
                      onClick={() => props.handleToggleEnableFilter(option.id, 'harMarkeringer')}
                      variant="outlined"
                      background={
                        props.enabledFilters.harMarkeringer.filters.includes(option.id) ? '#fcfcfc' : 'transparent'
                      }
                      disableRipple
                    />
                  ))}
                </StyledChipsWrap>
              </Grid>
            </Grid>
          </Grid>
        </StyledOptionsSection>
        {halfTabletMatch && (
          <FilterBottomButton resultsAmount={props.searchResultsLength} onClick={props.handleCloseFilter} />
        )}
      </StyledSectionWrap>
    </Slide>
  );
};

const StyledSectionWrap = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 1102;
  background-color: #eeeeee;

  @media (min-width: 706px) {
    position: static;
    min-height: 130px;
    background-color: transparent;
  }
`;

const StyledAppBar = styled(({ color, ...props }) => <AppBar {...props} />)`
  &.MuiAppBar-root {
    background-color: ${props => props.color};
    height: 56px;
    color: rgba(0, 0, 0, 0.87);

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
      height: 72px;
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  height: 56px;
  &.MuiToolbar-regular {
    @media (min-width: 543px) {
      min-height: 56px;
    }
  }
  &.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 706px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  @media (min-width: 706px) {
    height: 72px;
  }
`;
const StyledTypography = styled(({ color, fontWeight, fontSize, ...props }) => <Typography {...props} />)`
  color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    margin-left: 6px;
  }
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
  }
`;
const StyledOptionsSection = styled(Box)`
  padding: 24px 26px 16px;

  @media (max-width: 705px) {
    padding: 24px 0px 16px 8px;
  }
`;
const StyledChip = styled(({ background, borderColor, ...props }) => <Chip {...props} />)`
  &.MuiChip-root {
    margin-right: 8px;
    background-color: ${props => props.background};
    border-color: ${props => props.borderColor};
  }

  &&.MuiChip-clickable:hover,
  &&.MuiChip-clickable:focus {
    background-color: ${props => props.background};
  }
`;
const StyledChipsWrap = styled(Box)`
  white-space: nowrap;
  padding-bottom: 8px;
`;

export default FilterSection;
