import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

interface Props {
  open: boolean;
  modalText: string;
  handleClose: () => void;
  handleConfirmClick: () => void;
}

const Modal: React.FC<Props> = props => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth>
      <DialogContent>
        <DialogContentText>{props.modalText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleConfirmClick} color="secondary">
          Ja
        </Button>
        <Button onClick={props.handleClose} color="secondary">
          Nei
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
