import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Typography, IconButton, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { useAuth } from 'context/Auth';
import { getIsUserCanDeleteObject } from 'services/roles';

// icons
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { getBaseUrl } from '../../../../services/fetchData';

interface Props {
  videoData: any;
  video: any;
  disabledConditions: boolean;
  handleDeleteVideo: () => void;
  onOpenVideo: () => void;
}

const VideoItem: React.FC<Props> = props => {
  const theme = useTheme();
  const { userData } = useAuth();
  const [isImageError, setImageError] = useState(false);
  const handleError = () => {
    setImageError(true);
  };

  return (
    <Box>
      <StyledImageWrap color={theme.palette.secondary.white}>
        <Box style={{ position: 'relative', width: 'auto', height: '100%' }}>
          {isImageError ? null : (
            <>
              {props.videoData?.thumbnail_id && (
                <StyledAttachmentImg
                  src={`${getBaseUrl()}/multimedia/image/${props.videoData?.thumbnail_id}`}
                  onError={handleError}
                />
              )}
            </>
          )}
        </Box>
        {!props.disabledConditions &&
          getIsUserCanDeleteObject(
            userData?.user.role ?? '',
            userData?.user.artifact_id ?? '',
            props.videoData?.created_by_id,
          ) && (
            <StyledIconDeleteButton size="small" onClick={props.handleDeleteVideo}>
              <RemoveCircleIcon width="16px" height="16px" />
            </StyledIconDeleteButton>
          )}
        <StyledIconEditButton onClick={props.onOpenVideo}>
          <PlayArrowIcon />
        </StyledIconEditButton>
      </StyledImageWrap>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <StyledCaption variant="subtitle1">
            {props.videoData?.file_name || props.videoData?.artifact_name}
          </StyledCaption>
        </Grid>
      </Grid>
    </Box>
  );
};

// STYLED COMPONENTS
const StyledAttachmentImg = styled.img`
  display: block;
  height: 100%;
  max-width: 100%;
`;

const StyledImageWrap = styled(({ color, ...props }) => <Box {...props} />)`
  position: relative;
  padding: 36px 10px;
  background-color: ${props => props.color};
  height: 15vw;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    height: 40vw;
  }
  @media (max-width: 420px) {
    height: 30vw;
  }
`;

const StyledCaption = styled(props => <Typography {...props} />)`
  &.MuiTypography-root {
    padding: 10px 0;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledIconDeleteButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.54);
  }
`;
const StyledIconEditButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    opacity: 0.3;
    color: rgba(0, 0, 0, 0.54);
    transition: 0.3s all linear;

    &:hover {
      opacity: 1;
      background-color: #ffffff;
    }
  }
`;

export default VideoItem;
