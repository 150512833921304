import React, { useMemo } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Grid, Box, Typography, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// helpers
import { getCurrentIcon } from 'helpers/getCurrentIcon';
import { useAuth } from 'context/Auth';
import {
  getIsUserCanDeleteObject,
  getIsDeviationCanBeDeletedByStatus,
  getIsUserCanCreateOrEditObjectsByCollectionRole,
  getIsUserCanCreateOrEditObjectByAcl,
} from 'services/roles';

// icons
import { ReactComponent as EditIcon } from 'assets/icons/edit-24px.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';
import BuildIcon from '@material-ui/icons/Build';
import LinkIcon from '@material-ui/icons/Link';

import MeasureClassLabel from 'components/MeasureClassLabel/MeasureClassLabel';

interface Props {
  item: any;
  onOpen: () => void;
  onOpenWithEdit: () => void;
  onDelete: () => void;
  buildingCollectionId: string;
  buildingAcl: { restrictions: any[] };
}

const ListsItem = (props: Props) => {
  const { userData, userDataDetailed } = useAuth();
  const theme = useTheme();
  const handleOpenWithEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onOpenWithEdit();
  };
  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onDelete();
  };
  const getIsDocumentCanBeDeleted = () => {
    if (props.item.artifact_type_id_value === 'Avvik') {
      return (
        (getIsDeviationCanBeDeletedByStatus(props.item['status.status_type_id_value']) &&
          getIsUserCanDeleteObject(
            userData?.user.role ?? '',
            userData?.user.artifact_id ?? '',
            props.item.created_by_id,
          )) ||
        false
      );
    }
    return getIsUserCanDeleteObject(
      userData?.user.role ?? '',
      userData?.user.artifact_id ?? '',
      props.item.created_by_id,
    );
  };
  const userCollectionRights = useMemo(() => userDataDetailed?.collection_rights ?? [], [userDataDetailed]);
  const isUserCanCreateAndEdit =
    getIsUserCanCreateOrEditObjectsByCollectionRole(props.buildingCollectionId, userCollectionRights) &&
    getIsUserCanCreateOrEditObjectByAcl(props.buildingAcl);

  const renderEditAndDeleteButtons = () => (
    <Grid container justify="flex-end" alignItems="center" spacing={1} style={{ width: 'auto' }} wrap="nowrap">
      {isUserCanCreateAndEdit && (
        <Grid item>
          <IconButton edge="end" color="inherit" onClick={handleOpenWithEdit}>
            <EditIcon width="16px" height="16px" />
          </IconButton>
        </Grid>
      )}
      {getIsDocumentCanBeDeleted() && (
        <Grid item>
          <IconButton edge="end" color="inherit" onClick={handleDelete}>
            <DeleteIcon width="16px" height="16px" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );

  const renderLinkedObjects = () => {
    return (
      <Grid container alignItems="center" spacing={2} style={{ marginTop: 12 }}>
        {props.item['contexts.context_artifact_id_value'].map((value: any, idx: number) => {
          let contextName = value.split(':')[2] ? value.split(':')[2].trimLeft() : value[0];
          let contextId = value.match(': (.*):') === null ? value.match(': (.*)')[1] : value.match(': (.*):')[1];

          if (!value.includes('Bygning:')) {
            const nameArray = value.split(':');
            contextName = `${nameArray[0]}:${nameArray[1]}`;
            contextId = nameArray[2] || props.item['contexts.context_artifact_id'][idx] || 'id';
          }
          return (
            <Grid key={idx} item>
              <Grid container alignItems="center" spacing={1}>
                <StyledGrid item>
                  <LinkIcon />
                </StyledGrid>
                <StyledGrid item>
                  <StyledTypography fontWeight="700" fontSize="0.85rem">
                    {contextName}
                  </StyledTypography>
                </StyledGrid>
                <StyledGrid item>
                  <StyledTypography
                    variant="caption"
                    fontSize="0.85rem"
                    color="textSecondary"
                    fontFamily="Akkurat Mono"
                  >
                    {contextId}
                  </StyledTypography>
                </StyledGrid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  /**
   * Method used to display the correct date-values for the different object types listed.
   */
  function itemDate(obj: any) {
    if (obj.object_type === 'BuildingTreatmentEvent') {
      let toD = '';
      const fromD = format(obj['timespan.from_date'].dd_date, 'dd.MM.yyyy');
      if (obj['timespan.to_date']) {
        toD = format(obj['timespan.to_date'].dd_date, 'dd.MM.yyyy');
      }
      return toD !== '' ? `${fromD} til ${toD}` : `${fromD}`;
    } else if (obj.object_type === 'task') {
      return obj.dealine_date ? format(obj.deadline_date.dd_date, 'dd.MM.yyyy') : null;
    } else {
      return format(obj.updated_at.dd_date, 'dd.MM.yyyy');
    }
  }

  return (
    <StyledListItemWrap boxShadow={1} onClick={props.onOpen}>
      <Grid container wrap="nowrap" direction="column">
        <Grid container wrap="nowrap">
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <StyledFlexBox margin="0 12px 0 0">
              {props.item.artifact_type_id_value === 'Avvik' &&
                props.item['priority.priority_type_id_value'] &&
                getCurrentIcon(props.item['priority.priority_type_id_value'])}

              {props.item.artifact_type_id_value === 'Tilstandsvurdering bygning' && (
                <MeasureClassLabel
                  label={
                    props.item['condition.measure_class_type_id_value'] &&
                    props.item['condition.measure_class_type_id_value'] !== null
                      ? props.item['condition.measure_class_type_id_value'].substr(0, 3)
                      : 'N/A'
                  }
                />
              )}

              {props.item.artifact_type_id_value === 'Bygningsarbeid' && <BuildIcon />}
              {props.item.object_type === 'task' &&
                props.item['priority.priority_type_id_value'] &&
                getCurrentIcon(props.item['priority.priority_type_id_value'])}
            </StyledFlexBox>
            <Grid item container>
              <Grid item style={{ marginBottom: 4 }}>
                <StyledTypography fontWeight="700">
                  {props.item.artifact_type_id_value === 'Avvik' &&
                    (props.item['title.title'] || props.item.artifact_name || props.item.artifact_type_id_value)}

                  {props.item.artifact_type_id_value === 'Tilstandsvurdering bygning' &&
                    (`${props.item['purpose.purpose_type_id_value'] || ''} ${format(
                      props.item.created_at.dd_date,
                      'yyyy',
                    )}` ||
                      props.item.artifact_name)}

                  {props.item.artifact_type_id_value === 'Bygningsarbeid' &&
                    (props.item?.title?.title ?? props.item.artifact_name)}

                  {props.item.object_type === 'task' && (props.item.artifact_name || props.item.artifact_type_id_value)}
                </StyledTypography>
              </Grid>
              <Grid item container wrap="nowrap">
                <StyledDescriptionWrap>
                  {(props.item.artifact_type_id_value === 'Avvik' || props.item.object_type === 'task') && (
                    <StyledLabelBox
                      component="div"
                      borderColor={theme.palette.secondary.greyWhite}
                      backGroundColor={theme.palette.secondary.white}
                    >
                      <StyledTypography color="textSecondary" variant="subtitle2" fontSize="0.75rem">
                        {props.item['status.status_type_id_value'] || 'Status'}
                      </StyledTypography>
                    </StyledLabelBox>
                  )}

                  <StyledTypography fontSize="0.85rem" whiteSpace="nowrap" cropped color="textSecondary">
                    {props.item.artifact_type_id_value === 'Avvik' &&
                      (props.item['damage.description.damage_description']
                        ? props.item['damage.description.damage_description']
                        : '<beskrivelse mangler>')}
                    {props.item.artifact_type_id_value === 'Tilstandsvurdering bygning' &&
                      (props.item['condition.measure_class_type_id_value']
                        ? props.item['condition.measure_class_type_id_value'].substr(5)
                        : '<beskrivelse mangler>')}
                    {props.item.artifact_type_id_value === 'Bygningsarbeid' && props.item['description.description']}
                    {props.item.object_type === 'task' &&
                      ((props.item['actors.actor_id_value'] &&
                        props.item['actors.actor_id_value'].length !== 0 &&
                        props.item['actors.actor_id_value'].join(', ')) ||
                        '<ansvarlig ikke oppgitt>')}
                  </StyledTypography>
                  <StyledGrid item>
                    <StyledTypography variant="caption" fontSize="0.85rem" color="textSecondary">
                      {itemDate(props.item)}
                    </StyledTypography>
                  </StyledGrid>
                </StyledDescriptionWrap>
              </Grid>
            </Grid>
          </Grid>
          {renderEditAndDeleteButtons()}
        </Grid>
        {props.item.object_type === 'task' && renderLinkedObjects()}
      </Grid>
    </StyledListItemWrap>
  );
};

const StyledLabelBox = styled(({ borderColor, backGroundColor, ...props }) => <Box {...props} />)`
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.borderColor || '#ffffff'};
  border-radius: 18px;
  min-height: 22px;
  padding: 0 12px;
  background-color: ${props => props.backGroundColor || 'transparent'};
  margin-right: 6px;
`;
const StyledTypography = styled(({ fontSize, fontWeight, fontFamily, whiteSpace, cropped, ...props }) => (
  <Typography {...props} />
))`
  // color: ${props => props.color || 'default'};
  &.MuiTypography-root {
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    ${props => props.fontFamily && 'font-family: ' + props.fontFamily}
    ${props => props.whiteSpace && 'white-space: ' + props.whiteSpace}
    ${props =>
      props.cropped &&
      `
      max-width: 60vw;
      width: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 6px;
    `}
  }
  @media (max-width: 767px) {
    &.MuiTypography-root {
      ${props => props.cropped && 'max-width: 50vw'} 
    }
  }
  @media (max-width: 600px) {
    &.MuiTypography-root {
      ${props => props.cropped && 'max-width: 40vw'} 
    }
  }
  @media (max-width: 495px) {
    &.MuiTypography-root {
      ${props => props.cropped && 'max-width: 20vw'} 
    }
  }
`;
const StyledListItemWrap = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 12px 18px;
  background-color: #ffffff;
  margin-bottom: 1px;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 20px 20px 20px;
  }
`;
const StyledGrid = styled(props => <Grid {...props} />)`
  &.MuiGrid-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledFlexBox = styled(({ margin, ...props }) => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.margin && `margin: ${props.margin}`}
`;
const StyledDescriptionWrap = styled(Box)`
  display: flex;
  align-items: center;
`;

ListsItem.defaultProps = {
  onOpenEditReport: () => null,
};

export default ListsItem;
