export enum ActionTypes {
  UPDATE_DEVIATION_PANEL_STATE = 'UPDATE_DEVIATION_PANEL_STATE',
  RESET_DEVIATION_STATE = 'RESET_DEVIATION_STATE',
  PREPARE_EDITED = 'PREPARE_EDITED',
}

export type Action = {
  type: ActionTypes;
  payload?: Partial<State>;
};

export interface State {
  // expanded: string | false;
  deviationTitle: string;
  observeDate: Date | null;
  isObserveDatePickerOpen: boolean;
  deviationDescription: string;
  deviationPriority: {
    title: string;
    value: string;
    id: string;
    priority_type_id?: string;
    priority_type_id_value?: string;
  };
  deviationStatus: string;
  buildingPartInput: {
    title: string;
    id: string;
  };
  deviationLocation: string;
  deviationType: {
    title: string;
    id: string;
  };
  media: any[];
  isMediaPanelOpen: boolean;
  selectedMedia: any | null;
  annotationEvents: any[];
  deviationReason: string;
  occureDate: Date | null;
  isOccureDatePickerOpen: boolean;
  backgroundShowStatus: string;
  isFirstSaved: boolean;
  imagesData: any[];
  attachments: any[];
  attachmentsData: any[];
  videos: any[];
  videosData: any[];
  isEditChanged: boolean;
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_DEVIATION_PANEL_STATE:
      const isEditChanged = action.payload?.isEditChanged === false ? (action.payload?.isEditChanged as boolean) : true;
      return {
        ...state,
        ...action.payload,
        isEditChanged,
      };
    case ActionTypes.PREPARE_EDITED:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.RESET_DEVIATION_STATE:
      return {
        ...state,
        deviationTitle: '',
        observeDate: null,
        deviationDescription: '',
        deviationPriority: { title: '', id: '', value: '', priority_type_id: '', priority_type_id_value: '' },
        deviationStatus: '',
        buildingPartInput: { title: '', id: '' },
        deviationLocation: '',
        deviationType: { title: '', id: '' },
        deviationReason: '',
        occureDate: null,
        isFirstSaved: false,
        imagesData: [],
        attachments: [],
        attachmentsData: [],
        videos: [],
        videosData: [],
        isEditChanged: false,
      };

    default:
      return state;
  }
};
