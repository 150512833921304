export const buildingTemplateMock = {
  artifact_id: '',
  identifier: '',
  collection_id_value: '',
  collection_id: '',
  designations: [{ designation_type_id_value: '' }],
  measure_class_type: '',
  protection_type_id_value: '',
  hazards: [
    {
      hazard_type_id: '',
      hazard_type_id_value: '',
    },
  ],
  images: [],
  has_images: '',
  updated_at: {
    dd_date: 1,
  },
  $$acl: {
    restrictions: [],
  },
};
