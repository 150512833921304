import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Typography, IconButton, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import throttle from 'lodash/throttle';

import { useAuth } from 'context/Auth';
import { getIsUserCanDeleteObject } from 'services/roles';

// icons
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { ReactComponent as EditIcon } from 'assets/icons/edit-24px.svg';
import { getBaseUrl } from '../../../../services/fetchData';

interface Props {
  onOpenMediaPanel: () => void;
  attachment: any;
  name: string;
  annotations: any[];
  handleDeleteImage: () => void;
  disabledConditions: boolean;
  imageData: any;
}

const MediaAttachmentItem: React.FC<Props> = props => {
  const theme = useTheme();
  const { userData } = useAuth();
  const imageWrapRef = useRef<HTMLImageElement>(null);
  const [annotations, setAnnotations] = useState<any>(props.annotations);
  const [windowSize, setWindowSize] = useState(0);

  const imageWrap: any = imageWrapRef.current;

  useEffect(() => {
    const imageWrap: any = imageWrapRef.current;
    window.addEventListener('resize', throttledHandleResize);
    imageWrap.onload = function() {
      setAnnotations([...props.annotations]);
    };
    return () => window.removeEventListener('resize', throttledHandleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setAnnotations([...annotations]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);
  useEffect(() => {
    setAnnotations(props.annotations);
  }, [props.annotations]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWindowSize(windowSize);
  };
  const throttledHandleResize = throttle(handleResize, 500);

  const calcWidth = (item: any, wrapperWidth: number) => {
    const x1InPx = item.x1 * wrapperWidth;
    const x2InPx = item.x2 * wrapperWidth;

    return x2InPx - x1InPx;
  };
  const calcHeight = (item: any, wrapperHeight: number) => {
    const y1InPx = item.y1 * wrapperHeight;
    const y2InPx = item.y2 * wrapperHeight;

    return y2InPx - y1InPx;
  };

  return (
    <Box>
      <StyledImageWrap color={theme.palette.secondary.white}>
        <Box style={{ position: 'relative', width: 'auto', height: '100%' }}>
          {annotations.map((annot: any) => {
            return (
              <StyledBadge
                key={annot.uuid}
                color={annot.color}
                borderRadius={annot.a_type === 'square' ? '0' : '50%'}
                top={annot.y1 * (imageWrap && imageWrap.height) || 0}
                left={annot.x1 * (imageWrap && imageWrap.width) || 0}
                width={calcWidth(annot, (imageWrap && imageWrap.width) || 0)}
                height={calcHeight(annot, (imageWrap && imageWrap.height) || 0)}
              />
            );
          })}
          <StyledAttachmentImg
            src={`${getBaseUrl()}/multimedia/image/${props.attachment.image_id}?size=medium`}
            ref={imageWrapRef}
          />
        </Box>
        {!props.disabledConditions &&
          getIsUserCanDeleteObject(
            userData?.user.role ?? '',
            userData?.user.artifact_id ?? '',
            props.imageData?.created_by_id,
          ) && (
            <StyledIconDeleteButton size="small" onClick={props.handleDeleteImage}>
              <RemoveCircleIcon width="16px" height="16px" />
            </StyledIconDeleteButton>
          )}
        {!props.disabledConditions && (
          <StyledIconEditButton onClick={props.onOpenMediaPanel}>
            <EditIcon />
          </StyledIconEditButton>
        )}
      </StyledImageWrap>
      <Grid container alignItems="center" spacing={1}>
        {annotations.length > 0 && (
          <Grid item>
            <StyledLabelBox>
              <Typography variant="subtitle1">{annotations.length}</Typography>
            </StyledLabelBox>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

// STYLED COMPONENTS
const StyledAttachmentImg = styled.img`
  display: block;
  height: 100%;
  max-width: 100%;
`;

const StyledImageWrap = styled(({ color, ...props }) => <Box {...props} />)`
  position: relative;
  padding: 36px 10px;
  background-color: ${props => props.color};
  height: 15vw;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    height: 40vw;
  }
  @media (max-width: 420px) {
    height: 30vw;
  }
`;

const StyledIconDeleteButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.54);
  }
`;
const StyledIconEditButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    opacity: 0.3;
    color: rgba(0, 0, 0, 0.54);
    transition: 0.3s all linear;

    &:hover {
      opacity: 1;
      background-color: #ffffff;
    }
  }
`;
const StyledLabelBox = styled(Box)`
  border-radius: 50%;
  background-color: #6aacf4;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  margin-top: -2px;
`;
const StyledBadge = styled(({ color, borderRadius, top, left, width, height, ...props }) => <Box {...props} />)`
  border: 2px solid ${props => props.color};
  background-color: transparent;
  border-radius: ${props => props.borderRadius};
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

export default MediaAttachmentItem;
