import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

import { fetchVideoContent } from 'services/fetchData';

interface Props {
  videoId: string;
}

interface VideoSources {
  [keyof: string]: string;
}

const renderSources = (sources: VideoSources | null) => (
  <>{sources && Object.values(sources).map(source => <source key={source} src={source} />)}</>
);

const VideoSlide: React.FC<Props> = ({ videoId }) => {
  const [videoSources, setVideoSources] = useState<VideoSources | null>(null);

  useEffect(() => {
    (async () => {
      const sources = await fetchVideoContent(videoId);
      setVideoSources(sources);
    })();
  }, [videoId]);
  return (
    <StyledVideoWrap>
      <StyledVideo autoPlay controls>
        {renderSources(videoSources)}
      </StyledVideo>
    </StyledVideoWrap>
  );
};

const StyledVideoWrap = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const StyledVideo = styled.video`
  display: block;
  height: auto;
  width: 700px;

  @media (max-width: 996px) {
    width: 600px;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;

export default VideoSlide;
