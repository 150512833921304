import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Grid,
  FormHelperText,
  Typography,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  useMediaQuery,
} from '@material-ui/core';

// mocks
// import { priorityMock } from '../../mocks/priorityMock';
import { deviationStatusMock } from '../../mocks/statusesMock';
import { deviationTypesMock } from '../../mocks/deviationTypesMock';

import FormTextArea from '../../shared/FormTextArea/FormTextArea';
import FormInput from '../../shared/FormInput/FormInput';
import Datepicker from '../../shared/Datepicker/Datepicker';
import PriorityLabelBox from '../../shared/PriorityLabelBox/PriorityLabelBox';
import { getPrioritiesList, PriorityListItem } from 'services/PrioritiesList';

interface Props {
  deviationTitle: string;
  deviationDescription: string;
  isObserveDatePickerOpen: boolean;
  observeDate: Date | null;
  deviationPriority: PriorityListItem;
  deviationStatus: string;
  handleDeviationTitleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleDeviationDescriptionChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleObserveDateChange: (date: Date | null) => void;
  handleOpenObserveDatePicker: () => void;
  handleCloseObserveDatePicker: () => void;
  handleDeviationPriorityChange: (deviation: PriorityListItem) => void;
  handleDeviationStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabledConditions: boolean;
  deviationType: string;
  handleDeviationTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DeviationTitleForm = (props: Props) => {
  const tabletMatch = !useMediaQuery('(min-width: 768px)');
  const [priorities, setPriorities] = useState<Array<PriorityListItem>>([]);

  const usingTablet = () => {
    return tabletMatch;
  };

  useEffect(() => {
    async function getPriorityItems() {
      setPriorities(await getPrioritiesList());
    }
    getPriorityItems();
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormInput
          value={props.deviationTitle}
          handleChange={props.handleDeviationTitleChange}
          disabled={props.disabledConditions}
          label="Tittel"
        />
        <FormHelperText>Kort beskrivelse av avviket</FormHelperText>
      </Grid>
      <Grid item>
        <Datepicker
          value={props.observeDate}
          handleChange={props.handleObserveDateChange}
          open={props.isObserveDatePickerOpen}
          onOpen={props.handleOpenObserveDatePicker}
          onClose={props.handleCloseObserveDatePicker}
          label="Dato observert"
          disabled={props.disabledConditions}
        />
      </Grid>
      <Grid item>
        <FormTextArea
          value={props.deviationDescription}
          handleChange={props.handleDeviationDescriptionChange}
          label="Detaljer"
          disabled={props.disabledConditions}
        />
        <FormHelperText>Evt. utfyllende beskrivelse av avviket</FormHelperText>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" style={{ marginBottom: 10 }}>
          Prioritet
        </Typography>
        <Grid container wrap="nowrap" spacing={1} style={{ maxWidth: 500 }}>
          {priorities?.map((deviation: PriorityListItem) => {
            return (
              <Grid item xs={4} key={deviation.id}>
                <PriorityLabelBox
                  deviation={deviation}
                  handleDeviationPriorityChange={props.handleDeviationPriorityChange}
                  priority={props.deviationPriority}
                  disabledConditions={props.disabledConditions}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <RadioGroup
            name="deviation-status"
            value={props.deviationStatus}
            onChange={props.handleDeviationStatusChange}
            row
          >
            {deviationStatusMock.map(({ value, id }) => (
              <StyledFormControlLabel key={id} value={value} control={<Radio color="primary" />} label={value} />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl component="fieldset" disabled={props.disabledConditions}>
          <FormLabel component="legend" style={{ marginBottom: 4 }}>
            Type avvik
          </FormLabel>
          <div style={usingTablet() ? { paddingBottom: '50px' } : undefined}>
            <RadioGroup name="avvik-type" value={props.deviationType} onChange={props.handleDeviationTypeChange}>
              {deviationTypesMock.map(deviationType => {
                return (
                  <StyledFormControlLabel
                    key={deviationType.title}
                    value={deviationType.title}
                    control={<Radio color="primary" />}
                    label={deviationType.title}
                  />
                );
              })}
            </RadioGroup>
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: #000000;
  }
`;

export default DeviationTitleForm;
