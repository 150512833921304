import React from 'react';
import { Grid } from '@material-ui/core';
import { PeopleTypes } from '../../ActorsPanel/ActorsPanel';
import WorkTitleDragItem from '../WorkTitleDragItem/WorkTitleDragItem';
import ActorsPanel from '../../ActorsPanel/ActorsPanel';

interface Props {
  dragItems: any[];
  isActorsPanelOpen: boolean;
  selectedEdit: string | null;
  handleToggleActorsPanel: (id: string) => void;
  handleSaveActorsPanel: (actorData: { person: { title: string; id: string } }) => void;
  moveDragItem: (dragIndex: any, hoverIndex: any) => void;
  emptyDragItem: {
    resp_actor_item_actor_id: string;
    resp_actor_item_actor_id_value: string;
    responsibilities: string;
  };
  handleCloseActorsPanel: () => void;
  handleDeleteDrag: (id: string) => void;
  disabledConditions: boolean;
}

const WorkTitleDrags: React.FC<Props> = props => {
  const selectedDragItem = props.dragItems.find(
    item =>
      props.selectedEdit !== null &&
      ((item.no && item.no === props.selectedEdit) ||
        (item.resp_actor_item_actor_id && item.resp_actor_item_actor_id === props.selectedEdit)),
  );

  const getPreparedObject = () => {
    return {
      actor_id_value:
        selectedDragItem?.resp_actor_item_actor_id_value || props.emptyDragItem.resp_actor_item_actor_id_value,
      actor_id: selectedDragItem?.resp_actor_item_actor_id || props.emptyDragItem.resp_actor_item_actor_id,
    };
  };

  return (
    <>
      {props.dragItems.length > 0 && (
        <Grid item container direction="column" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {props.dragItems.map(
            ({ resp_actor_item_actor_id_value, resp_actor_item_actor_id, responsibilities, _destroy, no }, index) => {
              if (_destroy) return null;

              const editPanelRenderRule =
                selectedDragItem &&
                props.isActorsPanelOpen &&
                ((selectedDragItem.no && selectedDragItem.no === no) ||
                  (selectedDragItem.resp_actor_item_actor_id !== null &&
                    selectedDragItem.resp_actor_item_actor_id === resp_actor_item_actor_id));

              // render actor panel instead drag if editing
              if (editPanelRenderRule) {
                return (
                  <Grid key={no || resp_actor_item_actor_id} item style={{ position: 'relative' }}>
                    <ActorsPanel
                      isEditing={!!props.selectedEdit}
                      drag={getPreparedObject()}
                      onSaveActorsPanel={props.handleSaveActorsPanel}
                      handleCloseActorsPanel={props.handleCloseActorsPanel}
                      dragsLength={props.dragItems.length}
                      type={PeopleTypes.ACTORS}
                    />
                  </Grid>
                );
              }
              return (
                <WorkTitleDragItem
                  key={no || resp_actor_item_actor_id}
                  id={no || resp_actor_item_actor_id}
                  index={index}
                  title={resp_actor_item_actor_id_value}
                  handleEdit={() => props.handleToggleActorsPanel(no === null ? resp_actor_item_actor_id : no)}
                  onDeleteDrag={() => props.handleDeleteDrag(no === null ? resp_actor_item_actor_id : no)}
                  moveCard={props.moveDragItem}
                  disabledConditions={props.disabledConditions}
                  responsibilities={responsibilities}
                />
              );
            },
          )}
        </Grid>
      )}
      {/* Render actor panel when new report */}
      {props.isActorsPanelOpen && !selectedDragItem && (
        <Grid item style={{ position: 'relative' }}>
          <ActorsPanel
            isEditing={!!props.selectedEdit}
            drag={selectedDragItem || props.emptyDragItem}
            onSaveActorsPanel={props.handleSaveActorsPanel}
            handleCloseActorsPanel={props.handleCloseActorsPanel}
            dragsLength={props.dragItems.length}
            type={PeopleTypes.ACTORS}
          />
        </Grid>
      )}
    </>
  );
};

export default WorkTitleDrags;
