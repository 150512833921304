import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button, Typography, Grid, Box } from '@material-ui/core';

interface Props {
  label: string;
  icon?: ReactElement;
  onClick?: () => void;
  fullWidth?: boolean;
  padding?: string;
}

const PrimaryButton: React.FC<Props> = props => {
  return (
    <StyledButton
      // color="#0069BD"
      color="#2c2f38"
      fullWidth={props.fullWidth || false}
      onClick={props.onClick || (() => null)}
      padding={props.padding}
    >
      <Grid container alignItems="center" justify="center" direction="row" wrap="nowrap">
        {props.icon && (
          <Grid item style={{ paddingRight: 10 }}>
            <StyledBox>{props.icon}</StyledBox>
          </Grid>
        )}
        <Grid item>
          <Typography style={{ marginBottom: -2, fontSize: props.padding && '0.75rem' }} variant="subtitle2">
            {props.label}
          </Typography>
        </Grid>
      </Grid>
    </StyledButton>
  );
};

const StyledButton = styled(({ color, padding, ...props }) => <Button {...props} />)`
  &.MuiButton-root {
    background-color: ${props => props.color};
    padding: ${props => props.padding || '16px 24px 16px 24px'};
    color: #ffffff;
    border-radius: 0;
    text-transform: none;
    justify-content: flex-start;
    min-height: 56px;

    &:hover {
      background-color: #4d5261;
    }

    @media (max-width: 597px) {
      padding: 12px;
    }
  }
`;

const StyledBox = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PrimaryButton;
