import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';

import FormInput from '../../../shared/FormInput/FormInput';
import NumberedFormInput from '../../../shared/NumberedFormInput/NumberedFormInput';

interface Props {
  actionSuggestion: string;
  handleActionSuggestionChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  actionDetailsShowStatus: string;
  handleActionDetailsShowStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  actionCost: number | null;
  handleActionCostChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  actionTimings: number | null;
  handleActionTimingsChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const AssessmentActionForm: React.FC<Props> = props => (
  <StyledFormWrapper>
    <Typography variant="h4" style={{ paddingBottom: 18 }}>
      {props.actionSuggestion || 'Tiltak'}
    </Typography>
    <Grid container spacing={3} direction="column">
      <Grid item xs={12}>
        <FormInput
          value={props.actionSuggestion}
          handleChange={props.handleActionSuggestionChange}
          label="Forslag til tiltak"
        />
        <FormHelperText>Hva skal til for å løse problemet?</FormHelperText>
      </Grid>
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Vet du hva tiltaket vil koste, eller hvor lang tid det tar?</FormLabel>
          <RadioGroup
            name="action-details-show"
            value={props.actionDetailsShowStatus}
            onChange={props.handleActionDetailsShowStatusChange}
            row
          >
            <StyledFormControlLabel value="yes" control={<Radio color="primary" />} label="Ja" />
            <StyledFormControlLabel value="no" control={<Radio color="primary" />} label="Nei" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {props.actionDetailsShowStatus === 'yes' && (
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <NumberedFormInput
              value={props.actionCost}
              handleChange={props.handleActionCostChange}
              label="Estimert kostnad"
            />
            <FormHelperText>Estimert kostnad i norske krone</FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <NumberedFormInput
              value={props.actionTimings}
              handleChange={props.handleActionTimingsChange}
              label="Estimert tidsbruk"
            />
            <FormHelperText>Hvor lang tid tar det å gjennomføre tiltaket?</FormHelperText>
          </Grid>
        </Grid>
      )}
    </Grid>
  </StyledFormWrapper>
);

const StyledFormWrapper = styled(Paper)`
  &.MuiPaper-root {
    padding: 18px;
    background-color: #ffffff;
  }

  @media (max-width: 767px) {
    &.MuiPaper-root {
      padding: 16px;
    }
  }
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: #000000;
  }
`;

export default AssessmentActionForm;
